import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ParcelaComponent } from './parcela.component';

@NgModule({
  declarations: [ParcelaComponent],
  imports: [CommonModule, IonicModule],
  exports: [ParcelaComponent],
})
export class ParcelaModule {


}
