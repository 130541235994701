import { IsBoolean, IsEnum, IsString } from 'class-validator';
import { CondicoesGerais } from '../condicoes-gerais.enum';
import { TubulacaoTipo } from './tubulacao-tipo.enum';

export class InstalacaoHidrossanitarias {
  @IsBoolean()
  ligacaoRedePublica: boolean;

  @IsBoolean()
  possuiCaixaGordura: boolean;

  @IsEnum(TubulacaoTipo)
  tubulacao: TubulacaoTipo;

  @IsBoolean()
  eletrodutosDeteriorados: boolean;

  @IsBoolean()
  loucasMetaisCompletos: boolean;

  @IsString()
  especifique: string;

  @IsEnum(CondicoesGerais)
  condicoesGerais: CondicoesGerais;
}
