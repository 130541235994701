export enum StatusUpload {
  /**
   * Upload pendente.
   */
  PENDENTE = 'PENDENTE',
  /**
   *  Upload foi enviado.
   */
  ENVIADO = 'ENVIADO',
  /**
   * Upload aprovado.
   */
  APROVADO = 'APROVADO',
  /**
   * Upload rejeitado
   */
  REJEITADO = 'REJEITADO'
}
