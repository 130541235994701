export enum Nationality {
  BRASIL = 'Brasil',
  ABECASIA = 'Abecásia',
  AFEGANISTAO = 'Afeganistão',
  AFRICA_DO_SUL = 'África do Sul',
  ALBANIA = 'Albânia',
  ALEMANHA = 'Alemanha',
  ANDORRA = 'Andorra',
  ANGOLA = 'Angola',
  ANTIGUA_E_BARBUDA = 'Antígua e Barbuda',
  ARÁBIA_SAUDITA = 'Arábia Saudita',
  ARGÉLIA = 'Argélia',
  ARGENTINA = 'Argentina',
  ARMÊNIA = 'Armênia',
  AUSTRÁLIA = 'Austrália',
  ÁUSTRIA = 'Áustria',
  AZERBAIJÃO = 'Azerbaijão',
  BAHAMAS = 'Bahamas',
  BAHREIN = 'Bahrein',
  BANGLADESH = 'Bangladesh',
  BARBADOS = 'Barbados',
  BÉLGICA = 'Bélgica',
  BELIZE = 'Belize',
  BENIM = 'Benim',
  BIELORRÚSSIA = 'Bielorrússia',
  BOLÍVIA = 'Bolívia',
  BÓSNIA_E_HERZEGOVINA = 'Bósnia e Herzegovina',
  BOTSWANA = 'Botswana',
  BRUNEI = 'Brunei',
  BULGÁRIA = 'Bulgária',
  BURKINA = 'Burkina',
  BURUNDI = 'Burundi',
  BUTÃO = 'Butão',
  CABO_VERDE = 'Cabo Verde',
  CAMARÕES = 'Camarões',
  CAMBOJA = 'Camboja',
  CANADÁ = 'Canadá',
  CATAR = 'Catar',
  CAZAQUISTÃO = 'Cazaquistão',
  CHADE = 'Chade',
  CHILE = 'Chile',
  CHINA = 'China',
  CHIPRE = 'Chipre',
  CINGAPURA = 'Cingapura',
  COLÔMBIA = 'Colômbia',
  COMORES = 'Comores',
  CONGO = 'Congo',
  COREIA_DO_NORTE = 'Coreia do Norte',
  COREIA_DO_SUL = 'Coreia do Sul',
  COSTA_DO_MARFIM = 'Costa do Marfim',
  COSTA_RICA = 'Costa Rica',
  CROÁCIA = 'Croácia',
  CUBA = 'Cuba',
  DINAMARCA = 'Dinamarca',
  DJIBOUTI = 'Djibouti',
  DOMINICA = 'Dominica',
  EGITO = 'Egito',
  EL_SALVADOR = 'El Salvador',
  EMIRADOS_ÁRABES_UNIDOS = 'Emirados Árabes Unidos',
  EQUADOR = 'Equador',
  ERITREIA = 'Eritreia',
  ESCÓCIA = 'Escócia',
  ESLOVÁQUIA = 'Eslováquia',
  ESLOVÊNIA = 'Eslovênia',
  ESPANHA = 'Espanha',
  ESTADOS_FEDERADOS_DA_MICRONÉSIA = 'Estados Federados da Micronésia',
  ESTADOS_UNIDOS_DA_AMÉRICA = 'Estados Unidos da América',
  ESTÔNIA = 'Estônia',
  ESWATINI = 'Eswatini',
  ETIÓPIA = 'Etiópia',
  FIJI = 'Fiji',
  FILIPINAS = 'Filipinas',
  FINLÂNDIA = 'Finlândia',
  FRANÇA = 'França',
  GABÃO = 'Gabão',
  GÂMBIA = 'Gâmbia',
  GANA = 'Gana',
  GEÓRGIA = 'Geórgia',
  GRANADA = 'Granada',
  GRÉCIA = 'Grécia',
  GUATEMALA = 'Guatemala',
  GUIANA = 'Guiana',
  GUINÉ = 'Guiné',
  GUINÉ_BISSAU = 'Guiné-Bissau',
  GUINÉ_EQUATORIAL = 'Guiné Equatorial',
  HAITI = 'Haiti',
  HOLANDA = 'Holanda',
  HONDURAS = 'Honduras',
  HUNGRIA = 'Hungria',
  IÊMEN = 'Iêmen',
  ÍNDIA = 'Índia',
  INDONÉSIA = 'Indonésia',
  INGLATERRA = 'Inglaterra',
  IRÃ = 'Irã',
  IRAQUE = 'Iraque',
  IRLANDA_DO_NORTE = 'Irlanda do Norte',
  IRLANDA = 'Irlanda',
  ISLÂNDIA = 'Islândia',
  ISRAEL = 'Israel',
  ITÁLIA = 'Itália',
  JAMAICA = 'Jamaica',
  JAPÃO = 'Japão',
  JORDÂNIA = 'Jordânia',
  KIRIBATI = 'Kiribati',
  KOSOVO = 'Kosovo',
  KUWAIT = 'Kuwait',
  LAOS = 'Laos',
  LESOTO = 'Lesoto',
  LETÔNIA = 'Letônia',
  LÍBANO = 'Líbano',
  LIBÉRIA = 'Libéria',
  LÍBIA = 'Líbia',
  LIECHTENSTEIN = 'Liechtenstein',
  LITUÂNIA = 'Lituânia',
  LUXEMBURGO = 'Luxemburgo',
  MACEDÔNIA_DO_NORTE = 'Macedônia do Norte',
  MADAGASCAR = 'Madagascar',
  MALÁSIA = 'Malásia',
  MALAWI = 'Malawi',
  MALDIVAS = 'Maldivas',
  MALI = 'Mali',
  MALTA = 'Malta',
  MARROCOS = 'Marrocos',
  MARSHALL = 'Marshall',
  MAURÍCIA = 'Maurícia',
  MAURITÂNIA = 'Mauritânia',
  MÉXICO = 'México',
  MIANMAR = 'Mianmar',
  MICRONÉSIA = 'Micronésia',
  MOÇAMBIQUE = 'Moçambique',
  MOLDÁVIA = 'Moldávia',
  MÔNACO = 'Mônaco',
  MONGÓLIA = 'Mongólia',
  MONTENEGRO = 'Montenegro',
  NAMÍBIA = 'Namíbia',
  NAURU = 'Nauru',
  NEPAL = 'Nepal',
  NICARÁGUA = 'Nicarágua',
  NÍGER = 'Níger',
  NIGÉRIA = 'Nigéria',
  NORUEGA = 'Noruega',
  NOVA_ZELÂNDIA = 'Nova Zelândia',
  OMÃ = 'Omã',
  OSSÉTIA_DO_SUL = 'Ossétia do Sul',
  PAÍS_DE_GALES = 'País de Gales',
  PALAU = 'Palau',
  PALESTINA = 'Palestina',
  PANAMÁ = 'Panamá',
  PAPUA_NOVA_GUINÉ = 'Papua-Nova Guiné',
  PAQUISTÃO = 'Paquistão',
  PARAGUAI = 'Paraguai',
  PERU = 'Peru',
  POLÔNIA = 'Polônia',
  PORTUGAL = 'Portugal',
  QATAR = 'Qatar',
  QUÊNIA = 'Quênia',
  QUIRGUISTÃO = 'Quirguistão',
  QUIRIBATI = 'Quiribati',
  REINO_UNIDO = 'Reino Unido',
  REPÚBLICA_ÁRABE_SAARAUI_DEMOCRÁTICA = 'República Árabe Saaraui Democrática',
  REPÚBLICA_CENTRO_AFRICANA = 'República Centro-Africana',
  REPÚBLICA_DEMOCRÁTICA_DO_CONGO = 'República Democrática do Congo',
  REPÚBLICA_DO_CONGO = 'República do Congo',
  REPÚBLICA_DOMINICANA = 'República Dominicana',
  REPÚBLICA_TCHECA = 'República Tcheca',
  REPÚBLICA_TURCA_DE_CHIPRE_DO_NORTE = 'República Turca de Chipre do Norte',
  ROMÊNIA = 'Romênia',
  RUANDA = 'Ruanda',
  RÚSSIA = 'Rússia',
  SALOMÃO = 'Salomão',
  SAMOA = 'Samoa',
  SAN_MARINO = 'San Marino',
  SANTA_LÚCIA = 'Santa Lúcia',
  SÃO_CRISTÓVÃO_E_NÉVIS = 'São Cristóvão e Névis',
  SÃO_TOMÉ_E_PRÍNCIPE = 'São Tomé e Príncipe',
  SÃO_VICENTE_E_GRANADINAS = 'São Vicente e Granadinas',
  SENEGAL = 'Senegal',
  SERRA_LEOA = 'Serra Leoa',
  SÉRVIA = 'Sérvia',
  SEYCHELLES = 'Seychelles',
  SINGAPURA = 'Singapura',
  SÍRIA = 'Síria',
  SOMÁLIA = 'Somália',
  SRI_LANKA = 'Sri Lanka',
  SUAZILÂNDIA = 'Suazilândia',
  SUDÃO_DO_SUL = 'Sudão do Sul',
  SUDÃO = 'Sudão',
  SUÉCIA = 'Suécia',
  SUÍÇA = 'Suíça',
  SURINAME = 'Suriname',
  TAILÂNDIA = 'Tailândia',
  TAIWAN = 'Taiwan',
  TAJIQUISTÃO = 'Tajiquistão',
  TANZÂNIA = 'Tanzânia',
  TCHÉQUIA = 'Tchéquia',
  TIMOR_LESTE = 'Timor-Leste',
  TOGO = 'Togo',
  TONGA = 'Tonga',
  TRINIDAD_E_TOBAGO = 'Trinidad e Tobago',
  TUNÍSIA = 'Tunísia',
  TURCOMENISTÃO = 'Turcomenistão',
  TURQUIA = 'Turquia',
  TUVALU = 'Tuvalu',
  UCRÂNIA = 'Ucrânia',
  UGANDA = 'Uganda',
  URUGUAI = 'Uruguai',
  UZBEQUISTÃO = 'Uzbequistão',
  VANUATU = 'Vanuatu',
  VATICANO = 'Vaticano',
  VENEZUELA = 'Venezuela',
  VIETNÃ = 'Vietnã',
  ZÂMBIA = 'Zâmbia',
  ZIMBÁBUE = 'Zimbábue',
}
