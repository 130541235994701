<ion-content class="ion-padding">
    <div class="content-vertical-space-between">
        <div>
            <ion-text class="title title--large">
                {{(documentid !== 'novo' && isEditing) ? 'Alteração' : ' Envio'}} de documento
            </ion-text>
            <div class="ion-padding-top">
                <ion-text class="font-text">
                    Tire foto do documento, de forma que ele fique totalmente legível.
                     Adicione fotos de mais páginas, conforme necessário.
                </ion-text>
            </div>
            <div class="ion-padding-top ion-text-center" *ngIf="documentName">
                <ion-text class="font-text" *ngIf="isAcquisition"><b>{{documentName | PropertyAcquisitionDocumentPipe}}</b></ion-text>
                <ion-text class="font-text" *ngIf="isOwnership"><b>{{documentName | PropertyOwnershipDocumentPipe}}</b></ion-text>
                <ion-text class="font-text" *ngIf="!isAcquisition && !isOwnership"><b>{{documentName}}</b></ion-text>
            </div>
        </div>
        <div>
            <ion-grid class="ion-no-padding ion-no-margin">
                 <ion-row class="ion-text-center">
                    <ion-col *ngIf="base64Image.length > 0">
                        <ion-text class="description-text description-text--small">
                            Pagina {{ selectedIndex + 1 }} de {{ base64Image.length }}
                        </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                   <ion-col class=" ion-justify-content-center" size="2">
                        <div class="ion-text-center" *ngIf="selectedIndex > 0" (click)="backImage()">
                            <ion-icon class="icon" color="primary" name="caret-back-circle-sharp"></ion-icon>
                            <p class="font-text font-text--extra-small ion-text-center">
                                <ion-text color="primary">
                                    <strong>
                                      Voltar<br> página
                                    </strong>
                                </ion-text>
                            </p>
                        </div>
                    </ion-col>
                    <ion-col>
                        <usucampeao-photo [imageUrl]="base64Image[selectedIndex]?.img" class="ion-margin-top" type="document"
                            (setImage)="setImage($event)" (remove)="remove()"></usucampeao-photo>
                    </ion-col>
                    <ion-col   class="align ion-justify-content-center" size="2">
                        <div  class="ion-text-center" (click)="addImage()" *ngIf="selectedIndex+1 === base64Image.length">
                            <ion-icon class="icon" color="primary" name="add-circle"></ion-icon>
                            <p class="font-text font-text--extra-small ion-text-center">
                                <ion-text color="primary">
                                    <strong>
                                      Adicionar<br> página
                                    </strong>
                                </ion-text>
                            </p>
                        </div>
                        <div class="ion-text-center" (click)="nextImage()" *ngIf="selectedIndex+1 < base64Image.length">
                            <ion-icon class="icon" color="primary" name="caret-forward-circle-sharp"></ion-icon>
                            <p class="font-text font-text--extra-small ion-text-center">
                                <ion-text color="primary">
                                    <strong>
                                      Avançar <br>página
                                    </strong>
                                </ion-text>
                            </p>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div>
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col sizeMd="6" sizeLg="3">
                     <p *ngIf="base64Image.length < 1" class="ion-text-center font-text font-text--small">
                            <ion-text>Não possui um documento?</ion-text>
                            <br>
                            <ion-text color="primary" (click)="navigateToJustification()">
                                <strong>
                                  Adicione uma justificativa
                                </strong>
                            </ion-text>
                        </p>

                        <ion-button mode="md" *ngIf="base64Image.length > 0 && !blockAccess" expand="block" (click)="navigateForward()">
                            {{ isEditing ? 'Salvar' : 'Adicionar' }} documento
                        </ion-button>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                    <ion-col sizeMd="6" sizeLg="3">
                        <ion-button fill="outline" mode="md" expand="block" (click)="navigateBack()">
                            Cancelar
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>
    <usucampeao-footer></usucampeao-footer>
</ion-content>
