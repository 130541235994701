import { Type } from 'class-transformer';
import { IsBoolean, IsDateString, IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { Address } from '../address/address.model';
import { UserType } from '../enums';
import { UserStatus } from '../enums/user-status.enum';

export class SellerDto {
  @IsString()
  @IsOptional()
  id?: string;

  @IsEnum(UserStatus)
  @IsOptional()
  status?: UserStatus;

  @IsEnum(UserType)
  @IsOptional()
  type?: UserType;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsOptional()
  accountBalance?: string;

  @IsString()
  @IsOptional()
  currentBalance?: string;

  @IsBoolean()
  @IsOptional()
  isMobile?: boolean;

  @IsString()
  @IsOptional()
  businessName?: string;

  @IsString()
  @IsOptional()
  businessPhone?: string;

  @IsEmail()
  @IsOptional()
  businessEmail?: string;

  @IsString()
  @IsOptional()
  businessWebsite?: string;

  @IsString()
  @IsOptional()
  businessDescription: string;

  @Type(() => Address)
  @IsOptional()
  businessAddress?: Address;

  @IsString()
  @IsNotEmpty()
  firstName: string;

  @IsString()
  @IsNotEmpty()
  lastName: string;

  @IsEmail()
  @IsNotEmpty()
  email: string;

  @IsString()
  @IsNotEmpty()
  document: string;

  @IsString()
  @IsOptional()
  documentOwner?: string;

  @IsString()
  @IsNotEmpty()
  phoneNumber: string;

  @IsDateString()
  @IsNotEmpty()
  birthdate: Date;

  @Type(() => Address)
  @IsNotEmpty()
  address: Address;
}
