export class RgDocument {
  /**
   *  Numero do rg.
   */
  numero: string;
  /**
   * Data de emissão do rg.
   */
  data_emissao?: Date;
  /**
   * Orgão emissor do rg.
   */
  orgao_emissor?: string;
  /**
   * Estado do RG.
   */
  estado?: string;
}
