<div id="print-section" class="c-data-contract__text-terms">
    <div class="title">
        <h1><b>PROCURAÇÃO</b></h1>
    </div>
    <div class="personal-data">
        <table class="contract-table">
            <tr>
                <td>
                    <p>
                        <span style="font-weight: bold;">OUTORGANTE 1:</span> {{ proprietario?.name }},
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>brasileira,</strong> {{ proprietario?.naturalidade || '' }}, portador <strong>CPF nº</strong> {{ proprietario?.cpf }}, e identidade {{ proprietario?.rg?.number || '' }}, domiciliado e residednte à {{ imovel?.address?.street
                        }}, {{ imovel?.address?.number }}, {{imovel?.address?.zipCode}}, {{imovel?.address?.city}} telefone: {{proprietario?.phones?.cellphone}}, E-mail: {{proprietario?.email}}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <span style="font-weight: bold;">OUTORGANTE 2:</span> {{ conjuge?.name }},
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>brasileira,</strong> {{ conjuge?.naturalidade || '' }}, portador <strong>CPF nº</strong> {{ conjuge?.cpf }}, e identidade {{ conjuge?.rg?.number || '' }}, domiciliado e residednte à {{ imovel?.address?.street }}, {{ imovel?.address?.number
                        }}, {{imovel?.address?.zipCode}}, {{imovel?.address?.city}} telefone: {{conjuge?.phones?.cellphone}}, E-mail: {{conjuge?.email}}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <b>OUTORGADO:</b> USU CAMPEAO TECNOLOGIA EM REGULARIZACAO IMOBILIARIA LTDA, devidamente inscrita no CNPJ/MF sob o nº 36.174.049/0001-08, com sede na Rua Cláudio Soares, 72, CJ 617, bairro Pinheiros, CEP 05422-030, na cidade de
                        São Paulo, Estado de São Paulo, e filial a Rua José Rodrigues Pereira 524, Salas 204-208, Condomínio Datasinc, Buritis, Belo Horizonte – MG, com endereço eletrônico contato@usucampeao.com.br, telefone (48) 99926-0832.
                        <b>Poderes:</b> para isoladamente ou em conjunto, representar o(s) outorgante(s) e defender seus interesses, com os poderes especiais para nos termos da Lei nº 13.465/17, propor, requerer e assinar pedidos de averbação de construção,
                        junto ao Município competente, bem como poderes especiais para nos termos da Lei nº 13.465/17, propor, requerer e assinar pedidos de (REURB) “S”/“E”, junto ao município competente, referente ao imóvel localizado no cadastro anexo
                        deste mandato, podendo ainda, apresentar à qualquer órgão da administração pública direta ou indireta e ao CARTÓRIO DEREGISTRO DE IMÓVEIS, o pedido de averbação de construção e o pedido de instauração de processo de REURB de acordo
                        com a legislação pertinente, realizar defesas e recursos, impetrar medidas preventivas ou assecuratórias, transigir, firmar compromissos ou acordos, atualizar, alterar e corrigir cadastro, requerer intimações, solicitar a declaração
                        de existência de edificação, requerer a averbação da construção na matrícula, acompanhar processos em todos os seus termos e instâncias administrativas com o fito do bom e fiel cumprimento do presente mandato, podendo substabelecer
                        com ou sem reserva de iguais poderes e, ainda, usar de todos os meios admitidos em direito, para o bom e fiel cumprimento do presente mandato.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        São Bernardo do Campo, ____ de ________________ de 2022.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        -----------------------------------------------------------------------------------------
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        OUTORGANTE 1
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        -----------------------------------------------------------------------------------------
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        OUTORGANTE 2
                    </p>
                </td>
            </tr>
        </table>
    </div>


    <div class="subscription">
        <div class="table">
            <div class="column">
                <div class="column__infos">
                    São Paulo - Rua Cláudio Soares, 72, CJ 617, Pinheiros, São Paulo – SP CEP 05422-030 – fone: (11) 2537-.9430 – cel. 11-97123-3055 – www.usucampeao.com.br e-mail: MG@usucampeao.com.br UsuCampeão Belo Horizonte – Rua Jose Rodrigues Pereira, 514, 2˚ andar
                    – Ed Datasinc – Buritis – BH/MG
                </div>
            </div>

        </div>
    </div>