import { IsDateString, IsNotEmpty, IsNumber, IsOptional } from 'class-validator';

export class PhaseDto {
  @IsNumber()
  @IsNotEmpty()
  id: number;

  @IsDateString()
  @IsOptional()
  date?: Date;
}
