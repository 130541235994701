export class RelatorioVendasDto {
  quantidades: number[];
  valores: number[];
  datas: Date[];

  constructor() {
    this.quantidades = [];
    this.valores = [];
    this.datas = [];
  }
}
