import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FaseNucleoComponent } from '../../components/fase-nucleo/fase-nucleo.component';

@NgModule({
  declarations: [FaseNucleoComponent],
  imports: [CommonModule, IonicModule],
  providers: [DatePipe],
  exports: [FaseNucleoComponent],
})
export class FaseNucleoModule {



}
