import { Expose } from 'class-transformer';
import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class ProjetoEndereco {
  @Expose()
  @IsString()
  @IsNotEmpty()
  logradouro: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  numero: number;

  @Expose()
  @IsString()
  @IsOptional()
  complemento?: string;
}
