import { Type } from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsDateString,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { Ref } from '../ref/ref.model';
import { CadastroSocialStatus } from './cadastro-social-status.enum';
import { CaracterizacaoDomicilio } from './caracterizacao-domicilio/caracterizacao-domicilio.model';
import { GrupoFamiliar } from './grupo-familiar.model';
import { ImovelCadastroSocial } from './imovel/imovel.model';
import { MoradorCadastroSocial } from './morador/morador.model';
export class CadastroSocial {
  @IsString()
  @IsOptional()
  id: string;

  @Type(() => MoradorCadastroSocial)
  @ValidateNested()
  @IsNotEmpty()
  morador: MoradorCadastroSocial;

  @IsOptional()
  @Type(() => ImovelCadastroSocial)
  imovel: ImovelCadastroSocial;

  @IsArray()
  @IsOptional()
  @Type(() => MoradorCadastroSocial)
  moradoresAdicionais?: MoradorCadastroSocial[];

  @Type(() => GrupoFamiliar)
  @ValidateNested()
  dadosGrupoFamiliar: GrupoFamiliar;

  @IsString()
  @IsOptional()
  quadra: string;

  @IsString()
  @IsOptional()
  lote: string;

  @IsBoolean()
  @IsOptional()
  proprietarioOutroImovel?: boolean;

  @IsBoolean()
  @IsOptional()
  proprietarioOutroImovelNucleo?: boolean;

  @IsBoolean()
  @IsOptional()
  legitimacaoFundiaria?: boolean;

  @IsBoolean()
  @IsOptional()
  concessaoOutroImovel?: boolean;

  @IsBoolean()
  @IsOptional()
  foreiro?: boolean;

  @IsDateString()
  @IsOptional()
  dataCriacao: Date;

  @IsDateString()
  @IsOptional()
  dataCadastramento: Date;

  @IsBoolean()
  @IsOptional()
  declaracaoVeracidadeIdentificacaoImovel: boolean;

  @IsDateString()
  @IsOptional()
  dataDeclaracaoVeracidadeIdentificacaoImovel: Date;

  @Type(() => CaracterizacaoDomicilio)
  caracterizacaoDomicilio: CaracterizacaoDomicilio;

  @Type(() => Ref)
  @ValidateNested()
  usuarioCriacao: Ref;

  @Type(() => Ref)
  @ValidateNested()
  usuarioAtualizacao: Ref;

  @IsEnum(CadastroSocialStatus)
  @IsOptional()
  status: CadastroSocialStatus;

  @IsDateString()
  @IsOptional()
  dataAtualizacao?: Date;
}
