
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./appointment/appointment.module').then((m) => m.AppointmentPageModule),
  },
  {
    path: 'horario',
    loadChildren: () =>
      import('./select-hour/select-hour.module').then((m) => m.SelectHourPageModule),
  },
  {
    path: 'confirmar',
    loadChildren: () =>
      import('./confirm-appointment/confirm-appointment.module').then((m) => m.ConfirmAppointmentPageModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AttendanceValidationPageRoutingModule { }
