/**
 * Enumeration of Atendimento status.
 */
export enum AttendanceStatus {
  /**
   * Blank.
   */
  VAZIO = 'VAZIO',
  /**
   * Started.
   */
  INICIADO = 'INICIADO',
  /**
   * Data collected.
   */
  PREENCHIDO = 'PREENCHIDO',
  /**
   * Contract accepted.
   */
  CONTRATO_ACEITO = 'CONTRATO_ACEITO',
  /**
   * Contract signed.
   */
  CONTRATO_ASSINADO = 'CONTRATO_ASSINADO',
  /**
   * Paid.
   */
  PAGAMENTO_REALIZADO = 'PAGAMENTO_REALIZADO',
  /**
   * Concluded.
   */
  FINALIZADO = 'FINALIZADO',
  /**
   * Cancel.
   */
  CANCELADO = 'CANCELADO',
  /**
   * Withdrawal.
   */
  DESISTENCIA = 'DESISTENCIA',
  /**
   * Not interested.
   */
  SEM_INTERESSE = 'SEM_INTERESSE',
  /**
   * Lead.
   */
  LEAD = 'LEAD'
}
