import { Component, Input } from '@angular/core';
import { CadastroContratoDto, MaritalStatus, MeioPagamento, OwnerDto, PropertyDto, PropertyUsage } from '@usucampeao/lib-reurb-simplificado';


@Component({
  selector: 'usu-declaracao-de-autonomo-e-renda',
  templateUrl: './declaracao-de-autonomo-e-renda.html',
  styleUrls: ['./declaracao-de-autonomo-e-renda.scss'],
})
export class DeclaracaoDeautonomoRendaComponent {
  @Input() cadastro!: CadastroContratoDto;
  @Input() proprietario!: Partial<OwnerDto>;
  @Input() conjuge!: Partial<OwnerDto>;
  @Input() imovel!: Partial<PropertyDto>;


  public get meioPagamento(): typeof MeioPagamento {
    return MeioPagamento
  }

  public get imovelTipo(): typeof PropertyUsage {
    return PropertyUsage
  }

  public get maritalStatus(): typeof MaritalStatus {
    return MaritalStatus
  }



}
