<usucampeao-header [stepNavigation]="true" (navigationEmit)="previousStep.emit()" mode="md" title="Agendamento" backRoute="/">
</usucampeao-header>
<div class='ion-padding-start ion-padding-end'>
    <ion-grid class="ion-no-margin ion-no-padding">
        <ion-row>
            <ion-col>
                <ion-text>
                    <div class="font-text">
                        <p class="ion-no-margin">
                            Você selecionou a seguinte data e horário para visita do agente da prefeitura para a validação presencial dos seus documentos e dados informados.
                        </p>
                        <p>
                            É muito importante estar no imóvel informado na data e horário selecionados para que o seu processo não atrase.
                        </p>
                    </div>
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-no-margin ion-no-padding">
            <ion-col sizeMd="6" sizeLg="3">
                <div class="div-color ion-padding">
                    <ion-text>
                        <h3>
                            <ion-icon name="calendar-sharp" class="ion-padding-end"></ion-icon> <strong>{{selectedDate | date:
                'longDate'}}</strong>
                        </h3>
                        <h3 class="ion-text-left">
                            <ion-icon name="time-sharp" class="ion-padding-end"></ion-icon> <strong>{{selectedDate | date:
                'shortTime'}}</strong>
                        </h3>
                    </ion-text>
                </div>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
            <ion-col sizeMd="6" sizeLg="3">
                <ion-text>
                    <p class="ion-text-left font-text">Confirmar o agendamento?</p>
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
            <ion-col sizeMd="6" sizeLg="3">
                <ion-button mode="md" expand="block" (click)="confirmAppointment()">
                    Continuar
                </ion-button>
                <ion-text>
                    <p class="ion-text-center ion-padding font-text">Agende a validação presencial dos documentos.<br> Em caso de dúvidas, <a (click)="presentModalContact()"><b>entre em contato.</b></a></p>
                </ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
    <usucampeao-footer></usucampeao-footer>
</div>