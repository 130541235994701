<ion-content class="ion-padding" scrollY="false">
    <div class="content-vertical-space-between">
        <div>
            <ion-text class="title">Ponto de apoio</ion-text>
        </div>
        <div>
            <ion-text class="font-text font-text--medium">
                O trailer da USUCAMPEÃO está localizado no seguinte local:
                <p class="ion-padding-top">
                    <strong>Rua Inácio Monteiro, 10.000</strong>
                </p>
            </ion-text>
        </div>
        <div>
            <ion-grid class="ion-no-padding">
                <ion-row>
                    <ion-col></ion-col>
                    <ion-col>
                        <ion-button fill="clear" (click)="dismiss()">Entendi</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>
</ion-content>
