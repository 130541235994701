import { Expose, plainToInstance } from 'class-transformer';
import { Bs2BoletoConectarDto } from '../boleto';
import { Bs2IntegracaoCriarDto } from './bs2-integracao-criar.dto';

export class Bs2IntegracaoCriarCompletoDto extends Bs2IntegracaoCriarDto {
  @Expose()
  bs2Id: string;

  @Expose()
  bs2Numero: number;

  @Expose()
  valorPago?: number;

  @Expose()
  dataPagamento?: Date;

  /**
   * Cria uma nova instância de Bs2IntegracaoCriarCompletoDto
   * @param props DTO de Bs2IntegracaoCriarCompletoDto
   * @returns nova instância de Bs2IntegracaoCriarCompletoDto
   */
  static from(props: Bs2BoletoConectarDto): Bs2IntegracaoCriarCompletoDto {
    return plainToInstance(Bs2IntegracaoCriarCompletoDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
