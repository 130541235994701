import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import bugsnag from '@bugsnag/js';
import { LoadingController, ViewWillLeave } from '@ionic/angular';
import { ElementBase, InputMode, Textbox, toFormGroup } from '@usucampeao/ui-mobile';
import * as firebase from 'firebase/app';
import { ToastService } from '../../../services/toast.service';
import { AuthQuery } from '../state/auth.query';
import { AuthService } from '../state/auth.service';
import { WindowsService } from '../state/windows.service';

@Component({
  selector: 'usucampeao-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
})
export class LoginPage implements OnInit, ViewWillLeave {
  fieldForm: ElementBase<string>[] | null = [];
  form$!: FormGroup;
  isLoading$ = this.authQuery.selectLoading();
  public windowRef: any;
  isLogin = false;
  public showCodePage = false;

  constructor(
    private authQuery: AuthQuery,
    private authService: AuthService,
    private loadingController: LoadingController,
    private toastService: ToastService,
    public winref: WindowsService
  ) { }

  ngOnInit() {
    this.fieldForm = this.getForm();
    this.form$ = toFormGroup(this.getForm() as ElementBase<string>[]);
    firebase.auth().useDeviceLanguage();

    this.windowRef = this.winref.win;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        'size': 'invisible',
      }
    );
    this.windowRef.recaptchaVerifier.render();
  }

  ionViewWillLeave(): void {
    this.showCodePage = false;
  }

  public async submit(): Promise<void> {
    this.form$.markAllAsTouched();
    if (this.form$.invalid) {
      return;
    }

    const code = this.windowRef.recaptchaVerifier;
    const phone = this.form$?.get('phoneNumber')?.value;
    if (!phone) {
      return;
    }
    const phoneNumber = '+55' + phone.replace(/\D/g, '');

    const loading = await this.loadingController.create();
    await loading.present();

    try {
      const recaptchaResponse = await this.authService.login({
        phoneNumber,
        code,
      });
      this.windowRef.confirmationResult = recaptchaResponse;

      this.winref.set(this.windowRef);
      this.winref.setCode(code);
      this.showCodePage = true;
      this.authService.updateUserState({ phone: phone });

    } catch (error) {
      bugsnag.notify('Login - Erro atualizar login', error);
      await this.toastService.erro('Não foi possível fazer o login. Tente novamente mais tarde.');
    }
    await loading.dismiss();
  }

  getForm() {
    const phoneform: ElementBase<string>[] = [
      new Textbox({
        key: 'phoneNumber',
        type: 'tel',
        mask: 'telefone',
        label: 'Telefone celular',
        inputMode: InputMode.PHONE,
        validators: [Validators.required, Validators.minLength(15), Validators.maxLength(15)]
      }),
    ];
    return phoneform;
  }

  backLoginPage() {
    this.showCodePage = false
  }
}
