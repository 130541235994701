import { IsDate, IsOptional } from 'class-validator';
import {
  CreateDateColumn,
  UpdateDateColumn
} from 'typeorm';
export class BaseEntity {
  @CreateDateColumn({ type: 'timestamptz' })
  @IsDate()
  @IsOptional()
  data_criacao: Date;

  @UpdateDateColumn({ type: 'timestamptz' })
  @IsDate()
  @IsOptional()
  data_atualizacao: Date;
}
