import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { filter, map } from 'rxjs/operators';
import { AuthQuery } from '../../pages/auth/state/auth.query';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'usucampeao-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() backRoute!: string;
  @Input() title!: string;
  @Input() setPadding = false;
  @Input() stepNavigation = false;
  @Output() navigationEmit = new EventEmitter<void>();

  image = '';
  defaultImage = '../../../assets/person-circle-outline.svg';
  constructor(
    private navigationService: NavigationService,
    public authQuery: AuthQuery,
    private menu: MenuController,
  ) { }

  public openMenu(): void {
    this.menu.open('menu');
  }

  public backButton(): void {
    if (this.stepNavigation) {
      this.navigationEmit.emit();
    } else {
      this.navigationService.goBack();
    }
  }

  public get userPhoto() {
    return this.authQuery.photoId$
      .pipe(
        filter(id => !!id),
        map(id => `/users/my-photo/${id}`),
      );
  }
}
