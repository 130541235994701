<ion-content>
  <usucampeao-header mode="md" backRoute=".." title="Enviar Cadastro"> </usucampeao-header>
  <div class="ion-padding-start ion-padding-end">
    <ion-text>
      <p class="ion-no-padding font-text">
        O cadastro preenchido para o imóvel identificado acima será enviado para pré-análise por nossa equipe técnica.
      </p>
    </ion-text>
    <ion-grid class="ion-no-padding ion-no-margin">
      <div class="ion-padding ion-margin-bottom div-color">
        <ion-row>
          <ion-col>
            <ion-text class="title"> {{ projeto?.nome }} </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="font-text">
            <ion-icon name="map-outline" class="ion-padding-end"></ion-icon>
            <ion-text
              class="description-text description-text--medium"
              *ngIf="!(cadastro?.blockId || cadastro?.lotId)"
            >
              <ion-skeleton-text animated style="width: 30%; height: 0.875rem; display: inline-block">
              </ion-skeleton-text>
            </ion-text>
            <ion-text *ngIf="(cadastro?.blockId || cadastro?.lotId)">
              Quadra {{cadastro?.blockId}} Lote {{cadastro?.lotId}}
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="font-text">
            <ion-icon name="location-outline" class="ion-padding-end"></ion-icon>
            <ion-text
              class="description-text description-text--medium"
              *ngIf="!(cadastro?.address?.street || cadastro?.address?.number )"
            >
              <ion-skeleton-text animated style="width: 30%; height: 0.875rem; display: inline-block">
              </ion-skeleton-text>
            </ion-text>
            <ion-text> {{cadastro?.address?.street}}, {{ cadastro?.address?.number }} </ion-text>
          </ion-col>
        </ion-row>
      </div>
      <ion-row>
        <ion-col>
          <ion-text class="font-text">
            <p>
              Durante esta etapa, nosso time verificará as informações e os documentos fornecidos, indicando se alguma
              alteração será necessária para o prosseguimento do processo. Por conta disso, não será possível realizar
              nenhuma modificação no cadastro após o seu envio.
            </p>

            <p>
              Se estiver tudo certo, a próxima etapa é o agendamento da visita de validação presencial, para confirmação
              da localização do imóvel e vistoria dos documentos enviados.
            </p>
            <p>
              Para marcar o agendamento, você precisará realizar o pagamento da parcela de entrada ou do processo
              completo. Para podermos organizar essa próxima etapa, nos indique como gostaria de realizar o pagamento do
              serviço.
            </p>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-no-padding ion-justify-content-center ion-padding-top" *ngIf="projeto?.averbacao">
        <ion-col sizeMd="6">
          <ion-checkbox mode="md" [(ngModel)]="comAverbacao" (ngModelChange)="selecionarTabelaPreco()"></ion-checkbox>
          <ion-text class="font-text font-text--small line-height">
            Adicionar a averbação.
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="tabelaPreco">
        <ion-col>
          <form mode="md" [formGroup]="form">
            <div>
              <div>
                <usucampeao-build-form *ngFor="let field of formPayment$" [field]="field" [form]="form">
                </usucampeao-build-form>
              </div>
              <div>
                <usucampeao-build-form *ngFor="let field of formNumberInstallments$" [field]="field" [form]="form">
                </usucampeao-build-form>
              </div>
              <div *ngIf="hasCardDueDate">
                <usucampeao-build-form *ngFor="let field of formCardInstallments$" [field]="field" [form]="form">
                </usucampeao-build-form>
              </div>
              <div *ngIf="showDueDate">
                <usucampeao-build-form *ngFor="let field of formDueDate" [field]="field" [form]="form">
                </usucampeao-build-form>
              </div>
            </div>
          </form>
        </ion-col>
      </ion-row>
      <ion-row class="div-color-padding-top" *ngIf="pagamentoSelecionado">
        <ion-col class="ion-padding-start">
          <ion-text class="description-text">
            <span class="ion-padding-start">entrada de</span>
          </ion-text>
        </ion-col>
        <ion-col>
          <ion-text>
            <span class="title">{{ pagamentoSelecionado?.valorAto | currency:'BRL' }}</span>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="div-color-padding" *ngIf="pagamentoSelecionado">
        <ion-col class="ion-padding-start">
          <ion-text class="description-text">
            <span class="ion-padding-start">{{ pagamentoSelecionado?.parcela | tabelaPrecoParcela }} de</span>
          </ion-text>
        </ion-col>
        <ion-col>
          <ion-text>
            <span class="title">{{ pagamentoSelecionado?.valorParcela | currency:'BRL' }}</span>
          </ion-text>
        </ion-col>
        <hr />
      </ion-row>
      <ion-row class="div-color-padding-bottom" *ngIf="pagamentoSelecionado">
        <ion-col class="ion-padding-start">
          <ion-text class="description-text">
            <span class="ion-padding-start">total</span>
          </ion-text>
        </ion-col>
        <ion-col>
          <ion-text>
            <span class="title">{{ pagamentoSelecionado?.valorTotal | currency:'BRL' }}</span>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-text class="font-text">
            Não se preocupe, você somente será cobrado após seu cadastro ser pré-aprovado!
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-no-padding ion-justify-content-center ion-padding-top">
        <ion-col sizeMd="6">
          <ion-checkbox mode="md" [(ngModel)]="acceptTerms"></ion-checkbox>
          <ion-text class="font-text font-text--small line-height">
            Estou ciente de que, ao clicar aqui, estou realizando a contratação da empresa USUCAMPEÃO de acordo com as
            condições presentes no <a (click)="presentModalContract()"><b>Contrato de Adesão</b></a>.
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-top">
        <ion-col class="ion-text-center" lines="none">
          <ion-button class="ion-margin-bottom" mode="md" expand="block" (click)="presentModalConfirmaEnvio()">
            Enviar para análise
          </ion-button>
          <ion-button class="ion-margin-bottom" mode="md" fill="outline" expand="block" (click)="goBack()">
            Voltar
          </ion-button>
          <ion-text class="p"> Se ainda estiver com dúvidas, </ion-text>
          <ion-text>
            <p class="ion-no-margin">
              <a class="ion-no-margin text-link" (click)="presentModalClickHereRequestHelp()">
                clique aqui e solicite ajuda
              </a>
            </p>
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-footer class="ion-text-center ion-padding-top">
      <img src="../../../../assets/imgs/logo-sbc.png" />
    </ion-footer>
  </div>
</ion-content>
