import { Component, Input, OnInit } from '@angular/core';
import { PagamentoListagemDto, PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';
import { addDays, endOfDay, startOfDay } from 'date-fns';

@Component({
  selector: 'usucampeao-parcela',
  templateUrl: './parcela.component.html',
  styleUrls: ['./parcela.component.scss'],
})
export class ParcelaComponent implements OnInit {
  @Input() pagamento: PagamentoListagemDto;
  @Input() home = false;

  private readonly statusPagamentosPendentes = [PagamentoStatus.CRIADO, PagamentoStatus.NAO_PAGO, PagamentoStatus.PROGRAMADO, PagamentoStatus.SOLICITADO_EMISSAO_PAGAMENTO];

  public mostrarStatusPagamento = false;
  public pagamentoAtrasado = false;

  ngOnInit(): void {
    this.configurarStatus();
  }

  private configurarStatus(): void {
    const dataVencimentoSeteDias = endOfDay(addDays(new Date(), 7));
    const dataVenciemento = new Date(this.pagamento.dataVencimento)
    this.mostrarStatusPagamento = this.statusPagamentosPendentes.includes(this.pagamento.status) && dataVenciemento < dataVencimentoSeteDias;
    this.pagamentoAtrasado = this.pagamento.status === PagamentoStatus.NAO_PAGO || dataVenciemento < startOfDay(new Date());
  }

  public get cor() {
    switch (this.pagamento.status) {
      case PagamentoStatus.CRIADO:
        return "medium";
      case PagamentoStatus.EM_ANALISE:
        return "warning";
      case PagamentoStatus.NAO_PAGO:
        return "danger";
      case PagamentoStatus.PAGO:
      case PagamentoStatus.PROGRAMADO:
        return "success";
      default:
        break;
    }
  }

}
