import { Pipe, PipeTransform } from '@angular/core';
import { ProprietaryDocument } from '../models/proprietary-document.enum';

@Pipe({
  name: 'ProprietaryDocument',
})
export class ProprietaryDocumentPipe implements PipeTransform {
  transform(value: ProprietaryDocument): string {
    switch (value) {
      case ProprietaryDocument.RG:
      return 'RG';
      case ProprietaryDocument.MARRIAGE_CERTIFICATE:
      return 'certidão de casamento';
      case ProprietaryDocument.DIVORCE_CERTIFICATE:
      return 'certidão de divórcio';
      case ProprietaryDocument.DEATH_CERTIFICATE:
      return 'certidão de óbito';
      case ProprietaryDocument.CPF:
      return 'CPF';
      case ProprietaryDocument.CNH:
      return 'CNH';
      case ProprietaryDocument.BIRTH_CERTIFICATE:
      return 'certidão de nascimento';

      default:
      return 'Status não cadastrado';
    }
  }
}
