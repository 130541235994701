import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProjetoDetalhesComerciaisDto } from '@usucampeao/lib-reurb-simplificado';
import { Fase } from '../../models/fase';

@Component({
  selector: 'usucampeao-fase-nucleo',
  templateUrl: './fase-nucleo.component.html',
  styleUrls: ['./fase-nucleo.component.scss'],
})
export class FaseNucleoComponent {
  public phases: Fase[] = [];
  @Input() set data(detalhesComerciais: ProjetoDetalhesComerciaisDto) {
    if (detalhesComerciais) {
      this.phases = this.transformData(detalhesComerciais);
    }
  };

  constructor(private datePipe: DatePipe) { }

  public format(date: string) {
    return this.datePipe.transform(date, 'dd/MM/YYYY')
  }

  private transformData(data): Fase[] {
    return [
      {
        id: 1,
        fase: 'Fase 1',
        text: 'Realizar cadastro de imóveis, envio dos documentos na plataforma e ponto de apoio no bairro (escritório móvel)',
        days: `${this.format(data?.cadastroDataInicio)} a  ${this.format(data?.cadastroDataFim)}`,
        obs: `${data?.assembleiaLocal.complemento} ${data?.assembleiaLocal.numero}`,
      },
      {
        id: 2,
        fase: 'Fase 2',
        text: 'Pré-análise dos dados e documentos enviados',
        days: `${this.format(data?.preAnaliseDataInicio)} a  ${this.format(data?.preAnaliseDataFim)}`,
        obs: `Início da emissão de boletos para os clientes, com datas de vencimento entre ${this.format(data?.preAnaliseDataInicio)} a  ${this.format(data?.preAnaliseDataFim)}`,
      },
      {
        id: 3,
        fase: 'Fase 3',
        text: 'Validação dos dados e documentos por técnico da SEHAB',
        days: `${this.format(data?.visitaDataInicio)} a  ${this.format(data?.visitaDataFim)}`,
        obs: 'Inicío das visitas técnicas das assistentes',
      },
      {
        id: 4,
        fase: 'Fase 4',
        text: 'Aprovação e publicação no Diário Oficial',
        days: `${this.format(data?.cartorioDataInicio)} a  ${this.format(data?.cartorioDataFim)}`,

      },
      {
        id: 5,
        fase: 'Fase 5',
        text: 'Titulação do Ocupante no Cartório de Registro de Imóvel ',
        days: `${this.format(data?.prefeituraDataInicio)} a  ${this.format(data?.prefeituraDataFim)}`,

      },
      {
        id: 6,
        fase: 'Fase 6',
        text: 'Entrega pela Secretaria de Habitação',
        days: `${this.format(data?.matriculaDataInicio)} a  ${this.format(data?.matriculaDataFim)}`,

      }
    ];
  }

  ngOnInit(): void {

  }
}
