<ion-content class="ion-padding" scrollY="false">
  <div class="content-vertical-space-between">
    <div>
      <ion-text class="title title--medium">Cadastros encerrados</ion-text>
    </div>
    <div>
      <ion-grid class="ion-no-padding ion-no-margin">
        <ion-row class="ion-padding-bottom">
          <ion-col>
            <ion-text class="font-text">
              Novos cadastros estão indisponíveis após <b>30/01</b>.
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text class="font-text">
              Caso possua um imóvel neste bairro e deseje realizar a sua regularização, entre em contato informando seu
              interesse.
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col></ion-col>
          <ion-col>
            <ion-button fill="clear" (click)="dismiss()">Entendi</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
