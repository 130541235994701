import { Type } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { PayloadNotificacaoDto } from './payload-notificacao.dto';
import { TipoNotificacao } from './tipo-notificacao.enum';

export class CreateNotificacaoDto {
  @Type(() => PayloadNotificacaoDto)
  @IsOptional()
  payload?: PayloadNotificacaoDto;

  @IsString()
  @IsNotEmpty()
  titulo: string;

  @IsString()
  @IsNotEmpty()
  descricao: string;

  @IsEnum(TipoNotificacao)
  @IsNotEmpty()
  tipo: TipoNotificacao;

  @IsString()
  @IsNotEmpty()
  cadastroId: string;

  constructor(
    titulo: string,
    descricao: string,
    tipo: TipoNotificacao,
    cadastroId: string,
    payload?: PayloadNotificacaoDto
  ) {
    this.titulo = titulo;
    this.descricao = descricao;
    this.tipo = tipo;
    this.cadastroId = cadastroId;
    this.payload = payload;
  }
}
