<ion-content>
    <usucampeao-header mode="md" backRoute="/editar-proprietario/proprietario-editar-dados" title="Proprietário">
    </usucampeao-header>
    <div class="ion-padding-start ion-padding-end">
        <ion-grid class="ion-no-padding ion-no-margin" *ngIf="ownerPersonalData?.main">
            <ion-row class="ion-justify-content-center">
                <ion-col sizeXs="12" sizeSm="6">
                    <ion-text>
                        Informe dados básico do {{ ownerPersonalData?.main ? "proprietário do imóvel" : "cônjuge ou companheiro(a)"}}
                    </ion-text>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center" *ngIf="alteracoes && alteracoes.length > 0">
                <ion-col sizeXs="12" sizeSm="6" class="ion-padding-top ion-padding-bottom">
                    <usucampeao-change-alert [changes]='alteracoes'></usucampeao-change-alert>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
                <ion-col sizeXs="12" sizeSm="6">
                    <form mode="md" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div>
                            <div>
                                <usucampeao-build-form *ngFor="let field of formProprietary$" [field]="field" [form]="form">
                                </usucampeao-build-form>
                            </div>
                            <div *ngIf="isOtherProprietaryState">
                                <usucampeao-build-form *ngFor="let field of formProprietaryState$" [field]="field" [form]="form">
                                </usucampeao-build-form>
                            </div>
                            <div>
                                <usucampeao-build-form *ngFor="let field of formMaritalStatus$" [field]="field" [form]="form">
                                </usucampeao-build-form>
                            </div>
                            <div *ngIf="isOtherMaritalStatus">
                                <usucampeao-build-form *ngFor="let field of formPropertyRegime$" [field]="field" [form]="form">
                                </usucampeao-build-form>
                            </div>
                            <div>
                                <usucampeao-build-form *ngFor="let field of formProfission$" [field]="field" [form]="form">
                                </usucampeao-build-form>
                            </div>
                        </div>
                    </form>
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-grid class="ion-no-padding ion-no-margin" *ngIf="!ownerPersonalData?.main">
            <ion-row class="ion-justify-content-center">
                <ion-col sizeXs="12" sizeSm="6">
                    <form mode="md" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div>
                            <div *ngFor="let field of formSpouse$">
                                <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
                            </div>
                            <div *ngIf="isOtherSpouseState">
                                <usucampeao-build-form *ngFor="let field of formSpouseState$" [field]="field" [form]="form">
                                </usucampeao-build-form>
                            </div>
                            <div *ngFor="let field of formSpouseProfission$">
                                <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
                            </div>
                        </div>
                    </form>
                </ion-col>
            </ion-row>
        </ion-grid>
        <usucampeao-footer>
            <div *ngIf="((this.authQuery.isConnectionOnline$ |async))">
                <ion-button *ngIf="!blockAccess" class="ion-margin-bottom" mode="md" expand="block" (click)="onSubmit()">
                    {{ fluxo ? 'Continuar': 'Salvar' }}</ion-button>
            </div>

            <ion-label *ngIf="(this.authQuery.isConnectionOnline$ |async)===false">
                <ion-icon name="cloud-offline-outline" slot="start"></ion-icon>
                Sem internet
            </ion-label>
        </usucampeao-footer>
    </div>
</ion-content>
