<ion-content>
    <usucampeao-header mode="md" backRoute=".." title="Proprietário"></usucampeao-header>
    <div class="ion-padding-start ion-padding-end">
        <ion-grid class="ion-no-padding ion-no-margin">
            <ion-row class="ion-justify-content-center">
                <ion-col sizeXs="12" sizeSm="6">
                    <ion-text *ngIf="isMain">
                        Informe os documentos do proprietário do imóvel
                    </ion-text>
                    <ion-text *ngIf="!isMain">
                        Informe os documentos do cônjuge ou companheiro(a)
                    </ion-text>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center" *ngIf="alteracoes && alteracoes.length > 0">
                <ion-col sizeXs="12" sizeSm="6" class="ion-padding-top ion-padding-bottom">
                    <usucampeao-change-alert [changes]='alteracoes'></usucampeao-change-alert>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
                <ion-col sizeXs="12" sizeSm="6">
                    <form mode="md" (ngSubmit)="onSubmit()">
                        <div>
                            <div *ngFor="let field of form$">
                                <usucampeao-build-form mode="md" [field]="field" [form]="form"></usucampeao-build-form>
                            </div>

                            <div *ngFor="let field of formRg$">
                                <usucampeao-build-form mode="md" [field]="field" [form]="formRg"></usucampeao-build-form>
                            </div>
                        </div>
                    </form>
                </ion-col>
            </ion-row>
        </ion-grid>
        <usucampeao-footer>
            <div *ngIf="((this.authQuery.isConnectionOnline$ |async))">
                <ion-button *ngIf="!blockAccess" class="ion-margin-bottom" mode="md" expand="block" (click)="onSubmit()">
                    {{ fluxo ? 'Continuar': 'Salvar' }}</ion-button>
            </div>
            <ion-label *ngIf="(this.authQuery.isConnectionOnline$ |async)===false">
                <ion-icon name="cloud-offline-outline" slot="start"></ion-icon>
                Sem internet
            </ion-label>
        </usucampeao-footer>
    </div>
</ion-content>