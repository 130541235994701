<ng-container *ngIf="phases.length === 0">
  <div class="fases-nucleo__item-loader ion-margin-top" *ngFor="let item of [0,0,0,0,0,0]">
    <ion-skeleton-text animated style="width: 50px;"></ion-skeleton-text>
    <ion-skeleton-text animated></ion-skeleton-text>
    <ion-skeleton-text animated></ion-skeleton-text>
    <ion-skeleton-text animated style="width: 135px;"></ion-skeleton-text>
    <ion-skeleton-text animated></ion-skeleton-text>
  </div>
</ng-container>

<ion-row class="ion-no-border ion-margin-top ion-justify-content-center" *ngFor="let phase of phases">
    <ion-col class="ion-no-border" sizeXs="12" sizeSm="6">
        <ion-text class="">
            <p class="ion-no-margin description-text description-text--variant">{{ phase.fase }}</p>
            <p class="ion-no-margin">
                <strong>{{ phase.text }}</strong>
            </p>
            <p class="ion-no-margin text-link-fase">{{ phase.days }}</p>
            <p class="ion-no-margin description-text" *ngIf="phase.obs">{{ phase.obs }}</p>
        </ion-text>
    </ion-col>
</ion-row>
