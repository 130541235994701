import { filter, map } from 'rxjs/operators';
import { NotificationsState, NotificationsStore } from './notification.store';
import { Injectable } from "@angular/core";
import { QueryEntity } from '@datorama/akita';
import { StatusNotificacao } from '@usucampeao/lib-reurb-simplificado';

@Injectable({ providedIn: 'root' })
export class NotificationsQuery extends QueryEntity<NotificationsState> {
  public areLoaded$ = this.select(state => {
    return state.loaded;
  });

  public totalNotificacoesNaoLidas$ = this.selectAll()
    .pipe(
      map(notificacoes =>
        notificacoes.filter(notificacao => notificacao.status !== StatusNotificacao.LIDA).length
      )
    );

  constructor(protected store: NotificationsStore) {
    super(store);
  }
}