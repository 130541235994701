import { ContactModule } from './../../../modal/contact/contact.module';


import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ConfirmAppointmentPageRoutingModule } from './confirm-appointment-routing.module';
import { ConfirmAppointmentPage } from './confirm-appointment.page';

@NgModule({
  imports: [
    ConfirmAppointmentPageRoutingModule,
    CommonModule,
    ContactModule,
    FooterModule,
    HeaderModule,
    IonicModule,
  ],
  declarations: [ConfirmAppointmentPage],
  providers: [DatePipe],
  exports: [ConfirmAppointmentPage]
})
export class ConfirmAppointmentPageModule { }
