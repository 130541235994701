import { GraficoDto } from './grafico.dto';
import { RelatorioPagamentoTipo } from './relatorio-pagamento-tipo.enum';

export class RelatorioPagamentoDto {
  dados: GraficoDto<RelatorioPagamentoTipo>[];
  datas: Date[];

  constructor() {
    this.dados = [];
    this.datas = [];
  }
}
