<ion-content class="ion-align-itens-center">
    <div class="content-vertical-space-between" *ngIf="step === 0">
        <usucampeao-header mode="md" title="Agendamento" backRoute="/"></usucampeao-header>
        <div class="appointment__content ion-padding-start ion-padding-end ion-margin-bottom">
            <ion-text>
                <p class="ion-no-margin font-text">Selecione uma data para a visita de um agente para validação presencial dos documentos e dos dados informados.</p>
            </ion-text>
            <ion-grid class="ion-no-padding">
                <ion-row class="ion-justify-content-center ion-padding-top">
                    <ion-col sizeMd="6" class="ion-text-center">
                        <div align="center">
                            <form [formGroup]="form">
                                <ion-item class="input">
                                    <ion-label position="stacked" class="font-text font-text-small">Data da visita de validação
                                    </ion-label>
                                    <ion-input readonly [ngModel]="minDate | date: 'dd MMMM, y'" formControlName="extendedDate"></ion-input>
                                </ion-item>
                                <br>
                                <ion-datetime mode="md" [ngModel]="minDate" [presentation]="minDate" formControlName="date" class="ion-padding" (ionChange)="setDate()" [min]="minDate" [max]="maxDate"></ion-datetime>
                            </form>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <usucampeao-footer class="ion-padding-start ion-padding-end">
           <!--  <ion-button mode="md" expand="block" type="submit" (click)="selectDate()">
                Continuar
            </ion-button> -->
            <ion-text>
                <p class="ion-padding font-text">Agende a validação presencial dos documentos. Em caso de dúvidas, <b class="text-link" (click)="presentModalContact()">entre em contato.</b></p>
            </ion-text>
        </usucampeao-footer>
    </div>

    <usucampeao-select-hour *ngIf="step === 1" [selectedDate]="selectedDate" (previousStep)="previousStep()" (nextStep)="selectHour($event)">
    </usucampeao-select-hour>

    <usucampeao-confirm-appointment *ngIf="step === 1" [selectedDate]="selectedDate" [registrationId]="registrationId" (previousStep)="previousStep()">
    </usucampeao-confirm-appointment>
</ion-content>
