import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OwnersPage } from './owners.page';

const routes: Routes = [
  { path: '', component: OwnersPage },
  {
    path: 'detalhes',
    loadChildren: () =>
      import('./details/details.module').then((m) => m.DetailsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OwnersRoutingModule {}
