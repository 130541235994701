import { IsDateString, IsEnum, IsOptional } from 'class-validator';
import { RegimeBem } from '../../enums/regime-bem.enum';

export class DadosMaritais {
  @IsDateString()
  @IsOptional()
  data: Date;

  @IsEnum(RegimeBem)
  @IsOptional()
  regimeBem: RegimeBem;
}
