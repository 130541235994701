import { PropertyDto } from '@usucampeao/interfaces';
import { Expose, plainToInstance } from 'class-transformer';
import { IsNotEmpty, IsNumber } from 'class-validator';
import { PropertyFillInStatusDto } from './property-fill-in-status.dto';

export class GetUpdatePropertyLocalizationDto {
  @Expose()
  @IsNumber()
  @IsNotEmpty()
  blockId: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  lotId: number;

  quadra?: string;

  lote?: string;

  fillInStatus?: PropertyFillInStatusDto;

  /**
   * Cria uma nova instância de GetUpdatePropertyLocalization a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdatePropertyLocalization
   */
  static from(props?: PropertyDto | Partial<PropertyDto>): GetUpdatePropertyLocalizationDto {
    return plainToInstance(GetUpdatePropertyLocalizationDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
