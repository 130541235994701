import { HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import bugsnag from '@bugsnag/js';
import { AlertController, ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { ElementBase, InputMode, Textbox, toFormGroup } from '@usucampeao/ui-mobile';
import { Subject } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoadingService } from '../../../../services/loading.service';
import { ToastService } from '../../../../services/toast.service';
import { ContactModal } from '../../../modal/contact/contact.modal';
import { RegisterQuery } from '../../state/register.query';
import { RegisterService } from '../../state/register.service';
import { ContactSupportModal } from './../../../modal/contact-support-point/contact-support.modal';

@Component({
  selector: 'usucampeao-access-code',
  templateUrl: 'allotment-code.page.html',
  styleUrls: ['allotment-code.page.scss'],
})
export class AllotmentCodePage implements OnInit, ViewWillEnter, ViewWillLeave, OnDestroy {
  public form$: ElementBase<string>[] | null = [];
  public form!: FormGroup;
  private registrationId: string;
  private ngUnsubscribe = new Subject();
  allotment = 'Jardim Pérola II';

  constructor(
    private modalController: ModalController,
    private alertCtrl: AlertController,
    private loadingService: LoadingService,
    private registerQuery: RegisterQuery,
    private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.form$ = this.getForm();
    this.form = toFormGroup(this.getForm() as ElementBase<string>[]);
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        first(),
        tap(params => this.registrationId = params.id),
        switchMap(() => this.registerQuery.selectLoading()),
        tap(async isLoading => {
          if (isLoading) {
            await this.loadingService.createLoader();
          } else {
            await this.loadingService.dismiss();
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public getForm(): ElementBase<string>[] {
    return [
      new Textbox({
        key: 'code',
        label: 'Código de acesso',
        value: '',
        type: 'text',
        inputMode: InputMode.PHONE,
        mask: 'codenumber',
        validators: [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      })];
  }

  public async onSubmit(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      await this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
      return;
    }

    const code = this.form.get('code').value;
    this.validateCode(code);
  }

  private validateCode(code: string): void {
    this.registerService.validateAllotmentCode(this.registrationId, code)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        () => this.router.navigate([`../lote`], { relativeTo: this.route }),
        async err => {
          let msg = 'Erro ao validar código';
          if (err.status === HttpStatusCode.Forbidden) {
            msg = 'Código informado inválido.';
          } else {
            bugsnag.notify(err);
          }
          await this.showAlertError(msg);
        });
  }

  async presentModalContact() {
    const modal = await this.modalController.create({
      component: ContactModal,
      cssClass: 'contact-modal',
    });
    return await modal.present();
  }

  async presentModalContactSupportPoint() {
    const modal = await this.modalController.create({
      component: ContactSupportModal,
      cssClass: 'allotment-support-modal',
    });
    return await modal.present();
  }

  private async showAlertError(msg: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-error',
      header: 'Atenção',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }

}
