import { RemoveSpecialCharacters } from '@usucampeao/utils';
import { Expose } from 'class-transformer';
import {
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsNumberString,
  IsOptional,
  IsString,
  Length
} from 'class-validator';
import { UF } from '../enums/uf.enum';
export class Endereco {
  @Expose()
  @RemoveSpecialCharacters()
  @IsNumberString()
  @Length(8, 9)
  cep: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  rua: string;

  @Expose()
  @IsNumber()
  @IsOptional()
  numero?: number;

  @Expose()
  @IsString()
  @IsOptional()
  complemento?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  bairro: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  cidade: string;

  @Expose()
  @IsEnum(UF)
  @IsNotEmpty()
  estado: UF;

  @Expose()
  @IsString()
  @IsOptional()
  ibge?: string;
}
