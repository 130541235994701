<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
    <ion-text>
        <p class="title">Ajuda sobre o cadastro</p>
    </ion-text>
    <ion-text class="font-text">
        <p>Se o seu cadastro estiver incompleto você precisa preencher cada etapa do cadastro e precisa está com o símbolo (verde) para sinalizar que está pronto.</p>
        <p>Caso precise de suporte, você pode assistir o passo a passo desta página 
            <a href="https://lp.usucampeao.com.br/lp-pode-regularizar" target="_blank">clicando aqui</a>!</p>
        <p>Se ainda assim não der certo, é só ligar em nossa central de atendimento de forma gratuita: <a href="tel:08005911328">0800 591 1328</a>.</p>
    </ion-text>
    <ion-grid>
        <ion-row class="ion-justify-content-end">
            <ion-col size="auto">
                <a (click)="dismiss()"><b>Entendi</b></a>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
