import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { DeclaracaoDeautonomoRendaComponent } from './contratos/declaracao-de-autonomo-e-renda/declaracao-de-autonomo-e-renda';
import { DeclaracaoDeDomicilioComponent } from './contratos/declaracao-de-domicilio';
import { DeclaracaoDeVizinhosComponent } from './contratos/declaracao-de-vizinhos';
import { MoradorAverbacaoComponent } from './contratos/morador-averbacao';
import { MoradorUsucampeaoReurbAverbacaoComponent } from './contratos/morador-usucampeao-reurb-averbacao';
import { MoradorUsucampeao2021Component } from './contratos/morador-usucampeao_2021';
import { ProcuracaoAverbacaoComponent } from './contratos/procuracao-averbacao';
import { ProcuracaoReurbAverbacaoComponent } from './contratos/procuracao-reurb-averbacao';
import { QuestionarioJuridicoComponent } from './contratos/questionario-juridico';
import { PrintContractComponent } from './print-contract/print-contract.component';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    DeclaracaoDeautonomoRendaComponent,
    DeclaracaoDeDomicilioComponent,
    DeclaracaoDeVizinhosComponent,
    MoradorAverbacaoComponent,
    MoradorUsucampeao2021Component,
    MoradorUsucampeaoReurbAverbacaoComponent,
    ProcuracaoAverbacaoComponent,
    ProcuracaoReurbAverbacaoComponent,
    QuestionarioJuridicoComponent,
    PrintContractComponent,
  ],
  exports: [
    DeclaracaoDeautonomoRendaComponent,
    DeclaracaoDeDomicilioComponent,
    DeclaracaoDeVizinhosComponent,
    MoradorAverbacaoComponent,
    MoradorUsucampeao2021Component,
    MoradorUsucampeaoReurbAverbacaoComponent,
    ProcuracaoAverbacaoComponent,
    ProcuracaoReurbAverbacaoComponent,
    QuestionarioJuridicoComponent,
    PrintContractComponent,
  ],
})
export class ContractModule { }
