import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { FooterModule } from '../../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../../components/header/header.module';
import { PipesModule } from '../../../../../pipes/pipes.module';
import { ChangeAlertModule } from './../../../../../components/change-alert/change-alert.module';
import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentsPage } from './documents.page';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    UiMobileModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CommonModule,
    FooterModule,
    HeaderModule,
    DocumentsRoutingModule,
    ChangeAlertModule,
  ],
  declarations: [
    DocumentsPage
  ],
})
export class DocumentsModule { }
