import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { UiMobileModule } from "@usucampeao/ui-mobile";
import { InformationAlertModule } from "../../../../modal/information-alert/information-alert.module";
import { ChangeAlertModule } from "./../../../../../components/change-alert/change-alert.module";
import { FooterModule } from "./../../../../../components/footer/footer.module";
import { HeaderModule } from "./../../../../../components/header/header.module";
import { ProcessProgressModule } from "./../../../../../components/process-progress/process-progress.module";
import { SkeletonItemModule } from "./../../../../../components/skeleton-item/skeleton-item.module";
import { PipesModule } from "./../../../../../pipes/pipes.module";
import { SentDocumentsRoutingModule } from "./sent-documents-routing.module";
import { SentDocumentsPage } from "./sent-documents.page";

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    UiMobileModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ProcessProgressModule,
    FooterModule,
    HeaderModule,
    SkeletonItemModule,
    SentDocumentsRoutingModule,
    ChangeAlertModule,
    InformationAlertModule
  ],

  declarations: [
    SentDocumentsPage
  ],
})
export class SentDocumentsModule { }
