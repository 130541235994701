import { AddressDto } from '../address/address.dto';
import { FileDto } from '../file';
import { PropertyFillInStatusDto } from './property-fill-in-status.dto';
import { PropertyOccupancy } from './property-occupancy.enum';
import { PropertySituation } from './property-situation.enum';
import { PropertyUsage } from './property-usage.enum';

export class PropertyDto {
  id: string;
  projetoFid: string;
  blockId: number;
  quadra: string;
  lotId: number;
  lote: string;
  photoId: string;
  address: AddressDto;
  usage: PropertyUsage;
  specifyUsage: string;
  occupation: PropertyOccupancy;
  situation: PropertySituation;
  specifySituation: string;
  loteArea: number;
  possuiOutroImovel: boolean;
  // residentsQuantity: number;
  // residenceTime: number;
  // housesQuantity: number;
  // housesQuantitySameOwner: number;
  // entriesQuantity: number;
  // entriesQuantitySamePavement: number;
  createdAt: string;
  updatedAt: string;
  files: FileDto[]
  fillInStatus: PropertyFillInStatusDto;
}
