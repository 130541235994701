import { ValidatorFn } from '@angular/forms';

export class ElementBase<T> {
  value: T | undefined;
  key: string;
  min: string;
  max: string;
  label: string;
  required: boolean;
  controlType: string;
  type: string;
  readonly: boolean;
  options: { key: string | boolean | number, value: string }[];
  disabled: boolean;
  mask: string;
  description: string;
  note: string;
  inputMode: string;
  visible: boolean;
  validators: ValidatorFn[];
  minDate?: Date;
  maxDate?: Date;

  constructor(options: {
    value?: T;
    key?: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    mask?: string;
    order?: number;
    controlType?: string;
    type?: string;
    readonly?: boolean;
    options?: { key: string | boolean | number, value: string }[];
    disabled?: boolean;
    description?: string;
    note?: string;
    inputMode?: string;
    visible?: boolean;
    validators?: ValidatorFn[];
    min?: string;
    max?: string;
    minDate?: Date;
    maxDate?: Date;
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.mask = options.mask || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.readonly = options.readonly || false;
    this.options = options.options || [];
    this.disabled = options.disabled || false;
    this.description = options.description || '';
    this.note = options.note || '';
    this.inputMode = options.inputMode || '';
    this.visible = options.visible === false ? false : true;
    this.validators = options.validators || [];
    this.min = options.min || '';
    this.max = options.max || '';
    this.minDate = options.minDate;
    this.maxDate = options.maxDate;
  }
}
