import { PersonalDocumentType } from '@usucampeao/interfaces';

export function getDocumentInformation(type: string): { name: PersonalDocumentType, text: string } {
  if (type.includes('RG')) {
    return {
      name: PersonalDocumentType.RG,
      text: 'Tire foto do seu RG aberto, de forma que ambos os lados fiquem visíveis na imagem.'
    }
  }
  else if (type.includes('CNH')) {
    return {
      name: PersonalDocumentType.CNH,
      text: 'Tire foto da sua CNH aberta, de forma que ambos os lados fiquem visíveis na imagem. '
    }
  }
  else if (type.includes('CPF')) {
    return {
      name: PersonalDocumentType.CPF,
      text: 'Tire foto do seu CPF de forma que os dados fiquem visíveis.'
    }
  }
  else if (type.includes('nascimento')) {
    return {
      name: PersonalDocumentType.CERTIDAO_NASCIMENTO,
      text: 'Tire foto do documento  aberto, de forma que ambos os lados fiquem visíveis na imagem.'
    }
  }
  else if (type.includes('casamento')) {
    return {
      name: PersonalDocumentType.CERTIDAO_CASAMENTO,
      text: 'Tire foto do documento aberto, de forma que ambos os lados fiquem visíveis na imagem.'
    }
  }
  else if (type.includes('divorcio')) {
    return {
      name: PersonalDocumentType.CERTIDAO_DIVORCIO,
      text: 'Tire foto do documento  aberto, de forma que ambos os lados fiquem visíveis na imagem.'
    }
  }
  else if (type.includes('obito')) {
    return {
      name: PersonalDocumentType.CERTIDAO_OBITO,
      text: 'Tire foto do documento aberto, de forma que ambos os lados fiquem visíveis na imagem.'
    }
  }
  else {
    return {
      name: PersonalDocumentType.RG,
      text: 'Tire foto do seu RG aberto, de forma que ambos os lados fiquem visíveis na imagem.'
    }
  }
}
