import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { TopicMensagemDto } from './topic-mensagem.dto';

export class TopicDto<T> {
  @Type(() => TopicMensagemDto)
  @ValidateNested()
  @IsNotEmpty()
  message: TopicMensagemDto<T>;

  @IsString()
  @IsOptional()
  subscription: string;
}
