import { Component, Input } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-agendamento-disponivel',
  templateUrl: './agendamento-disponivel.component.html',
  styleUrls: ['./agendamento-disponivel.component.scss'],
})
export class AgendamentoDisponivelComponent {
  @Input()
  public notification!: NotificacaoDto;

  constructor() {}

}