<ion-content class="ion-padding">
  <ion-text class="title title--large">
    Justificativa
  </ion-text>
  <p>
    <ion-text class="font-text">
      Justificativa e comentários para o não envio de documento do imóvel necessário
    </ion-text>
  </p>
  <ion-grid class="ion-no-padding ion-no-margin">
    <ion-row class="align-middle">
      <ion-col sizeXs="2" sizeSm="1">
        <ion-icon color="medium" class="icon" name="document-attach-outline"></ion-icon>
      </ion-col>
      <ion-col class="ion-text-start" sizeXs="10" sizeSm="2">
        <ion-row>
          <ion-text class="font-text">{{isOwnershipDocument ? 'Documento de aquisição' :
            'Documento de posse'}}</ion-text>
        </ion-row>
        <ion-row>
          <ion-text class="description-text description-text--small">
            Documento faltante
          </ion-text>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col sizeMd="6" sizeLg="3">
        <form mode="md" [formGroup]="form" class="ion-text-center">
          <div *ngFor="let field of form$" class="form-row ion-no-padding">
            <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
          </div>
        </form>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col sizeMd="6" sizeLg="3" class="ion-padding-top">
        <ion-button *ngIf="!editar" mode="md" expand="block" (click)="onSubmit()">Adicionar justificativa
        </ion-button>
        <ion-button *ngIf="editar" mode="md" expand="block" (click)="onSubmit()">Editar justificativa
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding-top">
      <ion-col sizeMd="6" sizeLg="3">
        <ion-button fill="outline" (click)="back()" mode="md" expand="block">voltar</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <usucampeao-footer></usucampeao-footer>
</ion-content>
