import { Gender } from '../../enums/gender.enum';

export class UserDto {
  id: string;
  name: string;
  cpf: string;
  birthDate: Date;
  gender: Gender;
  email?: string;
  phone: string;
  photo: string;
}
