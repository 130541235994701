import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationPage } from './notification.page';
import { ToSeePage } from './toSee/to-see.page';

const routes: Routes = [
  {
    path: '',
    component: NotificationPage,
  },
  {
    path: ':id',
    component: ToSeePage,
  },
  {
    path: 'pagamento/:id/:registration',
    loadChildren: () =>
      import('../../pages/registration/pagamento/detalhes/detalhes.module').then((m) => m.DetalhesPageModule),
  }, {
    path: 'pagamento/:id/:registration',
    loadChildren: () =>
      import('../../pages/registration/pagamento/detalhes/detalhes.module').then((m) => m.DetalhesPageModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationPageRoutingModule { }
