<div id="print-section" class="c-data-contract__text-terms">
    <div class="title">
        <h1>DECLARAÇÃO DE AUTÔNOMO E RENDA</h1>
    </div>
    <div class="personal-data">
        <table class="contract-table">
            <tr>
                <td>
                    <p>
                        Eu,
                        <span style="font-weight: bold;">NOME:</span> {{ proprietario?.name }},
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        portador do (a) do <strong>RG nº</strong> {{ proprietario?.rg?.number || '' }}, inscrito no <strong>CPF nº</strong> {{ proprietario?.cpf }}, estado civil {{ proprietario?.maritalStatus }}, <b>
                          declaro,
                        </b> sob pena da lei que desenvolvo a atividade de {{ proprietario?.profession }}, como trabalhador (a) autônomo, recebendo uma renda mensal média de R$ {{ proprietario?.familyIncome }}.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <b>Declaro</b>, ainda, estar ciente de que, comprovada a falsidade desta declaração, estarei sujeito às penas previstas no artigo 299 do Código Penal Brasileiro.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        E, por ser expressão da verdade, firmo o presente.
                    </p>
                </td>
            </tr>
        </table>
    </div>
    <div class="subscription">
        <div class="table">
            <div class="column">
                <p>{{ imovel?.address?.city }},{{cadastro?.contractDate }}
            </div>

            <div class="column">
                <p>______________________________________________</p>
                <div class="column__infos">
                    <p>{{ proprietario?.name }} </p>
                    <p>RG nº {{ proprietario?.rg?.number }}</p>
                </div>
                <div class="column__infos">
                    <p>USUCAMPEAO TECNOLOGIA EM REGULARIZAÇÃO IMOBILIÁRIA LTDA </p>
                    <p>CNPJ: 36.174.049/0001-08 Endereço: Rua Cláudio Soares, 72, 6˚ andar / Telefone: 2537.9430 / Site: www.usucampeao.com.br</p>
                </div>
            </div>

        </div>
    </div>
