import { Polygon } from 'geojson';
import { LoteStatus } from './lote-status.enum';

export class LoteListarMapaDto {
  id: number;
  label: string;
  quadra: string;
  quadra_id: number;
  coords: Polygon;
  status: LoteStatus;
}
