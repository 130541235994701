import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ModalController, ViewWillEnter,
  ViewWillLeave
} from '@ionic/angular';
import { PersonalDocumentType } from '@usucampeao/interfaces';
import { AlteracaoDto, RegistrationStatus, TipoAlteracao } from '@usucampeao/lib-reurb-simplificado';
import { Observable, Subject } from 'rxjs';
import { catchError, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { getStatusProgressInformation, StatusProgress } from '../../../../../models/status-progress.enum';
import { ToastService } from '../../../../../services/toast.service';
import { AuthQuery } from '../../../../auth/state/auth.query';
import { InformationAlertModal } from '../../../../modal/information-alert/information-alert.modal';
import { InstructionModal } from '../../../../modal/instruction/instruction.modal';
import { RegistrationService } from '../../../state/registration.service';
import { LoadingService } from './../../../../../services/loading.service';
import { NavigationService } from './../../../../../services/navigation.service';
import { RegistrationQuery } from './../../../state/registration.query';

@Component({
  selector: 'usucampeao-sent-documents',
  templateUrl: 'sent-documents.page.html',
  styleUrls: ['sent-documents.page.scss'],
})
export class SentDocumentsPage
  implements ViewWillEnter, ViewWillLeave, OnDestroy {
  private ownerId: string;
  private registrationId: string;
  private ngUnsubscribe = new Subject();
  public isIdentitySended = false;
  public isSocialStateSended = false;
  public blockAccess = false;
  AllDocuments;
  fluxo: boolean;
  registration = {
    idDocuments: StatusProgress.COMPLETE,
    maritalDocuments: StatusProgress.COMPLETE,
  };
  public alteracoes: AlteracaoDto[] = [];

  constructor(
    private registrationService: RegistrationService,
    private loaderService: LoadingService,
    private modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    private registrationQuery: RegistrationQuery,
    private navigationService: NavigationService,
    public authQuery: AuthQuery,
    private toastService: ToastService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.fluxo = params['fluxo'];
    });
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        first(),
        tap((params) => this.registrationId = params.id),
        tap((params) => this.ownerId = params.ownerId),
        tap(async () => await this.loaderService.createLoader()),
        tap(async () => {
          const data = await this.registrationService.getFileOwner(this.registrationId, this.ownerId);
          this.getSocialState(data);
          this.AllDocuments = data;
          await this.loaderService.dismiss();
        }),
        // tap(() => this.getRegistration(this.registrationId)),
        switchMap(() => this.registrationQuery.selectEntity(this.registrationId)),
        tap((listRegistration) => {
          const status = listRegistration.status;
          this.validateStatus(status);
        }),
        switchMap(() => this.registrationService.buscaAlteracoesCadastro(this.registrationId, TipoAlteracao.PROPRIETARIO_DOCUMENTOS)),
        tap(alteracoes => this.alteracoes = alteracoes),
        catchError(async () => await this.toastService.erro('Erro ao buscar dados. Tente novamente mais tarde.')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  /**
   * Observable para o status de loading de dados do cadastro, no estado.
   */
  public get isLoading$(): Observable<boolean> {
    return this.registrationQuery.select('loading');
  }

  public statusProgressInformation(
    status: StatusProgress
  ): {
    name: string | null;
    nameClass: string | null;
    icon: string | null;
    iconClass: string | null;
  } {
    return getStatusProgressInformation(status, null);
  }

  navigateToType(type: string): void {
    this.router.navigate([type], { relativeTo: this.route });
  }

  gotoRoute(data: any) {
    this.router.navigate(['escolher/foto/', data?.type, data?.id], { relativeTo: this.route });
  }

  async presentModalInstruction() {
    const modal = await this.modalController.create({
      component: InstructionModal,
      cssClass: 'documents-help-modal',
    });
    return await modal.present();
  }

  private async presentModalWarning() {
    const modal = await this.modalController.create({
      component: InformationAlertModal,
      cssClass: 'small-modal',
      componentProps: {
        title: 'Documentos faltantes',
        text:
          'Você ainda não enviou todos os documentos necessários. Seu cadastro continuará pendente enquanto todos os documentos não forem anexados.',
        subtext: 'Continuar mesmo assim?',
        clearButton: 'SIM',
        fillButton: 'VOLTAR',
        fillButtonRota: 'dismiss',
        clearButtonRota: '../..',
      },
    });
    return await modal.present();
  }

  getSocialState(documentsList): void {
    this.isIdentitySended = documentsList.some((item) => {
      return [
        PersonalDocumentType.CNH,
        PersonalDocumentType.RG,
        PersonalDocumentType.CPF
      ].includes(item.type)
    });


    this.isSocialStateSended = documentsList.some((item) => {
      return [
        PersonalDocumentType.CERTIDAO_NASCIMENTO,
        PersonalDocumentType.CERTIDAO_CASAMENTO,
        PersonalDocumentType.CERTIDAO_DIVORCIO,
        PersonalDocumentType.CERTIDAO_OBITO
      ].includes(item.type)
    });
  }

  public onSubmit(): void {
    if (this.fluxo) {
      if (
        this.registration.maritalDocuments === StatusProgress.COMPLETE &&
        this.registration.idDocuments === StatusProgress.COMPLETE
      ) {
        this.navigationService.goBack();
      } else {
        this.presentModalWarning();
      }
    } else {
      if (
        this.registration.maritalDocuments === StatusProgress.COMPLETE &&
        this.registration.idDocuments === StatusProgress.COMPLETE
      ) {
        this.navigationService.goBack();
      } else {
        this.presentModalWarning();
      }
    }
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
    }
  }
}
