import { Pipe, PipeTransform } from '@angular/core';
import { PropertyUsage } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'propertyUsage'
})
export class PropertyUsagePipe implements PipeTransform {

  transform(value: PropertyUsage): string {
    switch (value) {
      case PropertyUsage.COMMERCIAL:
        return 'Comercial';

      case PropertyUsage.RESIDENTIAL:
        return 'Residencial';

      case PropertyUsage.MIXED:
        return 'Misto';

      default:
        return value;
    }
  }

}
