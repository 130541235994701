import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { RegistrationSubmitHelpModal } from './registration-submit-help.modal';

@NgModule({
  declarations: [RegistrationSubmitHelpModal],
  imports: [
    CommonModule,
    IonicModule,
    UiMobileModule,
  ],
  exports:[RegistrationSubmitHelpModal],
})
export class RegistrationSubmitHelpModule {}
