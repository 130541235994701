import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, ViewWillLeave } from '@ionic/angular';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ToastService } from '../../../../services/toast.service';
import { PropertyService } from '../state/property.service';


@Component({
  selector: 'usucampeao-edit-photo',
  templateUrl: 'edit-photo.page.html',
  styleUrls: ['edit-photo.page.scss'],
})
export class EditPhotoPage implements OnDestroy, ViewWillLeave {
  public photo: File;
  private ngUnsubscribe = new Subject();
  private id: string;
  private registrationId: string;

  constructor(
    private propertyService: PropertyService,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    this.route.params
      .pipe(
        first(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.registrationId = params.id;
        this.id = params.propertyId;
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  /**
   * Seta a foto no component
   * @param photo foto selecionada
   */
  public setImage(photo: File): void {
    this.photo = photo;
  }

  /**
   * Atualiza a foto da fachada do imóvel
   */
  public async updateFacadePhoto(): Promise<void> {
    this.propertyService.updateFacadePhoto(this.registrationId, this.id, this.photo)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        () => this.back(),
        async () => await this.toastService.erro('Erro ao atualizar foto da fachada. Tente novamente mais tarde')
      );
  }

  public back(): void {
    this.navCtrl.pop();
  }
}
