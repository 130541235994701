export enum TipoSolicitacao {
  ATUALIZACAO_CADASTRAL = 'ATUALIZACAO_CADASTRAL',
  BUSCA_INFORMACOES = 'BUSCA_INFORMACOES',
  INTERESSE_REURB = 'INTERESSE_REURB',
  INTERESSE_USUCAPIAO = 'INTERESSE_USUCAPIAO',
  SOLICITACAO_PARCERIA = 'SOLICITACAO_PARCERIA',
  SOLICITACAO_BOLETO = 'SOLICITACAO_BOLETO',
  SOLICITACAO_CONTATO = 'SOLICITACAO_CONTATO',
  SOLICITACAO_SELAGEM = 'SOLICITACAO_SELAGEM',
  OUTRO = 'OUTRO',
}
