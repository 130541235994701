import { IsEmail, IsNotEmpty, IsNumberString, IsOptional, IsString, Length } from 'class-validator';
import { ResidentDto } from '../resident/resident-dto.model';

export class Owner {
  @IsString()
  @IsNotEmpty()
  id: string

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsEmail()
  @IsOptional()
  email?: string;

  @IsString()
  @Length(11, 11)
  @IsOptional()
  document?: string;

  @IsNumberString()
  @Length(10, 11)
  @IsOptional()
  phone?: string;

  @IsOptional()
  @IsString()
  externalPlatformId?: string;
}

export function residentDtoToOwner(resident: ResidentDto): Owner {
  if (!resident) {
    return null;
  }

  return {
    id: resident.id,
    name: resident.name,
    email: resident.email,
    ...resident.document ? { document: resident.document } : {},
    ...resident.phone ? { phone: resident.phone } : {},
  }
}
