<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
    <ion-text>
        <p class="title">Confirmar o reenvio?</p>
    </ion-text>
    <ion-text class="font-text">
        <p>Caso tenha finalizado as correções, reenvie o cadastro para análise para continuar o processo.</p>
        <p>Enquanto o cadastro estiver em análise, não será possível alterá-lo.</p>
        <p>Reenviar para análise?</p> 
    </ion-text>
    <ion-grid>
        <ion-row class="ion-justify-content-end">
          
            <ion-col size="auto">
                <a class="ion-padding-end alinhamento" (click)="dismiss()"><b>VOLTAR</b></a>
                <ion-button mode="md" (click)="reenviar()">Enviar</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
