import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ConfirmaEnvioModal } from './confirma-envio.modal';

@NgModule({
  declarations: [ConfirmaEnvioModal],
  imports: [
    CommonModule,
    IonicModule,
    UiMobileModule,
  ],
  exports:[ConfirmaEnvioModal],
})
export class ConfirmaEnvioModule {}
