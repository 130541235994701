import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ClickHereRequestHelpModal } from './click-here-request-help.modal';

@NgModule({
  imports: [CommonModule,IonicModule, UiMobileModule, CommonModule],

  declarations: [ClickHereRequestHelpModal],

  exports:[ClickHereRequestHelpModal],
})
export class ClickHereRequestHelpModule {}
