<ion-content>
    <div class="content-vertical-space-between">
        <div>
            <usucampeao-header mode="md" backRoute="/" title="Novo Cadastro"></usucampeao-header>
            <div class="ion-padding-start ion-padding-end ion-padding-bottom">
                <ion-text class="font-text">
                    Precisamos de uma foto da fachada do seu imóvel para conferir a localização.
                </ion-text>
            </div>
        </div>
        <div>
            <usucampeao-photo class="ion-margin-top" type="lot" (setImage)="setImage($event)"></usucampeao-photo>
        </div>
        <usucampeao-footer class="ion-padding-start ion-padding-end">
            <p class="font-text" *ngIf="!photo">
                <ion-text  class="description-text description-text--large">Não é um bom momento?</ion-text>
                <ion-text class="text-link text-link--large" (click)="skipPhoto()"><strong> Deixar para depois</strong></ion-text>
            </p>
            <ion-button mode="md" expand="block" class="ion-margin-bottom" (click)="updateFacadePhoto()" *ngIf="photo">
                Continuar
            </ion-button>
        </usucampeao-footer>
    </div>
</ion-content>
