import { TabelaPrecoParcela } from '@usucampeao/lib-reurb-simplificado';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tabelaPrecoParcela',
})
export class PropertyNumberInstallmentsPipe implements PipeTransform {
  transform(value: TabelaPrecoParcela): string {
    switch (value) {
      case TabelaPrecoParcela.PARCELA_0:
        return 'À vista';
      case TabelaPrecoParcela.PARCELA_1:
        return '1 parcela';
      case TabelaPrecoParcela.PARCELA_2:
        return '2 parcelas';
      case TabelaPrecoParcela.PARCELA_3:
        return '3 parcelas';
      case TabelaPrecoParcela.PARCELA_4:
        return '4 parcelas';
      case TabelaPrecoParcela.PARCELA_5:
        return '5 parcelas';
      case TabelaPrecoParcela.PARCELA_6:
        return '6 parcelas';
      case TabelaPrecoParcela.PARCELA_7:
        return '7 parcelas';
      case TabelaPrecoParcela.PARCELA_8:
        return '8 parcelas';
      case TabelaPrecoParcela.PARCELA_9:
        return '9 parcelas';
      case TabelaPrecoParcela.PARCELA_10:
        return '10 parcelas';
      case TabelaPrecoParcela.PARCELA_11:
        return '11 parcelas';
      case TabelaPrecoParcela.PARCELA_12:
        return '12 parcelas';

      default:
        return value;
    }
  }
}
