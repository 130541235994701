
import { Component, Input } from '@angular/core';
import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';

import { getIconName, getStatusDescription, getStatusTitle } from '../../models/status-simplified-reurb.enum';

@Component({
  selector: 'usucampeao-process-status',
  templateUrl: 'process-status.component.html',
  styleUrls: ['process-status.component.scss'],
})
export class ProcessStatusComponent {
  @Input() status!: RegistrationStatus;

  constructor() {

  }

  public statusIcon(status: RegistrationStatus): string {
    return getIconName(status);
  }

  public statusTitle(status: RegistrationStatus): string {
    return getStatusTitle(status);
  }

  public statusDescription(status: RegistrationStatus): string {
    return getStatusDescription(status);
  }
}
