<ion-content>
  <usucampeao-header mode="md" backRoute=".." title="Parcela"></usucampeao-header>
  <ion-grid class="ion-padding-start ion-padding-end">
    <ion-row class="ion-justify-content-center">
      <ion-col sizeXs="12" sizeSm="6" class="ion-no-padding ion-padding-bottom">
        <ion-text class="font-text">Aqui estão os dados do boleto referente à parcela
          {{pagamentoSelecionado?.parcela===0?'Entrada':pagamentoSelecionado?.parcela}}.
          Fique atento à data de vencimento, para evitar a incidência de juros e multa. Caso o boleto esteja vencido há
          mais de 30 dias, entre em contato e solicite um novo boleto.</ion-text>

      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding-top">
      <ion-col sizeXs="12" sizeSm="6" class="ion-no-padding">
        <ion-item *ngIf="pagamentoSelecionado?.status !== 'PAGO'" class="ion-padding-bottom" lines="none">
          <ion-grid class="caixa-cinza">
            <ion-row>
              <ion-col>
                <ion-row>
                  <ion-col size="auto">
                    <ion-icon color="medium" name="calendar-sharp"></ion-icon>
                  </ion-col>
                  <ion-col>
                    <ion-text class="title">{{pagamentoSelecionado?.dataVencimento| date: 'dd/MM/yyyy'||'--'}}
                    </ion-text>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="auto">
                    <ion-icon color="medium" name="cash-sharp"></ion-icon>
                  </ion-col>
                  <ion-col>
                    <ion-text class="title">{{pagamentoSelecionado?.valorDevido | currency:'BRL' || ''}}</ion-text>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="pagamentoSelecionado?.linhaDigitavel" (click)="copiarCodigoBarras()">
                  <ion-col size="auto">
                    <ion-icon color="medium" name="barcode-sharp"></ion-icon>
                  </ion-col>
                  <ion-col class="codigo-barras">
                    <span>{{ pagamentoSelecionado.linhaDigitavel }}</span>
                    <ion-icon color="medium" name="copy-outline"></ion-icon>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item *ngIf="pagamentoSelecionado?.status === 'PAGO'" class="ion-padding-bottom" lines="none">
          <ion-grid class="caixa-cinza">
            <ion-row>
              <ion-col>
                <ion-row>
                  <ion-col size="auto">
                    <ion-icon name="checkmark-circle-sharp" class="icone-verde"></ion-icon>
                  </ion-col>
                  <ion-col>
                    <ion-text class="title">PAGO</ion-text>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="auto">
                    <ion-icon color="medium" name="calendar-sharp"></ion-icon>
                  </ion-col>
                  <ion-col>
                    <ion-text class="font-text">{{pagamentoSelecionado.dataVencimento}}</ion-text>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="auto">
                    <ion-icon color="medium" name="cash-sharp"></ion-icon>
                  </ion-col>
                  <ion-col>
                    <ion-text class="font-text">{{pagamentoSelecionado.valorDevido | currency:'BRL' || ''}}</ion-text>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="auto">
                    <ion-icon color="medium" name="barcode-sharp"></ion-icon>
                  </ion-col>
                  <ion-col>
                    <ion-text class="font-text">{{pagamentoSelecionado.linhaDigitavel}} <ion-icon color="medium"
                        name="copy-outline"></ion-icon>
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button *ngIf="pagamentoSelecionado?.downloadUrl" class="ion-margin-bottom" mode="md" expand="block"
          (click)="abrirBoleto()">Visualizar Boleto</ion-button>
        <ion-button class="ion-margin-bottom" mode="md" fill="outline" expand="block" (click)="goBack()">Voltar
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-text class="font-text font-text--small">Em caso de dúvidas a respeito do boletos,<ion-text color="strong">
            <strong class="text-link" (click)="presentModalContact()">entre em contato</strong>
          </ion-text>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
