import {
  IsDateString,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString
} from 'class-validator';
import { UF } from '../enums';
import { ProjectBlockStatus } from './project-block-status.enum';

export class ProjectDto {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  @IsOptional()
  code?: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsOptional()
  @IsEnum(UF)
  state?: UF;

  @IsNumber()
  @IsOptional()
  cityId?: number;

  @IsString()
  @IsOptional()
  city?: string;

  @IsNumber()
  @IsNotEmpty()
  districtId: number;

  @IsString()
  @IsOptional()
  district?: string;

  @IsDateString()
  @IsOptional()
  createdAt?: Date;

  @IsNumber()
  @IsOptional()
  status?: number;

  @IsNumber()
  @IsOptional()
  omieProjectId?: number;

  @IsEnum(ProjectBlockStatus)
  @IsOptional()
  statusBlock?: ProjectBlockStatus;
}
