import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FileDto, RegistrationSimplifiedDto } from '@usucampeao/lib-reurb-simplificado';

export interface RegistrationState extends EntityState<RegistrationSimplifiedDto> {
  loaded: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'registration',
  resettable: true
})
export class RegistrationStore extends EntityStore<RegistrationState> {
  constructor() {
    super();
  }

  public loadRegistration(registrations: RegistrationSimplifiedDto[], loaded: boolean) {
    this.set(registrations);

    this.update(state => ({
      ...state,
      loaded
    }));
  }

  public changeFile(id: string, file: FileDto) {
    this.update(id, { files: [file] })
  }

}
