import { Expose } from 'class-transformer';
import { StatusPreenchimento } from '../../enums';

export class PropertyFillInStatusDto {
  @Expose()
  localizationData?: StatusPreenchimento;

  @Expose()
  addressData?: StatusPreenchimento;

  @Expose()
  additionalInformationsData?: StatusPreenchimento;

  @Expose()
  documents?: StatusPreenchimento;

  @Expose()
  facadePhoto?: StatusPreenchimento;
}
