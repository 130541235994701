import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { EncerraCadastroModal } from './encerra-cadastro.modal';

@NgModule({
  imports: [CommonModule,IonicModule, UiMobileModule, CommonModule],

  declarations: [EncerraCadastroModal],

  exports:[EncerraCadastroModal],
})
export class EncerraCadastroModule {}
