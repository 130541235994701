import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'usucampeao-invite',
  templateUrl: 'invite.modal.html',
  styleUrls: ['invite.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InviteModal {
  constructor(
    public ModalController: ModalController,
    public router: Router,
    ) {}

  dismiss() {
    this.ModalController.dismiss();
  }

  navigateTo(rota: string): void {
    if (rota === 'dismiss') {
      this.dismiss();
    } else {
      this.dismiss();
      this.router.navigate([rota]);
    }
  }
}
