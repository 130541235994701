import { Injectable } from '@angular/core';
import { StoreConfig, Store, ID } from '@datorama/akita';

export interface AuthState {
  connected?:boolean;
  id?: ID;
  token?: string;
  name?: string;
  cpf?: string;
  birthDate?: any;
  gender?: string;
  phoneNumber?:string;
  email?: string;
  phone?: string;
  photo?: string;
  recaptcha?:any;
  code?:string;
  photoId?:string;
}

export function createInitialState(): AuthState {
  return {
    id: '',
    token: '',
    connected:true
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth',
  resettable: true
})
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
