import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Endereco } from '@brunoc/ngx-viacep';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { AddressDto, AlteracaoDto, RegistrationStatus, TipoAlteracao } from '@usucampeao/lib-reurb-simplificado';
import { ElementBase, InputMode, Textbox, toFormGroup } from '@usucampeao/ui-mobile';
import { of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { LoadingService } from '../../../../services/loading.service';
import { NavigationService } from '../../../../services/navigation.service';
import { ToastService } from '../../../../services/toast.service';
import { AuthQuery } from '../../../auth/state/auth.query';
import { RegistrationService } from '../../state/registration.service';
import { PropertyQuery } from '../state/property.query';
import { PropertyService } from '../state/property.service';
import { RegistrationQuery } from './../../state/registration.query';


@Component({
  selector: 'usucampeao-edit-adress',
  templateUrl: 'edit-address.page.html',
  styleUrls: ['edit-address.page.scss'],
})
export class EditAddressPage
  implements OnInit, ViewWillEnter, ViewWillLeave, OnDestroy {
  public form$: ElementBase<string>[] | null = [];
  public form!: FormGroup;
  private propertyId: string;
  private registrationId: string;
  private ngUnsubscribe = new Subject();
  fluxo: boolean;
  public blockAccess = false;
  public alteracoes: AlteracaoDto[] = [];

  constructor(
    public authQuery: AuthQuery,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private propertyQuery: PropertyQuery,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private registrationQuery: RegistrationQuery,
    private registrationService: RegistrationService,
  ) { }

  ngOnInit() {
    this.form$ = this.getForm(false);
    this.form = toFormGroup(this.getForm(false) as ElementBase<string>[]);
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        const registration = this.registrationQuery.getValue();
        const status = registration.entities[params.id];
        this.validateStatus(status.status);
        this.propertyId = params.propertyId;
        this.registrationId = params.id;
        this.getAddress();
      });

    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.fluxo = params['fluxo'];
      });

    this.propertyQuery
      .selectLoading()
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async (isLoading) => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  /**
   * Busca endereço por cep e popula o formulário
   * @param zipCode cep selecionado
   */
  public getAddressByZipCode(zipCode: string): void {
    zipCode = zipCode.replace(/\D/g, '');
    this.propertyService
      .getAddressByZipCode(zipCode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (endereco: Endereco) => {
          this.populaDadosForm(endereco);
        },
        async () => await this.toastService.erro('Erro ao buscar dados do endereço. Tente novamente mais tarde.'));
  }

  /**
   * Popula formulário com os dados do endereço
   * @param address endereço selecionado
   */
  public populaDadosForm(address: Endereco) {
    if (!address) {
      this.clearForm();
    }
    this.form.patchValue({
      street: address.logradouro,
      neighborhood: address.bairro || 'Jardim Pérola II',// TODO: Obter dados do loteamento
      city: address.localidade || 'São Paulo',
      state: address.uf || 'SP',
    });
    if (address.logradouro) {
      this.form.controls['street'].disable();
    } else {
      this.form.controls['street'].enable();
    }
  }

  public clearForm() {
    this.form.patchValue({
      street: '',
      neighborhood: '',
      city: '',
      state: '',
    });
  }

  /**
   * Cria os campos do formulário
   * @returns Formulário criado
   */
  public getForm(readOnlyOption: boolean): ElementBase<string>[] {
    const questions: ElementBase<string>[] = [
      new Textbox({
        key: 'zipCode',
        label: 'CEP',
        readonly: readOnlyOption,
        description: 'Endereço',
        mask: 'cep',
        inputMode: InputMode.NUMBER,
        validators: [Validators.required, Validators.minLength(9), Validators.maxLength(9)]
      }),
      new Textbox({
        key: 'street',
        label: 'Logradouro',
        readonly: readOnlyOption,
        required: true,
        validators: [Validators.required]
      }),
      new Textbox({
        key: 'number',
        label: 'Número',
        readonly: readOnlyOption,
        inputMode: InputMode.NUMBER,
        type: 'number',
        required: true,
        validators: [Validators.required]
      }),
      new Textbox({
        key: 'complement',
        label: 'Complemento',
        readonly: readOnlyOption,
      }),
      new Textbox({
        key: 'neighborhood',
        readonly: readOnlyOption,
        visible: false,
      }),
      new Textbox({
        key: 'city',
        readonly: readOnlyOption,
        visible: false,
      }),
      new Textbox({
        key: 'state',
        readonly: readOnlyOption,
        visible: false,
      }),
    ];
    return questions;
  }

  /**
   * Valida o form e atualiza endereço
   */
  public async onSubmit(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      await this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
      return;
    }

    this.updateAddress();
  }

  /**
   * Busca os dados de endereço do imóvel
   */
  private getAddress(): void {
    this.propertyQuery
      .selectEntity(this.propertyId)
      .pipe(
        take(1),
        switchMap((property) =>
          property.addressDataLoaded
            ? of(AddressDto.from(property))
            : this.propertyService.getAddressData(this.propertyId)
        ),
        tap((address) => {
          if (address && Object.keys(address).length > 0) {
            this.form.patchValue(address);
            if (address.street) {
              this.form.controls['street'].disable();
            } else {
              this.form.controls['street'].enable();
            }
          }
        }),
        switchMap(() => this.registrationService.buscaAlteracoesCadastro(this.registrationId, TipoAlteracao.IMOVEL_DADOS_ENDERECO)),
        tap(alteracoes => this.alteracoes = alteracoes),
        switchMap(() =>
          this.form.get('zipCode')?.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            filter((data) => data.length === 9),
            tap((data) => this.getAddressByZipCode(data)),
            takeUntil(this.ngUnsubscribe)
          )
        ),
        catchError(async () => await this.toastService.erro('Erro ao buscar dados do imóvel. Tente novamente mais tarde.'))
      )
      .subscribe();

  }

  /**
   * Atualiza os dados de endereço do imóvel
   */
  public updateAddress(): void {
    const address = Object.assign({}, this.form.value);
    address.street = this.form.get('street').value;
    address.zipCode = address.zipCode.replace(/\D/g, '');
    this.propertyService
      .updateAddressData(this.registrationId, this.propertyId, address)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (this.fluxo) {
          this.router.navigate([`../informacoes-adicionais`], {
            relativeTo: this.route,
            queryParams: { fluxo: true },
          });
        } else {
          this.navigationService.goBack();
        }
      }, async () => await this.toastService.erro('Erro ao atualizar dados do imóvel. Tente novamente mais tarde.'));
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro, a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
      this.form$ = this.getForm(true);
      this.form = toFormGroup(this.getForm(true) as ElementBase<string>[]);
    }
  }
}
