import { Expose, Transform, Type } from 'class-transformer';
import { IsDate, IsNotEmpty, ValidateNested } from 'class-validator';
import { ProjetoEndereco } from './projeto-endereco.model';

export class ProjetoConfigurarDetalhesComerciaisDto {
  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  cadastroDataInicio: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  cadastroDataFim: Date;

  @Type(() => ProjetoEndereco)
  @Expose()
  @ValidateNested()
  @IsNotEmpty()
  assembleiaLocal: ProjetoEndereco;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  assembleiaData: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  escritorioDataInicio: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  escritorioDataFim: Date;

  @Type(() => ProjetoEndereco)
  @Expose()
  @ValidateNested()
  @IsNotEmpty()
  escritorioLocal: ProjetoEndereco;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  preAnaliseDataInicio: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  preAnaliseDataFim: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  visitaDataInicio: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  visitaDataFim: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  cartorioDataInicio: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  cartorioDataFim: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  prefeituraDataInicio: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  prefeituraDataFim: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  matriculaDataInicio: Date;

  @Expose()
  @Transform(({ value }) => value ? new Date(value.setHours(12)) : undefined)
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  matriculaDataFim: Date;
}
