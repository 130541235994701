import { Component, Input } from '@angular/core';

@Component({
  selector: 'usucampeao-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() icon!: string;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() navigation = true;
  @Input() notificationNumber = 0;

  constructor() { }

}
