<ion-content>
    <usucampeao-header mode="md" mode="md" backRoute="/" title="Documentos"></usucampeao-header>

    <ion-grid class="ion-no-margin ion-no-padding ion-padding-start ion-padding-end">
        <ion-row class="ion-justify-content-center ion-padding-bottom">
            <ion-col sizeXs="12" sizeSm="6">
                <ion-text class="font-text">
                    Inserir fotos dos documentos do proprietário
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center" *ngIf="alteracoes && alteracoes.length > 0">
            <ion-col sizeXs="12" sizeSm="6">
                <usucampeao-change-alert [changes]='alteracoes'></usucampeao-change-alert>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-col sizeXs="12" sizeSm="6">
                <ion-list *ngIf="isLoading$ | async">
                    <usucampeao-skeleton-item></usucampeao-skeleton-item>
                    <usucampeao-skeleton-item></usucampeao-skeleton-item>
                </ion-list>
                <ng-container *ngIf="(isLoading$ | async) === false">
                    <usucampeao-process-progress *ngIf="!isIdentitySended" icon="document-attach-outline" title="Documento de identidade" titleClass="small" statusIcon='ellipse-outline' statusIconClass='icon-incomplete' statusText="Enviar" statusTextClass="secondary-red-text"
                        (click)="navigateToType('identidade')"></usucampeao-process-progress>
                    <usucampeao-process-progress *ngIf="!isSocialStateSended" icon="document-attach-outline" title="Comprovante de estado civil" titleClass="small" statusIcon='ellipse-outline' statusIconClass='icon-incomplete' statusText="Enviar" statusTextClass="secondary-red-text"
                        (click)="navigateToType('estado-civil')"></usucampeao-process-progress>
                    <usucampeao-process-progress *ngFor="let item of AllDocuments" icon="document-attach-outline" [status]="item.status" [title]="(item.name || item.type) | ProprietaryDocument" titleClass="small" [statusIcon]="statusProgressInformation(registration.idDocuments).icon" [statusIconClass]="statusProgressInformation(registration.idDocuments).iconClass"
                        [statusText]="statusProgressInformation(registration.idDocuments).name" [statusTextClass]="statusProgressInformation(registration.idDocuments).nameClass" (click)="gotoRoute(item)"></usucampeao-process-progress>
                </ng-container>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding-top">
            <div class="font-text font-text--small ion-text-center">
                <ion-text>Precisa de ajuda?</ion-text>&nbsp;
                <ion-text color="primary" (click)="presentModalInstruction()"><strong>Veja as instruções</strong>.</ion-text>
            </div>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-col sizeMd="3">
                <div class="ion-padding-top">
                    <div *ngIf="((this.authQuery.isConnectionOnline$ |async))">
                        <ion-button *ngIf="!blockAccess" mode="md" expand="block" (click)="navigateToType('escolher')">Adicionar documento</ion-button>
                    </div>

                    <div class="padding-top">
                        <div *ngIf="((this.authQuery.isConnectionOnline$ |async))">
                            <ion-button *ngIf="!blockAccess" class="ion-margin-bottom" fill="outline" mode="md" expand="block" (click)="onSubmit()">
                                {{ fluxo ? 'Continuar': 'Salvar' }}</ion-button>
                        </div>

                    </div>
                    <ion-label *ngIf="(this.authQuery.isConnectionOnline$ |async)===false">
                        <ion-icon name="cloud-offline-outline" slot="start"></ion-icon>
                        Sem internet
                    </ion-label>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
    <usucampeao-footer>
    </usucampeao-footer>
</ion-content>
