import { IsEnum, IsNotEmpty, IsNumber, IsNumberString, IsOptional, IsString, Length } from 'class-validator';
import { UF } from '../enums';

export class PropertyDto {
  @IsString()
  @IsOptional()
  id?: string;

  @IsString()
  @IsNotEmpty()
  street: string;

  @IsNumber()
  @IsOptional()
  number?: number;

  @IsString()
  @IsOptional()
  complement?: string;

  @IsNumberString()
  @Length(8, 8)
  zipCode: string;

  @IsString()
  @IsNotEmpty()
  district: string;

  @IsString()
  @IsNotEmpty()
  city: string;

  @IsEnum(UF)
  @IsNotEmpty()
  state: UF;
}
