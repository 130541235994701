import { Pipe, PipeTransform } from '@angular/core';
import { MaritalStatus } from '@usucampeao/lib-reurb-simplificado';


@Pipe({
  name: 'MaritalStatus'
})
export class MaritalStatusPipe implements PipeTransform {
  transform(value: MaritalStatus): string {
    switch (value) {
      case MaritalStatus.MARRIED:
        return 'Casado';
      case MaritalStatus.DIVORCED:
        return 'Divorciado';
      case MaritalStatus.SEPARATED:
        return 'Separado';
      case MaritalStatus.SINGLE:
        return 'Solteiro';
      case MaritalStatus.WIDOWED:
        return 'Viúvo';
      default:
        return 'Status não cadastrado';
    }
  }
}
