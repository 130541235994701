export class DashboardResumoDto {
  totalLotes: number;
  contratosVendidos: number;
  vendasValor: number;
  contratosCanceladosQuantidade: number;
  contratosCanceladosValor: number;

  constructor(
    totalLotes: number,
    contratosVendidos: number,
    vendasValor: number,
    contratosCanceladosQuantidade: number,
    contratosCanceladosValor: number,
  ) {
    this.totalLotes = totalLotes;
    this.contratosVendidos = contratosVendidos;
    this.vendasValor = vendasValor;
    this.contratosCanceladosQuantidade = contratosCanceladosQuantidade;
    this.contratosCanceladosValor = contratosCanceladosValor;
  }
}
