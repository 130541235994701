/* import { ApiProperty } from '@nestjs/swagger'; */
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, Length } from 'class-validator';
import { UF } from '../enums';

export class Address {
  @IsString()
  @Length(8, 9)
  /* @ApiProperty() */
  zipCode: string;

  @IsString()
  @IsNotEmpty()
  /* @ApiProperty() */
  street: string;

  @IsNumber()
  @IsNotEmpty()
  /* @ApiProperty() */
  number: number;

  @IsString()
  @IsOptional()
  /* @ApiProperty() */
  complement?: string;

  @IsString()
  @IsNotEmpty()
  /* @ApiProperty() */
  district: string;

  @IsString()
  @IsNotEmpty()
  /* @ApiProperty() */
  city: string;

  @IsEnum(UF)
  @IsNotEmpty()
  /*  @ApiProperty({ enum: UF, example: UF.SP }) */
  state: UF;

  @IsString()
  @IsOptional()
  /* @ApiProperty() */
  ibge?: string;
}
