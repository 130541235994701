import { ConfirmaEnvioModule } from './../../modal/confirma-envio/confirma-envio.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { FooterModule } from '../../../components/footer/footer.module';
import { HeaderModule } from '../../../components/header/header.module';
import { ToolbarModule } from '../../../components/toolbar/toolbar.module';
import { DiaPagamentoPipe } from '../../../pipes/dia-pagamento.pipe';
import { PipesModule } from '../../../pipes/pipes.module';
import { PropertyCardInstallmentsPipe } from '../../../pipes/property-card-installments.pipe';
import { PropertyNumberInstallmentsPipe } from './../../../pipes/property-number-installments.pipe';
import { PropertyPaymentPipe } from './../../../pipes/property-payment.pipe';
import { PropertyToSendRoutingModule } from './property-to-send-routing.module';
import { PropertyToSendPage } from './property-to-send.page';

@NgModule({
  imports: [
    CommonModule,
    UiMobileModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    CommonModule,
    ToolbarModule,
    FooterModule,
    HeaderModule,
    PropertyToSendRoutingModule,
    PipesModule,
    ConfirmaEnvioModule,
  ],
  providers: [
    DiaPagamentoPipe,
    PropertyNumberInstallmentsPipe,
    PropertyCardInstallmentsPipe,
    PropertyPaymentPipe,
  ],
  declarations: [PropertyToSendPage, PropertyNumberInstallmentsPipe,],
})
export class PropertyToSendModule { }
