<ion-content class="ion-padding-end ion-padding-start">
  <div class="content-vertical-space-between ion-no-padding">
    <ion-header class="ion-no-border ion-no-padding">
      <ion-toolbar>
        <ion-text slot="start" *ngIf="showPreviousButton" color="strong"
          ><strong class="text-link ion-no-padding" (click)="slidePrev()">Anterior</strong></ion-text
        >
        <ion-text slot="end" *ngIf="showNextButton" color="strong"
          ><strong class="text-link ion-no-padding" (click)="slideNext()">Próximo</strong></ion-text
        >
      </ion-toolbar>
    </ion-header>
    <div class="tutorial-content ion-text-center">
      <swiper #swiper (slideChange)="swiperSlideChanged()" [config]="config" [allowTouchMove]="true">
        <ng-container *ngFor="let slide of SLIDES">
          <ng-template swiperSlide>
            <div class="swiper-content">
              <img [src]="slide.img" />
              <p class="ion-no-margin ion-margin-top ion-margin-bottom font-text">{{slide.text}}</p>
            </div>
          </ng-template>
        </ng-container>
      </swiper>
      <p *ngIf="showNextButton" style="margin: 0 auto">
        <ion-text color="strong"
        ><strong class="text-link ion-no-padding" (click)="skipTutorial()">Pular</strong></ion-text
        >
      </p>
    </div>
    <usucampeao-footer>
          <ion-button *ngIf="!showNextButton" mode="md" expand="block" (click)="finishTutorial()">FINALIZAR</ion-button>
    </usucampeao-footer>
  </div>
</ion-content>
