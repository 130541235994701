import { Expose, Type } from 'class-transformer';
import { ReurbModalidade } from '../../enums';
import { AddressDto } from '../address/address.dto';
import { FileDto } from '../file';
import { MeioPagamento } from '../pagamento';
import { CadastroStatusVendaType } from './cadastro-status-venda.enum';
import { ListRegistration } from './list-registration.dto';
import { PendingSections } from './pending-section.model';
import { RegistrationStatus } from './registration-status.enum';

export class RegistrationSimplifiedDto extends ListRegistration {
  @Expose()
  blockId: number;

  @Expose()
  lotId: number;

  @Expose()
  quadra: string;

  @Expose()
  lote: string;

  @Expose()
  fillInStatus: PendingSections;

  @Type(() => Date)
  @Expose()
  schedulingDate?: Date;

  @Expose()
  valorContrato: number;

  @Expose()
  totalParcelas: number;

  @Expose()
  valorParcela: number;

  @Expose()
  meioPagamento: MeioPagamento;

  @Expose()
  valorEntrada: number;

  constructor(
    id: string,
    status: RegistrationStatus,
    statusVenda: CadastroStatusVendaType,
    projetoFid: string,
    projetoNome: string,
    address: AddressDto,
    propertyId: string,
    files: FileDto[],
    blockId: number,
    quadra: string,
    lotId: number,
    lote: string,
    fillInStatus: PendingSections,
    reurbModalidade: ReurbModalidade,
    contractDate?: Date,
    schedulingDate?: Date,
    valorContrato?: number,
    totalParcelas?: number,
    valorParcela?: number,
    meioPagamento?: MeioPagamento,
    valorEntrada?: number
  ) {
    super(id, status, statusVenda, projetoFid, projetoNome, address, propertyId, files, reurbModalidade, contractDate);
    this.blockId = blockId;
    this.quadra = quadra;
    this.lotId = lotId;
    this.lote = lote;
    this.fillInStatus = fillInStatus;
    this.schedulingDate = schedulingDate;
    this.valorContrato = valorContrato;
    this.totalParcelas = totalParcelas;
    this.valorParcela = valorParcela;
    this.meioPagamento = meioPagamento;
    this.valorEntrada = valorEntrada;
  }
}
