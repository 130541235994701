import { Injectable } from '@angular/core';
import { AuthState, AuthStore } from './auth.store';
import { Query } from '@datorama/akita';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  isLoggedIn$ = this.select(user => !!user.cpf);
  mePhoneNumber$ = this.select(user => user.phone);
  meCpf$ = this.select(user => user.cpf);
  photoId$ = this.select(user => user.photoId);
  isConnectionOnline$ = this.select(user => user.connected);
  meName$ = this.select(user => user.name);
  windowRef$ = this.select(user => user.recaptcha);
  token$ = this.select(user => user.token);

  constructor(protected store: AuthStore, private afAuth: AngularFireAuth,) {
    super(store);
  }

  getToken() {
    return this.afAuth.idToken;
  }
}
