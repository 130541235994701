
  <div class="content-vertical-space-between">
    <ion-grid class="ion-no-padding ion-padding-start ion-padding-end">
      <ion-row>
        <ion-col>
          <ion-item class="ion-no-padding" lines="none">
            <usucampeao-notification-badge [tipo]="notification?.tipo"></usucampeao-notification-badge>
            <ion-text class="ion-padding-start">
              <p class="title title--medium ion-no-margin">
                {{ notification?.titulo }}
              </p>
              <p class="ion-no-margin font-text font-text--small">
                {{ notification?.dataEnvio || '' | date }}
              </p>
            </ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p>{{ notification?.descricao ||'' }}.</p>
          <p>As seguintes pendências foram encontradas:</p>
          <ul>
            <li *ngFor="let item of notification.payload.alteracoes "><strong>{{ item ||'' }}</strong></li>
          </ul>
          <p>
            Você também poderá ver os comentários sobre correções na ficha do cadastro.
            <a class="text-link" (click)="goRegister()">Clique aqui</a> para navegar diretamente para o cadastro.
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
