
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillLeave } from '@ionic/angular';
import { ProjetoDetalhesComerciaisDto, ProjetoSimplificadoDto, TabelaPrecoValoresDto } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { RegisterService } from '../state/register.service';

@Component({
  selector: 'usucampeao-instrucao-nucleo',
  templateUrl: 'allotment-detail.page.html',
  styleUrls: ['allotment-detail.page.scss'],
})
export class AllotmentDetailPage implements OnDestroy, ViewWillLeave {
  private readonly ngUnsubscribe = new Subject();

  public projeto: ProjetoSimplificadoDto;
  public detalhesComerciais: ProjetoDetalhesComerciaisDto;
  public tabelasPreco: TabelaPrecoValoresDto[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registerService: RegisterService
  ) {
    this.route.params
      .pipe(
        tap(params => this.buscarDetalhesComerciais(params.id)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public get dataValidaNovoCadastro(): boolean {
    return this.registerService.dataValidaNovoCadastro();
  }

  private async buscarDetalhesComerciais(id: string) {
    this.registerService.buscarDetalheComercialProjeto(id)
      .pipe(
        tap(detalhesComerciais => this.detalhesComerciais = detalhesComerciais),
        switchMap(() => this.registerService.buscarDetalheProjeto(id)),
        tap(projeto => this.projeto = projeto),
        switchMap(() => this.registerService.buscarValoresTabelaPrecoProjeto(id)),
        tap(tabelaValores => this.tabelasPreco = tabelaValores),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public async navigateToRegistrationAcessCode(): Promise<void> {
    this.router.navigate([`lote`], { relativeTo: this.route });
  }
}
