import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FooterModule } from '../../../components/footer/footer.module';
import { HeaderModule } from '../../../components/header/header.module';
import { ProcessProgressModule } from '../../../components/process-progress/process-progress.module';
import { OwnersRoutingModule } from './owners-routing.module';
import { OwnersPage } from './owners.page';

@NgModule({
  declarations: [
    OwnersPage
  ],
  imports: [
    CommonModule,
    FooterModule,
    HeaderModule,
    IonicModule,
    ProcessProgressModule,
    OwnersRoutingModule,
    LazyLoadImageModule
  ]
})
export class OwnersModule { }
