import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import bugsnag from '@bugsnag/js';
import { NotificacaoDto, NotificacaoSimplificadaDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NotificationsStore } from './notification.store';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private http: HttpClient,
    private notificationStore: NotificationsStore,
  ) { }

  public getNotifications(): Observable<NotificacaoSimplificadaDto[]> {
    this.notificationStore.setLoading(true);
    return this.http
      .get<NotificacaoSimplificadaDto[]>(
        `/notificacoes`
      )
      .pipe(
        tap((res: NotificacaoSimplificadaDto[]) => this.notificationStore.set(res)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getNotifications-notifications'
            event.addMetadata('metadata', {
              url: `/notificacoes`,
              title: 'Erro ao buscar dados de notificação no estado',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.notificationStore.setLoading(false)));
  }

  public getNotification(id: string): Observable<NotificacaoDto> {
    this.notificationStore.setLoading(true);
    return this.http
      .get<NotificacaoDto>(
        `/notificacoes/${id}`
      )
      .pipe(
        tap((res: NotificacaoDto) => this.notificationStore.update(id, res)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getNotifications-notifications/id'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/notificacoes/${id}`,
              title: 'Erro ao buscar dados de notificação no estado',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          });
          return throwError(erro);
        }),
        finalize(() => this.notificationStore.setLoading(false)));
  }
}
