  <div class="content-vertical-space-between">
    <ion-grid class="ion-no-padding ion-padding-start ion-padding-end">
        <ion-row>
          <ion-col>
            <ion-item class="ion-no-padding" lines="none">
              <usucampeao-notification-badge
              [tipo]="notification?.tipo"
              ></usucampeao-notification-badge>
              <ion-text class="ion-padding-start">
                <p class="title title--medium ion-no-margin">
                  {{ notification?.titulo }}
                </p>
                <p class="ion-no-margin font-text font-text--small">
                  {{ notification?.dataEnvio || '' | date }}
                </p>
              </ion-text>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="font-text">
              <p>
                Boa notícia, recebemos seu pagamento e agora o agendamento da visita
                de validação está disponível!
              </p>
              <p>
                Em breve, nossa equipe irá entrar em contato com você para agendar
                esta próxima etapa. Todas as informações também serão passadas para
                você nesta ligação.
              </p>
              <p>
                Tentaremos contato através dos telefones informados no seu cadastro.
                Caso não receba esse telefonema nos próximos dias, entre em contato
                com a gente.
              </p>
            </div>
          </ion-col>
        </ion-row>
    </ion-grid>
  </div>

