import { AlteracaoDto } from './alteracao.dto';
import { StatusRevisao } from './status-revisao.enum';

export class RevisaoDto {
  id: string;
  cadastroId: string;
  usuarioId: string;
  usuarioNome: string;
  alteracoes: AlteracaoDto[];
  status: StatusRevisao;
  dataCriacao: Date;
  dataAtualizacao: Date;
}
