import { Component, Input, OnInit } from '@angular/core';
import { AlteracaoDto } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-change-alert',
  templateUrl: './change-alert.component.html',
  styleUrls: ['./change-alert.component.scss'],
})
export class ChangeAlertComponent implements OnInit {
  @Input() changes: AlteracaoDto[];

  constructor() {

  }

  ngOnInit(): void { }
}
