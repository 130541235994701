export enum CoberturaTipo {
  TELHA = 'TELHA',
  LAJES_DE_CONCRETO = 'LAJES_DE_CONCRETO',
  MADEIRA_APARELHADA = 'MADEIRA_APARELHADA',
  ZINCO = 'ZINCO',
  PALHA = 'PALHA',
  SAPE = 'SAPE',
  MADEIRA_APROVEITADA = 'MADEIRA_APROVEITADA',
  OUTRO = 'OUTRO',
}
