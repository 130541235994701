import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Endereco } from '@brunoc/ngx-viacep';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import {
  UpdatePropertyAddressAdditionalInformationsDto
} from '@usucampeao/lib-reurb-simplificado';
import { ElementBase, toFormGroup } from '@usucampeao/ui-mobile';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged, switchMap, takeUntil
} from 'rxjs/operators';
import { PropertyOccupancyPipe } from '../../../../../pipes/property-occupancy.pipe';
import { PropertySituationPipe } from '../../../../../pipes/property-situation.pipe';
import { PropertyUsagePipe } from '../../../../../pipes/property-usage.pipe';
import { LoadingService } from '../../../../../services/loading.service';
import { NavigationService } from '../../../../../services/navigation.service';
import { ToastService } from '../../../../../services/toast.service';
import { getFormEditAdditional } from '../../../../registration/property/edit-additional-information/edit-additional-information.form';
import { PropertyService } from '../../../../registration/property/state/property.service';
import { RegisterQuery } from '../../../state/register.query';
import { RegisterService } from '../../../state/register.service';
import { getFormAddress } from './lot-address.form';

@Component({
  selector: 'usucampeao-lot-address',
  templateUrl: 'lot-address.page.html',
  styleUrls: ['lot-address.page.scss'],
})
export class LotAddressPage implements OnInit, ViewWillEnter, ViewWillLeave, OnDestroy {
  private ngUnsubscribe = new Subject();

  public form!: FormGroup;
  public formAddress: FormGroup;
  public formLotAddress$: ElementBase<string>[] | null = [];
  public formAddress$: ElementBase<string>[] = [];
  public formSpecifyUsagel$: ElementBase<string>[] = [];
  public formOccupation$: ElementBase<string>[] = [];
  public formSpecifySituation$: ElementBase<string>[] = [];
  public formResidentsQuantity$: ElementBase<string>[] = [];
  isOtherLotAddress$ = false;
  isOtherOccupation$ = false;

  constructor(
    private loadingService: LoadingService,
    private propertySituationPipe: PropertySituationPipe,
    private propertyUsagePipe: PropertyUsagePipe,
    private propertyOccupancyPipe: PropertyOccupancyPipe,
    private propertyService: PropertyService,
    private registerService: RegisterService,
    private registerQuery: RegisterQuery,
    private router: Router,
    private toastService: ToastService,
    private navigationService: NavigationService,
  ) { }

  ngOnInit() {
    this.initForms(false);
  }

  private initForms(readOnlyOption) {
    this.formAddress$ = getFormAddress();
    this.formAddress = toFormGroup(getFormAddress());
    this.formLotAddress$ = getFormEditAdditional(this.propertyUsagePipe, this.propertyOccupancyPipe, this.propertySituationPipe, readOnlyOption);
    this.form = toFormGroup(this.getForm(readOnlyOption) as ElementBase<string>[]);
  }

  ionViewWillEnter(): void {
    this.registerQuery.selectLoading()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(async isLoading => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });

    this.formAddress
      .get('zipCode')
      ?.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data) => {
        if (data.length === 9) {
          this.getZipCode(data);
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public get formAddressControls() {
    return this.formAddress$.filter((data) => data.visible);
  }

  public getZipCode(cep: string): void {
    this.propertyService
      .getAddressByZipCode(cep)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (endereco: Endereco) => {
          this.populaDadosForm(endereco);
        },
        async () => await this.toastService.erro('Erro ao buscar endereço. Por favor tente novamente.')
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public populaDadosForm(dados: any): void {
    this.formAddress.patchValue({
      street: dados.logradouro,
      number: null,
      complement: null,
      neighborhood: dados.bairro,
      city: dados.localidade,
      state: dados.uf,
    });

  }

  getForm(readOption: boolean) {
    const questions: ElementBase<string>[] = [
      ...getFormEditAdditional(this.propertyUsagePipe, this.propertyOccupancyPipe, this.propertySituationPipe, readOption),
    ];
    return questions;
  }

  public async navigateToProcessFile(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      await this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
      return;
    }

    const formData = this.form.value

    const value: UpdatePropertyAddressAdditionalInformationsDto = {
      ...this.form.value,
      address: { ...this.formAddress.value },
      loteArea: +formData.loteArea
      // residentsQuantity: +formValue.residentsQuantity,
      // residenceTime: +formValue.residenceTime,
      // housesQuantity: +formValue.housesQuantity,
      // housesQuantitySameOwner: +formValue.housesQuantitySameOwner,
      // entriesQuantity: +formValue.entriesQuantity,
      // entriesQuantitySamePavement: +formValue.entriesQuantitySamePavement,
    };


    this.registerService
      .addPropertyAddressAndAdditionalInformations(value)
      .pipe(
        switchMap(() => this.registerQuery.registrationId$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        id => {
          this.registerService.resetStore();
          this.navigationService.clearHistoryNavigation()
          this.router.navigate([`/atendimento`, id]);
        },
        async () => {
          await this.toastService.erro('Erro ao atualizar dados de endereço do imóvel. Tente novamente mais tarde.');
        }
      );
  }
}
