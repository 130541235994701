import { Expose } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsString, ValidateIf } from 'class-validator';
import { DocumentFileSubtype } from './document-file-subtype.enum';
import { DocumentFileType } from './document-file.dto';

export class JustifyDocumentDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  justification: string;

  @Expose()
  @IsEnum(DocumentFileSubtype)
  @IsNotEmpty()
  @ValidateIf(obj => !obj.type)
  subtype?: DocumentFileSubtype;

  @Expose()
  @IsNotEmpty()
  @ValidateIf(obj => !obj.subtype)
  type?: DocumentFileType;

  constructor(justification: string) {
    this.justification = justification;
  }
}
