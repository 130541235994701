import { TipoNotificacao } from '@usucampeao/lib-reurb-simplificado';
import { Component, Input } from '@angular/core';
import { getNotificationIconName, NotificationSimplifiedReurb } from '../../../models/notification-simplified-reurb.enum';

@Component({
  selector: 'usucampeao-notification-badge',
  templateUrl: 'notification-badge.component.html',
  styleUrls: ['notification-badge.component.scss'],
})
export class NotificationBadgeComponent {
  @Input()
  tipo!: TipoNotificacao;

  constructor() {}

  public get statusSimplified(): typeof NotificationSimplifiedReurb {
    return NotificationSimplifiedReurb;
  }

  public statusIcon(tipo: TipoNotificacao): string {
    return getNotificationIconName(tipo);
  }
}
