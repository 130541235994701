import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { FooterModule } from '../../../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../../../components/header/header.module';
import { ProcessProgressModule } from '../../../../../../components/process-progress/process-progress.module';
import { JustificationRoutingModule } from './justification-routing.module';
import { JustificationPage } from './justification.page';

@NgModule({
  declarations: [
    JustificationPage
  ],
  imports: [
    CommonModule,
    FooterModule,
    HeaderModule,
    IonicModule,
    FormsModule,
    ProcessProgressModule,
    JustificationRoutingModule,
    UiMobileModule,
    ReactiveFormsModule,
  ]
})
export class JustificationModule { }
