<div>
    <ion-grid class="ion-no-margin ion-no-padding">
        <ion-row class="ion-padding">
            <ion-col size="2" class="ion-padding-left">
                <ion-icon class="" name='warning-sharp'></ion-icon>
            </ion-col>
            <ion-col size="10">
                <ion-row>
                    <ion-text class="font-text font-text--large">Alterações necessárias</ion-text>
                </ion-row>
                <ion-row>
                    <ion-text class="description-text">Realize as alterações solicitadas para continuar com seu processo</ion-text>
                </ion-row>
            </ion-col>
        </ion-row>
        <ion-row class="ion-padding-start ion-padding-end ion-padding-bottom">
            <ion-col>
                <ion-text *ngFor="let item of changes" class="font-text">• {{item.descricao}}<br></ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>