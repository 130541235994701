
import { Component, Input } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';


@Component({
  selector: 'usucampeao-cadastro-pre-aprovado',
  templateUrl: './cadastro-pre-aprovado.component.html',
  styleUrls: ['./cadastro-pre-aprovado.component.scss'],
})
export class CadastroPreAprovadoComponent {
  @Input()
  public notification!: NotificacaoDto;

  constructor() {}
}