<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
    <ion-text>
        <h6 class="title">Encerrar cadastro?</h6>
    </ion-text>
    <ion-text class="font-text">
        <p>Para realizar a remoção do cadastro, inclua o motivo da solicitação em clique em <span> ENCERRAR. </span></p>
        <p><span> ATENÇÃO!</span> Esta operação <span> não poderá ser desfeita </span>e, caso deseje regularizar o seu imóvel, precisará iniciar um novo cadastro.</p>
    </ion-text>
    <ion-row class="ion-justify-content-center">
      <ion-col >
        <form mode="md" [formGroup]="form" class="ion-text-center">
          <div *ngFor="let field of form$" class="form-row ion-no-padding">
            <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
          </div>
        </form>
      </ion-col>
    </ion-row>
    <ion-grid >
        <ion-row class="ion-justify-content-end">
            <ion-col size="auto">
                <a class="ion-padding-end alinhamento" (click)="dismiss()"><b>VOLTAR</b></a>
                <ion-button mode="md" (click)="encerrar()">ENCERRAR</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
