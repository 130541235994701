import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FileDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PropertyStore, PropertyState } from './property.store';

@Injectable({ providedIn: 'root' })
export class PropertyQuery extends QueryEntity<PropertyState> {
  constructor(protected store: PropertyStore) {
    super(store);
  }

  /**
   * Busca arquivos de um imóvel
   * @param id id do imóvel
   * @returns arquivos do imóvel
   */
  public getFiles(id: string): Observable<FileDto[]> {
    return this.selectEntity(id)
      .pipe(
        map(property => property?.files)
      );
  }
}
