<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
  <ion-text>
    <p class="title">Confirma o envio?</p>
  </ion-text>
  <ion-text class="font-text">
    <p>
      Ao clicar em <b>ENVIAR</b>, você está aceitando os termos do contrato apresentado e realizando a contratação deste serviço de regularização imobiliária. Seu cadastro será enviado para análise pela nossa equipe.
    </p>
    <p>
      Enquanto o cadastro estiver em análise, não será possível alterá-lo.
    </p>
    <p>
      Enviar para análise?
    </p>
  </ion-text>
  <ion-grid class="ion-justify-content-end">
    <ion-row class="ion-justify-content-end">
      <ion-col size="auto" >
        <ion-button fill="clear" (click)="dismiss()"><b>Voltar</b></ion-button>
      </ion-col>
      <ion-col size="auto" >
        <ion-button (click)="navigateTo()" mode="md"> Enviar</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
