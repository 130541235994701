import { IsOptional, IsString } from 'class-validator';

export class PushNotification {
  @IsString()
  @IsOptional()
  uid: string;

  @IsString()
  @IsOptional()
  message: string;

  @IsString()
  @IsOptional()
  title: string;
}
