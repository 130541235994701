import { Validators } from '@angular/forms';
import {
  PropertyOccupancy,
  PropertySituation,
  PropertyUsage
} from '@usucampeao/lib-reurb-simplificado';
import { Dropdown, ElementBase, RadioList, Textbox } from '@usucampeao/ui-mobile';
import { PropertyOccupancyPipe } from '../../../../pipes/property-occupancy.pipe';
import { PropertySituationPipe } from './../../../../pipes/property-situation.pipe';
import { PropertyUsagePipe } from './../../../../pipes/property-usage.pipe';

export function getFormEditAdditional(
  propertyUsagePipe: PropertyUsagePipe,
  propertyOccupancyPipe: PropertyOccupancyPipe,
  propertySituationPipe: PropertySituationPipe,
  readOnlyOption: boolean
): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'usage',
      label: 'Uso do domicílio',
      disabled: readOnlyOption,
      description: 'Situação e utilização',
      options: Object.values(PropertyUsage).map((usage) => {
        return {
          key: usage,
          value: propertyUsagePipe.transform(usage),
        };
      }),
      validators: [Validators.required]
    }),
    new Dropdown({
      key: 'occupation',
      label: 'Caracterização da ocupação',
      disabled: readOnlyOption,
      options: Object.values(PropertyOccupancy).map((occupation) => {
        return {
          key: occupation,
          value: propertyOccupancyPipe.transform(occupation),
        };
      }),
      validators: [Validators.required]
    }),
    new Dropdown({
      key: 'situation',
      label: 'Situação',
      disabled: readOnlyOption,
      options: Object.values(PropertySituation).map((situation) => {
        return {
          key: situation,
          value: propertySituationPipe.transform(situation),
        };
      }),
      validators: [Validators.required]
    }),
    new Textbox({
      key: 'loteArea',
      label: 'Área do terreno ou lote',
      type: 'tel',
      readonly: readOnlyOption,
    }),
    new RadioList({
      key: 'possuiOutroImovel',
      description: 'Possui outro imóvel?',
      options: [
        {
          key: false,
          value: 'Não'
        },
        {
          key: true,
          value: 'Sim'
        }
      ]
    })
  ];
}
