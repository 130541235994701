<ion-content class="ion-no-padding">
  <div class="content-vertical-space-between">
    <usucampeao-header mode="md" backRoute="/novo-cadastro/loteamento" title="Novo Cadastro"> </usucampeao-header>
    <ion-grid class="ion-no-border ion-padding-end ion-padding-start ion-no-padding">
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-header class="ion-no-border">
            <ion-label>
              <ion-text>
                <h4>
                  <b>{{ projeto?.nome || '' }}</b>
                </h4>
              </ion-text>
              <p>{{ projeto?.localizacao.rua || '' }}, {{ projeto?.localizacao.numero  || '' }}</p>
            </ion-label>
          </ion-header>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-text>
            <p class="p">
              O bairro <b>{{ projeto?.nome || '' }}</b> está participando do processo de regularização através da lei
              federal 13.465/17, mais conhecido como a Lei da REURB; no qual os possuidores de imóveis poderão receber o
              <b>título dos seus lotes</b>.
            </p>
            <p class="p">
              Para participar é muito simples, é só dar andamento a esse cadastro e ficar atento ao
              <b>cronograma</b> apresentado abaixo.
            </p>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-text>
            <h5><strong>Calendário</strong></h5>
          </ion-text>
        </ion-col>
      </ion-row>
      <usucampeao-fase-nucleo [data]="detalhesComerciais"></usucampeao-fase-nucleo>
      <ion-row class="ion-justify-content-center ion-margin-top">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-text>
            <h6><strong>Valor</strong></h6>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-text>
            <p class="p">O custo para contratar o serviço de REURB (regularização do imóvel) é de</p>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6" class="background-color">
          <ion-text class="title title--medium">
            <ng-container *ngFor="let tabelaPreco of tabelasPreco">
              <p class="price"><span *ngIf="tabelaPreco.modalidade">{{ tabelaPreco.modalidade | reurbModalidade }} - </span> {{ tabelaPreco.valorEfetivo | currency:'BRL' }}</p>
            </ng-container>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-text>
            <p class="p">
              Esse valor poderá ser parcelado em até 12x sem juros e você só irá realizar o primeiro pagamento após o
              envio e validação da sua documentação.
            </p>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-text>
            <p class="p">
              E o agendamento da visita somente será liberada após o reconhecimento do pagamento total ou da parcela de
              entrada.
            </p>
          </ion-text>
        </ion-col>
      </ion-row>
      <!-- <ion-row class="ion-justify-content-center">
                <ion-col sizeXs="12" sizeSm="6">
                    <ion-text>
                        <p class="p">
                            Em caso de dúvidas, não hesite em entrar em contato com a equipe de apoio presente no bairro, no endereço indicado acima. Você também pode conferir novamente nosso guia <a class="text-link " (click)="presentModalTutorial() ">clicando aqui.</a>
                        </p>
                    </ion-text>
                </ion-col>
            </ion-row> -->
      <ion-row class="ion-justify-content-center ion-margin-top" *ngIf="projeto?.vendaStatus==='EM_ANDAMENTO'">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-button mode="md" expand="block" (click)="navigateToRegistrationAcessCode()">Continuar</ion-button>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-margin-top" *ngIf="projeto?.vendaStatus !=='EM_ANDAMENTO'">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-text class="ion-text-center">
            <p class="cadastros-encerrados">Cadastros encerrados</p>
          </ion-text>
        </ion-col>
      </ion-row>
      <!-- <ion-row class="ion-justify-content-center">
                <ion-col sizeXs="12" sizeSm="6" class="ion-text-center">
                    <br>
                    <ion-text class="p">
                        Se ainda estiver com dúvidas,
                    </ion-text>
                    <a class="ion-no-margin text-link" (click)="presentModalClickHereRequestHelp() ">
                        clique aqui e solicite ajuda
                    </a>
                </ion-col>
            </ion-row> -->
    </ion-grid>
    <usucampeao-footer></usucampeao-footer>
  </div>
</ion-content>
