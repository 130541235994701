import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TypePage } from './type.page';

const routes: Routes = [
  { path: '', component: TypePage },
  {
    path: 'justificativa',
    loadChildren: () =>
      import('./justification/justification.module').then((m) => m.JustificationModule),
  },
  {
    path: 'foto/:type/:documentid',
    loadChildren: () =>
      import('./photo/photo.module').then((m) => m.PhotoPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TypeRoutingModule { }
