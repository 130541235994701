import { IsEnum, IsString } from 'class-validator';
import { CondicoesGerais } from '../condicoes-gerais.enum';
import { PisoTipo } from './piso-tipo.enum';

export class Piso {
  @IsEnum(PisoTipo)
  tipo: PisoTipo;

  @IsString()
  tipoEspecifique?: string;

  @IsEnum(CondicoesGerais)
  condicoesGerais: CondicoesGerais;
}
