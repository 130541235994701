import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';




@Component({
  selector: 'usucampeao-pendencias-cadastro',
  templateUrl: './pendencias-cadastro.component.html',
  styleUrls: ['./pendencias-cadastro.component.scss'],
})
export class PendenciasCadastroComponent {
  @Input()
  public notification!: NotificacaoDto;

  @Output() goto = new EventEmitter();

  constructor() { }

  goRegister() {
    this.goto.emit(this.notification);
  }
}