import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PropertyPage } from './property.page';

const routes: Routes = [
  { path: '', component: PropertyPage },
  {
    path: 'lote',
    loadChildren: () =>
      import('./edit-map/edit-map.module').then((m) => m.EditMapModule),
  },
  {
    path: 'endereco',
    loadChildren: () =>
      import('./edit-address/edit-address.module').then((m) => m.EditAddressModule),
  },
  {
    path: 'foto-fachada',
    loadChildren: () =>
      import('./edit-photo/edit-photo.module').then((m) => m.EditPhotoModule),
  },
  {
    path: 'informacoes-adicionais',
    loadChildren: () =>
      import('./edit-additional-information/edit-additional-information.module').then((m) => m.EditAdittionalInformationModule),
  },
  {
    path: 'documentos',
    loadChildren: () =>
      import('./property-documents/property-documents.module').then((m) => m.PropertyDocumentsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PropertyRoutingModule { }
