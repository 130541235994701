<ion-content class="ion-padding-start ion-padding-end">
  <div class="content-vertical-space-between">
    <usucampeao-header mode="md" title="Cadastros"></usucampeao-header>
    <ion-text style="margin-bottom: 1rem;" *ngIf="pagamentos.length > 0">
      Parcelas próximas do vencimento
    </ion-text>
    <ion-row>
      <ion-col>
        <ng-container *ngFor="let item of pagamentos; let i = index">
          <usucampeao-parcela *ngIf="i < 3 || mostrarTodosPagamentos" [pagamento]="item" [home]="true" (click)="navigateToParcela(item)"></usucampeao-parcela>
        </ng-container>
        <ion-button *ngIf="pagamentos.length > 3" mode="md" expand="block" class="ion-margin-bottom" (click)="toggleMostrarPagamentos()">
          {{ mostrarTodosPagamentos ? 'Esconder' : 'Mostrar todos' }}
        </ion-button>
      </ion-col>
    </ion-row>

    <div *ngIf="(registrations$ | async).length === 0" class="home__content">
      <ion-icon color="light" name="file-tray" class="home__icon"></ion-icon>
      <ion-text class="font-text ion-text-center">
        Nenhum processo cadastrado
        <br>
      </ion-text>
      <ion-text color="primary" routerLink="/novo-cadastro" (click)="navega()">
        <strong>Cadastre seu imóvel</strong>
      </ion-text>
    </div>
    <ion-grid *ngIf="(registrations$ | async).length > 0" class="ion-no-padding">
      <ion-row>
        <ion-col class="ion-no-padding ion-margin-bottom" sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="3"
          *ngFor="let item of registrations$ | async">
          <usucampeao-attendance-card [registration]="item"></usucampeao-attendance-card>
        </ion-col>
      </ion-row>
    </ion-grid>
    <usucampeao-footer>
      <ion-button mode="md" expand="block" class="ion-margin-bottom" (click)="navega()">
        Novo Cadastro
      </ion-button>
    </usucampeao-footer>
  </div>
</ion-content>
