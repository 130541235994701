<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
    <ion-text>
        <p>Documentos Necessários</p>
    </ion-text>
    <ion-text class="font-text">
        <p>
            São necessários, pelo menos, os seguintes documentos pessoais:
        </p>
        <ul>
            <li>
                1 documento de identidade com foto e números de RG e CPF
            </li>
            <li>
                1 comprovante de estado civil
            </li>
        </ul>
        <p>
            Também são necessários, pelo menos, os seguintes documentos do imóvel:
        </p>
        <ul>
            <li>
                1 documento de aquisição
            </li>
            <li>
                1 comprovante de tempo de posse
            </li>
        </ul>
        <p>
            Quanto mais documentos enviar, melhor será para o andamento do processo.
        </p>
        <p>
            Precisa de ajuda? <a href="https://lp.usucampeao.com.br/lp-pode-regularizar" target="_blank">Veja as instruções</a>.
        </p>
    </ion-text>
    <ion-grid>
        <ion-row class="ion-justify-content-end">
            <ion-col size="auto">
                <a (click)="dismiss()"><b>Entendi</b></a>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
