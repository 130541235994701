<ion-item [detail]="detail" [class.disabled]="disabled">
  <ion-grid class="ion-no-padding ion-padding-top ion-padding-bottom">
    <ion-row class="ion-align-items-center">
      <ion-col size="2">
        <ion-icon [name]="icon"></ion-icon>
      </ion-col>
      <ion-col size="8">
        <ion-text [class]="titleClass">
          {{ title || '' }}
        </ion-text>
        <br />
        <div *ngIf="subtitle">
          <ion-text class="description-text">
            {{ subtitle }}
          </ion-text>
          <br />
        </div>
        <ion-text [class]="statusTextClass">
          {{ statusText || '' }}
        </ion-text>
      </ion-col>
      <div>
        <ion-col class="ion-text-end ion-padding-start">
          <ion-icon [name]="nomeIcone" [class]="classeIcone"></ion-icon>
        </ion-col>
      </div>
    </ion-row>
  </ion-grid>
</ion-item>
