import { Expose, plainToInstance } from 'class-transformer';
import { ReurbModalidade } from '../../enums';
import { TabelaPrecoDto } from './tabela-preco.dto';

export class TabelaPrecoValoresDto {
  @Expose()
  valorBase: number;

  @Expose()
  valorEfetivo: number;

  @Expose()
  modalidade?: ReurbModalidade;

  /**
   * Cria uma nova instância de TabelaPrecoValoresDto a partir de dtos
   * @param props DTO de Tabela de preço
   * @returns nova instância de TabelaPrecoValoresDto
   */
  static from(props: TabelaPrecoDto): TabelaPrecoValoresDto {
    return plainToInstance(TabelaPrecoValoresDto, props, { excludeExtraneousValues: true, exposeUnsetFields: false });
  }
}
