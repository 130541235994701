import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { GenderPipe } from '../../../../../pipes/gender.pipe';
import { MaritalStatusPipe } from '../../../../../pipes/marital-status.pipe';
import { MatrimonialRegimePipe } from '../../../../../pipes/matrimonial-regime.pipe';
import { ChangeAlertModule } from './../../../../../components/change-alert/change-alert.module';
import { FooterModule } from './../../../../../components/footer/footer.module';
import { HeaderModule } from './../../../../../components/header/header.module';
import { PipesModule } from './../../../../../pipes/pipes.module';
import { BasicInformationRoutingModule } from './basic-information-routing.module';
import { BasicInformationPage } from './basic-information.page';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    UiMobileModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    BasicInformationRoutingModule,
    CommonModule,
    FooterModule,
    HeaderModule,
    ChangeAlertModule,
  ],

  declarations: [
    BasicInformationPage
  ],
  providers: [
    GenderPipe,
    MaritalStatusPipe,
    MatrimonialRegimePipe,
    CurrencyPipe
  ]
})
export class BasicInformationModule { }
