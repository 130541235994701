import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from './../../pages/notifications/state/notifications.service';
import { NotificationsQuery } from './../../pages/notifications/state/notifications.query';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ViewWillLeave } from '@ionic/angular';
import { AuthService } from '../../pages/auth/state/auth.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'usucampeao-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy, ViewWillLeave{
  private ngUnsubscribe = new Subject();
  public notifications$ = this.notificationsQuery.selectAll();
  public totalNotificacoesNaoLidas$ = this.notificationsQuery.totalNotificacoesNaoLidas$;

  constructor(
    private notificationsService: NotificationsService,
    private notificationsQuery: NotificationsQuery,
    private router: Router,
    private authService: AuthService,
    private menuCtrl: MenuController,
  ) { }

  ngOnInit(): void {
    this.notificationsService
      .getNotifications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public close(): void {
    this.menuCtrl.close();
  }

  public closeAndNavigate(route: string): void {
    this.close();
    this.router.navigate([route]);
  }

  logout() {
    this.close();
    this.authService.logout();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

}
