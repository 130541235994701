import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailsPage } from './details.page';

const routes: Routes = [
  { path: '', component: DetailsPage },
  {
    path: ':ownerId/dados-basicos',
    loadChildren: () =>
      import('./basic-information/basic-information.module').then((m) => m.BasicInformationModule)
  },
  {
    path: ':ownerId/documentos',
    loadChildren: () =>
      import('./documents/documents.module').then((m) => m.DocumentsModule)
  },
  {
    path: ':ownerId/contato',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule)
  },
  {
    path: ':ownerId/envio-documentos',
    loadChildren: () =>
      import('./sent-documents/sent-documents.module').then((m) => m.SentDocumentsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DetailsRoutingModule { }
