import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotPhotoRoutingModule } from './lot-photo-routing.module';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from '../../../../../components/header/header.module';
import { LotPhotoPage } from './lot-photo.page';
import { PhotoModule } from '../../../../../components/photo/photo.module';
import { FooterModule } from '../../../../../components/footer/footer.module';

@NgModule({
  declarations: [
    LotPhotoPage,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    IonicModule,
    PhotoModule,
    FooterModule,
    LotPhotoRoutingModule
  ]
})
export class LotPhotoModule { }
