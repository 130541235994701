import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController, ViewWillLeave } from '@ionic/angular';
import { Subject } from 'rxjs';
import { finalize, first, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { LoadingService } from '../../../../services/loading.service';
import { ContactModal } from '../../../modal/contact/contact.modal';
import { RegistrationQuery } from '../../state/registration.query';

@Component({
  selector: 'usucampeao-agendamento',
  templateUrl: 'appointment.page.html',
  styleUrls: ['appointment.page.scss'],
})
export class AppointmentPage implements OnInit, ViewWillLeave, OnDestroy {
  public form: FormGroup;
  public minDate = new Date(2022, 0, 5).toISOString();
  public maxDate = new Date(2022, 0, 30).toISOString();
  public step = 0;
  public selectedDate: Date;
  private ngUnsubscribe = new Subject();
  public registrationId: string;

  constructor(
    private loaderService: LoadingService,
    private registrationQuery: RegistrationQuery,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private modalController: ModalController
  ) {
    this.form = this.formBuilder.group({
      extendedDate: [this.extendedDate],
      date: [null, Validators.required],
    });

    this.route.params
      .pipe(
        first(),
        tap(params => this.registrationId = params.id),
        mergeMap(() => this.registrationQuery.selectLoading()),
        tap(async isLoading => {
          if (isLoading) {
            await this.loaderService.createLoader();
          } else {
            await this.loaderService.dismiss();
          }
        }),
        finalize(async () => await this.loaderService.dismiss()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  ngOnInit(): void { }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public get extendedDate(): string {
    const dateValue = this.form?.get('date')?.value;
    const date = dateValue ? new Date(dateValue) : new Date();
    return this.datePipe.transform(date, 'dd MMMM, yyyy') || '';
  }

  public setDate(): void {
    this.form.get('extendedDate')?.setValue(this.extendedDate);
  }

  public selectDate(): void {
    this.selectedDate = new Date(this.form.get('date').value);
    this.nextStep();
  }

  public selectHour(date: Date): void {
    this.selectedDate = date;
    this.nextStep();
  }

  public nextStep(): void {
    this.step += 1;
  }

  public previousStep(): void {
    this.step -= 1;
  }
  async presentModalContact() {
    const modal = await this.modalController.create({
      component: ContactModal,
      cssClass: 'small-modal',
    });
    return await modal.present();
  }
}
