import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OwnersStore, OwnerState } from './owners.store';

@Injectable({ providedIn: 'root' })
export class OwnersQuery extends QueryEntity<OwnerState> {
  constructor(protected store: OwnersStore) {
    super(store);
  }

}
