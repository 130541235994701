import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { InstructionModal } from './instruction.modal';

@NgModule({
  imports: [CommonModule,IonicModule, UiMobileModule, CommonModule],

  declarations: [InstructionModal],

  exports:[InstructionModal],
})
export class InstructionModule {}
