import { Type } from 'class-transformer';
import { IsEmail, IsNotEmpty, IsNumber, IsNumberString, IsOptional, IsString, Length, ValidateNested } from 'class-validator';
import { Address } from './../address/address.model';

export class OmieCustomerDto {
  @IsNumber()
  @IsOptional()
  //@ApiProperty()
  id?: number;

  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  personId: string;

  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  name: string;

  @IsEmail()
  @IsNotEmpty()
  //@ApiProperty()
  email: string;

  @IsNumberString()
  @Length(10, 11)
  @IsNotEmpty()
  //@ApiProperty()
  phone: string;

  @IsNumberString()
  @Length(11, 14)
  @IsNotEmpty()
  //@ApiProperty()
  document: string;

  @Type(() => Address)
  @ValidateNested()
  @IsNotEmpty()
  //@ApiProperty()
  address: Address;
}
