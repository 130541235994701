import { IsNotEmpty, IsString } from 'class-validator';

export class AccessCode {
  @IsString()
  @IsNotEmpty()
  code: string;

  constructor(code: string) {
    this.code = code;
  }
}
