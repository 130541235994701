import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { PagamentoStatus, RegistrationSimplifiedDto, RegistrationStatus, StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';
import { format } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuItem, MenuItemClassColor } from '../../models/menu-item.interface';
import { getSimplifiedStatusText } from '../../models/status-simplified-reurb.enum';
import { LoadingService } from '../../services/loading.service';
import { NavigationService } from '../../services/navigation.service';
import { ToastService } from '../../services/toast.service';
import { ConfirmaReenvioCadastroModal } from '../modal/confirma-reenvio-cadastro/confirma-reenvio-cadastro.modal';
import { RegistrationHelpModal } from '../modal/registration-help/registration-help.modal';
import { ProjetoQuery } from '../projeto/state/projeto.query';
import { ExcluiCadastroModal } from './../modal/exclui-cadastro/exclui-cadastro.modal';
import { PagamentoService } from './pagamento/state/pagamentos.service';
import { PropertyService } from './property/state/property.service';
import { RegistrationQuery } from './state/registration.query';
import { RegistrationService } from './state/registration.service';

@Component({
  selector: 'usucampeao-registration',
  templateUrl: 'registration.page.html',
  styleUrls: ['registration.page.scss'],
})
export class RegistrationPage
  implements ViewWillEnter, ViewWillLeave, OnDestroy {
  private registrationId: string;
  public registration: RegistrationSimplifiedDto;
  public loadingImage = '../../../../assets/imgs/defaultImage.svg';
  public defaultImage = '../../../../assets/imgs/placeholder.png';
  private ngUnsubscribe = new Subject();
  public projeto: any;
  public situacaoPagamento: string;

  constructor(
    private loadingService: LoadingService,
    private modalController: ModalController,
    private registrationQuery: RegistrationQuery,
    private registrationService: RegistrationService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private pagamentoService: PagamentoService,
    private toastController: ToastController,
    private toastService: ToastService,
    private projetoQuery: ProjetoQuery,
    private navigationService: NavigationService
  ) { }

  ionViewWillEnter(): void {
    this.navigationService.clearHistoryNavigation();
    this.route.params
      .pipe(
        tap(params => this.registrationId = params.id),
        tap(() => this.getRegistration(this.registrationId)),
        switchMap(() => this.pagamentoService.getPayments(this.registrationId)),
        map(pagamentos => pagamentos.filter((pagamento) => pagamento.status !== PagamentoStatus.PAGO).length),
        tap(quantidadePagamentosNaoPagos => this.situacaoPagamento = quantidadePagamentosNaoPagos > 0 ? 'Parcelas em aberto' : 'Todas as parcelas pagas'),
        switchMap(() => this.registrationQuery.selectEntity(this.registrationId)),
        tap((cadastro: RegistrationSimplifiedDto) => this.registration = cadastro),
        switchMap((cadastro: RegistrationSimplifiedDto) => this.projetoQuery.selectEntity(cadastro.projetoFid)),
        tap(projeto => this.projeto = projeto),
        catchError(async () => await this.toastService.erro('Erro ao buscar dados do cadastro. Tente novamente mais tarde.')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    this.registrationQuery
      .selectLoading()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (isLoading) => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  /**
   * Controle para habilitar ou desabilitar item de validação
   */
  public get disabledValidationItem(): boolean {
    return this.registration?.status !== RegistrationStatus.AVAILABLE_SCHEDULE;
  }

  public get menuItemValidation(): MenuItem {
    const menuItem = new MenuItem();
    menuItem.title = 'Validação presencial';
    menuItem.description = 'Um agente irá validar as informações';
    menuItem.classColor = MenuItemClassColor.PENDING;
    menuItem.status = 'Indisponível até finalização do preenchimento';

    switch (this.registration?.status) {
      case RegistrationStatus.AVAILABLE_SCHEDULE:
        menuItem.status = 'Agende a visita para validação';
        break;

      case RegistrationStatus.IN_ANALYSIS:
        menuItem.status = 'Informações em pré-análise';
        break;

      case RegistrationStatus.VALIDATION_SCHEDULED:
        menuItem.status = `Agendado para ${format(
          this.registration.schedulingDate,
          'DD/MM'
        )}`;
        menuItem.classColor = MenuItemClassColor.SENT;
        break;

      case RegistrationStatus.MISSED_SCHEDULE:
        menuItem.status = 'Faça um novo agendamento';
        break;

      case RegistrationStatus.VALIDATED:
      case RegistrationStatus.PUBLISHED_OFFICIAL_JOURNAL:
      case RegistrationStatus.REGISTERED:
      case RegistrationStatus.DELIVERY_SCHEDULED:
      case RegistrationStatus.DELIVERED_TITLE:
        menuItem.status = 'Visita realizada';
        menuItem.classColor = MenuItemClassColor.SENT;
        break;
    }
    return menuItem;
  }

  public goToValidation(): void {
    if (!this.disabledValidationItem) {
      this.navigateTo('agendamento');
    }
  }

  /**
   * Foto da fachada do imóvel
   */
  public get propertyFacadePhoto(): Observable<string> {
    return this.registrationQuery.getFiles(this.registration?.id).pipe(
      map((files) => (files ? files[0] : null)),
      filter(() => !!this.registration?.propertyId),
      map((file) => {
        if (file?.id) {
          return `/properties/${this.registration.propertyId}/photo/${file?.id}`;
        }
        return this.defaultImage;
      }, shareReplay({ bufferSize: 1, refCount: true }))
    );
  }

  public simplifiedStatusText(
    status: RegistrationStatus
  ): { buttonLabel: string | null; statusText: string } {
    return getSimplifiedStatusText(status);
  }

  public navigateTo(route: string, fluxo: boolean = false): void {
    if (fluxo) {
      this.router.navigate([route], {
        relativeTo: this.route,
        queryParams: { fluxo: true },
      });
    } else {
      this.router.navigate([route], { relativeTo: this.route });
    }
  }

  public navigateToPayment(route: string, id: string): void {
    this.router.navigate([route, id], { relativeTo: this.route });
  }

  public continue(): void {
    if (this.registration.status === RegistrationStatus.READY_TO_SUBMIT) {
      if (this.registration.contractDate) {
        this.presentModalReenvioCadastro(this.registrationId);
      }
      else {
        this.navigateTo(`enviar-cadastro`);
      }
    } else if (
      this.isPropertyFilledIn === StatusPreenchimento.PENDENTE ||
      this.isPropertyFilledIn === StatusPreenchimento.ALTERACAO_NECESSARIA
    ) {
      this.navigateTo(`imovel/${this.registration.propertyId}`, true);
    } else if (
      this.isOwnerFilledIn === StatusPreenchimento.PENDENTE ||
      this.isOwnerFilledIn === StatusPreenchimento.ALTERACAO_NECESSARIA
    ) {
      this.navigateTo(`proprietarios/detalhes`, true);
    } else if (this.registration?.fillInStatus?.propertyData?.documents === StatusPreenchimento.PENDENTE) {
      this.navigateTo('imovel/' + this.registration.propertyId + '/documentos', true);
    }
  }
  public get statusPreenchimento(): typeof StatusPreenchimento {
    return StatusPreenchimento;
  }

  public get isPropertyFilledIn(): StatusPreenchimento {
    const propertyData = this.registration?.fillInStatus?.propertyData;
    if (
      [
        propertyData?.additionalInformationsData,
        propertyData?.addressData,
        propertyData?.localizationData,
        propertyData?.documents
      ].includes(StatusPreenchimento.ALTERACAO_NECESSARIA)
    ) {
      return StatusPreenchimento.ALTERACAO_NECESSARIA;
    }
    if (
      [
        propertyData?.additionalInformationsData,
        propertyData?.addressData,
        propertyData?.localizationData,
        propertyData?.documents
      ].includes(StatusPreenchimento.PENDENTE)
    ) {
      return StatusPreenchimento.PENDENTE;
    }
    return StatusPreenchimento.APROVADO;
  }

  public get isOwnerFilledIn(): StatusPreenchimento {
    return this.registration?.fillInStatus?.ownersData?.reduce(
      (acc: StatusPreenchimento, curr) => {
        const fillInStatus = curr.fillInStatus;
        if (
          [
            fillInStatus.contactData,
            fillInStatus.documents,
            fillInStatus.documentsData,
            fillInStatus.personalData,
          ].includes(StatusPreenchimento.ALTERACAO_NECESSARIA)
        ) {
          acc = StatusPreenchimento.ALTERACAO_NECESSARIA;
        }
        if (
          [
            fillInStatus.contactData,
            fillInStatus.documents,
            fillInStatus.documentsData,
            fillInStatus.personalData,
          ].includes(StatusPreenchimento.PENDENTE)
        ) {
          acc = StatusPreenchimento.PENDENTE;
        }
        return acc;
      },
      StatusPreenchimento.ENVIADO
    );
  }

  private getRegistration(id: string): void {
    this.registrationService
      .getRegistration(id)
      .pipe(
        catchError(async () => await this.toastService.erro('Erro ao buscar dados do cadastro. Tente novamente mais tarde.')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  async presentModalContact() {
    const modal = await this.modalController.create({
      component: RegistrationHelpModal,
      cssClass: 'registration-help-modal',
    });
    return await modal.present();
  }

  /**
   * Alteração foto de fachada
   * @param photo foto selecionada
   */
  public setImage(photo: any): void {
    this.propertyService.updateFacadePhoto(this.registrationId, this.registration.propertyId, photo)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async () => {
        const toast = await this.toastController.create({
          message: 'Foto alterada com sucesso',
          duration: 2000
        });
        toast.present();
      }, async () => await this.toastService.erro('Erro ao buscar atualizar foto da fachada do imóvel. Tente novamente mais tarde.'));
  }

  async presentModalReenvioCadastro(id: string) {
    const modal = await this.modalController.create({
      component: ConfirmaReenvioCadastroModal,
      cssClass: 'contact-modal',
      componentProps: {
        id: id,
      },
    });
    return await modal.present();
  }

  async excluiCadastro() {
    const modal = await this.modalController.create({
      component: ExcluiCadastroModal,
      cssClass: 'exclui-cadastro',
      componentProps: {
        id: this.registrationId,
      }
    });
    return await modal.present();
  }
}
