import { PropertyDto } from '@usucampeao/interfaces';
import { Expose, plainToInstance } from 'class-transformer';
import { IsBoolean, IsEnum, IsNotEmpty, IsPositive } from 'class-validator';
import { PropertyFillInStatusDto } from './property-fill-in-status.dto';
import { PropertyOccupancy } from './property-occupancy.enum';
import { PropertySituation } from './property-situation.enum';
import { PropertyUsage } from './property-usage.enum';

export class GetUpdatePropertyAdditionalInformationsDto {
  @Expose()
  @IsEnum(PropertyUsage)
  @IsNotEmpty()
  usage: PropertyUsage;

  @Expose()
  @IsEnum(PropertyOccupancy)
  @IsNotEmpty()
  occupation: PropertyOccupancy;

  @Expose()
  @IsEnum(PropertySituation)
  @IsNotEmpty()
  situation: PropertySituation;

  @Expose()
  @IsPositive()
  @IsNotEmpty()
  loteArea: number;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  possuiOutroImovel: boolean;

  fillInStatus: PropertyFillInStatusDto;

  /**
   * Cria uma nova instância de GetUpdatePropertyLocalization a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdatePropertyLocalization
   */
  static from(props?: PropertyDto | Partial<PropertyDto>): GetUpdatePropertyAdditionalInformationsDto {
    return plainToInstance(GetUpdatePropertyAdditionalInformationsDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
