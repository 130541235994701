
export enum NotificationStatus {
  /**
   * Pending
   */
  PENDENTE = 'PENDENTE',
  /**
   * Error.
   */
  ERRO = 'ERRO',
  /**
   * Sent.
   */
  ENVIADO = 'ENVIADO',
  /**
   * Received.
   */
  RECEBIDO = 'RECEBIDO',
  /**
   * Read.
   */
  LIDO = 'LIDO'
}
