import { Ref } from '@usucampeao/interfaces';
import { IsNotEmpty } from 'class-validator';

/**
 * Enumeration of realty/property regularization team.
 */
export class DomainTeamDto {
  /**
   * Local regional (business) leader.
   */
  @IsNotEmpty()
  localPartner: Ref;
  /**
   * General manager.
   */
  @IsNotEmpty()
  generalCoordinator: Ref;
  /**
   * Legal manager.
   */
  @IsNotEmpty()
  legalCoordinator: Ref;
  /**
   * Engineering manager.
   */
  @IsNotEmpty()
  engineering: Ref;
  /**
   * Local city/district/county contact.
   */
  @IsNotEmpty()
  cityHallContact: Ref;
  /**
   * (Partner) Team supervisor.
   */
  @IsNotEmpty()
  localSupervisor: Ref;
  /**
   * Sales coordinator.
   */
  @IsNotEmpty()
  salesCoordinator: Ref;
  /**
   * Operations coordinator.
   */
  @IsNotEmpty()
  operationsCoordinator: Ref;
  /**
   * Financial coordinator.
   */
  @IsNotEmpty()
  financialCoordinator: Ref;
}
