export class ProjetoSituacaoQuadraDto {
  quadra: string;
  totalLotes: number;
  totalLotesVendidos: number;

  constructor(
    quadra: string,
    totalLotes: number,
    totalLotesVendidos: number,
  ) {
    this.quadra = quadra;
    this.totalLotes = totalLotes;
    this.totalLotesVendidos = totalLotesVendidos;
  }
}
