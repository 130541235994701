import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLotModuleRoutingModule } from './select-lot-routing.module';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from '../../../../components/header/header.module';
import { SelectLotPage } from './select-lot.page';
import { MapModule } from '../../../../components/map/map.module';
import { LotSelectionInstructionModule } from '../../../modal/lot-selection/lot-selection.module';

@NgModule({
  declarations: [
    SelectLotPage
  ],
  imports: [
    CommonModule,
    HeaderModule,
    IonicModule,
    MapModule,
    SelectLotModuleRoutingModule,
    LotSelectionInstructionModule,
  ]
})
export class SelectLotModule { }
