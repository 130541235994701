/**
 * Enum de status de boleto no BS2.
 */
export enum Bs2BoletoStatus {
  /**
   * Boleto em aberto para pagamento.
   */
  ABERTO = 1,
  /**
   * Boleto vencido.
   */
  VENCIDO = 2,
  /**
   * Boleto cancelado.
   */
  CANCELADO = 3,
  /**
   * Boleto pago.
   */
  PAGO = 4,
}
