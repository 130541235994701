import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { ModalController, ToastController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import {
  AlteracaoDto,
  DocumentFileSubtype,
  ProjetoSimplificadoDto,
  ProjetoTipo,
  PropertyAcquisitionDocument,
  PropertyOwnershipDocument,
  RegistrationSimplifiedDto,
  RegistrationStatus,
  TipoAlteracao
} from '@usucampeao/lib-reurb-simplificado';
import { Observable, Subject } from 'rxjs';
import { finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoadingService } from '../../../../services/loading.service';
import { NavigationService } from '../../../../services/navigation.service';
import { AuthQuery } from '../../../auth/state/auth.query';
import { AskHelpModal } from '../../../modal/ask-help/ask-help.modal';
import { InformationAlertModal } from '../../../modal/information-alert/information-alert.modal';
import { InstructionModal } from '../../../modal/instruction/instruction.modal';
import { RegisterService } from '../../../register/state/register.service';
import { RegistrationQuery } from '../../state/registration.query';
import { RegistrationService } from '../../state/registration.service';
import { JustificationPage } from './type/justification/justification.page';

@Component({
  selector: 'usucampeao-property-documents',
  templateUrl: 'property-documents.page.html',
  styleUrls: ['property-documents.page.scss'],
})
export class PropertyDocumentsPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  registration = {
    acquisitionDocuments: [],
    ownershipDocuments: [],
  };
  private ngUnsubscribe = new Subject();
  public isIdentitySended = false;
  public isSocialStateSended = false;
  public blockAccess = false;
  public projeto: ProjetoSimplificadoDto;

  private registrationId: string;
  private propertyId: string;

  public alteracoes: AlteracaoDto[] = [];

  constructor(
    public authQuery: AuthQuery,
    private modalController: ModalController,
    private loaderService: LoadingService,
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private registrationQuery: RegistrationQuery,
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
    private registerService: RegisterService,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        first(),
        tap(async () => await this.loaderService.createLoader()),
        tap((params) => {
          this.registrationId = params.id;
          this.propertyId = params.propertyId;
        }),
        switchMap(() => this.registrationQuery.selectEntity(this.registrationId)),
        tap(r => this.validateStatus((r as RegistrationSimplifiedDto).status)),
        switchMap(cadastro => this.registerService.buscarDetalheProjeto(cadastro.projetoFid)),
        tap(projeto => this.projeto = projeto),
        switchMap(() => this.registrationService.buscaAlteracoesCadastro(this.registrationId, TipoAlteracao.IMOVEL_DOCUMENTOS)),
        tap(alteracoes => this.alteracoes = alteracoes),
        finalize(async () => await this.loaderService.dismiss()),
        takeUntil(this.ngUnsubscribe),
      ).subscribe(() => {
        this.registrationService
          .getFilePropety(this.registrationId, this.propertyId)
          .then(async (data) => {
            this.getDocumentsState(data);
            await this.loaderService.dismiss();
          }, async (error) => {
            await this.loaderService.dismiss();
            Bugsnag.notify('Documentos do imovel - Erro ao buscar documentos.', error)
            const toast = await this.toastController.create({
              message: 'Erro ao buscar informações. Tente novamente mais tarde.',
              duration: 5000,
            });
            toast.present();
          });
      }, async (error) => {
        Bugsnag.notify('Erro ao buscar alteracoes do cadastro', error)
        const toast = await this.toastController.create({
          message: 'Erro ao buscar informações. Tente novamente mais tarde.',
          duration: 5000,
        });
        toast.present();
      }
      );
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  getDocumentsState(data): void {
    this.registration.acquisitionDocuments = data.filter(item =>
      Object.values(PropertyAcquisitionDocument).includes(item.type) ||
      item.subtype === DocumentFileSubtype.ACQUISITION);

    this.registration.ownershipDocuments = data.filter(item =>
      Object.values(PropertyOwnershipDocument).includes(item.type) ||
      item.subtype === DocumentFileSubtype.OWNERSHIP);
  }

  /**
   * Observable para o status de loading de dados do cadastro, no estado.
   */
  public get isLoading$(): Observable<boolean> {
    return this.registrationQuery.selectLoading();
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
    }
  }

  public get documentFileSubtype(): typeof DocumentFileSubtype {
    return DocumentFileSubtype;
  }

  public get projetoTipo(): typeof ProjetoTipo {
    return ProjetoTipo;
  }

  navigateToDocs(id?: string): void {
    this.router.navigate([`documento/`, (id || 'escolher')], { relativeTo: this.route });
  }
  gotoRoute(data: any) {
    this.router.navigate([`documento/${data.id}/foto/`, data?.type, data.id], { relativeTo: this.route, queryParams: { nome: data.name } });
  }

  async irJustificativa(data: any) {

    const modal = await this.modalController.create({
      component: JustificationPage,
      componentProps: {
        id: this.registrationId,
        propertyId: this.propertyId,
        subtype: data.subtype,
        justification: data.justification,
        editar: true,
      }
    });
    return await modal.present();

  }

  async presentModalInstruction() {
    const modal = await this.modalController.create({
      component: InstructionModal,
      cssClass: 'documents-help-modal',
    });
    return await modal.present();
  }
  async presentModalAskHelp() {
    const modal = await this.modalController.create({
      component: AskHelpModal,
      cssClass: 'small-modal',
    });
    return await modal.present();
  }
  async presentModalWarning() {
    const modal = await this.modalController.create({
      component: InformationAlertModal,
      cssClass: 'small-modal',
      componentProps: {
        title: 'Documentos faltantes',
        text:
          'Você ainda não enviou todos os documentos necessários. Seu cadastro continuará pendente enquanto todos os documentos não forem anexados.',
        subtext:
          'Se não conseguir encontrar os documentos, você pode adicionar justificativas ou comentários. Continuar mesmo assim?',
        clearButton: 'SIM',
        fillButton: 'VOLTAR',
        fillButtonRota: 'dismiss',
        clearButtonRota: '..',
      },
    });
    return await modal.present();
  }

  navigateForward() {
    this.navigationService.goBack();
  }
}
