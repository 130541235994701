import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { forkJoin, of, Subject } from 'rxjs';
import { first, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastService } from '../../../../../../../services/toast.service';
import { RegistrationQuery } from '../../../../../state/registration.query';
import { RegistrationService } from '../../../../../state/registration.service';
import { LoadingService } from './../../../../../../../services/loading.service';
import { NavigationService } from './../../../../../../../services/navigation.service';
import { getDocumentInformation } from './document-photo.information';

@Component({
  selector: 'usucampeao-document-photo',
  templateUrl: 'document-photo.page.html',
  styleUrls: ['document-photo.page.scss'],
})
export class DocumentPhotoPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  private ownerId: string;
  private registrationId: string;
  private ngUnsubscribe = new Subject();
  isEditing = false;
  private imgId = '';
  img64;
  document: {
    name: any;
    text: string;
  };
  public blockAccess = false;
  public base64Image = [];
  public documentName: any;
  public selectedIndex = 0;
  public allPhotosData = [];
  public novasfotos = [];
  public allPhotosSelectedToRemove = [];

  constructor(
    private loaderService: LoadingService,
    private navigationService: NavigationService,
    private registration: RegistrationService,
    private registrationQuery: RegistrationQuery,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    this.document = getDocumentInformation(this.router.url);
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        first(),
        tap((params) => this.registrationId = params?.id || 'novo'),
        tap((params) => this.ownerId = params.ownerId),
        tap((params) => {
          if (params?.imgid && params?.imgid !== 'novo') {
            this.imgId = params?.imgid;
            this.isEditing = true;
            this.getPicture(params?.imgid);
          }
        }),
        switchMap(() => this.registrationQuery.selectEntity(this.registrationId)),
        tap((listRegistration) => {
          const status = listRegistration.status;
          this.validateStatus(status);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }
  public async setImage(img: any): Promise<void> {
    const convert = await this.blobToBase64(img);
    this.base64Image[this.selectedIndex] = { img: convert, file: img };
    this.novasfotos = [{ img: convert, file: img }, ...this.novasfotos]
  }

  public remove(): void {
    if (this.base64Image.length === 0) return;
    this.base64Image.splice(this.selectedIndex, 1);
    const selected = this.allPhotosData[this.selectedIndex];
    this.allPhotosSelectedToRemove.push(selected);
    this.allPhotosData = this.allPhotosData.filter((item) => item.id !== selected.id)
  }

  public addImage(): void {
    if (this.base64Image[this.selectedIndex]) {
      this.base64Image.push('');
      this.selectedIndex += 1;
    }
  }

  public nextImage(): void {
    if (this.base64Image[this.selectedIndex]) {
      this.selectedIndex += 1;
    }
  }

  private blobToBase64(blob): Promise<string | ArrayBuffer> {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  public backImage(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex -= 1;
    }
  }
  navigateBack(): void {
    this.navigationService.goBack();
  }


  async getPicture(id: string) {
    await this.loaderService.createLoader();
    this.registration
      .getFileOwer(id)
      .pipe(
        switchMap((data) => {
          this.allPhotosData = data;
          return of(
            data.map((photo) => this.registration.getFiles(id, photo.id))
          ).pipe(mergeMap((q) => forkJoin(q)));
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        async (res: any) => {

          await this.loaderService.dismiss();
          if (res) {
            res.map(async (value, index) => {
              this.base64Image[index] = {
                img: await this.blobToBase64B(value),
                file: value,
              };
            });
          }

        },
        async () => {
          await this.toastService.erro('Erro ao buscar foto. Tente novamente mais tarde.')
          await this.loaderService.dismiss();
        }
      );
  }

  blobToBase64B(blob): Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }



  async navigateForward(): Promise<void> {
    await this.loaderService.createLoader();
    const data = this.isEditing
      ? this.registration.updateFileOwer(
        this.registrationId,
        this.ownerId,
        this.imgId,
        this.novasfotos.map((item) => item.file),
        this.allPhotosSelectedToRemove.map((item) => item.id)
      )
      : this.registration.sendFileOwer(
        this.registrationId,
        this.ownerId,
        this.imgId,
        this.base64Image.map((item) => item.file),
        getDocumentInformation(this.router.url)
      );

    data.then(
      async () => {
        await this.loaderService.dismiss();
        this.navigationService.goBack();
      },
      async () => {
        await this.loaderService.dismiss();
        await this.toastService.erro('Erro ao enviar foto. Tente novamente mais tarde.');
      }
    );
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
    }
  }
}
