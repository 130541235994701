
  <div class="content-vertical-space-between">
    <ion-grid class="ion-no-padding ion-padding-start ion-padding-end">
      <ion-row>
        <ion-col>
          <ion-item class="ion-no-padding" lines="none">
            <usucampeao-notification-badge
              [tipo]="notification?.tipo"
            ></usucampeao-notification-badge>
            <ion-text class="ion-padding-start">
              <p class="title title--medium ion-no-margin">
                {{ notification?.titulo }}
              </p>
              <p class="ion-no-margin font-text font-text--small">
                {{ notification?.dataEnvio || '' | date }}
              </p>
            </ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p>
            {{ notification?.descricao }}
          </p>
          <p>
            É muito importante estar presente no endereço do imóvel nesta data e
            horário, para que nossa equipe possa verificar os dados e os
            documentos informados.
          </p>
          <p>
            Esta é a última etapa antes do envio para a prefeitura. Falta pouco
            para ter seu imóvel regularizado.
          </p>
          <p>Nos vemos lá!</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

