<ion-content>
    <usucampeao-header mode="md" backRoute="/" title="Novo cadastro"></usucampeao-header>
    <ion-grid class="ion-padding-start ion-padding-end">
        <ion-row class="ion-justify-content-center">
            <ion-col class="ion-no-margin ion-no-padding" sizeMd="6">
                <ion-text class="font-text"> Informe dados do seu lote </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
            <ion-col sizeMd="6" class="ion-no-padding ion-no-margin">
                <form mode="md" [formGroup]="form" (ngSubmit)="navigateToProcessFile()">
                    <div *ngFor="let field of formAddressControls" class="form-row ion-no-padding">
                        <usucampeao-build-form [field]="field" [form]="formAddress"></usucampeao-build-form>
                    </div>

                    <div class="margin-top">
                        <div class="form-row ion-no-padding">
                            <usucampeao-build-form *ngFor="let field of formLotAddress$" [field]="field" [form]="form"></usucampeao-build-form>
                        </div>
                        <div *ngIf="isOtherLotAddress$" class="form-row ion-no-padding">
                            <usucampeao-build-form *ngFor="let field of formSpecifyUsagel$" [field]="field" [form]="form"></usucampeao-build-form>
                        </div>
                        <div class="form-row ion-no-padding">
                            <usucampeao-build-form *ngFor="let field of formOccupation$" [field]="field" [form]="form"></usucampeao-build-form>
                        </div>
                        <div *ngIf="isOtherOccupation$" class="form-row ion-no-padding">
                            <usucampeao-build-form *ngFor="let field of formSpecifySituation$" [field]="field" [form]="form"></usucampeao-build-form>
                        </div>
                        <div class="form-row ion-no-padding">
                            <usucampeao-build-form *ngFor="let field of formResidentsQuantity$" [field]="field" [form]="form"></usucampeao-build-form>
                        </div>
                    </div>
                </form>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-col sizeMd="6" sizeLg="3" class="ion-no-margin ion-no-padding">
                <ion-button expand="block" mode="md" (click)="navigateToProcessFile()">Continuar</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
    <usucampeao-footer></usucampeao-footer>
</ion-content>