import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationPage } from './registration.page';

const routes: Routes = [
  {
    path: '', component: RegistrationPage
  },
  {
    path: 'imovel/:propertyId',
    loadChildren: () =>
      import('./property/property.module').then((m) => m.PropertyModule),
  },
  {
    path: 'enviar-cadastro',
    loadChildren: () =>
      import('./property-to-send/property-to-send.module').then((m) => m.PropertyToSendModule),
  },
  {
    path: 'agendamento',
    loadChildren: () =>
      import('./attendance-validation/attendance-validation.module').then((m) => m.AttendanceValidationPageModule),
  },
  {
    path: 'proprietarios',
    loadChildren: () =>
      import('./owners/owners.module').then((m) => m.OwnersModule),
  },
  {
    path: 'pagamentos/:id',
    loadChildren: () =>
      import('./pagamento/pagamento.module').then((m) => m.PagamentoPageModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule { }
