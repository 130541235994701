import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { AuthQuery } from '../state/auth.query';
import { selectPersistStateInit } from '@datorama/akita';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router, private authQuery: AuthQuery) {}

  canActivate(): Observable<boolean> {
   
      
    return combineLatest([
      this.authQuery.isLoggedIn$,
      selectPersistStateInit(),
    ]).pipe(
      map(([isAuth]) => {
        if(isAuth) {
          return true;
        }
        this.router.navigateByUrl('login');
        return false;
      }),
      take(1)
    );

  }
}
