export enum SituacaoProfissional {
  EMPREGADO = 'EMPREGADO',
  DESEMPREGADO = 'DESEMPREGADO',
  SERVIDOR_PUBLICO = 'SERVIDOR_PUBLICO',
  AUTONOMO = 'AUTONOMO',
  ESTAGIARIO = 'ESTAGIARIO',
  APOSENTADO = 'APOSENTADO',
  PENSIONISTA = 'PENSIONISTA',
  OUTRO = 'OUTRO',
}
