<div id="print-section" class="c-data-contract__text-terms">
    <div class="title">
        <h1>CONTRATO DE ADESÃO PARA A PRESTAÇÃO DE SERVIÇOS DE REGULARIZAÇÃO FUNDIÁRIA </h1>
    </div>
    <div class="personal-data">
        <table class="contract-table">
            <tr>
                <td>
                    <p>
                        Por este instrumento particular, de um lado,
                        <span style="font-weight: bold;">NOME:</span> {{ proprietario?.name }},
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        inscrito (a) no <strong>RG nº</strong> {{ proprietario?.rg?.number || '' }}, e no <strong>CPF nº</strong> {{ proprietario?.cpf }}, estado civil {{ proprietario?.maritalStatus }} (se casado qualificar o cônjuge e indicar o regime
                        de casamento; se companheiro/convivente, qualificar o outro companheiro/convivente),{{ proprietario?.matrimonialRegime }}, nacionalidade {{ proprietario?.nationality }}, portador (a) no <strong>RG nº</strong> {{ conjuge?.rg?.number
                        || '' }}, e no <strong>CPF nº</strong> {{ conjuge?.cpf }}, <b>declara(m)</b>sob pena da lei que reside (m) no imóvel situando no lote {{ cadastro?.lote }}, da Quadra {{ cadastro?.quadra }}, do núcleo denominado {{ imovel?.address?.neighborhood
                        }}, objeto de REURB, e atestam que o mesmo ocupa o imóvel há _______.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <b>Declara(m)</b>, ainda, estar ciente(s) que, comprovada a falsidade desta declaração estará (ão) sujeito(s) penas previstas no artigo 299 do Código Penal Brasileira.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <b>1ª Testemunha:</b>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        _____________________________________, portador do RG nº _________________ e CPF nº___________________, residente e domiciliado (a) na Rua _________________, nº ______, Bairro ______________________, Cidade ___________, Estado ____, Telefone _________________.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Qual o segmento de uso do imóvel:</strong> (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.RESIDENTIAL">X
                        </ng-container>) Residencial (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.COMMERCIAL">X
                        </ng-container>) Comercial (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.MIXED">X
                        </ng-container>) Misto
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        ______________________________
                    </p>
                    <p>
                        <b>
                        Assinatura da 1ª testemunha
                      </b>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><b>
                      2ª Testemunha:
                    </b>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        _____________________________________, portador do RG nº _________________ e CPF nº___________________, residente e domiciliado (a) na Rua _________________, nº ______, Bairro ______________________, Cidade ___________, Estado ____, Telefone __________________.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        ______________________________
                    </p>
                    <p>
                        <b>
                      Assinatura da 2ª testemunha
                    </b>
                    </p>
                </td>
            </tr>
        </table>
    </div>
    <div class="subscription">
        <div class="table">
            <div class="column">
                <div class="column__infos">
                    <p>
                        <b>USU CAMPEAO TECNOLOGIA EM REGULARIZAÇÃO IMOBILIÁRIA LTDA </b>
                    </p>
                </div>
                <div class="column__infos">
                    <p>
                        NPJ: 36.174.049/0001-08 Endereço: RUA CLÁUDIO SOARES 72, CJ 617 - PINHEIROS Telefone: 2537.9430 / Site: www.usucampeao.com.br
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>