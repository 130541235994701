import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'StatusSimplifiedReurb',
})
export class StatusSimplifiedReurbPipe implements PipeTransform {
  transform(value: RegistrationStatus): string {
    switch (value) {
      case RegistrationStatus.INCOMPLETE:
        return 'Incompleto';
      case RegistrationStatus.READY_TO_SUBMIT:
        return 'Pronto para envio';
      case RegistrationStatus.IN_ANALYSIS:
        return 'Em análise';
      case RegistrationStatus.CHANGES_REQUESTED:
        return 'Alterações necessárias';
      case RegistrationStatus.AVAILABLE_SCHEDULE:
        return 'Agendamento disponível';
      case RegistrationStatus.VALIDATION_SCHEDULED:
        return 'Agendado';
      case RegistrationStatus.MISSED_SCHEDULE:
        return 'Agendamento perdido';
      case RegistrationStatus.VALIDATED:
        return 'Validado';
      case RegistrationStatus.PUBLISHED_OFFICIAL_JOURNAL:
        return 'Publicado no Diário Oficial';
      case RegistrationStatus.REGISTERED:
        return 'Registrado em cartório';
      case RegistrationStatus.DELIVERY_SCHEDULED:
        return 'Entrega dos títulos';
      case RegistrationStatus.DELIVERED_TITLE:
        return 'Título entregue';
      default:
        return 'Status não cadastrado';
    }
  }
}
