import { UF } from '@usucampeao/interfaces';
import { IsNotEmpty, IsNumberString, IsOptional, IsString, Length } from 'class-validator';
import { ProjetoModalidadeType } from './projeto-modalidade.enum';

export class ProjetoEditarFormDataDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsString()
  @IsOptional()
  descricao?: string;

  @IsNumberString()
  @Length(8, 8)
  cep: string;

  @IsString()
  @IsNotEmpty()
  rua: string;

  @IsNumberString()
  @IsOptional()
  numero?: string;

  @IsString()
  @IsOptional()
  complemento?: string;

  @IsString()
  @IsOptional()
  bairro?: string;

  @IsString()
  @IsNotEmpty()
  cidade: string;

  @IsString()
  @IsNotEmpty()
  estado: UF;

  @IsString()
  @IsOptional()
  modalidade?: ProjetoModalidadeType;

  @IsString()
  @IsOptional()
  averbacao?: string;
}
