<div id="print-section" class="c-data-contract__text-terms">
    <div class="title">
        <h1>DECLARAÇÃO DE DOMICÍLIO</h1>
    </div>
    <div class="personal-data">
        <table class="contract-table">
            <tr>
                <td>
                    <p>
                        {{ proprietario?.name }},
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        inscrito (a) no <strong>RG nº</strong> {{ proprietario?.rg?.number || '' }}, e no <strong>CPF nº</strong> {{ proprietario?.cpf }}, estado civil {{ proprietario?.maritalStatus }} (se casado qualificar o cônjuge e indicar o regime
                        de casamento; se companheiro/convivente, qualificar o outro companheiro/convivente),{{ proprietario?.matrimonialRegime }}, nacionalidade {{ proprietario?.nationality }}, portador (a) no <strong>RG nº</strong> {{ conjuge?.rg?.number
                        || '' }}, e no <strong>CPF nº</strong> {{ conjuge?.cpf }}, <b>declara(m)</b>sob pena da lei que reside (m) no imóvel situando no lote {{ cadastro?.lote }}, da Quadra {{ cadastro?.quadra }}, do núcleo denominado {{ imovel?.address?.neighborhood
                        }}.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <b>Declara</b> (m), ainda, estar ciente de que, comprovada a falsidade desta declaração estará (ão) sujeito (s) às penas previstas no artigo 299 do Código Penal Brasileiro.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Rua:</strong> {{ imovel?.address?.street }} Nº.{{ imovel?.address?.number }}
                        <ng-container *ngIf="imovel?.address?.complement">
                            <strong>Complemento: </strong> {{ imovel?.address?.complement }}
                        </ng-container>
                        <strong>Bairro: </strong> {{ imovel?.address?.neighborhood }} <strong>Cidade: </strong> {{ imovel?.address?.city }} <strong>UF: </strong> {{ imovel?.address?.state }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        E, por ser expressão da verdade, firmam o presente.
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        {{ imovel?.address?.city }}, {{ cadastro?.contractDate }}
                    </p>
                </td>
            </tr>
        </table>
    </div>
    <div class="subscription">
        <div class="table">
            <div class="column">
                <p>______________________________________________</p>
                <div class="column__infos">
                    <p>RG: {{ proprietario?.rg?.number }}</p>
                </div>
            </div>
            <div class="column">
                <p>______________________________________________</p>
                <div class="column__infos">
                    <p>RG: {{ conjuge?.rg?.number }}</p>
                </div>
            </div>
            <div class="column">
                <p><b>USU CAMPEAO TECNOLOGIA EM REGULARIZAÇÃO IMOBILIÁRIA LTDA </b> CNPJ: 36.174.049/0001-08 Endereço: Rua Cláudio Soares, 72, 6˚ andar / Telefone: 2537.9430 / Site: www.usucampeao.com.br
                </p>
            </div>
        </div>
    </div>