import { IonicModule } from '@ionic/angular';
import { PipesModule } from './../../../pipes/pipes.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContractModule } from '@usucampeao/lib-contratos';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { MatrimonialRegimePipe } from '../../../pipes/matrimonial-regime.pipe';
import { ContractModal } from './contract.modal';

@NgModule({
  imports: [CommonModule, IonicModule, UiMobileModule, PipesModule, ContractModule],

  declarations: [ContractModal],

  exports: [ContractModal],
  providers: [MatrimonialRegimePipe]


})
export class ContratoModule { }
