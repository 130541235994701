import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FaseNucleoModule } from '../../../components/fase-nucleo/fase-nucleo.module';
import { FooterModule } from '../../../components/footer/footer.module';
import { HeaderModule } from '../../../components/header/header.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ClickHereRequestHelpModule } from './../../modal/click-here-request-help/click-here-request-help.module';
import { ClickingHereModule } from './../../modal/clicking-here/clicking-here.module';
import { EncerraCadastroModule } from './../../modal/encerra-cadastro/encerra-cadastro.module';
import { AllotmentDetailRoutingModule } from './allotment-detail-routing.module';
import { AllotmentDetailPage } from './allotment-detail.page';


@NgModule({
  declarations: [
    AllotmentDetailPage
  ],
  imports: [
    ClickHereRequestHelpModule,
    CommonModule,
    ClickingHereModule,
    EncerraCadastroModule,
    HeaderModule,
    IonicModule,
    FooterModule,
    AllotmentDetailRoutingModule,
    FaseNucleoModule,
    PipesModule
  ]
})
export class AllotmentDetailModule { }
