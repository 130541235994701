<ion-content>
  <usucampeao-header mode="md" backRoute="/home" title="Ver Cadastro"></usucampeao-header>
  <div class="ion-padding-start ion-padding-end">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6" class="ion-no-margin ion-no-padding ion-text-center">
          <img
            class="img-facade"
            [lazyLoad]="propertyFacadePhoto | async"
            [defaultImage]="loadingImage"
            [errorImage]="defaultImage"
          />
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
          <ion-col class="ion-text-center ion-justify-content-center ion-no-margin ion-no-padding" sizeXs="12" sizeSm="6">
            <usucampeao-photo
              [imageUrl]="propertyFacadePhoto | async"
              [requiredphoto]="true"
              messageRequired="Foto da fachada é obrigatória"
              class="ion-margin-top"
              [button]="true"
              type="lot"
              (setImage)="setImage($event)"
            >
            </usucampeao-photo>
          </ion-col>

      </ion-row>
      <ion-row class="ion-padding-top ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6" class="exclui-nucleo">
          <ion-text class="title title--medium"> {{projeto?.nome || ''}} </ion-text>
          <ion-button id="trigger-button" class="button-exclui-cadastro" fill="clear" mode="md" ><ion-icon size="small" name="ellipsis-vertical"></ion-icon></ion-button>
          <ion-popover trigger="trigger-button" [dismissOnSelect]="true">
            <ng-template>
              <ion-button fill="clear" (click)="excluiCadastro()" class="btn-encerra-cadastro">Encerrar Cadastro</ion-button>
            </ng-template>
          </ion-popover>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-top ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-icon name="map-outline" color="medium"></ion-icon>
          <ion-text
            class="description-text description-text--medium"
            *ngIf="registration?.blockId || registration?.lotId"
          >
            Quadra {{ registration?.quadra || "" }} Lote {{ registration?.lote || "" }}
          </ion-text>
          <ion-text
            class="description-text description-text--medium"
            *ngIf="!(registration?.blockId || registration?.lotId)"
          >
            <ion-skeleton-text animated style="width: 30%; height: 0.875rem; display: inline-block"></ion-skeleton-text>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-top ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-icon name="location-outline" color="medium"></ion-icon>
          <ion-text class="description-text description-text--medium" *ngIf="registration">
            {{registration?.address | address }}
          </ion-text>
          <ion-text class="description-text description-text--medium" *ngIf="!registration">
            <ion-skeleton-text animated style="width: 30%; height: 0.875rem; display: inline-block"></ion-skeleton-text>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-top ion-justify-content-center"  *ngIf="registration?.valorContrato" >
        <ion-col sizeXs="12" sizeSm="6">
          <div class="card-pagamento ion-padding" (click)="navigateToPayment('pagamentos',registration.id)">
            <ion-row>
              <ion-col size="auto" class="ion-padding-start ion-padding-end ion-justify-content-center centro">
                <ion-icon class="cash-icon" color="medium" name="cash-sharp"></ion-icon>
              </ion-col>
              <ion-col>
                <ion-row>
                <ion-col>
                  <ion-text class="font-text">{{registration.valorContrato | currency:'BRL' || ''}}</ion-text>
                </ion-col>
                <ion-col size="auto">
                  <ion-chip>
                    <ion-label class="texto-branco">{{situacaoPagamento || ''}}</ion-label>
                  </ion-chip>
                </ion-col>
              </ion-row><ion-row>
                <ion-text class="description-text">Entrada de {{ registration.valorEntrada | currency:'BRL' || ''}} + {{registration.totalParcelas || ''}} parcelas de {{ registration.valorParcela | currency:'BRL' || ''}}</ion-text></ion-row>
              </ion-col>
              <ion-col class="ion-justify-content-center centro" size="auto">
                <ion-icon name="chevron-forward-sharp" color="medium" ></ion-icon></ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-padding-top">
        <ion-col sizeXs="12" sizeSm="6">
          <usucampeao-process-status [status]="registration?.status"></usucampeao-process-status>

          <ng-container *ngIf="registration">
            <usucampeao-process-progress
              icon="home-outline"
              title="Imóvel"
              titleClass="large"
              subtitle="Preencha os dados do imóvel"
              [status]="isPropertyFilledIn"
              [statusText]="isPropertyFilledIn  === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="isPropertyFilledIn === statusPreenchimento.PENDENTE ?  'secondary-red-text' : 'secondary-text'"
              (click)="navigateTo('imovel/' + registration.propertyId)"
            >
            </usucampeao-process-progress>

            <usucampeao-process-progress
              icon="people-outline"
              title="Proprietários"
              titleClass="large"
              subtitle="Dados e documentos dos proprietários"
              [status]="isOwnerFilledIn"
              [statusText]="isOwnerFilledIn === statusPreenchimento.PENDENTE ?    'Preencher' : 'Alterar'"
              [statusTextClass]="isOwnerFilledIn === statusPreenchimento.PENDENTE ? 'secondary-red-text' :'secondary-text'"
              (click)="navigateTo('proprietarios/detalhes')"
            >
            </usucampeao-process-progress>

            <usucampeao-process-progress
              icon="calendar-outline"
              [title]="menuItemValidation.title"
              titleClass="large"
              [subtitle]="menuItemValidation.description"
              [statusText]="menuItemValidation.status"
              [statusTextClass]="menuItemValidation.classColor"
              (click)="goToValidation()"
              [disabled]="disabledValidationItem"
            >
            </usucampeao-process-progress>
          </ng-container>
          <ion-list *ngIf="!registration">
            <usucampeao-skeleton-item></usucampeao-skeleton-item>
            <usucampeao-skeleton-item></usucampeao-skeleton-item>
            <usucampeao-skeleton-item></usucampeao-skeleton-item>
            <usucampeao-skeleton-item></usucampeao-skeleton-item>
            <usucampeao-skeleton-item></usucampeao-skeleton-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
    <usucampeao-footer>
      <br />
      <ion-button
        mode="md"
        expand="block"
        *ngIf="simplifiedStatusText(registration?.status).buttonLabel"
        (click)="continue()"
      >
        {{simplifiedStatusText(registration?.status).buttonLabel || ""}}
      </ion-button>
      <br />
      <ion-text class="font-text font-text--small">
        {{simplifiedStatusText(registration?.status).statusText || ""}}
      </ion-text>
      <br />
      <ion-text class="font-text font-text--small">
        Em caso de dúvida,
        <ion-text color="strong"
          ><strong class="text-link" (click)="presentModalContact()">entre em contato</strong>
        </ion-text>
      </ion-text>
    </usucampeao-footer>
  </div>
</ion-content>
