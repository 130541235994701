import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';
import { AllotmentDocumentsPipe } from './allotment-documents.pipe';
import { DiaPagamentoPipe } from './dia-pagamento.pipe';
import { GenderPipe } from './gender.pipe';
import { MatrimonialRegimePipe } from './matrimonial-regime.pipe';
import { PagamentoStatusPipe } from './pagamento-status.pipe';
import { PropertyAcquisitionDocumentPipe } from './property-acquisition-document.pipe';
import { PropertyOccupancyPipe } from './property-occupancy.pipe';
import { PropertyOccupationOppositionPipe } from './property-occupation-opposition.pipe';
import { PropertyOwnershipDocumentPipe } from './property-ownership-document.pipe';
import { PropertySituationPipe } from './property-situation.pipe';
import { PropertyUsagePipe } from './property-usage.pipe';
import { ProprietaryDocumentPipe } from './proprietary-document.pipe';
import { ReurbModalidadePipe } from './reurb-modalidade.pipe';
import { StatusSimplifiedReurbPipe } from './status-simplified-reurb.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AllotmentDocumentsPipe,
    PropertyOwnershipDocumentPipe,
    ProprietaryDocumentPipe,
    PropertyAcquisitionDocumentPipe,
    PropertyOccupationOppositionPipe,
    PropertyUsagePipe,
    PropertyOccupancyPipe,
    DiaPagamentoPipe,
    PropertySituationPipe,
    StatusSimplifiedReurbPipe,
    AddressPipe,
    MatrimonialRegimePipe,
    GenderPipe,
    PagamentoStatusPipe,
    ReurbModalidadePipe
  ],
  exports: [
    AddressPipe,
    AllotmentDocumentsPipe,
    GenderPipe,
    PropertyOwnershipDocumentPipe,
    ProprietaryDocumentPipe,
    PropertyAcquisitionDocumentPipe,
    PropertyOccupationOppositionPipe,
    DiaPagamentoPipe,
    PropertyUsagePipe,
    PropertyOccupancyPipe,
    PropertySituationPipe,
    MatrimonialRegimePipe,
    PagamentoStatusPipe,
    StatusSimplifiedReurbPipe,
    ReurbModalidadePipe
  ],
})
export class PipesModule { }
