<ion-content>
  <div class="content-vertical-space-between">
    <usucampeao-header mode="md" backRoute=".." title="Ver Notificações"> </usucampeao-header>
    <ion-grid class="ion-no-padding ion-padding-start ion-padding-end">
      <ion-row>
        <ion-col>
          <ng-container [ngSwitch]="notificacao?.tipo">
            <usucampeao-agendamento-disponivel
              [notification]="notificacao"
              *ngSwitchCase="tipoNotificacao.AGENDAMENTO_DISPONIVEL"
            ></usucampeao-agendamento-disponivel>
            <usucampeao-agendamento-realizado
              [notification]="notificacao"
              *ngSwitchCase="tipoNotificacao.CADASTRO_AGENDADO"
            ></usucampeao-agendamento-realizado>
            <usucampeao-cadastro-pre-aprovado  (goto)="navigateTo($event)"
              [notification]="notificacao"
              *ngSwitchCase="tipoNotificacao.CADASTRO_PRE_APROVADO"
            ></usucampeao-cadastro-pre-aprovado>
            <usucampeao-pendencias-cadastro
            (goto)="navigateTo($event)"
              [notification]="notificacao"
              *ngSwitchCase="tipoNotificacao.ALTERACAO_NECESSARIA"
            ></usucampeao-pendencias-cadastro>
            <usucampeao-vencimento-proximo
            (goto)="navigateTo($event)"
              [notification]="notificacao"
              *ngSwitchCase="tipoNotificacao.VENCE_PROXIMO">
            </usucampeao-vencimento-proximo>
            <usucampeao-vencimento-amanha
            (goto)="navigateTo($event)"
              [notification]="notificacao"
              *ngSwitchCase="tipoNotificacao.VENCE_AMANHA">
            </usucampeao-vencimento-amanha>
            <usucampeao-venha-buscar-titulo
              [notification]="notificacao"
              *ngSwitchCase="tipoNotificacao.CADASTRO_VALIDADO"
            ></usucampeao-venha-buscar-titulo>
            <usucampeao-venha-buscar-titulo
              [notification]="notificacao"
              *ngSwitchCase="tipoNotificacao.BUSCAR_TITULO"
            ></usucampeao-venha-buscar-titulo>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="ion-no-padding ion-padding-start ion-padding-end">
      <usucampeao-footer>
        <ion-button mode="md" expand="block" class="button-outline" (click)="navigateToNotification()">
          Voltar
        </ion-button>
      </usucampeao-footer>
    </div>
  </div>
</ion-content>
