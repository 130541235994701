import { IsNotEmpty, IsString } from 'class-validator';

export class CadastroCancelarDto {
  @IsString()
  @IsNotEmpty()
  justificativaCancelamento: string;

  usuarioCancelamentoId: string;
  canceladoPeloCliente: boolean;
}
