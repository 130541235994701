import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ElementBase } from './elements';

export function toFormGroup(questions: ElementBase<string>[]): FormGroup {
  const group: any = {};

  questions.forEach((question) => {
    group[question.key] = question.validators?.length > 0
      ? new FormControl(question.value || '', Validators.compose(question.validators))
      : new FormControl(question.value || '');
  });
  return new FormGroup(group);
}
