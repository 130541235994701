import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsNotEmpty, IsString, ValidateNested } from 'class-validator';

import { OwnerDto } from './owner.dto';
import { IsCpf } from '../../validators/valid-cpf/valid-cpf';
import { RgDto } from './rg.dto';
import { OwnerFillInStatusDto } from './owner-fill-in-status.dto';

export class GetUpdateOwnerDocumentsDto {
  @Expose()
  @IsString()
  @IsCpf()
  @IsNotEmpty()
  cpf: string;

  @Expose()
  @Type(() => RgDto)
  @ValidateNested()
  @IsNotEmpty()
  rg: RgDto;

  fillInStatus: OwnerFillInStatusDto;

  /**
   * Cria uma nova instância de GetUpdateOwnerDocumentsDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdateOwnerDocumentsDto
   */
  static from(props?: OwnerDto | Partial<OwnerDto>): GetUpdateOwnerDocumentsDto {
    return plainToInstance(GetUpdateOwnerDocumentsDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
