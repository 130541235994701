import { Pipe, PipeTransform } from '@angular/core';
import { ReurbModalidade } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'reurbModalidade'
})
export class ReurbModalidadePipe implements PipeTransform {

  transform(modalidade: ReurbModalidade): string {
    switch (modalidade) {
      case ReurbModalidade.REURB_E:
        return 'Reurb-E'

      case ReurbModalidade.REURB_S:
        return 'Reurb-S'

      default:
        return modalidade;
    }
  }

}
