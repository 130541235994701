import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'usucampeao-cadastro-finalizado',
  templateUrl: 'registration-completed.page.html',
  styleUrls: ['registration-completed.page.scss'],
})
export class RegistrationCompletedPage {

  constructor(public router: Router) { }

  public navigateToRegistrationCompleted(): void {
    this.router.navigate([`/home`]);
  }
}
