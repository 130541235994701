import { Component, Input } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';


@Component({
  selector: 'usucampeao-agendamento-realizado',
  templateUrl: './agendamento-realizado.component.html',
  styleUrls: ['./agendamento-realizado.component.scss'],
})
export class AgendamentoRealizadoComponent {
  @Input()
  public notification!: NotificacaoDto;

  constructor() {}
}