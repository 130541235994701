<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
    <ion-text>
        <p>A educação pela pedra</p>
    </ion-text>
    <ion-text class="font-text">
        <p>Outra educação pela pedra: no Sertâo</p>
        <p>(de dentro para fora, e pré-didática).</p>
        <p>No Sertão a pedra não sabe lecionar,</p>
        <p>e, se lecionasse, não ensinaria nada;</p>
        <p>lá não se aprende a pedra: lá a pedra,</p>
        <p>uma pedra de nascença, entranha a alma.</p>
    </ion-text>
    <ion-grid>
        <ion-row class="ion-justify-content-end">
            <ion-col size="auto">
                <a (click)="dismiss()"><b>Entendi</b></a>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
