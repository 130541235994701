import { NotificationBadgeComponent } from './notification-badge.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {UiMobileModule}from '@usucampeao/ui-mobile';


@NgModule({
  imports: [CommonModule,UiMobileModule, IonicModule, PipesModule],
  declarations: [NotificationBadgeComponent],
  exports: [NotificationBadgeComponent],
})
export class NotificationBadgeComponentModule {}
