import { OwnerFillInStatusDto } from '../owner/owner-fill-in-status.dto';

export class PendingOwnerData {
  id: string;
  fillInStatus: OwnerFillInStatusDto;

  constructor(id: string, fillInStatus: OwnerFillInStatusDto) {
    this.id = id;
    this.fillInStatus = fillInStatus;
  }
}
