import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { FooterModule } from '../../../components/footer/footer.module';
import { HeaderModule } from '../../../components/header/header.module';
import { ToolbarModule } from '../../../components/toolbar/toolbar.module';
import { AttendanceValidationPageRoutingModule } from './attendance-validation-routing.module';

@NgModule({
  imports: [
    CommonModule,
    UiMobileModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    CommonModule,
    ToolbarModule,
    FooterModule,
    HeaderModule,
    AttendanceValidationPageRoutingModule
  ],
})
export class AttendanceValidationPageModule { }
