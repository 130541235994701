import { ContactModule } from './../../../modal/contact/contact.module';


import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AppointmentPage } from './appointment.page';
import { AppointmentPageRoutingModule } from './appointment-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectHourPageModule } from '../select-hour/select-hour.module';
import { ConfirmAppointmentPageModule } from '../confirm-appointment/confirm-appointment.module';

@NgModule({
  imports: [
    AppointmentPageRoutingModule,
    CommonModule,
    ContactModule,
    FooterModule,
    FormsModule,
    HeaderModule,
    IonicModule,
    ReactiveFormsModule,
    SelectHourPageModule,
    ConfirmAppointmentPageModule
  ],
  declarations: [AppointmentPage],
  providers: [DatePipe],
})
export class AppointmentPageModule { }
