import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-privacy-policy',
  templateUrl: 'privacy-policy.page.html',
  styleUrls: ['privacy-policy.page.scss'],
})
export class PrivacyPolicyPage {
  
  email = "ajuda@poderegularizar.com.br";
  site ="www.poderegularizar.com.br"

  constructor(
    public modalController: ModalController,
  ) {}

  dismiss() {
    this.modalController.dismiss();
  }
}
