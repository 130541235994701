<ion-content>
    <usucampeao-header mode="md" backRoute="/" title="Imóvel"></usucampeao-header>
    <ion-grid class="ion-no-padding">

        <ion-row class="ion-justify-content-center">
            <ion-col sizeXs="12" sizeSm="6" class="ion-no-margin ion-no-padding ion-text-center">
                <div *ngIf="id">
                    <img class="img-facade" [lazyLoad]="propertyFacadePhoto | async" [defaultImage]="defaultImage" [errorImage]="placeholder" />
                </div>
            </ion-col>
        </ion-row>

        <ion-row class="ion-text-center ion-justify-content-center ion-no-margin ion-no-padding">
            <ion-col sizeXs="12" sizeSm="6">
                <usucampeao-photo  [imageUrl]="propertyFacadePhoto | async" [requiredphoto]="true" class="ion-margin-top" [button]="true" type="lot" (setImage)="setImage($event)">
                </usucampeao-photo>
            </ion-col>
        </ion-row>

        <ion-row class="ion-padding-top ion-justify-content-center ion-padding-start ion-padding-end">
            <ion-col sizeXs="12" sizeSm="6">
                <ion-text class="title title--medium">
                  {{ projeto?.nome }}
                </ion-text>
            </ion-col>
        </ion-row>

        <ion-row class="ion-justify-content-center ion-padding-top ion-padding-start ion-padding-end" *ngIf="property">
            <ion-col sizeXs="12" sizeSm="6">
                <usucampeao-process-progress icon="map-outline" [title]="'Quadra ' + property.quadra + ' Lote ' + property.lote" titleClass="small" subtitle="Identificação do lote do imóvel" [status]="property.fillInStatus?.localizationData" [statusText]="property.fillInStatus?.localizationData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
                    [statusTextClass]="property.fillInStatus?.localizationData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'" (click)="goTo('lote')">
                </usucampeao-process-progress>

                <usucampeao-process-progress icon="location-outline" [title]="property.address | address" titleClass="small" subtitle="Endereço do imóvel" [status]="property.fillInStatus?.addressData" [statusText]="property.fillInStatus?.addressData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
                    [statusTextClass]="property.fillInStatus?.addressData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'" (click)="goTo('endereco')">
                </usucampeao-process-progress>

                <usucampeao-process-progress icon="home-outline" title="Outras informações" titleClass="small" subtitle="Informações adicionais sobre o imóvel" [status]="property.fillInStatus?.additionalInformationsData" [statusText]="property.fillInStatus?.additionalInformationsData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
                    [statusTextClass]="property.fillInStatus?.additionalInformationsData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'" (click)="goTo('informacoes-adicionais')">
                </usucampeao-process-progress>

                <usucampeao-process-progress
                icon="document-attach-outline"
                title="Documentos do imóvel"
                titleClass="large"
                subtitle="Documentos do imóvel"
                [status]="property.fillInStatus?.documents"
                [statusText]="property.fillInStatus?.documents === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
                [statusTextClass]="property.fillInStatus?.documents === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
                (click)="goTo('documentos')"
              >
              </usucampeao-process-progress>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding-top ion-padding-start ion-padding-end" *ngIf="!property">
            <ion-col sizeXs="12" sizeSm="6">
                <ion-list>
                    <usucampeao-skeleton-item></usucampeao-skeleton-item>
                    <usucampeao-skeleton-item></usucampeao-skeleton-item>
                    <usucampeao-skeleton-item></usucampeao-skeleton-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
    <usucampeao-footer></usucampeao-footer>
</ion-content>
