/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import Bugsnag from '@bugsnag/browser';
import { ModalController, ToastController } from '@ionic/angular';
import {
  DocumentFileSubtype,
  JustifyDocumentDto,
  PropertyAcquisitionDocument,
  PropertyOwnershipDocument
} from '@usucampeao/lib-reurb-simplificado';
import { ElementBase, TextArea, toFormGroup } from '@usucampeao/ui-mobile';
import { take } from 'rxjs/operators';
import { RegistrationService } from '../../../../state/registration.service';

@Component({
  selector: 'usucampeao-justification',
  templateUrl: 'justification.page.html',
  styleUrls: ['justification.page.scss'],
})
export class JustificationPage implements OnInit {
  @Input() id: string;
  @Input() propertyId: string;
  @Input() type: PropertyAcquisitionDocument | PropertyOwnershipDocument;
  @Input() subtype: DocumentFileSubtype;
  @Input() editar = false;
  @Input() justification: string;

  form$: ElementBase<string>[] | null = [];
  form!: FormGroup;

  constructor(
    private modalController: ModalController,
    private registrationService: RegistrationService,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    this.form$ = this.getForm();
    this.form = toFormGroup(this.getForm() as ElementBase<string>[]);
  }

  public get isOwnershipDocument(): boolean {
    return this.subtype === DocumentFileSubtype.ACQUISITION || Object.values(PropertyAcquisitionDocument).includes(this.type as any);
  }

  private getForm(): ElementBase<string>[] {
    return [
      new TextArea({
        key: 'justification',
        label: 'Especificar',
        value: this.justification || '',
        validators: [Validators.required]
      }),
    ];
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const justifyDocument = new JustifyDocumentDto(this.form.get('justification').value);
    if (this.type) {
      justifyDocument.type = this.type;
    } else {
      justifyDocument.subtype = this.subtype;
    }

    this.justifyDocument(justifyDocument);
  }

  private justifyDocument(justifyDocument: JustifyDocumentDto): void {
    this.registrationService.justifyDocument(this.id, this.propertyId, justifyDocument)
      .pipe(
        take(1)
      )
      .subscribe(
        () => {
          this.back(true)
        },
        async (err) => {
          Bugsnag.notify('Justificativa imovel - erro ao justificar documento', err);
          const toast = await this.toastController.create({
            message: 'Erro ao justificar documento. Tente novamente mais tarde.',
            duration: 5000,
          });
          toast.present();
          this.back();
        }
      );
  }

  public async back(navegar = false): Promise<void> {
    await this.modalController.dismiss({ navegar });

  }
}
