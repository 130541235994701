import { Component, OnDestroy } from '@angular/core';
import { ViewWillLeave } from '@ionic/angular';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../../../services/loading.service';
import { NavigationService } from '../../../../services/navigation.service';
import { ToastService } from '../../../../services/toast.service';
import { AuthService } from '../../state/auth.service';

@Component({
  selector: 'usucampeao-edit-personal-photo',
  templateUrl: 'edit-personal-photo.page.html',
  styleUrls: ['edit-personal-photo.page.scss'],
})
export class EditPersonalPhotoPage implements OnDestroy, ViewWillLeave, OnDestroy {
  private ngUnsubscribe = new Subject();
  public photo: File;

  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) { }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  /**
   * Seta a foto no component
   * @param photo foto selecionada
   */
  public setImage(photo: File): void {
    this.photo = photo;
  }

  /**
   * Atualiza a foto da fachada do imóvel
   */
  public async updateFacadePhoto(): Promise<void> {
    await this.loadingService.createLoader();
    this.authService
      .updateAccountPhoto(this.photo)
      .pipe(
        finalize(async () => await this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async () => {
        await this.authService.getUserData();
        this.navigationService.goBack();
      },
        async () => {
          await this.toastService.erro('Erro ao atualizar imagem de perfil');
        });
  }

  /**
   * Navega para a página anterior
   */
  public goBack(): void {
    this.navigationService.goBack();
  }
}
