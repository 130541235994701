import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  /**
   * Exibe um toast de erro
   * @param mensagem mensagem a ser exibida
   */
  public async erro(mensagem: string): Promise<void> {
    const toast = await this.toastController.create({
      header: 'Atenção',
      message: mensagem,
      icon: 'warning',
      position: 'bottom',
      color: 'danger',
      duration: 8000,
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
        }
      ]
    });

    await toast.present();
  }
}
