import { IsOptional, IsString } from 'class-validator';

export class DadosIptu {
  @IsString()
  @IsOptional()
  titular: string;

  @IsString()
  @IsOptional()
  numeroCadastro: string;
}
