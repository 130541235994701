import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import {
  AlteracaoDto,
  GetUpdatePropertyAdditionalInformationsDto,
  OwnerSimplifiedDto, RegistrationStatus,
  TipoAlteracao
} from '@usucampeao/lib-reurb-simplificado';
import { ElementBase, toFormGroup } from '@usucampeao/ui-mobile';
import { of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ToastService } from '../../../../../app/services/toast.service';
import { PropertyOccupancyPipe } from '../../../../pipes/property-occupancy.pipe';
import { PropertySituationPipe } from '../../../../pipes/property-situation.pipe';
import { PropertyUsagePipe } from '../../../../pipes/property-usage.pipe';
import { LoadingService } from '../../../../services/loading.service';
import { NavigationService } from '../../../../services/navigation.service';
import { AuthQuery } from '../../../auth/state/auth.query';
import { RegistrationService } from '../../state/registration.service';
import { PropertyQuery } from '../state/property.query';
import { PropertyService } from '../state/property.service';
import { RegistrationQuery } from './../../state/registration.query';
import { getFormEditAdditional } from './edit-additional-information.form';


@Component({
  selector: 'usucampeao-edit-additional-information',
  templateUrl: 'edit-additional-information.page.html',
  styleUrls: ['edit-additional-information.page.scss'],
})
export class EditAdittionalInformationPage implements OnInit, ViewWillEnter, ViewWillLeave, OnDestroy {
  public form$: ElementBase<string>[] | null = [];
  public form!: FormGroup;
  private propertyId: string;
  private registrationId: string;
  private ngUnsubscribe = new Subject();
  private fluxo: boolean;
  public owner: OwnerSimplifiedDto;
  public formEditAdditional$: ElementBase<string>[] | null = [];
  public formSpecifyUsagel$: ElementBase<string>[] | null = [];
  public formOccupation$: ElementBase<string>[] | null = [];
  public formSpecifyOccupation$: ElementBase<string>[] | null = [];
  public formResidentsQuantity$: ElementBase<string>[] | null = [];
  isOtherEditAdditional = false;
  isOtherOccupation = false;
  public blockAccess = false;
  public alteracoes: AlteracaoDto[] = [];

  constructor(
    public authQuery: AuthQuery,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private propertyOccupancyPipe: PropertyOccupancyPipe,
    private propertyQuery: PropertyQuery,
    private propertyService: PropertyService,
    private propertySituationPipe: PropertySituationPipe,
    private propertyUsagePipe: PropertyUsagePipe,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private registrationQuery: RegistrationQuery,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.initForms(false);
  }

  private initForms(readOption: boolean) {
    this.formEditAdditional$ = getFormEditAdditional(this.propertyUsagePipe, this.propertyOccupancyPipe, this.propertySituationPipe, readOption);
    this.form = toFormGroup(this.getForm(readOption) as ElementBase<string>[]);
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        const registration = this.registrationQuery.getValue();
        const status = registration.entities[params.id];
        this.validateStatus(status.status);
        this.propertyId = params.propertyId;
        this.registrationId = params.id;
        this.getAdditionalInformations();
      });

    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.fluxo = params['fluxo'];
      });

    this.propertyQuery.selectLoading()
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async isLoading => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  getForm(readOption: boolean) {
    return [
      ...getFormEditAdditional(this.propertyUsagePipe, this.propertyOccupancyPipe, this.propertySituationPipe, readOption)
    ];
  }

  public async onSubmit(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      await this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
      return;
    }

    this.updateAdditionalInformations();
  }

  private getAdditionalInformations(): void {
    this.propertyQuery
      .selectEntity(this.propertyId)
      .pipe(
        take(1),
        switchMap((property) => {
          if (property.additionalDataLoaded) {
            return of(
              GetUpdatePropertyAdditionalInformationsDto.from(property)
            );
          }
          return this.propertyService.getAdditionalInformationsData(
            this.propertyId
          );
        }),
        tap((additionalInformations) => {
          if (Object.keys(additionalInformations).length > 0) {
            this.form.patchValue(additionalInformations);
          }
        }),
        switchMap(() => this.registrationService.buscaAlteracoesCadastro(this.registrationId, TipoAlteracao.IMOVEL_DADOS_ADICIONAIS)),
        tap(alteracoes => this.alteracoes = alteracoes),
        catchError(async () => await this.toastService.erro('Erro ao buscar dados do imóvel. Tente novamente mais tarde.'))
      )
      .subscribe();
  }

  public updateAdditionalInformations(): void {
    const formValue = this.form.value;
    const value: GetUpdatePropertyAdditionalInformationsDto = {
      ...formValue,
      loteArea: +formValue.loteArea
      // residentsQuantity: +formValue.residentsQuantity,
      // residenceTime: +formValue.residenceTime,
      // housesQuantity: +formValue.housesQuantity,
      // housesQuantitySameOwner: +formValue.housesQuantitySameOwner,
      // entriesQuantity: +formValue.entriesQuantity,
      // entriesQuantitySamePavement: +formValue.entriesQuantitySamePavement,
    };

    this.propertyService.updateAdditionalInformation(this.registrationId, this.propertyId, value)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        () => {
          if (this.fluxo) {
            this.router.navigate(
              [`../../../proprietarios/detalhes`],
              { relativeTo: this.route, queryParams: { fluxo: true } }
            );
          } else {
            this.navigationService.goBack();
          }
        },
        async () => await this.toastService.erro('Erro ao atualizar dados do imóvel. Tente novamente mais tarde.'));
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
      this.initForms(true);
    }
  }
}
