import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class Bs2BoletoConectarDto {
  @IsString()
  @IsNotEmpty()
  pagamentoId: string;

  @IsNumber()
  @IsNotEmpty()
  parcela: number;

  @IsNumber()
  @IsNotEmpty()
  totalParcelas: number;

  @IsString()
  @IsNotEmpty()
  contratoId: string;

  constructor(
    pagamentoId: string,
    parcela: number,
    totalParcelas: number,
    contratoId: string
  ) {
    this.pagamentoId = pagamentoId;
    this.parcela = parcela;
    this.totalParcelas = totalParcelas;
    this.contratoId = contratoId;
  }
}
