import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

/**
 * Model interface (DTO) for 'File' entity.
 * represents an uploaded file.
 */
export class FileUpload {
  @IsString()
  @IsNotEmpty()
  base64EncodedFileString!: string;

  @IsString()
  @IsOptional()
  mimeType?: string;

  @IsString()
  @IsNotEmpty()
  local?: string;
}

export interface Files {
  url?: string;

  mimeType?: string;

  local?: string;
}
