import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endereco } from '@brunoc/ngx-viacep';
import bugsnag from '@bugsnag/js';
import { withTransaction } from '@datorama/akita';
import {
  AddressDto,
  FileDto,
  GetUpdatePropertyAdditionalInformationsDto,
  GetUpdatePropertyLocalizationDto,
  PropertyOccupationDto,
  PropertySimplifiedDto,
  StatusPreenchimento,
  UpdatePropertyAddressAdditionalInformationsDto
} from '@usucampeao/lib-reurb-simplificado';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { RegistrationStore } from '../../state/registration.store';
import { Property, PropertyStore } from './property.store';

@Injectable({ providedIn: 'root' })
export class PropertyService {

  constructor(
    private http: HttpClient,
    private registrationStore: RegistrationStore,
    private store: PropertyStore,
  ) { }

  /**
   * Busca os dados simplificados do imóvel
   * @param id id do imóvel
   * @returns dados simplificados do imóvel
   */
  public getSimplifiedData(id: string): Observable<PropertySimplifiedDto> {
    this.store.setLoading(true);
    return this.http.get<PropertySimplifiedDto>(`/properties/${id}/simplified`)
      .pipe(
        tap(property => {
          this.store.update(id, property);
        }),
        tap(simplifiedData => {
          const state: Partial<Property> = {
            id,
            ...simplifiedData,
            ...simplifiedData.address ? { addressDataLoaded: true } : {}
          }
          this.store.add(state as any);
        }),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-ggetSimplified-data'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/properties/${id}/simplified`,
              title: 'Erro ao buscar os dados simplificados do imóvel:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Busca os dados de localização do imóvel
   * @param id id do imóvel
   * @returns dados de localização do imóvel
   */
  public getLocalizationData(id: string): Observable<GetUpdatePropertyLocalizationDto> {
    this.store.setLoading(true);
    return this.http.get<GetUpdatePropertyLocalizationDto>(`/properties/${id}/localization`)
      .pipe(
        tap(localizationData => this.store.update(id, { ...localizationData, localizationDataLoaded: true })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getLocalization-data'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/properties/${id}/localization`,
              title: 'Erro ao buscar os dados de localização do imóvel:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Busca os dados de endereço do imóvel
   * @param id id do imóvel
   * @returns dados de endereço do imóvel
   */
  public getAddressData(id: string): Observable<AddressDto> {
    this.store.setLoading(true);
    return this.http.get<AddressDto>(`/properties/${id}/address`)
      .pipe(
        tap(address => this.store.update(id, { ...{ address }, addressDataLoaded: true })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getAddress-data'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/properties/${id}/address`,
              title: 'Erro ao buscar os dados de endereço do imóvel:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Busca as informações adicionais do imóvel
   * @param id id do imóvel
   * @returns informações adicionais do imóvel
   */
  public getAdditionalInformationsData(id: string): Observable<GetUpdatePropertyAdditionalInformationsDto> {
    this.store.setLoading(true);
    return this.http.get<GetUpdatePropertyAdditionalInformationsDto>(`/properties/${id}/additional-informations`)
      .pipe(
        tap(additionalInformations => this.store.update(id, { ...additionalInformations, additionalDataLoaded: true })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getAdditionalInformations-data'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/properties/${id}/additional-informations`,
              title: 'Erro ao buscar as informações adicionais do imóvel:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Busca os dados de ocupação do imóvel
   * @param id id do imóvel
   * @returns dados de ocupação do imóvel
   */
  public getPropertyOccupation(id: string): Observable<PropertyOccupationDto> {
    this.store.setLoading(true);
    return this.http.get<PropertyOccupationDto>(`/properties/${id}/property-occupation`)
      .pipe(
        tap(propertyOccupation => this.store.update(id, { propertyOccupation, occupationDataLoaded: true })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getProperty-Occupation'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/properties/${id}/property-occupation`,
              title: 'Erro ao buscar os dados de ocupação do imóvel:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Atualiza dados de localização do imóvel
   * @param registrationId id do atendimento
   * @param id id do imóvel
   * @param localizationData dados de localização
   */
  public updateLocalizationData(registrationId: string, id: string, localizationData: GetUpdatePropertyLocalizationDto): Observable<void> {
    this.store.setLoading(true);
    return this.http.put<void>(`/registrations/${registrationId}/properties/${id}/localization`, localizationData)
      .pipe(
        withTransaction(() => this.store.update(id, { ...localizationData, fillInStatus: { localizationData: StatusPreenchimento.ENVIADO } })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateLocalization-data'
            event.addMetadata('payload', localizationData);
            event.addMetadata('params', { id, registrationId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${id}/localization`,
              title: 'Erro ao atualizar dados de localização do imóvel:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Atualiza dados de localização do imóvel
   * @param registrationId id do atendimento
   * @param id id do imóvel
   * @param address dados de endereço
   */
  public updateAddressData(registrationId: string, id: string, address: AddressDto): Observable<void> {
    this.store.setLoading(true);
    return this.http.put<void>(`/registrations/${registrationId}/properties/${id}/address`, address)
      .pipe(
        withTransaction(() => {
          this.store.update(id, { address, fillInStatus: { addressData: StatusPreenchimento.ENVIADO } });
          this.registrationStore.update(registrationId, { address });
        }),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateAddress-data'
            event.addMetadata('payload', address);
            event.addMetadata('params', { id, registrationId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${id}/address`,
              title: 'Erro ao atualizar dados de localização do imóvel:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Atualiza dados adicionais do imóvel
   * @param registrationId id do atendimento
   * @param id id do imóvel
   * @param additionalInformation dados adicionais
   */
  public updateAdditionalInformation(registrationId: string, id: string, additionalInformation: GetUpdatePropertyAdditionalInformationsDto): Observable<void> {
    this.store.setLoading(true);
    return this.http.put<void>(`/registrations/${registrationId}/properties/${id}/additional-informations`, additionalInformation)
      .pipe(
        tap(() => this.store.update(id, additionalInformation)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateAdditional-Information'
            event.addMetadata('payload', additionalInformation);
            event.addMetadata('params', { id, registrationId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${id}/additional-informations`,
              title: 'Erro ao atualizar dados adicionais do imóvel:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Atualiza dados adicionais e de endereço do imóvel
   * @param registrationId id do atendimento
   * @param id id do imóvel
   * @param data dados de endereço e informações adicionais e de endereço
   */
  public updateAddressAndAdditionalInformation(registrationId: string, id: string, data: UpdatePropertyAddressAdditionalInformationsDto): Observable<void> {
    this.store.setLoading(true);
    return this.http.put<void>(`/registrations/${registrationId}/properties/${id}/additional-informations`, data)
      .pipe(
        tap(() => this.store.update(id, data)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateAddressAndAdditional-Information'
            event.addMetadata('payload', data);
            event.addMetadata('params', { id, registrationId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${id}/additional-informations`,
              title: 'Erro ao atualizar dados adicionais e de endereço do imóvel:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Altera foto da fachada do imóvel
   * @param id id do imóvel
   * @param file foto da fachada
   */
  public updateFacadePhoto(registrationId: string, id: string, file: File): Observable<FileDto> {
    this.store.setLoading(true);
    const formData = new FormData();
    formData.append('photo', file);
    return this.http.put<FileDto>(`/registrations/${registrationId}/properties/${id}/photo`, formData)
      .pipe(
        withTransaction(file => {
          this.store.changeFile(id, file);
          this.registrationStore.changeFile(registrationId, file);
        }),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateFacade-Photo'
            event.addMetadata('payload', formData);
            event.addMetadata('params', { id, registrationId });
            event.addMetadata('metadata', {
              url: `/properties/${id}/photo`,
              title: 'Erro ao alterar foto da fachada do imóvel:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  /**
   * Atualiza dados de ocupação do imóvel
   * @param registrationId id do atendimento
   * @param id id do imóvel
   * @param propertyOccupation dados de ocupação do imóvel
   */
  public updatePropertyOccupation(registrationId: string, id: string, propertyOccupation: PropertyOccupationDto): Observable<void> {
    this.store.setLoading(true);
    return this.http.put<void>(`/registrations/${registrationId}/properties/${id}/property-occupation`, propertyOccupation)
      .pipe(
        tap(() => this.store.update(id, { propertyOccupation })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateProperty-Occupation'
            event.addMetadata('payload', propertyOccupation);
            event.addMetadata('params', { id, registrationId });
            event.addMetadata('body', { propertyOccupation });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${id}/property-occupation`,
              title: 'Erro ao atualizar dados de ocupação do imóvel:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }


  /**
   * Busca endereço pelo CEP
   * @param zipCode cep selecionado
   * @returns endereço do cep
   */
  public getAddressByZipCode(zipCode: string): Observable<Endereco> {
    this.store.setLoading(true);
    zipCode = zipCode.replace(/\D/g, '');
    return this.http.get<Endereco>(`https://viacep.com.br/ws/${zipCode}/json/`)
      .pipe(
        take(1),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getAddressBy-ZipCode'
            event.addMetadata('params', { zipCode });
            event.addMetadata('metadata', {
              url: `https://viacep.com.br/ws/${zipCode}/json/`,
              title: 'Erro ao buscar endereço pelo CEP',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

}
