import { Expose, plainToInstance, Type } from 'class-transformer';
import { RevisaoDto } from '.';
import { AlteracaoDetalhesDto } from './alteracao-detalhes.dto';
import { StatusRevisao } from './status-revisao.enum';

export class RevisaoDetalhesDto {
  @Expose()
  usuarioNome: string;

  @Expose()
  @Type(() => AlteracaoDetalhesDto)
  alteracoes: AlteracaoDetalhesDto[];

  @Expose()
  status: StatusRevisao;

  @Expose()
  dataCriacao: Date;

  /**
  * Cria uma nova instância de GetUpdateOwnerDocumentsDto a partir de dtos
  * @param props DTO de Owner
  * @returns nova instância de GetUpdateOwnerDocumentsDto
  */
  static from(props?: RevisaoDto | Partial<RevisaoDto>): RevisaoDetalhesDto {
    return plainToInstance(RevisaoDetalhesDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
