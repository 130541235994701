<ion-content>
  <usucampeao-header mode="md" backRoute=".." title="Pagamentos"></usucampeao-header>
  <ion-grid class="ion-padding-start ion-padding-end">
    <ion-row class="ion-align-items-start">
      <ion-col sizeXs="12" sizeSm="6" class="ion-no-padding ion-padding-bottom">
        <ion-text class="font-text">Aqui está o resumo financeiro do seu processo.</ion-text>
      </ion-col>
        <ion-row *ngIf="resumepagamentos?.resume">
          <ion-col *ngFor="let item of resumepagamentos.resume">
            <usucampeao-resumo-financeiro [pagamento]="item"></usucampeao-resumo-financeiro>
          </ion-col>
        </ion-row>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding-top">
      <ion-col sizeXs="12" sizeSm="6" class="ion-no-padding">
        <ion-item class="ion-padding-bottom" lines="none">
          <ion-grid class="caixa-cinza ion-no-padding ion-padding-top">
            <ion-row>
              <ion-col size="2">
                <ion-icon class="cash-icon" color="medium" name="cash-sharp"></ion-icon>
              </ion-col>
              <ion-col size="10" class="ion-justify-text ion-no-padding">
                <ion-row>
                  <ion-text class="font-text font-text--large">{{ resumepagamentos?.total | currency:'BRL' || ''}}
                  </ion-text>
                </ion-row>
                <ion-row>
                  <ion-text *ngIf="resumepagamentos?.numeroBoletos === 1" class="description-text">Entrada de R$
                    {{resumepagamentos?.entrada | currency:'BRL' }} + {{resumepagamentos?.numeroBoletos||0}} parcela
                  </ion-text>

                  <ion-text *ngIf="resumepagamentos?.numeroBoletos > 1" class="description-text">Entrada de R$
                    {{resumepagamentos?.entrada | currency:'BRL'}} + {{resumepagamentos?.numeroBoletos||0}} parcelas de
                    R$ {{resumepagamentos?.valorParcela||0}}<br>Pagamento via boleto</ion-text>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-text class="font-text">Acompanhe, abaixo, a situação de cada parcela do seu processo. Atente-se às parcelas
          com boletos já disponíveis.</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-no-padding ion-padding-top ion-padding-bottom">
        <ion-text class="title title--medium">Parcelas em aberto</ion-text>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="resumepagamentos?.valorPendente">
      <ion-col>
        <usucampeao-parcela *ngFor="let item of resumepagamentos.valorPendente" [pagamento]="item"
          (click)="navigateToParcela(item.id)"></usucampeao-parcela>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="resumepagamentos?.valorPago.length>0">
      <ion-col class="ion-no-padding ion-padding-top ion-padding-bottom">
        <ion-text class="title title--medium">Parcelas pagas</ion-text>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="resumepagamentos?.valorPago">
      <ion-col>
        <usucampeao-parcela *ngFor="let item of resumepagamentos.valorPago" [pagamento]="item"
          (click)="navigateToParcela(item.id)"></usucampeao-parcela>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button class="ion-margin-bottom" mode="md" fill="outline" expand="block" (click)="goBack()">
          Voltar</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-text class="font-text font-text--small">
          Em caso de dúvidas a respeito das parcelas,
          <ion-text color="strong"><strong class="text-link" (click)="presentModalContact()">entre em contato</strong>
          </ion-text>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>
