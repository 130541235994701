import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';

import { StatusAttendanceBadgeComponentModule } from './status-attendance-badge/status-attendance-badge.module';
import { AttendanceCardComponent } from './attendance-card.component';
import { LazyLoadImageHooks } from '../../../hooks/lazy-load-image-hooks';


@NgModule({
  imports: [
    CommonModule,
    UiMobileModule,
    LazyLoadImageModule,
    IonicModule,
    StatusAttendanceBadgeComponentModule
  ],
  declarations: [AttendanceCardComponent],
  exports: [AttendanceCardComponent],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks
    }
  ]
})
export class AttendanceCardComponentModule { }
