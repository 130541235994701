import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EmptyListComponent } from './empty-list.component';

@NgModule({
  declarations: [
    EmptyListComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [EmptyListComponent]
})
export class EmptyListModule { }
