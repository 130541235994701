import { IsEnum, IsOptional, IsString } from 'class-validator';
import { UsoImovelTipo } from '../uso-imovel-tipo.enum';
import { RelacaoImovelAdicionalTipo } from './relacao-imovel-adicional-tipo.enum';

export class ImovelAdicional {
  @IsString()
  @IsOptional()
  formaAquisicao: string;

  @IsEnum(RelacaoImovelAdicionalTipo)
  @IsOptional()
  relacao: RelacaoImovelAdicionalTipo;

  @IsString()
  @IsOptional()
  relacaoEspecifique: string;

  @IsEnum(UsoImovelTipo)
  @IsOptional()
  uso: UsoImovelTipo;
}
