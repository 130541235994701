import { RelatorioVendasFiltro } from './relatorio-vendas-filtro.enum';
import { RelatorioVendasTipo } from './relatorio-vendas-tipo.enum';

export class RelatorioResponseDto<T> {
  dados: T;
  metadata: ProjetoRelatorioMetadata;
}

export interface ProjetoRelatorioMetadata {
  dataInicio: Date;
  dataFim: Date;
  filtro?: RelatorioVendasFiltro;
  tipo?: RelatorioVendasTipo;
}
