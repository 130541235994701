import { Expose, plainToClass } from 'class-transformer';
import { NotificacaoDto, StatusNotificacao } from '.';
import { TipoNotificacao } from './tipo-notificacao.enum';

export class NotificacaoSimplificadaDto {
  @Expose()
  id: string;

  @Expose()
  titulo: string;

  @Expose()
  descricao: string;

  @Expose()
  dataEnvio: Date;

  @Expose()
  status: StatusNotificacao;

  @Expose()
  tipo: TipoNotificacao;

  /**
   * Cria uma nova instância de NotificacaoSimplificadaDto a partir de dtos
   * @param props DTO de Notificação
   * @returns nova instância de NotificacaoSimplificadaDto
   */
  static from(props?: NotificacaoDto | Partial<NotificacaoDto>): NotificacaoSimplificadaDto {
    return plainToClass(NotificacaoSimplificadaDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
