/**
 * Enumeration of service document types.
 */
export enum ServiceDocumentType {
  /**
   * Contrato do atendimento
   */
  CONTRATO = 'CONTRATO',
  /**
   * Procuração
   */
  PROCURACAO = 'PROCURACAO'
}
