import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { PrivacyPolicyPage } from './privacy-policy.page';

@NgModule({
  imports: [CommonModule,IonicModule, UiMobileModule, CommonModule],

  declarations: [PrivacyPolicyPage],

  exports:[PrivacyPolicyPage],
})
export class PrivacyPolicyModule {}
