import { PropertyDto } from '@usucampeao/interfaces';
import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

import { AddressDto } from '../address/address.dto';
import { GetUpdatePropertyAdditionalInformationsDto } from './get-update-property-additional-informations.dto';

export class UpdatePropertyAddressAdditionalInformationsDto extends GetUpdatePropertyAdditionalInformationsDto {
  @Expose()
  @Type(() => AddressDto)
  @IsNotEmpty()
  address: AddressDto;

  /**
   * Cria uma nova instância de GetUpdatePropertyLocalization a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdatePropertyLocalization
   */
  static from(props?: PropertyDto | Partial<PropertyDto>): UpdatePropertyAddressAdditionalInformationsDto {
    return plainToInstance(UpdatePropertyAddressAdditionalInformationsDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
