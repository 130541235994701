import { Component, Input } from '@angular/core';
import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';

import { getIconName } from '../../../../models/status-simplified-reurb.enum';
@Component({
  selector: 'usucampeao-attendance-badge',
  templateUrl: 'status-attendance-badge.component.html',
  styleUrls: ['status-attendance-badge.component.scss'],
})
export class StatusAttendanceBadgeComponent {
  @Input()
  status!: RegistrationStatus;

  constructor() { }

  public get registrationStatus(): typeof RegistrationStatus {
    return RegistrationStatus;
  }

  public statusIcon(status: RegistrationStatus): string {
    return getIconName(status);
  }
}
