import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ProjetoSimplificadoDto } from '@usucampeao/lib-reurb-simplificado';

export interface ProjetoState extends EntityState<ProjetoSimplificadoDto> {
  loaded: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'projetos',
  resettable: true
})
export class ProjetoStore extends EntityStore<ProjetoState> {
  constructor() {
    super();
  }

  public loadProjectos(projetos: ProjetoSimplificadoDto[], loaded: boolean) {
    this.set(projetos);
    this.update(state => ({
      ...state,
      loaded
    }));
  }
}