/* eslint-disable @typescript-eslint/no-explicit-any */
import { MapsAPILoader } from '@agm/core';
import {
  AfterViewInit, Component, EventEmitter, Input, NgZone,
  OnInit, Output, ViewChild
} from '@angular/core';
import { AlertController, IonSearchbar } from '@ionic/angular';
import * as L from 'leaflet';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Lot } from '../../models/lot.interface';
import { RegisterService } from '../../pages/register/state/register.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'usucampeao-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnInit {
  @Input() selectedId: number;
  @Input() projetoId: string;
  @Output() setLot = new EventEmitter<Lot>();
  @ViewChild('searchbar', { read: IonSearchbar }) searchbar!: IonSearchbar;
  @ViewChild('map') mapContainer!: any;

  private map!: L.Map;
  private selectedEl!: any;
  private lotes;
  private minLat = -23.592134840506485;
  private maxLat = -23.58236652315143;
  private minLng = -46.385924355068475;
  private maxLng = -46.378233819529235;
  private lat = -23.5863;
  private lng = -46.3828;
  private zoomInicial = 12;
  private zoomMinimo = 12;
  private zoomMaximo = 50;
  private marker!: L.Marker;

  constructor(
    private alertCtrl: AlertController,
    private loadingService: LoadingService,
    private registerService: RegisterService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.initSearchAutoComplete();
  }

  ngAfterViewInit(): void {
    this.getDadosMapa();
  }

  async getDadosMapa() {

    await this.loadingService.createLoader();

    forkJoin([this.registerService.buscarLotes(this.projetoId), this.registerService.buscaConfigProjetoMapa(this.projetoId)]).subscribe(async (data) => {
      await this.loadingService.dismiss();
      const [lotes, config] = data;
      this.minLat = config.latitudeMinima;
      this.maxLat = config.latitudeMaxima;
      this.minLng = config.longitudeMinima;
      this.maxLng = config.longitudeMaxima;
      this.lat = (config.latitudeMaxima + config.latitudeMinima) / 2;
      this.lng = (config.longitudeMaxima + config.longitudeMinima) / 2;
      this.zoomInicial = config.zoomInicial;
      this.zoomMinimo = config.zoomMinimo;
      this.zoomMaximo = config.zoomMaximo;
      this.lotes = lotes;
      this.initMap();
    }, async () => {

      await this.loadingService.dismiss();
    })
  }

  /**
   * Inicializa autocomplete do google
   */
  private initSearchAutoComplete(): void {
    this.mapsAPILoader.load().then(async () => {
      const inputElement = await this.searchbar.getInputElement();
      const autocomplete = new google.maps.places.Autocomplete(inputElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(async () => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();

          if (this.validCurrentPosition(this.lat, this.lng)) {
            this.map.panTo(new L.LatLng(this.lat, this.lng));
            this.addMakerToMap();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Atenção',
              message: 'Local selecionado fora da área do bairro.',
              backdropDismiss: false,
              buttons: [
                {
                  text: 'Ok',
                  handler: () => {
                    inputElement.value = '';
                  },
                },
              ],
            });
            await alert.present();
          }
        });
      });
    });
  }

  /**
   * Inicializa o mapa
   */
  private initMap(): void {
    this.map = L.map(this.mapContainer.nativeElement, {
      center: [this.lat, this.lng],
      trackResize: true,
      maxBounds: [
        [this.maxLat, this.maxLng],
        [this.minLat, this.minLng],
      ],
      zoom: this.zoomInicial,
      layers: [
        L.tileLayer(`https://api.mapbox.com/styles/v1/usucampeaoti/ckwci25297tvw14o3tg0rx32l/tiles/256/{z}/{x}/{y}@2x?access_token=${environment.mapbox.token}`, {
          attribution: 'Dados de mapa &copy; <a href="http://www.geoja.com.br/">GeoJá</a> e <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagens &copy; <a href="http://www.geoja.com.br/">GeoJá</a> e <a href="https://www.mapbox.com/">Mapbox</a>',
          minZoom: this.zoomMinimo,
          maxZoom: this.zoomMaximo,
        }),
      ],
    });

    this.loadLots();
    this.map.whenReady(() => {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 500);

      navigator.geolocation.getCurrentPosition((resp) => {
        this.setMapToCurrentPosition(resp.coords.latitude, resp.coords.longitude);
      }, async () => {
        // alert error, provavelmente por conta de permissão
        const alert = await this.alertCtrl.create({
          header: 'Atenção',
          message: 'Não foi possível obter sua localização. Caso queira ver sua localização no mapa, conceda a permissão de acesso à sua localização e tente novamente.',
          backdropDismiss: true,
          buttons: [
            {
              text: 'Ok',
            },
          ],
        });
        await alert.present();
      });
    });
  }

  private async setMapToCurrentPosition(latitude: number, longitude: number) {
    if (this.validCurrentPosition(latitude, longitude)) {
      this.lat = latitude;
      this.lng = longitude;
      this.map.panTo(new L.LatLng(this.lat, this.lng));
      this.addMakerToMap();
    }
  }

  clearMarker() {
    if (this.marker != undefined) {
      this.map.removeLayer(this.marker);
    }
    this.marker = undefined as any;
  }

  /**
   * Adiciona o marcador no mapa
   */
  private addMakerToMap(): void {
    if (this.marker) {
      this.marker.setLatLng(new L.LatLng(this.lat, this.lng));
      return;
    }
    this.marker = L.marker([this.lat, this.lng], {
      icon: L.icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'assets/marker-icon.png',
      }),
    }).addTo(this.map);
  }

  /**
   * Valida se a posição selecionada está dentro do limite válido
   * @param lat latitude selecionada
   * @param lng longitude selecionada
   * @returns true caso a posição selecionada esteja dentro do limite válido ou false caso esteja fora
   */
  private validCurrentPosition(lat: number, lng: number): boolean {
    return (
      lng > this.minLng &&
      lng < this.maxLng &&
      lat > this.minLat &&
      lat < this.maxLat
    );
  }

  /**
   * Carrega os polígonos dos lotes e adiciona no mapa
   */
  private loadLots(): void {
    const filteredLotes: Lot[] = this.lotes.filter(l => l.status);
    for (const l of filteredLotes) {
      const primaryColor = '#f07e26';
      const selectedColor = '#4da3fa';
      const options = {
        weight: 2,
        color: l.id === this.selectedId ? selectedColor : primaryColor,
      };

      const lotePolygon = L.polygon(l.coords.coordinates as any, options);

      lotePolygon.addEventListener('add', (ev: L.LeafletEvent) => {
        if (l.id === this.selectedId) {
          this.selectedEl = ev.target;
          this.selectedEl.bringToFront();
        } else {
          ev.target.bringToBack();
        }
      });

      lotePolygon.addEventListener('click', (ev: L.LeafletEvent) => {
        this.selectedId = l.id;
        if (this.selectedEl) {
          this.selectedEl.setStyle({ color: primaryColor });
        }
        this.selectedEl = ev.target;
        this.selectedEl.setStyle({ color: selectedColor });
        this.selectedEl.bringToFront();
        this.setLot.emit(l);
      });

      lotePolygon.addTo(this.map);
      if (l.id === this.selectedId) {
        this.map.setView(lotePolygon.getBounds().getCenter(), 20);
      }
    }
  }
}
