<ion-content class="ion-no-padding" fullscreen=true>
    <div class="content-vertical-space-between ion-padding-end">

        <div>
            <usucampeao-header mode="md" backRoute="/home" title="Novo Cadastro">
 
            </usucampeao-header>
            <div class="ion-padding-start ion-padding-end ion-padding-bottom">
                <ion-text class="font-text">
                    Selecione o loteamento do seu imóvel
                </ion-text>
            </div>
        </div>
        
        <ion-grid class="ion-no-margin ion-no-padding">
            <ion-row class="ion-no-margin ion-no-padding  ion-justify-content-center">
                <ion-col sizeMd="12" sizeLg="6" class="ion-no-margin ion-no-padding">
                    <ion-list>
                        <ion-item *ngFor="let nucleo of  nucleos" (click)="navigateToCoreInstruction(nucleo)" class="" detail="true">
                            <ion-text>
                                <h4 class="font-text font-text--large ion-no-margin ion-margin-top">{{ nucleo.nome || '' }}</h4>

                                <p class="description-text ion-no-margin ion-margin-bottom">
                                    {{ nucleo.localizacao.rua || '' }}, {{ nucleo.localizacao.numero || '' }}, {{nucleo.localizacao.bairro || ''}}
                                </p>
                            </ion-text>
                        </ion-item>
                    </ion-list>
                </ion-col>
            </ion-row>
            <ion-row class="ion-text-center">
                <ion-col>
                    <ion-text class="font-text">
                        Não encontrou seu bairro?
                    </ion-text>
                    <a class="text-link" (click)="presentModalContact()">
                        Entre em contato
                    </a>
                </ion-col>
            </ion-row>
        </ion-grid>

        <usucampeao-footer></usucampeao-footer>
    </div>
</ion-content>
