import { IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { LoteStatus } from './lote-status.enum';

export class LoteCriarDto {
  @IsString()
  @IsNotEmpty()
  label: string;

  @IsString()
  @IsNotEmpty()
  integracaoId: number;

  @IsNumber()
  @IsNotEmpty()
  quadraId: number;

  @IsString()
  @IsOptional()
  logradouro?: string;

  @IsString()
  @IsOptional()
  numeroFachada?: string;

  @IsString()
  @IsOptional()
  logradouro2?: string;

  @IsString()
  @IsOptional()
  numeroFachada2?: string;

  @IsBoolean()
  @IsNotEmpty()
  esquina: boolean;

  @IsString()
  @IsOptional()
  complemento?: string;

  @IsString()
  @IsNotEmpty()
  arquivoNome: string;

  @IsString()
  @IsNotEmpty()
  arquivoUrlStorage: string;

  @ValidateNested()
  @IsNotEmpty()
  coords: number[][];

  @IsEnum(LoteStatus)
  @IsNotEmpty()
  status: LoteStatus;

  constructor(
    label: string,
    idIntegracao: number,
    quadraIntegracaoId: number,
    esquina: boolean,
    coords: number[][],
    status: LoteStatus,
    arquivoNome: string,
    arquivoUrlStorage: string,
    logradouro?: string,
    numeroFachada?: string,
    logradouro2?: string,
    numeroFachada2?: string,
    complemento?: string,
  ) {
    this.label = label;
    this.integracaoId = idIntegracao;
    this.quadraId = quadraIntegracaoId;
    this.esquina = esquina;
    this.coords = coords;
    this.status = status;
    this.arquivoNome = arquivoNome;
    this.arquivoUrlStorage = arquivoUrlStorage;
    this.logradouro = logradouro;
    this.numeroFachada = numeroFachada;
    this.complemento = complemento;
    this.logradouro2 = logradouro2;
    this.numeroFachada2 = numeroFachada2;
  }
}
