import { Type } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { Cobertura } from './cobertura/cobertura.model';
import { EsgotamentoSanitario } from './esgotamento-sanitario/esgotamento-sanitario.model';
import { Estrutura } from './estrutura/estrutura.model';
import { InstalacaoEletrica } from './instalacao-eletrica/instalacao-eletrica.model';
import { InstalacaoHidrossanitarias } from './instalacao-hidrossanitarias/instalacao-hidrossanitarias.model';
import { ParedesExternas } from './paredes-externas/paredes-externas.model';
import { Piso } from './piso/piso.model';

export class CaracterizacaoDomicilio {
  @Type(() => Estrutura)
  @ValidateNested()
  estrutura: Estrutura;

  @Type(() => ParedesExternas)
  @ValidateNested()
  paredesExternas: ParedesExternas;

  @Type(() => Cobertura)
  @ValidateNested()
  cobertura: Cobertura;

  @Type(() => Piso)
  @ValidateNested()
  piso: Piso;

  @Type(() => InstalacaoEletrica)
  @ValidateNested()
  instalacaoEletrica: InstalacaoEletrica;

  @Type(() => InstalacaoHidrossanitarias)
  @ValidateNested()
  instalacaoHidrossanitaria: InstalacaoHidrossanitarias;

  @Type(() => EsgotamentoSanitario)
  @ValidateNested()
  esgotamentoSanitario: EsgotamentoSanitario;
}
