export enum RelacaoFamiliar {
  CONJUGE = 'CONJUGE',
  FILHO = 'FILHO',
  ENTEADO = 'ENTEADO',
  NETO = 'NETO',
  PAI = 'PAI',
  MAE = 'MAE',
  AVO = 'AVO',
  SOBRINHO = 'SOBRINHO',
}
