import { IsBoolean, IsDateString, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator';
import { BaseEntityDto } from '../base-entity/base-entity-dto.model';
import { PaymentType } from '../enums';
import { PaymentStatus } from './payment-status.enum';


export class PaymentDto extends BaseEntityDto {
  @IsString()
  @IsOptional()
  id?: string;

  @IsString()
  @IsNotEmpty()
  attendanceId: string;

  @IsString()
  @IsNotEmpty()
  attendanceInfo: string;

  @IsEnum(PaymentStatus)
  @IsNotEmpty()
  status: PaymentStatus;

  @IsString()
  @IsNotEmpty()
  customerId: string;

  @IsString()
  @IsNotEmpty()
  customerName: string;

  @IsString()
  @IsNotEmpty()
  propertyId: string;

  @IsString()
  @IsNotEmpty()
  propertyAddress: string;

  @IsNumber()
  @IsNotEmpty()
  installment: number;

  @IsNumber()
  @IsNotEmpty()
  totalInstallment: number;

  @IsString()
  @IsNotEmpty()
  description: string;

  @IsEnum(PaymentType)
  @IsNotEmpty()
  paymentType: PaymentType;

  @IsNumber()
  @IsNotEmpty()
  amount: number;

  @IsDateString()
  @IsNotEmpty()
  dueDate: Date;

  @IsNumber()
  @IsOptional()
  amountPaid?: number;

  @IsDateString()
  @IsOptional()
  payDay?: Date;

  @IsString()
  @IsOptional()
  bankSlipBarCode?: string;

  @IsString()
  @IsOptional()
  bankSlipPdf?: string;

  @IsString()
  @IsOptional()
  bankSlipUrl?: string;

  @IsString()
  @IsOptional()
  wirecardOrderId?: string;

  @IsString()
  @IsOptional()
  wirecardPaymentId?: string;

  @IsString()
  @IsOptional()
  wirecardPaymentUrl?: string;

  @IsString()
  @IsOptional()
  bs2Id?: string;

  @IsNumber()
  @IsOptional()
  bs2Number?: number;

  @IsString()
  @IsOptional()
  cancelReason?: string;

  @IsString()
  @IsOptional()
  cancelUserId?: string;

  @IsString()
  @IsOptional()
  cancelUserName?: string;

  @IsDateString()
  @IsOptional()
  cancelRequestDate?: Date;

  @IsDateString()
  @IsOptional()
  cancelDate?: Date;

  @IsBoolean()
  @IsOptional()
  splitPayment?: boolean;

  @IsNumber()
  @IsNotEmpty()
  @ValidateIf(payment => payment.splitPayment)
  splitPercentage?: number;

  @IsString()
  @IsNotEmpty()
  @ValidateIf(payment => payment.splitPayment)
  splitPartnerId?: string;

  @IsBoolean()
  @IsOptional()
  paidManually?: boolean;

  @IsDateString()
  @IsOptional()
  paidManuallyDate?: Date;

  @IsNumber()
  @IsOptional()
  paidManuallyAmount?: number;

  @IsString()
  @IsOptional()
  paidManuallyReason?: string;

  @IsString()
  @IsOptional()
  paidManuallyOtherReason?: string;

  @IsString()
  @IsOptional()
  paidManuallyType?: string;

  @IsString()
  @IsOptional()
  transactionId?: string;
}
