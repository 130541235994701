export enum TipoNotificacao {
  ALTERACAO_NECESSARIA = 'ALTERACAO_NECESSARIA',
  CADASTRO_PRE_APROVADO = 'CADASTRO_PRE_APROVADO',
  AGENDAMENTO_DISPONIVEL = 'AGENDAMENTO_DISPONIVEL',
  CADASTRO_AGENDADO = 'CADASTRO_AGENDADO',
  CADASTRO_VALIDADO = 'CADASTRO_VALIDADO',
  VENCE_AMANHA = 'VENCE_AMANHA',
  VENCE_PROXIMO = 'VENCE_PROXIMO',
  BUSCAR_TITULO = 'BUSCAR_TITULO',
}
