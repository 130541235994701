import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationSimplifiedDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ProjetoQuery } from '../../projeto/state/projeto.query';
import { RegistrationQuery } from '../../registration/state/registration.query';

@Component({
  selector: 'usucampeao-attendance-card',
  templateUrl: 'attendance-card.component.html',
  styleUrls: ['attendance-card.component.scss'],
})
export class AttendanceCardComponent implements OnInit {
  @Input() registration: RegistrationSimplifiedDto;
  public projeto: any;

  public loadingImage = '../../../../assets/imgs/defaultImage.svg';
  public defaultImage = '../../../../assets/imgs/placeholder.png';
  constructor(
    public router: Router,
    private registrationQuery: RegistrationQuery,
    private projetoQuery: ProjetoQuery
  ) {

  }

  ngOnInit(): void {
    this.projetoQuery.selectEntity(this.registration.projetoFid).subscribe((data) => (this.projeto = data))
  }

  /**
   * Foto da fachada do imóvel
   */
  public get propertyFacadePhoto(): Observable<string> {
    return this.registrationQuery.getFiles(this.registration.id)
      .pipe(
        map(files => files ? files[0] : null),
        map(file => {
          if (file?.id) {
            return `/properties/${this.registration.propertyId}/photo/${file?.id}`
          }
          return this.defaultImage;
        },

          shareReplay({ bufferSize: 1, refCount: true }),
        )
      );
  }

  /**
   * Formata texto para bairro, cidade e estado, incluindo dados default, caso ausentes.
   * @param address dados de endereço
   * @returns Bairro, cidade, estado, formatados
   */
  public formatLocation(address?): string {
    return [
      address?.neighborhood || '',
      address?.city || 'São Paulo',
      address?.state || 'SP'
    ].filter(v => !!v).join(', ');
  }

  /**
   * Navega para detalhes do cadastro
   */
  public navigateToInformationForm(): void {
    this.router.navigate([`atendimento`, this.registration.id]);
  }

}
