
import { ProcessStatusComponent } from './process-status.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { PipesModule } from '../../pipes/pipes.module';
import { SkeletonItemModule } from '../skeleton-item/skeleton-item.module';


@NgModule({
  imports: [
    CommonModule,
    UiMobileModule,
    IonicModule,
    PipesModule,
    SkeletonItemModule,
  ],
  declarations: [ProcessStatusComponent],
  exports: [ProcessStatusComponent],
})
export class ProcessStatusModule { }
