import { Type } from 'class-transformer';
import { ArrayMinSize, IsArray, IsBoolean, IsDateString, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { BaseEntityDto } from '../base-entity/base-entity-dto.model';
import { AttendancePaymentStatus } from '../enums/attendance-payment-status.enum';
import { AttendanceStatus } from '../enums/attendance-status.enum';
import { ContractSentStatus } from '../enums/contract-sent-status.enum';
import { PayDay } from '../enums/pay-day.enum';
import { PaymentDueDay } from '../enums/payment-due-day.enum';
import { ReurbModality } from '../enums/reurb-modality.enum';
import { PropertyDto } from '../property/property-dto.model';
import { Ref } from '../ref/ref.model';
import { Owner } from './owner.model';
import { PhaseDto } from './phase-dto.model';

export class AttendanceDto extends BaseEntityDto {
  @IsString()
  @IsOptional()
  id?: string;

  @IsString()
  @IsOptional()
  status?: AttendanceStatus;

  @IsNumber()
  @IsOptional()
  statusId?: number;

  @IsEnum(ReurbModality)
  @IsNotEmpty()
  modality: ReurbModality;

  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  attendant: Ref;

  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  city: Ref;

  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  district: Ref;

  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  owner: Owner;

  @ValidateNested({ each: true })
  @ArrayMinSize(1)
  @Type(() => Owner)
  residents: Owner[];

  @Type(() => PropertyDto)
  @IsOptional()
  property?: PropertyDto;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  voucher?: Ref;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  paymentType?: Ref;

  @IsEnum(ContractSentStatus)
  @IsNotEmpty()
  contractSentStatus: ContractSentStatus;

  @IsDateString()
  @IsOptional()
  contractDate?: Date;

  @IsNumber()
  @IsOptional()
  amount?: number;

  @IsNumber()
  @IsOptional()
  baseAmount?: number;

  @IsNumber()
  @IsNotEmpty()
  blockId: number;

  @IsString()
  @IsOptional()
  block?: string; //TODO: Atendimentos antigos estão somente com a label da quadra. Quando os dados forem migrados, esse campo deve ser removido.

  @IsString()
  @IsOptional()
  projectCode?: string;

  @IsNumber()
  @IsNotEmpty()
  unitId: number;

  @IsNumber()
  @IsOptional()
  unit?: number; //TODO: Atendimentos antigos estão somente com o número do lote. Quando os dados forem migrados, esse campo deve ser removido.

  @IsBoolean()
  @IsOptional()
  sealing?: boolean;

  @IsNumber()
  @IsNotEmpty()
  totalResidentsIncome: number;

  @IsString()
  @IsOptional()
  note?: string;

  @IsBoolean()
  generatedPayment: boolean;

  @IsEnum(PaymentDueDay)
  @IsOptional()
  paymentDueDay?: PaymentDueDay;

  @IsEnum(PayDay)
  @IsOptional()
  payDay?: PayDay;

  @IsDateString()
  @IsOptional()
  lastPaymentDate?: Date;

  @IsEnum(AttendancePaymentStatus)
  @IsOptional()
  paymentStatus?: AttendancePaymentStatus;

  @IsNumber()
  @IsOptional()
  phaseId?: number;

  @ValidateNested({ each: true })
  @IsArray()
  @IsOptional()
  phases?: PhaseDto[];

  @IsDateString()
  @IsOptional()
  enrollmentDate?: Date;

  @IsString()
  @IsOptional()
  enrollmentAddress?: string;

  @IsString()
  @IsOptional()
  cityCode?: string;

  @IsString()
  @IsOptional()
  categoryCode?: string;

  @IsString()
  @IsOptional()
  bankAccount?: string;

  @IsString()
  @IsOptional()
  serviceCode?: string;
}
