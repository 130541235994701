import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../../../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../../../components/header/header.module';
import { PhotoModule } from '../../../../../../components/photo/photo.module';
import { ProcessProgressModule } from '../../../../../../components/process-progress/process-progress.module';
import { PipesModule } from '../../../../../../pipes/pipes.module';
import { PhotoRoutingModule } from './photo-routing.module';
import { PhotoPage } from './photo.page';

@NgModule({
  declarations: [
    PhotoPage
  ],
  imports: [
    CommonModule,
    FooterModule,
    HeaderModule,
    IonicModule,
    ProcessProgressModule,
    PhotoRoutingModule,
    PhotoModule,
    PipesModule,
  ]
})
export class PhotoPageModule { }
