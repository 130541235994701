import { Type } from 'class-transformer';
import { IsBoolean, IsDate, IsNotEmpty } from 'class-validator';

export class PagamentoAlterarVencimentoDto {
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  dataVencimento: Date;

  @IsBoolean()
  @IsNotEmpty()
  multa: boolean;
}
