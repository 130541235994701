export enum PropertyAcquisitionDocument {
  ASSIGNMENT_OF_RIGHTS_AGREEMENT = 'CONTRATO_DE_CESSÃO_DE_DIREITOS',
  DEED = 'ESCRITURA',
  DONATION_CONTRACT = 'CONTRATO_DE_DOAÇÃO',
  DONATION_DEED = 'ESCRITURA_DE_DOAÇÃO',
  FORMAL_SHARING = 'FORMAL_DE_PARTILHA',
  EXCHANGE_CONTRACT = 'CONTRATO_DE_PERMUTA',
  LAW_SUIT = 'AÇÃO_JUDICIAL_DE_USUCAPIÃO',
  LEASE_AGREEMENT = 'CONTRATO_DE_COMODATO',
  PUBLIC_CONCESSION = 'CONCESSÃO_DO_PODER_PÚBLICO',
  PURCHASE_AND_SALE_AGREEMENT = 'CONTRATO_DE_COMPRA_E_VENDA',
  PURCHASE_RECEIPT = 'RECIBO_DE_COMPRA',
  OTHERS = 'OUTROS',
}
