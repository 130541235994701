import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import bugsnag from '@bugsnag/js';
import { resetStores } from '@datorama/akita';
import { CreateUserDto, UpdateUserDto, UserDto } from '@usucampeao/lib-reurb-simplificado';
import 'firebase/auth';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { FcmService } from '../../../services/fcm.service';
import { AuthState, AuthStore } from './auth.store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    public auth: AngularFireAuth,
    private authStore: AuthStore,
    private fcmService: FcmService,
  ) { }

  async login(creds: AuthState) {
    return await this.auth.signInWithPhoneNumber(creds.phoneNumber, creds.code);
  }

  updateUserState(data) {
    this.authStore.update(data);
  }

  async getUserData() {
    const user = await this.http.get<UserDto>(`/users/me`).toPromise();
    if (user) {
      this.authStore.update(user as any);
      return true;
    }
    return false;
  }


  getUserPhoto(id) {
    return this.http.get(`/users/my-photo/${id}`, { responseType: 'blob' });
  }

  async createAccount(data: CreateUserDto) {
    const user = await this.http
      .post<CreateUserDto>(`/users/`, data)
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'Cria conta'
            event.setUser('newUser', data.phone, data.name)
            event.addMetadata('payload', data);
            event.addMetadata('metadata', {
              url: `/users/`,
              title: 'Erro ao criar conta',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
      )
      .toPromise();
    if (user) {
      this.authStore.update(user as any);
      return true;
    }
    return false;
  }

  async updateAccount(data: UpdateUserDto) {
    const user = await this.http
      .put<UserDto>(`/users/me`, data)
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'Envia dados'
            event.setUser(data.email, data.name)
            event.addMetadata('payload', data);
            event.addMetadata('metadata', {
              url: `/users/me`,
              title: 'Erro ao enviar dados',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
      )
      .toPromise();
    if (user) {
      this.authStore.update(user as any);
      return true;
    }
    return false;
  }


  public updateAccountPhoto(file: File): Observable<string> {
    this.authStore.setLoading(true);
    const formData = new FormData();
    formData.append('photo', file);
    return this.http.put(`/users/my-photo`, formData, { responseType: 'text' })
      .pipe(
        tap(response => this.authStore.update({ photoId: response })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'Envia foto'
            event.addMetadata('payload', formData);
            event.addMetadata('query params', { responseType: 'text' });
            event.addMetadata('metadata', {
              url: `/users/my-photo`,
              title: 'Erro ao enviar foto',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.authStore.setLoading(false))
      );
  }

  public setUserConnectionStatus(status: boolean) {
    this.authStore.update({ connected: status })
  }

  public setImage(base64: string) {
    this.authStore.update({ photo: base64 })
  }

  logout() {
    this.auth.signOut().then(async () => {
      resetStores();
      this.router.navigateByUrl('login');
      await this.fcmService.logoutFCM();
    });
  }
}
