import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'usucampeao-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  linkPhoto ='https://www.prefeitura.sp.gov.br/cidade/secretarias/upload/chamadas/Habita%C3%A7%C3%A3o_principal%20-%20horizontal_1614860517.png';

  constructor() {

  }

  ngOnInit(): void {}
}
