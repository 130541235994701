import { Expose, Transform } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsOptional, IsString, Length } from 'class-validator';
import { UF } from '../../enums';

export class Bs2Endereco {
  @Expose()
  @Transform(({ value }) => value.replace(/[^\d]+/g, ''))
  @IsString()
  @Length(8, 9)
  @IsNotEmpty()
  cep: string;

  @Expose()
  @IsEnum(UF)
  @IsNotEmpty()
  estado: UF;

  @Expose()
  @IsString()
  @IsNotEmpty()
  cidade: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  bairro: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  logradouro: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  numero: string;


  @Expose()
  @IsString()
  @IsOptional()
  complemento?: string;

  constructor(
    cep: string,
    logradouro: string,
    numero: string,
    bairro: string,
    cidade: string,
    estado: UF,
    complemento?: string,
  ) {
    this.cep = cep
    this.estado = estado
    this.cidade = cidade
    this.bairro = bairro
    this.logradouro = logradouro
    this.numero = numero
    this.complemento = complemento;
  }
}
