import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DocumentsPropertyState, DocumentsPropertyStore } from './store';

@Injectable({ providedIn: 'root' })
export class DocumentsPropertyQuery extends Query<DocumentsPropertyState> {
 
  constructor(protected store: DocumentsPropertyStore) {
    super(store);
  }

}
