export enum TipoContract {
  DECLARACAO_DE_AUTONOMO_DE_RENDA = 'DECLARACAO DE AUTONOMO DE RENDA',
  DECLARACAO_DE_DOMICILIO = 'DECLARACAOA DE DOIMICILIO',
  DECLARACAO_DE_VIZINHOS = 'DECLARACAO DE VIZINHOS',
  MORADOR_AVERBACAO = 'MORADOR AVERBACAO',
  MORADOR_USUCAMPEAO_2021 = 'MORADOR USUCAMPEAO 2021',
  MORADOR_USUCAMPEAO_REURB_AVERBACAO = 'MORADOR USUCAMPEAO REURB AVERBACAO',
  PROCURACAO_AVERBACAO = 'PROCURACAO AVERBACAO',
  PROCURACAO_REURB_AVERBACAO = 'PROCURACAO REURB AVERBACAO',
  QUESTIONARIO_JURIDICO = 'QUESTIONARIO JURIDICO'
}
