import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../../../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../../../components/header/header.module';
import { MenuItemModule } from '../../../../../../components/menu-item/menu-item.module';
import { ProcessProgressModule } from '../../../../../../components/process-progress/process-progress.module';
import { PipesModule } from '../../../../../../pipes/pipes.module';
import { DocumentTypeRoutingModule } from './document-type-routing.module';
import { DocumentTypePage } from './document-type.page';

@NgModule({
  declarations: [
    DocumentTypePage
  ],
  imports: [
    CommonModule,
    HeaderModule,
    IonicModule,
    ProcessProgressModule,
    DocumentTypeRoutingModule,
    FooterModule,
    MenuItemModule,
    PipesModule
  ]
})
export class DocumentTypeModule { }
