import { IsDateString, IsNotEmpty, IsNumberString, IsOptional, IsString } from 'class-validator';

export class BankSlipDto {
  @IsString()
  @IsOptional()
  id?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsOptional()
  barcode?: string;

  @IsNumberString()
  @IsOptional()
  referenceNumber?: string;

  @IsNumberString()
  @IsOptional()
  documentNumber?: string;

  @IsDateString()
  @IsNotEmpty()
  expirationDate: Date;

  @IsNumberString()
  @IsNotEmpty()
  bankCode: string;

  @IsString()
  @IsNotEmpty()
  customer: string;

  @IsNumberString()
  @IsOptional()
  sequence?: string;

  @IsString()
  @IsOptional()
  url?: string;

  @IsDateString()
  @IsOptional()
  paidAt?: Date;

  @IsDateString()
  @IsOptional()
  createdAt?: Date;

  @IsDateString()
  @IsOptional()
  updatedAt?: Date;
}
