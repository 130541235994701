import { Expose, plainToInstance, Type } from 'class-transformer';
import { AddressDto } from '../address/address.dto';
import { FileDto } from '../file';
import { PropertyFillInStatusDto } from './property-fill-in-status.dto';
import { PropertyDto } from './property.dto';

export class PropertySimplifiedDto {
  @Expose()
  photoId: string;

  @Expose()
  projetoFid: string;

  @Expose()
  blockId: number;

  @Expose()
  quadra: string;

  @Expose()
  lotId: number;

  @Expose()
  lote: string;

  @Expose()
  @Type(() => AddressDto)
  address: AddressDto;

  @Expose()
  files: FileDto[];

  @Expose()
  @Type(() => PropertyFillInStatusDto)
  fillInStatus: PropertyFillInStatusDto;

  /**
   * Cria uma nova instância de PropertySimplifiedDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de PropertySimplifiedDto
   */
  static from(props?: PropertyDto | Partial<PropertyDto>): PropertySimplifiedDto {
    return plainToInstance(PropertySimplifiedDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
