import { Nationality, UF } from '@usucampeao/interfaces';
import { Exclude, Transform, Type } from 'class-transformer';
import {
  IsDate,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Min,
  ValidateIf,
  ValidateNested
} from 'class-validator';
import { Gender } from '../../enums/gender.enum';
import { IsCpf } from '../../validators/valid-cpf/valid-cpf';
import { MaritalStatus } from './marital-status.enum';
import { MatrimonialRegime } from './matrimonial-regime.enum';
import { PhoneDto } from './phone.dto';

export class CreateOwnerDto {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsCpf()
  @IsNotEmpty()
  cpf: string;

  @IsEmail()
  @IsOptional()
  email?: string;

  @Type(() => PhoneDto)
  @ValidateNested()
  @IsOptional()
  phones?: PhoneDto;

  @IsEnum(Gender)
  @IsOptional()
  gender?: Gender;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  birthDate?: Date;

  @IsEnum(Nationality)
  @IsOptional()
  nationality?: Nationality;

  @IsEnum(UF)
  @Transform(({ value, obj }) => {
    if (obj.nationality !== Nationality.BRASIL && obj.state) {
      return null;
    }
    return value;
  })
  @IsOptional()
  @ValidateIf((object: CreateOwnerDto) => object.nationality === Nationality.BRASIL)
  state?: UF;

  @IsEnum(MaritalStatus)
  @IsOptional()
  maritalStatus?: MaritalStatus;

  @IsEnum(MatrimonialRegime)
  @Transform(({ value, obj }) => {
    if (obj.maritalStatus !== MaritalStatus.MARRIED && obj.matrimonialRegime) {
      return null;
    }
    return value;
  })
  @IsOptional()
  @ValidateIf((object: CreateOwnerDto) => object.maritalStatus === MaritalStatus.MARRIED)
  matrimonialRegime?: MatrimonialRegime;

  @Type(() => Date)
  @Transform(({ value, obj }) => {
    if (obj.maritalStatus !== MaritalStatus.MARRIED && obj.weddingDate) {
      return null;
    }
    return value;
  })
  @IsDate()
  @IsOptional()
  @ValidateIf((object: CreateOwnerDto) => object && object.maritalStatus === MaritalStatus.MARRIED)
  weddingDate?: Date;

  @IsString()
  @IsOptional()
  profession?: string;

  @IsNumber()
  @Min(0)
  @IsOptional()
  familyIncome?: number;

  @IsString()
  @IsOptional()
  spouseId?: string;

  @Exclude()
  main?: boolean;
}
