import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })

export class WindowsService {

    windowsRef = new BehaviorSubject(null);
    windowsCode = new BehaviorSubject(null);
    public get win() {
        return window;
      }

    set(data){
        this.windowsRef.next(data);
    }

    get(){
        return this.windowsRef.getValue();
    }


    setCode(data){
        this.windowsCode.next(data);
    }

    getCode(){
        return this.windowsCode.getValue();
    }
    

      
}
