  <div class="content-vertical-space-between">
    <ion-grid class="ion-no-padding ion-padding-start ion-padding-end">
      <ion-row>
        <ion-col>
          <ion-item class="ion-no-padding" lines="none">
            <usucampeao-notification-badge
              [tipo]="notification?.tipo"
            ></usucampeao-notification-badge>
            <ion-text class="ion-padding-start">
              <p class="title title--medium ion-no-margin">
                {{ notification?.titulo }}
              </p>
              <p class="ion-no-margin font-text font-text--small">
                {{ notification?.dataEnvio || '' | date }}
              </p>
            </ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p>
            Participe da cerimônia de entrega dos títulos dos imóveis do bairro 
            <!-- <strong> Jardim Elizabeth </strong> -->
            com a prefeitura.
          </p>
          <!-- <p>
            A entrega está marcada para <strong>03 de setembro de 2021,</strong> às <strong>10:30,</strong> no
            seguinte endereço:
          </p>
          <p>
            Associação de Moradores de Jardim Elizabeth Av. das Flores, 100,
            casa dos fundos Itaquera, São Paulo, SP
          </p> -->
          <p>
            Traga um documento pessoal com foto, e participe da cerimônia com os
            demais moradores do bairro. Você receberá seu título em mãos.
          </p>
          <p>Te aguardamos lá!</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

