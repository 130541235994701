import { StatusFile } from './status-file.enum';

export class FileDto {
  id?: string;
  name: string;
  storageFile: string;
  storageBucket: string;
  mimeType?: string;
  size: number;
  approvedDisapprovedDate?: Date;
  approvedDisapprovedUserId?: string;
  requestedDeleteDate?: Date;
  deletedDate?: Date;
  userDeleteId?: string;
  status: StatusFile;
  parentId: string;
  createdAt?: Date;
  userCreationId?: string;
  updatedAt?: Date;
  userUpdateId?: string;
  page: number;
}
