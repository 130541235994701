<ion-item color="warning"  lines="none" *ngIf="(this.authQuery.isConnectionOnline$ |async)===false" >

    <ion-label>
        <ion-icon   name="cloud-offline-outline" slot="start"></ion-icon>
      Sem internet
    </ion-label>
  </ion-item>
<ion-header class="ion-no-border">
    <ion-toolbar class="ion-no-padding ion-padding-top ion-padding-bottom">
      <div class="div">
        <span title="voltar" (click)="backButton()">
          <ion-icon slot="start" *ngIf="backRoute"
          class="icon ion-padding-horizontal" text="voltar" color="medium" name="arrow-back-outline"
          ></ion-icon>
        </span>
        <ion-title class="ion-no-border ion-no-padding">
          {{ title }}
        </ion-title>
      </div>
        <ion-avatar slot="end" [ngClass]="{'padding':backRoute}" (click)="openMenu()">
            <img [defaultImage]="defaultImage" [lazyLoad]="userPhoto | async" />
        </ion-avatar>
    </ion-toolbar>
</ion-header>

