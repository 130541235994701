<ion-content>
    <usucampeao-header mode="md" backRoute="/" title="Documentos do Imóvel"></usucampeao-header>
    <ion-grid class="ion-padding-start ion-padding-end ion-no-padding">
       <ion-row class="ion-justify-content-center ion-padding-bottom">
          <ion-col sizeXs="12" sizeSm="6">
             <ion-text class="font-text">
                Lista de fotos dos documentos do imóvel
             </ion-text>
          </ion-col>
       </ion-row>
       <ion-row class="ion-justify-content-center" *ngIf="alteracoes && alteracoes.length > 0">
          <ion-col sizeXs="12" sizeSm="6" class="ion-padding-top ion-padding-bottom">
             <usucampeao-change-alert [changes]='alteracoes'></usucampeao-change-alert>
          </ion-col>
       </ion-row>
       <ion-row class="ion-justify-content-center ion-padding-top">
          <ion-col sizeXs="12" sizeSm="6">
             <ng-container *ngIf="isLoading$ | async">
                <ion-list>
                   <usucampeao-skeleton-item></usucampeao-skeleton-item>
                   <usucampeao-skeleton-item></usucampeao-skeleton-item>
                </ion-list>
             </ng-container>
             <ng-container *ngIf="(isLoading$ | async) === false">
                <usucampeao-process-progress *ngIf="!registration.acquisitionDocuments.length && projeto?.tipo !== projetoTipo.AVERBACAO" icon="document-attach-outline" title="Documento de aquisição" titleClass="small" statusIcon='ellipse-outline' statusIconClass='icon-incomplete' statusText="Enviar" statusTextClass="secondary-red-text"
                (click)="navigateToDocs(documentFileSubtype.ACQUISITION)">
                </usucampeao-process-progress>
                <usucampeao-process-progress *ngIf="!registration.ownershipDocuments.length" icon="document-attach-outline" title="Comprovante de posse" titleClass="small" statusIcon='ellipse-outline' statusIconClass='icon-incomplete' statusText="Enviar" statusTextClass="secondary-red-text"
                (click)="navigateToDocs(documentFileSubtype.OWNERSHIP)">
                </usucampeao-process-progress>
                <ng-container *ngFor="let item of registration.acquisitionDocuments">
                   <usucampeao-process-progress *ngIf="item.status !=='JUSTIFICADO'"  icon="document-attach-outline" [status]="item.status" [title]="(item.name || item.type) | PropertyAcquisitionDocumentPipe" titleClass="small" subtitle="Documento de aquisição"
                   statusText="Alterar" statusTextClass="secondary-text" (click)="gotoRoute(item)">
                   </usucampeao-process-progress>
                   <usucampeao-process-progress *ngIf="item.status ==='JUSTIFICADO'" icon="document-attach-outline" [title]="(item?.type | PropertyAcquisitionDocumentPipe) || 'Documento de aquisição'"  titleClass="small" [status]="item.status" statusIcon='document-attach-outline' statusText="Justificado" statusTextClass="secondary-text"
                   (click)="irJustificativa(item)">
                   </usucampeao-process-progress>
                </ng-container>
                <ng-container *ngFor="let item of registration.ownershipDocuments">
                   <usucampeao-process-progress *ngIf="item.status !== 'JUSTIFICADO'" icon="document-attach-outline" [status]="item.status" [title]="(item.name || item.type) | PropertyOwnershipDocumentPipe" titleClass="small" subtitle="Comprovante de posse" statusText="Alterar"
                   statusTextClass="secondary-text" (click)="gotoRoute(item)">
                   </usucampeao-process-progress>
                   <usucampeao-process-progress *ngIf="item.status ==='JUSTIFICADO'" icon="document-attach-outline" [title]="(item?.type | PropertyOwnershipDocumentPipe) || 'Comprovante de posse'" titleClass="small" [status]="item.status" statusIcon='document-attach-outline' statusText="Justificado" statusTextClass="secondary-text"
                   (click)="irJustificativa(item)">
                   </usucampeao-process-progress>
                </ng-container>
             </ng-container>
          </ion-col>
       </ion-row>
       <ion-row class="ion-justify-content-center ion-padding-top">
          <div class="font-text font-text--small ion-text-center">
             <ion-text>Precisa de ajuda?</ion-text>
             &nbsp;
             <ion-text color="primary" (click)="presentModalInstruction()"><strong>Veja as instruções</strong>.
             </ion-text>
          </div>
       </ion-row>
       <ion-row class="ion-justify-content-center ion-padding-top">
          <ion-col sizeMd="3">
             <div class="ion-padding-top">
                <div *ngIf="((this.authQuery.isConnectionOnline$ | async))">
                   <ion-button *ngIf="!blockAccess" mode="md" expand="block" (click)="navigateToDocs()">
                   Adicionar documento</ion-button>
                </div>
                <div class="padding-top">
                   <div *ngIf="((this.authQuery.isConnectionOnline$ | async))">
                      <ion-button *ngIf="!blockAccess" fill="outline" mode="md" (click)="navigateForward()" expand="block">Continuar</ion-button>
                   </div>
                </div>
                <ion-label *ngIf="(this.authQuery.isConnectionOnline$ | async)===false">
                   <ion-icon name="cloud-offline-outline" slot="start"></ion-icon>
                   Sem internet
                </ion-label>
             </div>
          </ion-col>
       </ion-row>
    </ion-grid>
    <usucampeao-footer></usucampeao-footer>
 </ion-content>
