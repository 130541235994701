export enum TipoAlteracao {
  PROPRIETARIO_DADOS_BASICOS = 'PROPRIETARIO_DADOS_BASICOS',
  PROPRIETARIO_DADOS_DOCUMENTOS = 'PROPRIETARIO_DADOS_DOCUMENTOS',
  PROPRIETARIO_DADOS_CONTATO = 'PROPRIETARIO_DADOS_CONTATO',
  PROPRIETARIO_DOCUMENTOS = 'PROPRIETARIO_DOCUMENTOS',
  IMOVEL_DADOS_LOCALIZACAO = 'IMOVEL_DADOS_LOCALIZACAO',
  IMOVEL_DADOS_ENDERECO = 'IMOVEL_DADOS_ENDERECO',
  IMOVEL_DADOS_ADICIONAIS = 'IMOVEL_DADOS_ADICIONAIS',
  IMOVEL_DOCUMENTOS = 'IMOVEL_DOCUMENTOS',
}
