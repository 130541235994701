import { PipesModule } from '../../../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {UiMobileModule}from '@usucampeao/ui-mobile';
import { StatusAttendanceBadgeComponent } from './status-attendance-badge.component';


@NgModule({
  imports: [CommonModule,UiMobileModule, IonicModule, PipesModule],
  declarations: [StatusAttendanceBadgeComponent],
  exports: [StatusAttendanceBadgeComponent],
})
export class StatusAttendanceBadgeComponentModule {}
