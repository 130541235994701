import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LotDetailRoutingModule } from './lot-detail-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LotDetailRoutingModule
  ]
})
export class LotDetailModule { }
