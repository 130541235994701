export enum TabelaPrecoParcela {
  PARCELA_0 = 'PARCELA_0',
  PARCELA_1 = 'PARCELA_1',
  PARCELA_2 = 'PARCELA_2',
  PARCELA_3 = 'PARCELA_3',
  PARCELA_4 = 'PARCELA_4',
  PARCELA_5 = 'PARCELA_5',
  PARCELA_6 = 'PARCELA_6',
  PARCELA_7 = 'PARCELA_7',
  PARCELA_8 = 'PARCELA_8',
  PARCELA_9 = 'PARCELA_9',
  PARCELA_10 = 'PARCELA_10',
  PARCELA_11 = 'PARCELA_11',
  PARCELA_12 = 'PARCELA_12',
}
