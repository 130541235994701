import { ModalController } from '@ionic/angular';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'usucampeao-information-alert',
  templateUrl: 'information-alert.modal.html',
  styleUrls: ['information-alert.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InformationAlertModal {
  @Input() title!: string;
  @Input() text!: string;
  @Input() subtext!: string;
  @Input() clearButton!: string;
  @Input() fillButton!: string;
  @Input() clearButtonRota!: string;
  @Input() fillButtonRota!: string;

  constructor(public ModalController: ModalController, public router: Router) {}

  dismiss() {
    this.ModalController.dismiss();
  }

  navigateTo(rota: string): void {
    if (rota === 'dismiss') {
      this.dismiss();
    } else {
      this.dismiss();
      this.router.navigate([rota]);
    }
  }
}
