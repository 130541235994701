import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { RG } from '../../rg/rg.model';

export class DocumentosCadastroSocial {
  @IsNotEmpty()
  @IsString()
  cpf: string;

  @IsOptional()
  @Type(() => RG)
  @ValidateNested()
  rg: RG;

  @IsOptional()
  @IsString()
  identificacaoSocial: string;
}
