import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./select-allotment/select-allotment.module').then((m) => m.SelectAllotmentModule),
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./allotment-detail/allotment-detail.module').then((m) => m.AllotmentDetailModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterRoutingModule { }
