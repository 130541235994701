import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ConfirmaReenvioCadastroModal } from './confirma-reenvio-cadastro.modal';



@NgModule({
  declarations: [ConfirmaReenvioCadastroModal],
  imports: [
    CommonModule,
    IonicModule,
    UiMobileModule,
  ],
  exports: [ConfirmaReenvioCadastroModal],
})
export class ConfirmaReenvioCadastroModule { }
