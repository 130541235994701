export enum ReasonNotification {
  /**
 * Document rejection for Morador notification.
 */
  REJEICAO_DOC_MORADOR = 'REJEICAO_DOC_MORADOR',

  /**
   * Document rejection for Atendente notification.
   */
  REJEICAO_DOC_ATENDENTE = 'REJEICAO_DOC_ATENDENTE',

  /**
   * Payment due in 7 days notification.
   */
  PAYMENT_7_DAYS = 'PAYMENT_7_DAYS',

  /**
   * Payment due in 2 days notification.
   */
  PAYMENT_2_DAYS = 'PAYMENT_2_DAYS',

  /**
   * Payment due notification.
   */
  PAYMENT_DUE = 'PAYMENT_DUE',

  /**
   * Contract signed.
   */
  CONTRATO_ASSINADO = 'CONTRATO_ASSINADO',
  /**
  * Contract accepted.
  */
  CONTRATO_ACEITO = 'CONTRATO_ACEITO',
  /**
   * Upload do contrato enviado
   */
  CONTRATO_ENVIADO = 'CONTRATO_ENVIADO',

  /**
   * Troca de atendente.
   */
  TROCA_ATENDENTE = 'TROCA_ATENDENTE',

  /**
   * Solicitação de troca de atendente aprovada.
   */
  TROCA_ATENDENTE_APROVADO = 'TROCA_ATENDENTE_APROVADO',

  /**
   * Solicitação de troca de atendente negada.
   */
  TROCA_ATENDENTE_REJEITADO = 'TROCA_ATENDENTE_REJEITADO',

  /**
   * Erro ao emitir pagamento
   */
  ERRO_EMISSAO_PAGAMENTO = 'ERRO_EMISSAO_PAGAMENTO',

  /**
   * Other notification reasons.
   */
  OUTROS = 'OUTROS',
}
