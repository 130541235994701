import { Endereco } from '@usucampeao/interfaces';
import { LeadStatus } from './lead-status.enum';

export class LeadDto {
  id: string;
  nome: string;
  email: string;
  cpf: string;
  telefoneCelular: string;
  telefoneResidencial: string;
  celularWhatsapp: boolean;
  endereco: Endereco;
  usuarioCriacaoId: string;
  dataCriacao: Date;
  status: LeadStatus;
  cadastroId?: string;
}
