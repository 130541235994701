import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isPlatform, ModalController } from '@ionic/angular';
import { MaskPipe } from 'ngx-mask';
import { DatePickerModalComponent } from '../date-picker-modal/date-picker-modal.component';
import { ElementBase } from './models/elements';
import { Inputmask, InputMaskCurrencyFormatter, InputMaskTypes } from './models/form.elements';
import { InputMode } from './models/input-mode.enum';


@Component({
  selector: 'usucampeao-build-form',
  templateUrl: './build-form.component.html',
  styleUrls: ['./build-form.component.scss'],
})
export class BuildFormComponent {
  @Input() field!: ElementBase<string>;
  @Input() form!: FormGroup;

  private datePickerDisabled = false;

  get isValid() {
    return true;
  }

  // teclas especiais de navegação e controle
  private specialKeys = [
    'Backspace', 'Delete',
    'Home', 'End',
    'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
    'Enter',
    'Tab',
  ];

  // teclas que podem ser utilizadas em combinação com CTRL e CMD
  private controlKeys = ['KeyA', 'KeyC', 'KeyV', 'KeyX'];

  constructor(private maskPipe: MaskPipe, private modalCtrl: ModalController) { }

  /**
   * Valida se um valor digitado pode ser aceito e incluído no input, respeitando as regras da máscara.
   * Este método não consegue executar toda validação no caso de Android. Para estes casos, ele trabalha em conjunto com o método acceptWithMaskAndroid.
   * @param event (KeyboardEvent) evento de teclado (físico ou virtual) a ser validado
   * @param mask máscara a ser utilizada
   * @returns true se o valor digitado pode ser adicionado, false caso contrário
   */
  acceptWithMask(event: KeyboardEvent, mask: string): boolean {
    const key = event.code || event.key;
    return this.specialKeys.includes(key) // special keys (navigation, delete, backspace, etc)
      || (this.controlKeys.includes(key) && (event.ctrlKey || event.metaKey)) // ctrl or cmd key combos
      || (InputMaskTypes(mask).test(event.code || event.key)
        && (event.target as HTMLInputElement).value.length < (Inputmask(mask).length || 9999)); // mask specific keys (9999 max length)
  }

  /**
   * Valida se um valor digitado pode ser aceito e incluído no input, respeitando as regras da máscara.
   * Este método deve ser utilizado apenas para o caso de acesso via Android. Caso não seja Android, não realiza nenhuma validação.
   * @param event (InputEvent) evento de teclado (físico ou virtual) a ser validado
   * @param mask máscara a ser utilizada
   * @returns true se o valor digitado pode ser adicionado, false caso contrário
   */
  public acceptWithMaskAndroid(event: Event, mask: string) {
    if (!isPlatform("android")) return true;
    const data = (event as InputEvent).data;
    if (!data) return false;
    return (InputMaskTypes(mask).test(data)
      && (event.target as HTMLInputElement).value.length < (Inputmask(mask).length || 9999)); // mask specific keys (9999 max length)
  }

  /**
   * Trata o input de teclado, aplicando a máscara configurada no campo.
   * @param event evento de input de teclado (físico ou virtual) a ser tratado
   * @param key nome do controle do campo de formulário
   * @param mask máscara a ser aplicada (caso aplicável)
   */
  public updateWithMask(event: any, key: string, mask: string): void {
    if (mask) {
      let formattedValue;
      if (mask !== InputMode.CURRENCY) {
        formattedValue = this.maskPipe.transform(event.currentTarget.value, Inputmask(mask));
      } else {
        formattedValue = InputMaskCurrencyFormatter(event.currentTarget.value);
      }
      this.form.controls[key].setValue(formattedValue);
    }
  }

  /**
   * Controle para mostrar ou não a mensagem de erro
   */
  public get showMessageError(): boolean {
    const control = this.form.controls[this.field.key];
    return control.invalid && control.touched;
  }

  /**
   * Retorna mensagem de erro do campo
   */
  public get messageError(): string {
    const control = this.form.controls[this.field.key];
    if (
      control.hasError('minlength') ||
      control.hasError('maxlength') ||
      control.hasError('invalido') ||
      control.hasError('pattern')
    ) {
      return 'Campo inválido.';
    }

    if (control.hasError('email')) {
      return 'E-mail inválido.';
    }

    return 'Campo obrigatório.';
  }

  public async openDatepickerModal(): Promise<void> {
    if (this.field.readonly || this.datePickerDisabled) {
      return;
    };

    this.datePickerDisabled = true;
    const modal = await this.modalCtrl.create(
      {
        component: DatePickerModalComponent,
        cssClass: 'datepicker-modal',
        backdropDismiss: true,
        componentProps: {
          value: this.selectedDate ? new Date(this.selectedDate) : null,
          minDate: this.field.minDate,
          maxDate: this.field.maxDate
        }
      }
    );
    await modal.present();

    const { data } = await modal.onWillDismiss();
    this.datePickerDisabled = false;
    if (data) {
      this.form.get(this.field.key)?.setValue(new Date(data));
    }
  }

  public get selectedDate() {
    return this.form.get(this.field.key)?.value;
  }

}
