import { Expose, plainToInstance } from 'class-transformer';
import { OwnerFillInStatusDto } from '../owner';
import { OwnerDto } from '../owner/owner.dto';

export class ProprietariosCadastroDto extends OwnerDto {
  @Expose()
  main?: boolean;

  @Expose()
  fillInStatus: OwnerFillInStatusDto;

  @Expose()
  proprietarioId: string;

  /**
   * Cria uma nova instância de ProprietariosCadastroDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de ProprietariosCadastroDto
   */
  static from(props?: OwnerDto | Partial<OwnerDto>): ProprietariosCadastroDto {
    return plainToInstance(ProprietariosCadastroDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
