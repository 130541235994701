import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { NotificacaoDto, TipoNotificacao } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { catchError, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastService } from '../../../services/toast.service';
import { NavigationService } from './../../../services/navigation.service';
import { NotificationsQuery } from './../state/notifications.query';
import { NotificationsService } from './../state/notifications.service';

@Component({
  selector: 'usucampeao-to-see',
  templateUrl: 'to-see.page.html',
  styleUrls: ['to-see.page.scss'],
})
export class ToSeePage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  private ngUnsubscribe = new Subject();
  public notificacao: NotificacaoDto;
  private id: string

  constructor(
    private navigationService: NavigationService,
    private notificationService: NotificationsService,
    private notificationsQuery: NotificationsQuery,
    public route: ActivatedRoute,
    public router: Router,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter() {
    this.route.params
      .pipe(
        first(),
        switchMap((params) => this.getNotification(params.id)),
        switchMap((params) => this.notificationsQuery.selectEntity(params.id)),
        tap((notificacao: NotificacaoDto) => {
          this.notificacao = notificacao;
        }),

        catchError(async () => await this.toastService.erro('Erro ao buscar notificações. Tente novamente mais tarde.')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    this.notificationsQuery.selectLoading().pipe(
      takeUntil(this.ngUnsubscribe));
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  private getNotification(id: string) {
    return this.notificationService
      .getNotification(id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
  }
  public get tipoNotificacao(): typeof TipoNotificacao {
    return TipoNotificacao;
  }

  public closeAndNavigate(route: string): void {
    this.router.navigate([route]);
  }

  public navigateToNotification(): void {
    this.navigationService.goBack();
  }

  navigateTo(data) {

    if (data?.cadastroId && !data?.payload?.pagamento_id) {
      this.router.navigate([`/atendimento`, data.cadastroId], { relativeTo: this.route });
    } else {
      this.router.navigate(["/home/pagamento/", data.payload.pagamento_id, data.cadastroId], { relativeTo: this.route });
    }
  }
}
