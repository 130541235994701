<ion-grid *ngIf="!button" class="ion-no-padding ion-no-margin">
    <ion-row class="ion-align-items-center">
        <ion-col class="photo-registration__flex">
            <div class="photo-registration__image" *ngIf="!imageUrl" (click)="convertImage()">
                <ion-icon name="camera-outline" color="medium"></ion-icon>
                <p class="ion-no-margin">
                    Tirar Foto
                </p>
            </div>
            <ion-avatar class="photo-registration__image" *ngIf="imageUrl && type === 'person'">
                <img [defaultImage]="defaultImage" [lazyLoad]="imageUrl" />
            </ion-avatar>
            <img [defaultImage]="defaultImage" [lazyLoad]="imageUrl" class="img-facade" *ngIf="imageUrl && (type === 'lot')" />
            <img [defaultImage]="defaultImage" [lazyLoad]="imageUrl" class="img-document" *ngIf="imageUrl && (type ==='document')" />
        </ion-col>
    </ion-row>
    <ng-container *ngIf="imageUrl">
        <ion-row class="ion-text-center">
            <ion-col>
                <ion-button *ngIf="type ==='person' || type ==='lot'" fill="clear" color="secondary" (click)="convertImage()"><b>Trocar Foto</b></ion-button>
                <ion-button *ngIf="type ==='document'" fill="clear" (click)="removeImage()"><b>Remover</b></ion-button>
            </ion-col>
        </ion-row>
    </ng-container>
</ion-grid>

<p class="required-photo-message" *ngIf="defaultImage===imageUrl && requiredphoto">{{messageRequired}}</p>
<ion-button class="ion-justify-content-center" fill="clear" color="secondary" *ngIf="button" (click)="convertImage()">{{buttonText}}</ion-button>
