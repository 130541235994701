import { ArrayMinSize, IsArray, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsPositive, Max, Min } from 'class-validator';
import { ReurbModalidade } from '../../enums/reurb-modalidade.enum';
import { TabelaPrecoParcela } from './tabela-preco-parcela.enum';

export class TabelaPrecoDto {
  @IsPositive()
  @IsNotEmpty()
  valorBase: number;

  @IsPositive()
  @IsNotEmpty()
  valorEfetivo: number;

  @IsPositive()
  @IsNotEmpty()
  valorAto: number;

  @IsPositive()
  @IsOptional()
  valorAverbacao?: number;

  @IsArray()
  @ArrayMinSize(1)
  @IsEnum(TabelaPrecoParcela, { each: true })
  @IsNotEmpty()
  parcelamentos: TabelaPrecoParcela[];

  @Min(0)
  @Max(20)
  @IsNumber()
  @IsNotEmpty()
  desconto: number;

  @Min(0)
  @Max(20)
  @IsNumber()
  @IsNotEmpty()
  multa: number;

  @Min(0)
  @Max(20)
  @IsNumber()
  @IsNotEmpty()
  juros: number;

  @IsEnum(ReurbModalidade)
  @IsOptional()
  modalidade?: ReurbModalidade;

  static converteParcelaParaNumero(parcela: TabelaPrecoParcela): number {
    switch (parcela) {
      case TabelaPrecoParcela.PARCELA_0:
        return 0;
      case TabelaPrecoParcela.PARCELA_1:
        return 1;
      case TabelaPrecoParcela.PARCELA_2:
        return 2;
      case TabelaPrecoParcela.PARCELA_3:
        return 3;
      case TabelaPrecoParcela.PARCELA_4:
        return 4;
      case TabelaPrecoParcela.PARCELA_5:
        return 5;
      case TabelaPrecoParcela.PARCELA_6:
        return 6;
      case TabelaPrecoParcela.PARCELA_7:
        return 7;
      case TabelaPrecoParcela.PARCELA_8:
        return 8;
      case TabelaPrecoParcela.PARCELA_9:
        return 9;
      case TabelaPrecoParcela.PARCELA_10:
        return 10;
      case TabelaPrecoParcela.PARCELA_11:
        return 11;
      case TabelaPrecoParcela.PARCELA_12:
        return 12;

      default:
        return -1;
    }
  }
}
