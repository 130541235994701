import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../../components/header/header.module';
import { MenuItemModule } from '../../../../../components/menu-item/menu-item.module';
import { ProcessProgressModule } from '../../../../../components/process-progress/process-progress.module';
import { PipesModule } from '../../../../../pipes/pipes.module';
import { AnotherDocumentModule } from '../../../../modal/another-document/another-document.module';
import { TypeRoutingModule } from './type-routing.module';
import { TypePage } from './type.page';

@NgModule({
  declarations: [
    TypePage
  ],
  imports: [
    CommonModule,
    FooterModule,
    HeaderModule,
    IonicModule,
    ProcessProgressModule,
    TypeRoutingModule,
    MenuItemModule,
    PipesModule,
    AnotherDocumentModule,
  ]
})
export class TypeModule { }
