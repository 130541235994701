import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';
import { AuthQuery } from '../../../auth/state/auth.query';

@Component({
  selector: 'usucampeao-vencimento-proximo',
  templateUrl: './vencimento-proximo.component.html',
  styleUrls: ['./vencimento-proximo.component.scss'],
})
export class VencimentoProximoComponent {
  @Input()
  public notification!: NotificacaoDto;
  public nomeUsuario$ = this.authQuery.meName$;

  @Output() goto = new EventEmitter();

  constructor(
    private authQuery: AuthQuery
  ) { }

  goRegister() {
    this.goto.emit(this.notification);
  }

}