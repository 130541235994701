import { Type } from 'class-transformer';
import { IsDateString, IsOptional, ValidateNested } from 'class-validator';
import { Ref } from '../ref/ref.model';

export class BaseEntityDto {
  @IsDateString()
  @IsOptional()
  createdAt?: Date;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  userCreation?: Ref;

  @IsDateString()
  @IsOptional()
  updatedAt?: Date;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  userUpdate?: Ref;
}
