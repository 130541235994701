import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Storage } from '@capacitor/storage';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class FcmService {

  constructor(
    public toastController: ToastController,
    private http: HttpClient,
    protected angularFireMessaging: AngularFireMessaging
  ) {
    this.angularFireMessaging.usePublicVapidKey(environment.publicVapidKey).then(() => {
      this.getFcmToken();
      this.getMessage();
    });
  }

  getFcmToken(): void {

    this.angularFireMessaging.requestToken.subscribe(
      async (token) => {
        const { value } = await Storage.get({ key: 'userNotification' });
        if (value !== token) {
          this.http.post('/users/me/dispositivos', { token }).toPromise().then(async () => {
            await Storage.set({
              key: 'userNotification',
              value: token,
            });
          })
        }
      },
      async err => {
        const toast = await this.toastController.create({
          message: 'Não foi possível obter permissão para notificar.',
          duration: 2000
        });
        toast.present();
      }
    );
  }

  getMessage() {
    this.angularFireMessaging.messages
      .subscribe(async (data: any) => {
        const toast = await this.toastController.create({
          message: data.notification.body,
          duration: 5000,
          position: "top"
        });
        toast.present();
      });
  }

  async logoutFCM() {
    const toast = await this.toastController.create({
      message: 'Até mais',
      duration: 2000
    });
    toast.present();
    await Storage.set({
      key: 'userNotification',
      value: '',
    });

  }
}