import { Expose } from 'class-transformer';
import { StatusPreenchimento } from '../../enums';

export class OwnerFillInStatusDto {
  @Expose()
  personalData?: StatusPreenchimento;

  @Expose()
  documentsData?: StatusPreenchimento;

  @Expose()
  contactData?: StatusPreenchimento;

  @Expose()
  documents?: StatusPreenchimento;

  @Expose()
  proprietarioCadastroId?: string;

  constructor(proprietarioCadastroId: string) {
    this.proprietarioCadastroId = proprietarioCadastroId;
  }
}
