import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewWillLeave } from '@ionic/angular';
import {
  NotificacaoSimplificadaDto,
  StatusNotificacao
} from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { ToastService } from '../../services/toast.service';
import { NotificationsQuery } from './state/notifications.query';
import { NotificationsService } from './state/notifications.service';

@Component({
  selector: 'usucampeao-notification',
  templateUrl: 'notification.page.html',
  styleUrls: ['notification.page.scss'],
})
export class NotificationPage implements OnInit, ViewWillLeave, OnDestroy {
  private ngUnsubscribe = new Subject();
  public notifications$ = this.notificationsQuery.selectAll();

  constructor(
    private notificationsService: NotificationsService,
    private notificationsQuery: NotificationsQuery,
    public router: Router,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    // this.notificationsService
    //   .getNotifications()
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe),
    //     catchError(async () => await this.toastService.erro('Erro ao buscar notificações. Tente novamente mais tarde.'))
    //   )
    //   .subscribe();
  }

  public get statusNotificacao(): typeof StatusNotificacao {
    return StatusNotificacao;
  }
  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public navigateToNotification(): void {
    this.navigationService.goBack();
  }

  public navigateToSee(notification: NotificacaoSimplificadaDto): void {
    this.router.navigate([`/notificacoes`, notification.id]);
  }
}
