<ion-content>
    <usucampeao-header mode="md" backRoute="/lote" title="Endereço do Imóvel"></usucampeao-header>
    <ion-grid class="ion-padding-start ion-padding-end ion-no-padding">
        <ion-row class="ion-justify-content-center">
            <ion-col class="ion-no-margin ion-no-padding" sizeMd="6">
                <ion-text class="font-text"> Informe dados do seu lote </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center" *ngIf="alteracoes && alteracoes.length > 0">
            <ion-col sizeXs="12" sizeSm="6" class="ion-padding-top ion-padding-bottom">
                <usucampeao-change-alert [changes]='alteracoes'></usucampeao-change-alert>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
            <ion-col sizeMd="6" class="ion-no-padding ion-no-margin">
                <form mode="md" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div *ngFor="let field of form$" class="form-row ion-no-padding">
                        <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
                    </div>
                </form>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-col sizeMd="6" sizeLg="3" class="ion-no-margin ion-no-padding">
                <div *ngIf="((this.authQuery.isConnectionOnline$ |async))">
                    <ion-button *ngIf="!blockAccess" class="ion-margin-bottom" mode="md" expand="block" (click)="onSubmit()">
                        {{ fluxo ? 'Continuar': 'Salvar' }}</ion-button>
                </div>
                <ion-label *ngIf="(this.authQuery.isConnectionOnline$ |async)===false">
                    <ion-icon name="cloud-offline-outline" slot="start"></ion-icon>
                    Sem internet
                </ion-label>
            </ion-col>
        </ion-row>
    </ion-grid>
    <usucampeao-footer></usucampeao-footer>
</ion-content>