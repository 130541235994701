<ion-content>
  <usucampeao-header mode="md" backRoute=".." title="Proprietário">
    <ion-text>
      <p class="ion-padding-start description-text description-text--small">
        Proprietário principal
      </p>
    </ion-text>
  </usucampeao-header>
  <div class="ion-no-padding ion-padding-start ion-padding-end">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-justify-content-center">
          <ion-col sizeXs="12" sizeSm="6">
          <ion-item (click)="navigateToProprietaryEdit()" detail="true" class="ion-no-padding">
            <ion-list>
              <ion-item class="ion-no-padding" lines="none">
                <ion-thumbnail slot="start" class="ion-no-border">
                  <ion-avatar>
                    <img class="image" [defaultImage]="defaultImage" [lazyLoad]="''" />
                  </ion-avatar>
                </ion-thumbnail>
                <ion-label>
                  <h3>{{ owner?.name }}</h3>
                  <p class="description-text description-text--small">
                    Proprietário
                  </p>
                </ion-label>
              </ion-item>
              <ion-item class="ion-no-padding" lines="none">
                <ion-thumbnail slot="start">
                  <ion-avatar>
                    <img class="image" [defaultImage]="defaultImage" [lazyLoad]="''" />
                  </ion-avatar>
                </ion-thumbnail>
                <ion-label>
                  <h3>{{ spouse?.name }}</h3>
                  <p class="description-text description-text--small">
                    Cônjuge/companheiro(a)
                  </p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-button mode="md" expand="block" class="ion-margin-top" (click)="navigateToProprietary()">Novo Proprietário
    </ion-button>
    <usucampeao-footer> </usucampeao-footer>
  </div>
</ion-content>
