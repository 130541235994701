import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagamentoPage } from './pagamento.page';

const routes: Routes = [
  {
    path: '',
    component: PagamentoPage,
  },
  {
    path: 'detalhes/:id/:registration',
    loadChildren: () =>
      import('./detalhes/detalhes.module').then((m) => m.DetalhesPageModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagamentoPageRoutingModule { }
