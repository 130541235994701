import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import bugsnag from '@bugsnag/js';
import { cacheable } from '@datorama/akita';
import {
  AlteracaoDto,
  CadastraDadosPagamentoDto,
  CadastroContratoDto,
  CreateRegistrationDto,
  JustifyDocumentDto,
  ListRegistration,
  ProjetoSimplificadoDto,
  RegistrationSimplifiedDto,
  RegistrationStatus,
  SetSchedulingDate,
  TabelaPrecoDetalhesDto,
  TipoAlteracao,
  UpdatePropertyAddressAdditionalInformationsDto
} from '@usucampeao/lib-reurb-simplificado';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, shareReplay, tap } from 'rxjs/operators';
import { DocumentsOwnerStore } from '../owners/details/sent-documents/state/store';
import { DocumentsPropertyStore } from '../property/property-documents/state/store';
import { Property, PropertyStore } from '../property/state/property.store';
import { RegistrationStore } from './registration.store';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(
    private http: HttpClient,
    private ownerDocuments: DocumentsOwnerStore,
    private propertyDocumentStore: DocumentsPropertyStore,
    private propertyStore: PropertyStore,
    private registerStore: RegistrationStore,
  ) { }

  /**
   * Adiciona um novo cadastro
   * @param createRegistration dados para criar novo cadastro
   * @returns dados de criação de cadastro
   */
  public create(
    createRegistration: CreateRegistrationDto
  ): Observable<CreateRegistrationDto> {
    this.registerStore.setLoading(true);
    return this.http
      .post<CreateRegistrationDto>(`/registrations`, createRegistration)
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-create-registration'
            event.addMetadata('payload', createRegistration);
            event.addMetadata('metadata', {
              url: `/registrations`,
              title: 'Erro ao criar um novo cadastro',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)));
  }

  /**
   * Envia documentos do owner
   */
  public sendFileOwer(registrationId: string, ownerId: string, documentId: string, data, document): Promise<any> {
    this.registerStore.setLoading(true);
    const formData = new FormData();


    data.forEach((item) => {
      formData.append('files', item);
    })

    formData.append('type', document.name);

    return this.http
      .post(
        `/registrations/${registrationId}/owners/${ownerId}/documents`, formData
      )
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-sendFile-Owner'
            event.addMetadata('payload', formData);
            event.addMetadata('params', { registrationId, ownerId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/owners/${ownerId}/documents`,
              title: 'Erro ao enviar documento ao owner:' + registrationId + '/' + ownerId,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)))
      .toPromise();
  }

  /**
 * Envia documentos do owner
 */
  public updateFileOwer(registrationId: string, ownerId: string, documentId: string, data: File[], arquivosRemovidos): Promise<any> {
    this.registerStore.setLoading(true);

    if (arquivosRemovidos) {
      arquivosRemovidos.map((id) => {
        formData.append('arquivosRemovidos', id);
      });
    }


    const formData = new FormData();
    if (data) {
      data.forEach((item) => {
        formData.append('files', item);
      });
    }

    return this.http
      .put(
        `/registrations/${registrationId}/owners/${ownerId}/documents/${documentId}/files`, formData
      )
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateFile-ower'
            event.addMetadata('payload', formData);
            event.addMetadata('params', { registrationId, ownerId, documentId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/owners/${ownerId}/documents/${documentId}/files`,
              title: 'Erro ao enviar documento ao owner:' + registrationId + '/' + ownerId + '/' + documentId,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)))
      .toPromise();
  }

  /**
   * Buscar documento do owner
   */
  public getFileOwer(id: string): Observable<any> {
    return this.http
      .get(
        `/documents/${id}/files`
      ).pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getFile-owner'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/documents/${id}/files`,
              title: 'Erro ao buscar documento do owner:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
      )
  }

  /**
   * Buscar documento do owner
   */
  public getFiles(id: string, selected: string): Observable<any> {
    return this.http
      .get(
        `/documents/${id}/files/${selected}/download`
        , { responseType: 'blob' }).pipe(
          catchError(erro => {
            bugsnag.notify(erro, (event) => {
              event.context = 'service-getFile'
              event.addMetadata('params', { id, selected });
              event.addMetadata('query params', { responseType: 'blob' });
              event.addMetadata('metadata', {
                url: `/documents/${id}/files/${selected}/download`,
                title: 'Erro ao buscar documento do owner:' + id + '/' + selected,
                statusCode: erro.error.statusCode,
                message: erro.error.message,
                error: erro.error.error,
              });
            })
            return throwError(erro);
          }),
        )
  }

  public excluiCadastro(id: string, menssagem: any): Observable<any> {
    return this.http.put(`/registrations/${id}/cancelar`, menssagem)
      .pipe(tap(() => this.registerStore.remove(id)))
  }

  /**
   * Envia documentos do owner
   */
  public sendFilePropety(registrationId: string, propetyId: string, data: File[], document: string, documentName?: string): Promise<any> {
    this.registerStore.setLoading(true);
    const formData = new FormData();
    if (documentName) {
      formData.append('name', documentName);
    }

    data.forEach((item) => {
      formData.append('files', item);
    })

    formData.append('type', document);
    return this.http
      .post(
        `/registrations/${registrationId}/properties/${propetyId}/documents`, formData, { responseType: 'text' }
      )
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-sendFile-propety'
            event.addMetadata('payload', formData);
            event.addMetadata('params', { registrationId, propetyId });
            event.addMetadata('query params', { responseType: 'text' });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${propetyId}/documents`,
              title: 'Erro ao enviar documentos do owner:' + registrationId + '/' + propetyId,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)))
      .toPromise();
  }

  /**
   * Atualiza documentos do Propety
   */
  public updateFilePropety(registrationId: string, propetyId: string, documentId: string, data, arquivosRemovidos: string[]): Promise<any> {

    const formData = new FormData();

    if (arquivosRemovidos) {
      arquivosRemovidos.map((id) => {
        formData.append('arquivosRemovidos', id);
      })
    }


    if (data) {
      data.forEach((item) => {
        formData.append('files', item);
      })

    }

    return this.http
      .put(
        `/registrations/${registrationId}/properties/${propetyId}/documents/${documentId}/files`, formData, { responseType: 'text' }
      )
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateFile-propety'
            event.addMetadata('payload', formData);
            event.addMetadata('params', { registrationId, propetyId, documentId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${propetyId}/documents/${documentId}/files`,
              title: 'Erro ao atualizar documentos do propety:' + registrationId + '/' + propetyId + '/' + documentId,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)))
      .toPromise();
  }

  /**
   * Busca documentos do owner
   */
  public getFileOwner(registrationId: string, ownerId: string): Promise<any> {
    this.registerStore.setLoading(true);
    const request$ = this.http
      .get(
        `/registrations/${registrationId}/owners/${ownerId}/documents`
      )
      .pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
        tap((res) => this.ownerDocuments.update({ data: res })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getFile-owner'
            event.addMetadata('params', { registrationId, ownerId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${ownerId}/documents`,
              title: 'Erro ao buscar documentos do owner',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)));
    return cacheable(this.ownerDocuments, request$).toPromise();
  }

  public getDadosContrato(id: string): Observable<CadastroContratoDto> {
    return this.http.get<CadastroContratoDto>(`/registrations/${id}/dados-contrato`);
  }

  /**
   * Busca documentos do propety
   */
  public getFilePropety(registrationId: string, propetyId: string): Promise<any> {

    this.registerStore.setLoading(true);
    const request$ = this.http
      .get(
        `/registrations/${registrationId}/properties/${propetyId}/documents`
      )
      .pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
        tap((res) => this.propertyDocumentStore.update({ data: res })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getFile-propety'
            event.addMetadata('params', { registrationId, propetyId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/properties/${propetyId}/documents`,
              title: 'Erro ao buscar documentos do propety',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)));
    return cacheable(this.propertyDocumentStore, request$).toPromise();
  }


  /**
   * Lista os cadastros do usuário
   * @returns cadastros do usuário
   */
  public listRegistrationsByUser(): Observable<ListRegistration[]> {
    this.registerStore.setLoading(true);
    return this.http.get<ListRegistration[]>('/registrations/user').pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      tap((res: Partial<RegistrationSimplifiedDto[]>) => this.registerStore.loadRegistration(res, true)),
      catchError(erro => {
        bugsnag.notify(erro, (event) => {
          event.context = 'service-listRegistrations-user'
          event.addMetadata('metadata', {
            url: '/registrations/user',
            title: 'Erro ao listar os cadastros do usuário',
            statusCode: erro.error.statusCode,
            message: erro.error.message,
            error: erro.error.error,
          });
        })
        return throwError(erro);
      }),
      finalize(() => this.registerStore.setLoading(false))
    );
  }



  /**
   * Lista os dados do cadastro
   * @param id id do cadastro
   * @returns dados simplificados do cadastro
   */
  public getRegistration(id: string): Observable<RegistrationSimplifiedDto> {
    this.registerStore.setLoading(true);
    return this.http
      .get<RegistrationSimplifiedDto>(`/registrations/${id}/simplified`)
      .pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
        tap(registration => {
          this.registerStore.update(id, registration);
        }),
        tap(registration => {
          const state: Partial<Property> = {
            id: registration.propertyId,
            blockId: registration.blockId,
            lotId: registration.lotId,
            files: registration.files,
            ...registration.address ? { address: registration.address, addressDataLoaded: true } : {}
          };
          this.propertyStore.add(state as any);
        }),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getRegistration'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/registrations/${id}/simplified`,
              title: 'Erro ao listar os dados de cadastro:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)),
      );
  }

  /**
   * Adiciona endereço e informações adicionais no imóvel
   * @param registrationId id do cadastro
   * @param propertyId id do imóvel
   * @param data dados de endereço e informações adicionais do imóvel
   */
  public addPropertyAddressAndAdditionalInformations(
    data: UpdatePropertyAddressAdditionalInformationsDto
  ): Observable<void> {
    this.registerStore.setLoading(true);
    const registration = this.registerStore.getValue();
    return this.http
      .put<void>(
        `/registrations/${registration.id}/properties/${registration.propertyId}/address-additional-information`,
        data
      )
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-addPropertyAddressAndAdditional-informations'
            event.addMetadata('payload', data);
            event.addMetadata('params', { registration });
            event.addMetadata('metadata', {
              url: `/registrations/${registration.id}/properties/${registration.propertyId}/address-additional-information`,
              title: 'Erro ao adicionar endereço e informações adicionais no imóvel',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => {
          this.registerStore.setLoading(false);
          this.registerStore.reset();
        })
      );
  }

  /**
   * Seta a data de agendamento para validação
   * @param id id do cadastro
   * @param date data selecionada
   */
  public setSchedulingDate(id: string, date: Date): Observable<void> {
    this.registerStore.setLoading(true);
    const schedulingDate = new SetSchedulingDate(date);
    return this.http.put<void>(`/registrations/${id}/scheduling-date`, schedulingDate)
      .pipe(
        finalize(() => this.registerStore.setLoading(false)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-setScheduling-date'
            event.addMetadata('payload', schedulingDate);
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/registrations/${id}/scheduling-date`,
              title: 'Erro ao setar a data de agendamento para validação:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        })
      );
  }

  public cadastrarDadosPagamento(id: string, sendForAnalysis: CadastraDadosPagamentoDto): Observable<void> {
    this.registerStore.setLoading(true);
    return this.http.put<void>(`/registrations/${id}/payment-data`, sendForAnalysis)
      .pipe(
        tap(() => this.registerStore.update(id, { status: RegistrationStatus.IN_ANALYSIS })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-cadastrarDados-pagamento'
            event.addMetadata('payload', this.sendForAnalysis);
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/registrations/${id}/payment-data`,
              title: 'Erro ao cadastrar dados para pagamento:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false))
      );
  }
  public sendForAnalysis(id: string): Observable<void> {
    this.registerStore.setLoading(true);
    return this.http.put<void>(`/registrations/${id}/send-for-analysis`, {})
      .pipe(
        tap(() => this.registerStore.update(id, { status: RegistrationStatus.IN_ANALYSIS })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-sendFor-analysis'
            event.addMetadata('payload', this.sendForAnalysis);
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/registrations/${id}/send-for-analysis`,
              title: 'Erro ao enviar cadastro para análise:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)),
      );
  }

  /**
   * Justifica um documento
   * @param id id do atendimento
   * @param propertyId id do imóvel
   * @param justifyDocument dados de justificativa do documento
   * @returns
   */
  public justifyDocument(id: string, propertyId: string, justifyDocument: JustifyDocumentDto): Observable<any> {
    this.registerStore.setLoading(true);
    return this.http.put(`/registrations/${id}/properties/${propertyId}/documents/justify`, justifyDocument)
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-justify-document'
            event.addMetadata('payload', justifyDocument);
            event.addMetadata('params', { id, propertyId });
            event.addMetadata('metadata', {
              url: `/registrations/${id}/properties/${propertyId}/documents/justify`,
              title: 'Erro ao enviar justificativa de documento:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)),
      );
  }

  public setStatus(id) {
    this.registerStore.update(id, { status: RegistrationStatus.IN_ANALYSIS });
  }

  /**
   * Busca as alterações necessárias para um cadastro
   * @param id id do atendimento
   * @param tipo da pagina
   * @returns
   */
  public buscaAlteracoesCadastro(id: string, tipo: TipoAlteracao): Observable<AlteracaoDto[]> {
    return this.http.get<AlteracaoDto[]>(`/registrations/${id}/alteracoes`, { params: { tipo } })
      .pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-buscaAlterações-cadastro'
            event.addMetadata('params', { id });
            event.addMetadata('query params', { tipo });
            event.addMetadata('metadata', {
              url: `/registrations/${id}/alteracoes`,
              title: 'Erro ao buscar as alterações necessárias para um cadastro:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false)),
      );
  }

  public buscaDetalhesPreco(id: string): Observable<TabelaPrecoDetalhesDto[]> {
    return this.http.get<TabelaPrecoDetalhesDto[]>(`/projetos/${id}/tabela-preco`)
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-buscaDetalhesPreco'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/projetos/${id}/tabela-preco`,
              title: 'Erro ao buscar informações das parcelas do projeto:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
      )
  }

  public buscarDetalhesProjeto(id: string): Observable<ProjetoSimplificadoDto> {
    return this.http.get<ProjetoSimplificadoDto>(`/projetos/${id}`)
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-buscaDetalhesProjeto'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/projetos/${id}`,
              title: 'Erro ao buscar informações do projeto:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
      )
  }
}
