import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { NotificacaoSimplificadaDto, NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';

export interface NotificationsState extends EntityState<NotificacaoSimplificadaDto | NotificacaoDto> {
  loaded: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'notifications',
  resettable: true
})
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super();
  }

  public loadNotifications(notifications: NotificacaoSimplificadaDto[], loaded: boolean) {
    this.set(notifications);

    this.update(state => ({
      ...state,
      loaded
    }));
  }
}