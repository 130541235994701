import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString
} from 'class-validator';
import { BaseEntityDto } from '../base-entity/base-entity-dto.model';

export class BlockDto extends BaseEntityDto {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsNumber()
  @IsNotEmpty()
  projectId: number;

  @IsString()
  @IsOptional()
  blockId?: string;

  @IsString()
  @IsOptional()
  originalBlockId?: string;

  @IsString()
  @IsNotEmpty()
  label: string;

  @IsNumber()
  @IsOptional()
  shapeLength?: number;

  @IsString()
  @IsOptional()
  shapeArea?: string;

  @IsString()
  @IsOptional()
  status?: string;

  @IsNumber()
  @IsNotEmpty()
  lotAmount: number;

  @IsNumber()
  @IsOptional()
  lotsSold?: number;
}
