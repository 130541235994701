<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
    <ion-text>
        <p class="title">Selecionando o lote</p>
    </ion-text>
    <ion-text class="font-text">
        <p>Você precisa selecionar o seu lote no mapa e ele ficará azul. Dessa forma, você poderá dar continuidade ao processo de cadastro</p>
        <p>O lote selecionado será confirmado em visita, posteriormente.</p>
        <p>Caso seu lote não esteja disponível, entre em contato com a gente.</p>
    </ion-text>
    <ion-grid>
        <ion-row class="ion-justify-content-end">
            <ion-col size="auto">
                <a (click)="dismiss()"><b>Entendi</b></a>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
