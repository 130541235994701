import { IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString, Length } from 'class-validator';
import { UsuarioBackofficeTipo } from './usuario-backoffice-tipo.enum';

export class UsuarioBackofficeCriarDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsEmail()
  @IsNotEmpty()
  email: string;

  @IsString()
  @Length(10, 13)
  @IsOptional()
  telefone?: string;

  @IsString()
  @IsOptional()
  foto?: string;

  @IsEnum(UsuarioBackofficeTipo)
  @IsOptional()
  tipo?: UsuarioBackofficeTipo;
}
