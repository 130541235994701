/**
 * Enumeration of allotment document types.
 */
export enum AllotmentDocumentType {
  /**
   * Delimitação do Perímetro do Núcleo Irregular.
   */
  DELIMITACAO_PERIMETRO = 'DELIMITACAO_PERIMETRO',
  /**
   * Requerimentos de Processos.
   */
  REQUERIMENTOS_PROCESSOS = 'REQUERIMENTOS_PROCESSOS',
  /**
   * Levantamento Planialtimétrico e Cadastral.
   */
  LEVANTAMENTO_PLANIALTIMETRICO = 'LEVANTAMENTO_PLANIALTIMETRICO',
  /**
   * Planta do Perímetro com Demonstração das Matrículas.
   */
  PLANTA_PERIMETRO = 'PLANTA_PERIMETRO',
  /**
   * Projeto Urbanístico.
   */
  PROJETO_URBANISTICO = 'PROJETO_URBANISTICO',
  /**
   * Cronograma de Obras de Infraestrutura Essencial.
   */
  CRONOGRAMA_OBRAS = 'CRONOGRAMA_OBRAS',
  /**
   * Projetos em Geral.
   */
  PROJETOS_GERAL = 'PROJETOS_GERAL',
  /**
   * Trabalho Social do Bairro.
   */
  TRABALHO_SOCIAL = 'TRABALHO_SOCIAL',
  /**
   * Propostas de Soluções Jurídicas, Ambientais, Urbanísticas e Sociais.
   */
  PROPOSTAS_SOLUCOES = 'PROPOSTAS_SOLUCOES',
  /**
   * Memorial Descritivo.
   */
  MEMORIAL_DESCRITIVO = 'MEMORIAL_DESCRITIVO',
  /**
   * Certidão de Regularização Fundiária.
   */
  CERTIDAO_REGULARIZACAO = 'CERTIDAO_REGULARIZACAO',
  /**
   * Outros documentos.
   */
  OUTROS = 'OUTROS',
}
