import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { Nationality } from '@usucampeao/interfaces';
import {
  AlteracaoDto,
  GetUpdateOwnerPersonalDataDto,
  GroupsClassTransformer,
  MaritalStatus,
  RegistrationStatus, STATUS_REGIME_BENS_DATA_CASAMENTO, TipoAlteracao
} from '@usucampeao/lib-reurb-simplificado';
import { ElementBase, toFormGroup } from '@usucampeao/ui-mobile';
import { of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged, finalize, switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import { GenderPipe } from '../../../../../pipes/gender.pipe';
import { LoadingService } from '../../../../../services/loading.service';
import { ToastService } from '../../../../../services/toast.service';
import { AuthQuery } from '../../../../auth/state/auth.query';
import { RegistrationQuery } from '../../../state/registration.query';
import { RegistrationService } from '../../../state/registration.service';
import { OwnersQuery } from '../../state/owners.query';
import { OwnersService } from '../../state/owners.service';
import { MaritalStatusPipe } from './../../../../../pipes/marital-status.pipe';
import { MatrimonialRegimePipe } from './../../../../../pipes/matrimonial-regime.pipe';
import { NavigationService } from './../../../../../services/navigation.service';
import {
  getProprietaryForm,
  getProprietaryMaritalStatusForm,
  getProprietaryProfessionForm,
  getProprietaryPropertyRegimeForm,
  getProprietaryStateForm,
  getSpouseForm,
  getSpouseProfessionForm,
  getSpouseStateForm
} from './basic-information.form';

@Component({
  selector: 'usucampeao-basic-information',
  templateUrl: 'basic-information.page.html',
  styleUrls: ['basic-information.page.scss'],
})
export class BasicInformationPage implements OnInit, ViewWillEnter, ViewWillLeave, OnDestroy {
  form!: FormGroup;
  payLoad = '';
  public formProprietary$: ElementBase<string>[] | null = [];
  public formProprietaryState$: ElementBase<string>[] | null = [];
  public formMaritalStatus$: ElementBase<string>[] | null = [];
  public formPropertyRegime$: ElementBase<string>[] | null = [];
  public formProfission$: ElementBase<string>[] | null = [];
  public formSpouse$: ElementBase<string>[] | null = [];
  public formSpouseState$: ElementBase<string>[] | null = [];
  public formSpouseProfission$: ElementBase<string>[] | null = [];
  public isOtherProprietaryState = false;
  public isOtherMaritalStatus = false;
  public isOtherSpouseState = false;
  public blockAccess = false;
  public alteracoes: AlteracaoDto[] = [];

  private ngUnsubscribe = new Subject();
  private ownerId: string;
  private registrationId: string;
  public ownerPersonalData: GetUpdateOwnerPersonalDataDto;
  fluxo: boolean;

  constructor(
    private alertCtrl: AlertController,
    private genderPipe: GenderPipe,
    private loaderService: LoadingService,
    private maritalStatusPipe: MaritalStatusPipe,
    private matrimonialRegimePipe: MatrimonialRegimePipe,
    private navigationService: NavigationService,
    private ownersQuery: OwnersQuery,
    private ownerQuery: OwnersQuery,
    private ownerService: OwnersService,
    private registrationQuery: RegistrationQuery,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    public authQuery: AuthQuery,
    public currencyPipe: CurrencyPipe,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.initForms(false);
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        const registration = this.registrationQuery.getValue();
        const status = registration.entities[params.id];
        this.validateStatus(status.status);
        this.ownerId = params.ownerId;
        this.registrationId = params.id;
        this.getPersonalData();

      });


    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.fluxo = params['fluxo'];
      });

    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data) => this.validateForm(data));

    this.ownersQuery.selectLoading()
      .pipe(
        distinctUntilChanged(),
        finalize(async () => await this.loaderService.dismiss()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async loading => {
        if (loading) {
          await this.loaderService.createLoader();
        } else {
          await this.loaderService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  private initForms(readOption: boolean) {
    this.formProprietary$ = getProprietaryForm(this.genderPipe, readOption);
    this.formProprietaryState$ = getProprietaryStateForm(readOption);
    this.formMaritalStatus$ = getProprietaryMaritalStatusForm(this.maritalStatusPipe, readOption);
    this.formPropertyRegime$ = getProprietaryPropertyRegimeForm(this.matrimonialRegimePipe, readOption);
    this.formProfission$ = getProprietaryProfessionForm(readOption);
    this.formSpouseState$ = getSpouseStateForm(readOption);
    this.formSpouseProfission$ = getSpouseProfessionForm(readOption);
    this.formSpouse$ = getSpouseForm(this.genderPipe, readOption);
    this.form = toFormGroup(this.getForm(readOption) as ElementBase<string>[]);
  }

  private getForm(readOption): ElementBase<string>[] {
    const formCreate: ElementBase<string>[] = [
      ...getProprietaryForm(this.genderPipe, readOption),
      ...getProprietaryStateForm(readOption),
      ...getProprietaryMaritalStatusForm(this.maritalStatusPipe, readOption),
      ...getProprietaryPropertyRegimeForm(this.matrimonialRegimePipe, readOption),
      ...getProprietaryProfessionForm(readOption),
      ...getSpouseForm(this.genderPipe, readOption),
      ...getSpouseStateForm(readOption),
      ...getSpouseProfessionForm(readOption),
    ];
    return formCreate;
  }

  private validateForm(data: any): void {
    this.isOtherProprietaryState = this.validateNationality(data.nationality)
    this.isOtherMaritalStatus = this.validateMaritalStatus(data.maritalStatus)
    this.isOtherSpouseState = this.validateNationality(data.nationality)
  }

  private validateMaritalStatus(maritalStatus: MaritalStatus): boolean {
    if (STATUS_REGIME_BENS_DATA_CASAMENTO.includes(maritalStatus)) {
      this.form.controls['matrimonialRegime'].setValidators([Validators.required]);
      this.form.controls['matrimonialRegime'].updateValueAndValidity({ emitEvent: false });
      this.form.controls['weddingDate'].setValidators([Validators.required]);
      this.form.controls['weddingDate'].updateValueAndValidity({ emitEvent: false });
      return true;
    }
    else {
      this.form.controls['matrimonialRegime'].setErrors(null);
      this.form.controls['matrimonialRegime'].setValidators(null);
      this.form.controls['matrimonialRegime'].updateValueAndValidity({ emitEvent: false });
      this.form.controls['weddingDate'].setErrors(null);
      this.form.controls['weddingDate'].setValidators(null);
      this.form.controls['weddingDate'].updateValueAndValidity({ emitEvent: false });
    }
  }

  private validateNationality(nationality: Nationality): boolean {
    if (nationality === Nationality.BRASIL) {
      this.form.controls['state'].setValidators([Validators.required]);
      this.form.controls['state'].updateValueAndValidity({ emitEvent: false });
      this.form.controls['naturalidade'].setValidators([Validators.required]);
      this.form.controls['naturalidade'].updateValueAndValidity({ emitEvent: false });
      return true;
    }
    else {
      this.form.controls['state'].setErrors(null);
      this.form.controls['state'].setValidators(null);
      this.form.controls['state'].updateValueAndValidity({ emitEvent: false });
      this.form.controls['naturalidade'].setErrors(null);
      this.form.controls['naturalidade'].setValidators(null);
      this.form.controls['naturalidade'].updateValueAndValidity({ emitEvent: false });
      return false;
    }
  }

  public navigateToProprietary(): void {
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  public async onSubmit(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      await this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
      return;
    }

    const formValue = this.form.value;

    if (this.ownerPersonalData.spouseId && formValue.maritalStatus !== MaritalStatus.MARRIED) {
      this.showAlert();
    } else {
      this.updatePersonalData();
    }

  }

  private async showAlert(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção!',
      message: 'Alterando o estado civil, todos os dados do cônjuge cadastrado serão perdidos.',
      buttons: [
        {
          text: 'Cancelar',
        }, {
          text: 'Confirmar',
          handler: () => {

            this.updatePersonalData();
          }
        }
      ]
    });

    await alert.present();
  }

  private updatePersonalData(): void {
    const dados = this.form.value;
    dados.familyIncome = parseFloat((((`${dados.familyIncome}` || '').replace("R$", "")).replace(".", "")).replace(",", "."));
    this.ownerService.updatePersonalData(this.registrationId, this.ownerId, GetUpdateOwnerPersonalDataDto.from(this.form.value))
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        if (this.fluxo) {
          this.router.navigate([`../documentos`], {
            relativeTo: this.route,
            queryParams: { fluxo: true },
          });
        } else {
          this.navigationService.goBack();
        }
      }, async () => await this.toastService.erro('Erro ao atualizar dados pessoais. Tente novamente mais tarde.'));
  }

  /**
   * Busca os dados básicos do proprietário.
   */
  private getPersonalData(): void {
    this.ownerQuery.selectEntity(this.ownerId)
      .pipe(
        take(1),
        switchMap(owner => {
          if (owner.personalDataLoaded) {
            return of(GetUpdateOwnerPersonalDataDto.from(owner, [GroupsClassTransformer.SHOW_MAIN]));
          }
          return this.ownerService.getPersonalData(this.registrationId, this.ownerId);
        }),
        tap(personalData => {
          this.ownerPersonalData = personalData;
          this.form.patchValue(this.ownerPersonalData);
          const familyIncome = this.currencyPipe.transform(personalData?.familyIncome, 'BRL');
          this.form.get('familyIncome').setValue(familyIncome);
          this.validateForm(this.form.value);
        }),
        switchMap(() => this.registrationService.buscaAlteracoesCadastro(this.registrationId, TipoAlteracao.PROPRIETARIO_DADOS_BASICOS)),
        tap(alteracoes => this.alteracoes = (alteracoes || []).filter(alteracao => alteracao.proprietarioId === this.ownerId)),
        catchError(async () => await this.toastService.erro('Erro ao buscar dados pessoais. Tente novamente mais tarde.'))
      ).subscribe();
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do atendimento, a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
      this.initForms(true);
    }
  }
}
