import { Validators } from '@angular/forms';
import { Nationality, UF } from '@usucampeao/interfaces';
import { Gender, MaritalStatus, MatrimonialRegime } from '@usucampeao/lib-reurb-simplificado';
import {
  Datetime,
  Dropdown,
  ElementBase,
  InputMode,
  Textbox
} from '@usucampeao/ui-mobile';
import { GenderPipe } from '../../../../../pipes/gender.pipe';
import {
  getSubDay,
  getSubYears
} from '../../../../../services/shared.function.service';
import { MaritalStatusPipe } from './../../../../../pipes/marital-status.pipe';
import { MatrimonialRegimePipe } from './../../../../../pipes/matrimonial-regime.pipe';

export function getSpouseForm(genderPipe: GenderPipe, readOnlyOption: boolean): ElementBase<string>[] {
  return [
    new Textbox({
      key: 'name',
      label: 'Nome completo',
      readonly: readOnlyOption,
      validators: [Validators.required, Validators.pattern('^[A-zÀ-ú ]*$')]
    }),
    new Dropdown({
      key: 'gender',
      label: 'Gênero',
      disabled: readOnlyOption,
      options: Object.values(Gender).map((gender) => {
        return {
          key: gender,
          value: genderPipe.transform(gender),
        };
      }),
      validators: [Validators.required]
    }),
    new Datetime({
      key: 'birthDate',
      label: 'Data de nascimento',
      readonly: readOnlyOption,
      type: 'date',
      minDate: getSubYears(),
      maxDate: getSubDay(),
      inputMode: InputMode.NUMBER,
      validators: [Validators.required]
    }),
    new Dropdown({
      key: 'nationality',
      label: 'Nacionalidade',
      disabled: readOnlyOption,
      options: Object.values(Nationality).map((nationality) => {
        return {
          key: nationality,
          value: nationality,
        };
      }),
      validators: [Validators.required]
    }),
  ];
}
export function getSpouseStateForm(readOnlyOption: boolean): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'state',
      label: 'Estado',
      readonly: readOnlyOption,
      options: Object.values(UF).map((uf) => {
        return {
          key: uf,
          value: uf,
        };
      }),
      validators: [Validators.required],
    }),
    new Textbox({
      key: 'naturalidade',
      label: 'Naturalidade',
      readonly: readOnlyOption,
      validators: [Validators.required]
    }),
  ];
}

export function getSpouseProfessionForm(readOnlyOption: boolean): ElementBase<string>[] {
  return [
    new Textbox({
      key: 'profession',
      label: 'Profissão',
      readonly: readOnlyOption,
      description: 'Profissão',
      validators: [Validators.required]
    }),
  ];
}

export function getProprietaryForm(genderPipe: GenderPipe, readOnlyOption: boolean): ElementBase<string>[] {
  return [
    new Textbox({
      key: 'name',
      label: 'Nome completo',
      readonly: readOnlyOption,
      validators: [Validators.required]
    }),
    new Dropdown({
      key: 'gender',
      label: 'Gênero',
      disabled: readOnlyOption,
      options: Object.values(Gender).map((gender) => {
        return {
          key: gender,
          value: genderPipe.transform(gender),
        };
      }),
      validators: [Validators.required]
    }),
    new Datetime({
      key: 'birthDate',
      type: 'date',
      label: 'Data de nascimento',
      readonly: readOnlyOption,
      inputMode: InputMode.NUMBER,
      minDate: getSubYears(),
      maxDate: getSubDay(),
      validators: [Validators.required]
    }),
    new Dropdown({
      key: 'nationality',
      label: 'Nacionalidade',
      disabled: readOnlyOption,
      options: Object.values(Nationality).map((nationality) => {
        return {
          key: nationality,
          value: nationality,
        };
      }),
      validators: [Validators.required]
    }),
  ];
}

export function getProprietaryStateForm(readOnlyOption: boolean): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'state',
      label: 'Estado',
      readonly: readOnlyOption,
      options: Object.values(UF).map((uf) => {
        return {
          key: uf,
          value: uf,
        };
      }),
      validators: [Validators.required],
    }),
    new Textbox({
      key: 'naturalidade',
      label: 'Naturalidade',
      readonly: readOnlyOption,
      validators: [Validators.required]
    }),
  ];
}

export function getProprietaryMaritalStatusForm(
  pipeMaritalStatus: MaritalStatusPipe, readOnlyOption: boolean
): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'maritalStatus',
      label: 'Estado Civil',
      disabled: readOnlyOption,
      description: 'Estado civil',
      options: Object.values(MaritalStatus).map((maritalStatus) => {
        return {
          key: maritalStatus,
          value: pipeMaritalStatus.transform(maritalStatus),
        };
      }),
      validators: [Validators.required]
    }),
  ];
}

export function getProprietaryPropertyRegimeForm(pipeMatrimonialRegime: MatrimonialRegimePipe, readOnlyOption: boolean): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'matrimonialRegime',
      label: 'Regime de bens',
      disabled: readOnlyOption,
      options: Object.values(MatrimonialRegime).map((matrimonialRegime) => {
        return {
          key: matrimonialRegime,
          value: pipeMatrimonialRegime.transform(matrimonialRegime),
        };
      }),
      validators: [Validators.required]
    }),
    new Datetime({
      key: 'weddingDate',
      label: 'Data de casamento',
      readonly: readOnlyOption,
      type: 'date',
      minDate: getSubYears(),
      maxDate: getSubDay(),
      inputMode: InputMode.NUMBER,
      validators: [Validators.required]
    }),
  ];
}

export function getProprietaryProfessionForm(readOnlyOption: boolean): ElementBase<string>[] {
  return [
    new Textbox({
      key: 'profession',
      label: 'Profissão',
      readonly: readOnlyOption,
      description: 'Profissão e renda familiar',
      validators: [Validators.required]
    }),
    new Textbox({
      key: 'familyIncome',
      label: 'Renda Familiar',
      readonly: readOnlyOption,
      type: 'tel',
      inputMode: InputMode.CURRENCY,
      mask: InputMode.CURRENCY,
      validators: [Validators.required]
    }),
  ];
}
