import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import {
  AlteracaoDto,
  GetUpdateOwnerContactDto,
  RegistrationStatus,
  TipoAlteracao
} from '@usucampeao/lib-reurb-simplificado';
import { ElementBase, Inputmask, toFormGroup } from '@usucampeao/ui-mobile';
import { MaskPipe } from 'ngx-mask';
import { of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { LoadingService } from '../../../../../services/loading.service';
import { ToastService } from '../../../../../services/toast.service';
import { AuthQuery } from '../../../../auth/state/auth.query';
import { RegistrationService } from '../../../state/registration.service';
import { OwnersQuery } from '../../state/owners.query';
import { OwnersService } from '../../state/owners.service';
import { NavigationService } from './../../../../../services/navigation.service';
import { RegistrationQuery } from './../../../state/registration.query';
import { getProprietaryContactForm } from './contact.form';

@Component({
  selector: 'usucampeao-contact',
  templateUrl: 'contact.page.html',
  styleUrls: ['contact.page.scss'],
})
export class ContactPage
  implements OnInit, ViewWillEnter, ViewWillLeave, OnDestroy {
  form$: ElementBase<string>[] | null = [];
  form!: FormGroup;
  payLoad = '';
  isMain = true;
  private ownerId: string;
  private registrationId: string;
  private ngUnsubscribe = new Subject();
  fluxo: boolean;
  public blockAccess = false;
  public alteracoes: AlteracaoDto[] = [];

  constructor(
    private loaderService: LoadingService,
    private navigationService: NavigationService,
    private ownersQuery: OwnersQuery,
    private ownerService: OwnersService,
    private router: Router,
    private route: ActivatedRoute,
    public authQuery: AuthQuery,
    private registrationQuery: RegistrationQuery,
    private registrationService: RegistrationService,
    private maskPipe: MaskPipe,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.form$ = getProprietaryContactForm(false);
    this.form = toFormGroup(getProprietaryContactForm(false) as ElementBase<string>[]);
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        const registration = this.registrationQuery.getValue();
        const status = registration.entities[params.id];
        this.validateStatus(status.status);
        this.ownerId = params.ownerId;
        this.registrationId = params.id;
        this.getContactData();
      });

    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.fluxo = params['fluxo'];
      });

    this.ownersQuery.selectLoading()
      .pipe(
        distinctUntilChanged(),
        finalize(async () => await this.loaderService.dismiss()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async loading => {
        if (loading) {
          await this.loaderService.createLoader();
        } else {
          await this.loaderService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  /**
   * Valida o form e atualiza os dados
   */
  public async onSubmit(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      await this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
      return;
    }

    this.updateContactData();
  }

  /**
   * Busca os dados de documento do proprietário.
   */
  private getContactData(): void {
    this.ownersQuery.selectEntity(this.ownerId)
      .pipe(
        take(1),
        switchMap((owner) => {
          this.isMain = owner.main;
          if (owner.contactDataLoaded) {
            return of(GetUpdateOwnerContactDto.from(owner));
          }
          return this.ownerService.getContactData(this.registrationId, this.ownerId);
        }),
        tap((contactData) => {
          this.form.get('email').setValue(contactData?.email);
          if (this.isMain) {
            const userCellPhone = this.authQuery.getValue().phone;
            const cellphone = this.maskPipe.transform(userCellPhone.replace(/^\D+/g, ''), Inputmask('telefone'));
            this.form.get('cellphone').setValue(cellphone);
          } else {
            if (contactData?.phones?.cellphone) {
              const cellphone = this.maskPipe.transform(contactData?.phones?.cellphone.replace(/^\D+/g, ''), Inputmask('telefone'));
              this.form.get('cellphone').setValue(cellphone);
            }
          }
          const home = this.maskPipe.transform(contactData?.phones?.home, Inputmask('telefone'));
          this.form.get('home').setValue(home);
          const message = this.maskPipe.transform(contactData?.phones?.message, Inputmask('telefone'));
          this.form.get('message').setValue(message);
          this.form.get('contactName').setValue(contactData?.contactName);
        }),
        switchMap(() => this.registrationService.buscaAlteracoesCadastro(this.registrationId, TipoAlteracao.PROPRIETARIO_DADOS_CONTATO)),
        tap(alteracoes => this.alteracoes = (alteracoes || []).filter(alteracao => alteracao.proprietarioId === this.ownerId)),
        catchError(async () => await this.toastService.erro('Erro ao buscar dados de contato. Tente novamente mais tarde.'))
      ).subscribe();
  }

  /**
   * Atualiza dados do documento
   */
  private updateContactData(): void {
    const documentsData: GetUpdateOwnerContactDto = {
      email: this.form.get('email').value,
      phones: {
        cellphone: (this.form.get('cellphone').value as string)?.replace(/\D/g, ''),
        message: (this.form.get('message').value as string)?.replace(/\D/g, ''),
        home: (this.form.get('home').value as string)?.replace(/\D/g, ''),
      },
      contactName: this.form.get('contactName').value
    };

    this.ownerService.updateContactData(this.registrationId, this.ownerId, documentsData)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        () => {
          if (this.fluxo) {
            this.router.navigate([`../envio-documentos`], { relativeTo: this.route, queryParams: { fluxo: true } });
          } else {
            this.navigationService.goBack();
          }
        },
        async () => await this.toastService.erro('Erro ao atualizar dados de contato. Tente novamente mais tarde.'))
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro, a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
      this.form$ = getProprietaryContactForm(true);
      this.form = toFormGroup(getProprietaryContactForm(true) as ElementBase<string>[]);
    }
  }
}
