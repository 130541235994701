import { RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { Attendance } from './../interfaces/attendance.interface';

export function getIconName(status: RegistrationStatus): string {

  switch (status) {
    case RegistrationStatus.AVAILABLE_SCHEDULE:
    case RegistrationStatus.VALIDATION_SCHEDULED:
    case RegistrationStatus.MISSED_SCHEDULE:
    case RegistrationStatus.DELIVERY_SCHEDULED:
      return 'calendar-sharp';

    case RegistrationStatus.INCOMPLETE:
    case RegistrationStatus.CHANGES_REQUESTED:
      return 'warning-sharp';

    case RegistrationStatus.READY_TO_SUBMIT:
      return 'send-sharp';

    case RegistrationStatus.IN_ANALYSIS:
      return 'information-circle-sharp';

    case RegistrationStatus.VALIDATED:
      return 'checkmark-circle-sharp';

    case RegistrationStatus.PUBLISHED_OFFICIAL_JOURNAL:
      return 'newspaper-sharp';

    case RegistrationStatus.REGISTERED:
      return 'folder-sharp';

    case RegistrationStatus.DELIVERED_TITLE:
      return 'ribbon-sharp';

    default:
      return 'checkmark-circle-sharp';
  }
}

export function getStatusTitle(status: RegistrationStatus): string {

  switch (status) {
    case RegistrationStatus.AVAILABLE_SCHEDULE:
      return 'Agende a validação';
    case RegistrationStatus.VALIDATION_SCHEDULED:
      return 'Visita em ';
    case RegistrationStatus.MISSED_SCHEDULE:
      return 'Visita perdida';
    case RegistrationStatus.INCOMPLETE:
      return 'Cadastro incompleto';
    case RegistrationStatus.CHANGES_REQUESTED:
      return 'Alterações necessárias';
    case RegistrationStatus.READY_TO_SUBMIT:
      return 'Envie cadastro preenchido';
    case RegistrationStatus.IN_ANALYSIS:
      return 'Cadastro em análise';
    case RegistrationStatus.VALIDATED:
      return 'Cadastro validado';
    case RegistrationStatus.DELIVERED_TITLE:
      return 'Título emitido';
    case RegistrationStatus.PUBLISHED_OFFICIAL_JOURNAL:
      return 'Publicado no Diário Oficial';
    case RegistrationStatus.REGISTERED:
      return 'Registrado em cartório';

    default:
      return 'Status não definido';
  }
}

export function getStatusDescription(status: RegistrationStatus): string {

  switch (status) {
    case RegistrationStatus.AVAILABLE_SCHEDULE:
      return 'Agende a visita do nosso agente para validação presencial do seu cadastro';
    case RegistrationStatus.DELIVERY_SCHEDULED:
      return 'Nosso agente irá visitar o imóvel na data acima para validação do seu cadastro';
    case RegistrationStatus.MISSED_SCHEDULE:
      return 'Nosso agente visitou o imóvel em ' + ' às ' + ', mas não conseguiu realizar a validação do seu cadastro pelo seguinte motivo: ';
    case RegistrationStatus.INCOMPLETE:
      return 'Finalize o preenchimento do cadastro para prosseguir com o processo';
    case RegistrationStatus.CHANGES_REQUESTED:
      return 'Realize as alterações solicitadas para continuar com seu processo';
    case RegistrationStatus.READY_TO_SUBMIT:
      return 'Se não tiver mais alterações, envie-o para análise para continuar seu processo';
    case RegistrationStatus.IN_ANALYSIS:
      return 'Aguarde a análise do cadastro para prosseguir com o processo';
    case RegistrationStatus.VALIDATED:
      return 'Seu cadastro foi validado, agora aguarde a emissão do título do seu imóvel';
    case RegistrationStatus.DELIVERED_TITLE:
      return 'Parabéns! O título do seu imóvel foi emitido e você irá recebê-lo em breve';
    case RegistrationStatus.PUBLISHED_OFFICIAL_JOURNAL:
      return 'A regularização do seu imóvel foi publicada em' + '. Aguarde o registro em cartório';
    case RegistrationStatus.REGISTERED:
      return 'O título do seu imóvel foi registrado em' + '. Aguarde o agendamento da entrega';


    default:
      return 'Status não definido';
  }
}


export function getSimplifiedStatusText(status: RegistrationStatus): { buttonLabel: string | null, statusText: string } {
  switch (status) {
    case RegistrationStatus.INCOMPLETE:
      return {
        buttonLabel: 'Continuar',
        statusText: 'Continue preenchendo seu cadastro.'
      };
    case RegistrationStatus.READY_TO_SUBMIT:
      return {
        buttonLabel: 'Enviar para análise',
        statusText: 'Envie seu cadastro para pré-análise das informações e documentos para continuar seu processo.'
      };
    case RegistrationStatus.IN_ANALYSIS:
      return {
        buttonLabel: null,
        statusText: 'Aguarde a pré-análise das informações enviadas.'
      };
    case RegistrationStatus.CHANGES_REQUESTED:
      return {
        buttonLabel: 'Reenviar para análise ',
        statusText: 'Algumas alterações foram solicitadas para que seu processo possa continuar.'
      };
    case RegistrationStatus.AVAILABLE_SCHEDULE:
      return {
        buttonLabel: 'Continuar',
        statusText: 'Agende a validação presencial dos documentos.'
      };
    case RegistrationStatus.DELIVERY_SCHEDULED:
      return {
        buttonLabel: null,
        statusText: 'Aguarde a visita do nosso agente para validação presencial do seu cadastro.'
      };
    case RegistrationStatus.MISSED_SCHEDULE:
      return {
        buttonLabel: null,
        statusText: 'Aguarde a visita do nosso agente para validação presencial do seu cadastro.'
      };
    case RegistrationStatus.VALIDATED:
      return {
        buttonLabel: null,
        statusText: 'Aguarde a emissão do título do seu imóvel.'
      };
    case RegistrationStatus.DELIVERED_TITLE:
      return {
        buttonLabel: null,
        statusText: 'O título do seu imóvel foi emitido e você irá recebê-lo em breve.'
      };

    default:
      return {
        buttonLabel: null,
        statusText: ''
      };
  }
}
