<ion-content class="ion-no-padding ion-no-border login" scrollY="false">
    <div class="login__content" *ngIf="!showCodePage">
        <div class="login__background"></div>
        <div class="login__card">
            <div class="login-card ion-padding">
                <div class="login-card__container">
                    <span class="login-card__divider"></span>
                    <form mode="md" [formGroup]="form$" (ngSubmit)="submit()">
                        <ion-text class="title title-large"> Bem Vindo! </ion-text><br /><br />
                        <ion-text>
                            <p class="font-text ion-no-margin">
                                Acompanhe a regularização do seu imóvel. Para iniciar, digite o número do seu celular.
                            </p>
                        </ion-text>
                        <div *ngFor=" let field of fieldForm " class="form-row">
                            <usucampeao-build-form [field]="field " [form]="form$ "></usucampeao-build-form>
                        </div>
                        <ion-button expand="block " mode="md" type="submit">Entrar</ion-button>
                    </form>
                    <usucampeao-footer></usucampeao-footer>
                </div>
            </div>
        </div>
    </div>
    <div id="recaptcha-container"></div>

    <usucampeao-number-authentication *ngIf="showCodePage" (back)="backLoginPage()"></usucampeao-number-authentication>
</ion-content>
