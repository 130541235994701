import { IsBoolean, IsEnum, IsString } from 'class-validator';
import { DimensionamentoTipo } from './dimensionamento-tipo.enum';
import { FiacaoTipo } from './fiacao-tipo.enum';

export class InstalacaoEletrica {
  @IsBoolean()
  ligacaoRedePublica: boolean;

  @IsBoolean()
  possuiQuadroLuz: boolean;

  @IsEnum(FiacaoTipo)
  fiacao: FiacaoTipo;

  @IsBoolean()
  eletrodutosDeteriorados: boolean;

  @IsEnum(DimensionamentoTipo)
  dimensionamento: DimensionamentoTipo;

  @IsString()
  especifique: string;
}
