import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { IsArray, IsBoolean, IsDate, IsEnum, IsNotEmpty, IsNumber, IsString, ValidateIf } from 'class-validator';
import { PropertyAcquisitionDocument } from './property-acquisition-document.enum';
import { PropertyOccupationOpposition } from './property-occupation-opposition.enum';
import { PropertyOwnershipDocument } from './property-ownership-document.enum';

export class PropertyOccupationDto {
  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  hasAquisitionDocument: boolean;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!obj.hasAquisitionDocument && obj.aquisitionDocument) {
      return null;
    }
    return value;
  })
  @IsEnum(PropertyAcquisitionDocument)
  @IsNotEmpty()
  @ValidateIf((o: PropertyOccupationDto) => o.hasAquisitionDocument)
  aquisitionDocument?: PropertyAcquisitionDocument;

  @Expose()
  @Type(() => Date)
  @Transform(({ value, obj }) => {
    if (!obj.hasAquisitionDocument && obj.aquisitionDocumentDate) {
      return null;
    }
    return value;
  })
  @IsDate()
  @IsNotEmpty()
  @ValidateIf((o: PropertyOccupationDto) => o.hasAquisitionDocument)
  aquisitionDocumentDate?: Date;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  hasDocumentOwnership: boolean;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!obj.hasDocumentOwnership && obj.documentsOwnership) {
      return null;
    }
    return value;
  })
  @IsEnum(PropertyOwnershipDocument, { each: true })
  @IsArray()
  @IsNotEmpty()
  @ValidateIf((o: PropertyOccupationDto) => o.hasDocumentOwnership)
  documentsOwnership?: PropertyOwnershipDocument[];

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  currentResidentOccupationTime: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  oldResidentOccupationTime: number;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  hasContactOldResident: boolean;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  hasOtherProperty: boolean;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!obj.hasOtherProperty && obj.otherPropertyLocalization) {
      return null;
    }
    return value;
  })
  @IsString()
  @IsNotEmpty()
  @ValidateIf((o: PropertyOccupationDto) => o.hasOtherProperty)
  otherPropertyLocalization?: string;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!obj.hasOtherProperty && obj.usageOtherProperty) {
      return null;
    }
    return value;
  })
  @IsBoolean()
  @IsNotEmpty()
  @ValidateIf((o: PropertyOccupationDto) => o.hasOtherProperty)
  usageOtherProperty?: boolean;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  requestedCivilAction: boolean;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!obj.requestedCivilAction && obj.specifyCivilAction) {
      return null;
    }
    return value;
  })
  @IsString()
  @IsNotEmpty()
  @ValidateIf((o: PropertyOccupationDto) => o.requestedCivilAction)
  specifyCivilAction?: string;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  requestedOccupationOpposition: boolean;

  @Expose()
  @IsEnum(PropertyOccupationOpposition)
  @Transform(({ value, obj }) => {
    if (!obj.requestedOccupationOpposition && obj.requestedOccupationOppositionType) {
      return null;
    }
    return value;
  })
  @IsNotEmpty()
  @ValidateIf((o: PropertyOccupationDto) => o.requestedOccupationOpposition)
  requestedOccupationOppositionType?: PropertyOccupationOpposition;

  @Expose()
  @Transform(({ value, obj }) => {
    if (obj.requestedOccupationOppositionType !== PropertyOccupationOpposition.OTHER && obj.specifyOccupationOpposition) {
      return null;
    }
    return value;
  })
  @IsString()
  @IsNotEmpty()
  @ValidateIf((o: PropertyOccupationDto) => o.requestedOccupationOppositionType === PropertyOccupationOpposition.OTHER)
  specifyOccupationOpposition?: string;

  /**
   * Cria uma nova instância de PropertyOccupationDto a partir de dtos
   * @param props DTO de ocupação do imóvel
   * @returns nova instância de PropertyOccupationDto
   */
  static from(props?: PropertyOccupationDto): PropertyOccupationDto {
    return plainToInstance(PropertyOccupationDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
