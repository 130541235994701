import { ContactModule } from './../../../modal/contact/contact.module';


import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SelectHourPage } from './select-hour.page';
import { SelectHourPageRoutingModule } from './select-hour-routing.module';

@NgModule({
  imports: [
    SelectHourPageRoutingModule,
    CommonModule,
    ContactModule,
    FooterModule,
    HeaderModule,
    IonicModule,
  ],
  declarations: [SelectHourPage],
  providers: [DatePipe],
  exports: [SelectHourPage]
})
export class SelectHourPageModule { }
