import { IsBoolean, IsEnum, IsNumber, IsOptional } from 'class-validator';
import { CondicoesGerais } from '../condicoes-gerais.enum';
import { EstruturaTipoAcao } from './estrutura-tipo-acao.enum';
import { EstruturaTipo } from './estrutura-tipo.enum';

export class Estrutura {
  @IsEnum(EstruturaTipo)
  tipo: EstruturaTipo;

  @IsBoolean()
  situacaoRisco: boolean;

  @IsEnum(EstruturaTipoAcao)
  @IsOptional()
  tipoAcao: EstruturaTipoAcao;

  @IsNumber()
  numeroComodos: number;

  @IsNumber()
  numeroMoradorComodo: number;

  @IsNumber()
  numeroBanheiroExclusivo: number;

  @IsEnum(CondicoesGerais)
  condicoesGerais: CondicoesGerais;
}
