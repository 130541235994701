export enum IncomeBracket {
  /**
 * Family income of up to 2 times the minimum wage.
 */
  ATE_2_MINIMOS = 'ATE_2_MINIMOS',
  /**
   * Family income between 2 and 3 times the minimum wage.
   */
  DE_2_A_3_MINIMOS = 'DE_2_A_3_MINIMOS',
  /**
   * Family income between 3 and 5 times the minimum wage.
   */
  DE_3_A_5_MINIMOS = 'DE_3_A_5_MINIMOS',
  /**
   * Family income higher than 5 times the minimum wage.
   */
  ACIMA_5_MINIMOS = 'ACIMA_5_MINIMOS',
}
