<ion-item [detail]="navigation" class="ion-no-padding ion-margin-bottom">
  <ion-badge *ngIf="notificationNumber > 0" color="primary" mode="md" slot="end">{{ notificationNumber || "" }}
  </ion-badge>
  <ion-icon color="medium" [name]="icon" class="ion-padding-bottom" *ngIf="icon"></ion-icon>
  <div class="ion-padding-bottom ion-padding-start" *ngIf="subtitle && title">
    <ion-text>
      <h2 class="font-text font-text--large ion-no-margin">{{title}}</h2>
    </ion-text>
    <ion-text class="description-text">
      {{subtitle}}
    </ion-text>
  </div>
  <div *ngIf="!subtitle" class="padding-top">
    <ion-text>
      <h2 class="font-text font-text--large ion-no-margin">{{title}}</h2>
    </ion-text>
  </div>
</ion-item>