import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public auth: AngularFireAuth) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getCurrentIdToken()).pipe(
      mergeMap(token => {
        if (!request.url.includes('http')) {
          request = request.clone({
            url: environment.api.baseUrl + request.url,
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }

        return next.handle(request);
      })
    )
  }

  getCurrentIdToken() {
    return new Promise((resolve, reject) => {
      const auth = firebase.auth();
      const unsubscribe = auth.onIdTokenChanged(user => {
        unsubscribe();
        if (user) {
          user.getIdToken().then(token => {
            resolve(token);
          });
        } else {
          reject(null);
        }
      }, reject);
    });
  }
}
