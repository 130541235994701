import { ProjetoHistoricoTipo } from './projeto-historico-tipo.enum';

export class ProjetoHistoricoDto {
  tipo: ProjetoHistoricoTipo;
  usuarioId: string;
  usuarioNome: string;
  dataCriacao: Date;

  constructor(
    tipo: ProjetoHistoricoTipo,
    usuarioId: string,
    usuarioNome: string,
    dataCriacao: Date,
  ) {
    this.tipo = tipo;
    this.usuarioId = usuarioId;
    this.usuarioNome = usuarioNome;
    this.dataCriacao = dataCriacao;
  }
}
