import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Gender } from '@usucampeao/lib-reurb-simplificado';
import {
  Datetime,
  Dropdown,
  ElementBase,
  InputMode,
  Textbox,
  toFormGroup,
  validateCPF
} from '@usucampeao/ui-mobile';
import { getSubYears } from '../../../../services/shared.function.service';
import { ToastService } from '../../../../services/toast.service';
import { InformationAlertModal } from '../../../modal/information-alert/information-alert.modal';
import { AuthQuery } from '../../state/auth.query';
import { AuthService } from '../../state/auth.service';
import { PrivacyPolicyPage } from './privacy-policy/privacy-policy.page';

@Component({
  selector: 'usucampeao-create-account',
  templateUrl: 'create-account.page.html',
  styleUrls: ['create-account.page.scss'],
})
export class CreateAccountPage implements OnInit {
  form$: ElementBase<string>[] | null = [];
  form!: FormGroup;
  public aceitePrivacidade = false;

  constructor(
    private authQuery: AuthQuery,
    private authService: AuthService,
    private modalController: ModalController,
    public router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.form$ = this.getForm();
    this.form = toFormGroup(this.getForm() as ElementBase<string>[]);
  }

  getForm() {
    const questions: ElementBase<string>[] = [
      new Textbox({
        key: 'name',
        label: 'Nome completo',
        validators: [Validators.required, Validators.pattern('^[A-zÀ-ú ]*$')]
      }),
      new Textbox({
        key: 'cpf',
        label: 'CPF',
        mask: 'cpf',
        inputMode: InputMode.NUMBER,
        validators: [Validators.required, validateCPF]
      }),
      new Datetime({
        key: 'birthDate',
        label: 'Data de nascimento',
        type: 'date',
        minDate: getSubYears(100),
        maxDate: getSubYears(18),
        validators: [Validators.required]
      }),
      new Dropdown({
        key: 'gender',
        label: 'Gênero',
        options: [
          { key: Gender.MASCULINE, value: 'Masculino' },
          { key: Gender.FEMININE, value: 'Feminino' },
          { key: Gender.OTHERS, value: 'Outro' },
        ],
        validators: [Validators.required]
      }),
      new Textbox({
        key: 'email',
        label: 'E-mail',
        inputMode: InputMode.EMAIL,
        type: 'email',
        validators: [Validators.email]
      }),
    ];
    return questions;
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
      return;
    }
    if (!this.aceitePrivacidade) {
      this.presentPrivacyAlertModal();
      return;
    }
    this.navigateToPhoto();
  }

  private async navigateToPhoto(): Promise<void> {
    const data = this.authQuery.getValue();
    try {
      const user = await this.authService.createAccount({
        ...this.form.value,
        phone: data.phone.replace(/\D/g, ''),
      });
      if (user) {
        this.router.navigate([`tirar-foto`]);
      } else {
        await this.toastService.erro('Erro ao carregar dados da conta. Por favor tente novamente.');
      }
    } catch (error) {
      await this.toastService.erro('Erro na criação de conta. Tente novamente mais tarde.');
    }
  }

  private async presentModal() {
    const modal = await this.modalController.create({
      component: PrivacyPolicyPage,
    });
    return await modal.present();
  }

  private async presentPrivacyAlertModal() {
    const modal = await this.modalController.create({
      component: InformationAlertModal,
      cssClass: 'tiny-modal',
      componentProps: {
        title: 'Atenção',
        text:
          'Você precisa aceitar a política de privacidade para continuar com a criação da conta.',
        clearButton: 'Entendi',
        clearButtonRota: 'dismiss',
      },
    });
    return await modal.present();
  }

  navigateToPrivacyPolicy() {
    this.presentModal();
  }
}
