<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
    <ion-text>
        <p class="title">Outro bairro</p>
    </ion-text>
    <ion-text class="font-text">
        <p>Infelizmente no momento o seu bairro não está disponível na plataforma.</p>
        <p class="ion-padding-top">Para mais informações envie um e-mail para
            <a href="mailto:ajuda@poderegularizar.com.br" target="_blank">ajuda@poderegularizar.com.br</a>.</p>
    </ion-text>
    <ion-grid>
        <ion-row class="ion-justify-content-end">
            <ion-col size="auto">
                <a (click)="dismiss()"><b>Entendi</b></a>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
