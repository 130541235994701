import { IsEnum, IsNumber, IsString } from 'class-validator';
import { UF } from '../enums';

export class CityDto {
  @IsNumber()
  id: number;

  @IsString()
  name: string;

  @IsEnum(UF)
  state: UF;

  @IsString()
  satateDescription: string;

  @IsNumber()
  stateId: number;
}
