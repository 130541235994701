import { IntegrationOmieStatus } from './integration-omie-status.enum';
import { IsDateString, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { PaymentStatus } from './../payment/payment-status.enum';

export class IntegrationOmieAccountReceivableDto {
  @IsString()
  @IsNotEmpty()
  id: string;

  @IsNumber()
  @IsOptional()
  omieId?: number;

  @IsNumber()
  @IsNotEmpty()
  amount: number;

  @IsEnum(PaymentStatus)
  @IsNotEmpty()
  paymentStatus: PaymentStatus;

  @IsEnum(IntegrationOmieStatus)
  @IsNotEmpty()
  status: IntegrationOmieStatus;

  @IsDateString()
  @IsOptional()
  payDay?: Date;

  @IsDateString()
  @IsOptional()
  dueDate?: Date;

  @IsNumber()
  @IsNotEmpty()
  installment: number;

  @IsNumber()
  @IsNotEmpty()
  totalInstallment: number;

  @IsString()
  @IsNotEmpty()
  attendanceId: string;

  @IsDateString()
  @IsOptional()
  createdAt?: Date;

  @IsDateString()
  @IsOptional()
  updatedAt?: Date;
}
