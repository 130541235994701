import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { MaritalStatus, OwnerDocument, OwnerDto } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OwnersQuery } from '../../../state/owners.query';
import { OwnersService } from '../../../state/owners.service';
import { DocumentsOwnerQuery } from '../state/query';
import { NavigationService } from './../../../../../../services/navigation.service';

@Component({
  selector: 'usucampeao-document-type',
  templateUrl: 'document-type.page.html',
  styleUrls: ['document-type.page.scss'],
})
export class DocumentTypePage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  public type = 'all';
  public proprietario: OwnerDto;
  private ngUnsubscribe = new Subject();

  constructor(
    private propritarioService: OwnersService,
    private proprietarioQuery: OwnersQuery,
    private router: Router,
    private route: ActivatedRoute,
    private documentsOwnerQuery: DocumentsOwnerQuery,
    private navigationService: NavigationService
  ) {

  }

  public get tipoDocumentoProprietario(): typeof OwnerDocument {
    return OwnerDocument;
  }

  ionViewWillEnter(): void {
    this.route.params.pipe(
      first(),
      tap((params) => this.type = params.type),
      tap(params => this.carregarDadosBasicosProprietario(params.id, params.ownerId)),
      switchMap(params => this.proprietarioQuery.selectEntity(params.ownerId)),
      tap(proprietario => this.proprietario = proprietario),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  private carregarDadosBasicosProprietario(cadastroId: string, id: string): void {
    this.propritarioService.getPersonalData(cadastroId, id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).subscribe();
  }

  navigateBack(): void {
    this.navigationService.goBack();
  }

  navigateToPhoto(type: string): void {
    this.router.navigate(['foto/', type, 'novo'], { relativeTo: this.route, replaceUrl: true });
  }

  public documentoEnviado(tipoDocumento: OwnerDocument): boolean {
    const data = this.documentsOwnerQuery.getValue().data;
    return data.some((v) => v.type === tipoDocumento);
  }

  public semDocumentosIdentidade(): boolean {
    return this.documentoEnviado(this.tipoDocumentoProprietario.CPF) &&
      this.documentoEnviado(this.tipoDocumentoProprietario.RG) &&
      this.documentoEnviado(this.tipoDocumentoProprietario.CNH)
  }

  public semDocumentosEstadoCivil(): boolean {
    return this.documentoEnviado(this.tipoDocumentoProprietario.BIRTH_CERTIFICATE) &&
      this.documentoEnviado(this.tipoDocumentoProprietario.DEATH_CERTIFICATE) &&
      this.documentoEnviado(this.tipoDocumentoProprietario.DIVORCE_CERTIFICATE) &&
      this.documentoEnviado(this.tipoDocumentoProprietario.MARRIAGE_CERTIFICATE)
  }


  public mostrarItem(tipoDocumento: OwnerDocument): boolean {
    switch (tipoDocumento) {
      case OwnerDocument.DEATH_CERTIFICATE:
        return this.proprietario.maritalStatus === MaritalStatus.WIDOWED;

      case OwnerDocument.DIVORCE_CERTIFICATE:
        return this.proprietario.maritalStatus === MaritalStatus.DIVORCED;

      case OwnerDocument.MARRIAGE_CERTIFICATE:
        return this.proprietario.maritalStatus !== MaritalStatus.SINGLE;

      case OwnerDocument.BIRTH_CERTIFICATE:
        return this.proprietario.maritalStatus === MaritalStatus.SINGLE;

      default:
        return true;
    }
  }
}
