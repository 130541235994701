export class ProjetoRelatorioResumoDto {
  totalLotes: number;
  contratosVendidos: number;
  vendasValor: number;
  parcelaQuantidadeMedia: number;
  parcelaValorMedia: number;

  constructor(
    totalLotes: number,
    contratosVendidos: number,
    vendasValor: number,
    parcelaQuantidadeMedia: number,
    parcelaValorMedia: number,
  ) {
    this.totalLotes = totalLotes;
    this.contratosVendidos = contratosVendidos;
    this.vendasValor = vendasValor;
    this.parcelaQuantidadeMedia = parcelaQuantidadeMedia;
    this.parcelaValorMedia = parcelaValorMedia;
  }
}
