import { TipoNotificacao } from "@usucampeao/lib-reurb-simplificado";

export enum NotificationSimplifiedReurb {
  DEVLIVERED_TITLE = 'TITULO_ENTREGUE',
  DEVLIVERED_COMPLETE = 'VENHA_BUSCAR_SEU_TITULO',
  DELIVERY_TITLES = 'ENTREGA_DOS_TITULOS',
  REGISTRED_CANCEL = 'CADASTRO_ENCERRADO',
  REGISTRED = 'REGISTRADO_EM_CARTORIO',
  PUBLISHED = 'PUBLICADO_PELA_PREFEITURA',
  READY_TO_SEND = 'CADASTRO_VALIDADO_COM_SUCESSO',
  AVAILABLE_SCHEDULE = 'AGENDAMENTO_DISPONIVEL',
  NECESSARY_CHANGES = 'ALTERACOES_NECESSARIAS',
  SCHEDULED = 'AGENDADO',
  MISSED_SCHEDULE = 'VISITA_PERDIDO',
  VALIDATED = 'VALIDADO',
  MISSED_RESGISTRED = 'CADASTRO_DISPONIVEL',
  INCOMPLETE = 'PENDENCIAS_NO_CADASTRO',
  INCOMPLETE_PEN = 'PENDENCIAS_ENCONTRADAS',
}

export function getNotificationIconName(tipo: TipoNotificacao): string {

  switch (tipo) {
    case TipoNotificacao.AGENDAMENTO_DISPONIVEL:
    case TipoNotificacao.CADASTRO_AGENDADO:
      return 'calendar-sharp';

    case TipoNotificacao.VENCE_AMANHA:
    case TipoNotificacao.VENCE_PROXIMO:
      return 'barcode-sharp'

    case TipoNotificacao.ALTERACAO_NECESSARIA:
     return 'warning-sharp';

    case TipoNotificacao.CADASTRO_PRE_APROVADO:
      return 'checkmark-circle-sharp';

    case TipoNotificacao.BUSCAR_TITULO:
      return 'ribbon-sharp';

    /*   case TipoNotificacao.PUBLISHED:
      return 'newspaper-sharp';

      case TipoNotificacao.REGISTRED:
      return 'folder-sharp';

      case TipoNotificacao.REGISTRED_CANCEL:
      return 'close-circle-sharp';

      case TipoNotificacao.MISSED_RESGISTRED:
      return 'flag-sharp'; */

    default:
      return 'checkmark-circle-sharp';
  }
}

