import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { LotSelectionModal } from './lot-selection.modal';

@NgModule({
  declarations: [LotSelectionModal],
  imports: [
    CommonModule,
    IonicModule,
    UiMobileModule,
  ],
  exports:[LotSelectionModal],
})
export class LotSelectionInstructionModule {}
