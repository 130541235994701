<ion-content>
    <usucampeao-header mode="md" backRoute="/" title="Novo Cadastro"></usucampeao-header>
    <div class="content-vertical-space-between-allotment">
        <ion-grid class="ion-no-padding ion-padding-start ion-padding-end">
            <ion-row>
                <ion-col class="ion-padding-top">
                    <ion-text class="title title--large">
                        Código de acesso
                    </ion-text>
                    <br><br>
                    <ion-text class="font-text">
                        Para proteger todos os proprietários de imóveis do bairro<strong>
              {{allotment}} </strong>, é necessário digitar o código de acesso para iniciar o cadastro.
                    </ion-text>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center ion-padding-top">
                <ion-col sizeMd="12" sizeLg="6">
                    <form mode="md" [formGroup]="form" (ngSubmit)="onSubmit()" class="ion-text-center">
                        <div *ngFor="let field of form$" class="form-row ion-no-padding">
                            <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
                        </div>
                        <ion-button class="ion-margin-top" mode="md" expand="block" type="submit">
                            Continuar
                        </ion-button>
                    </form>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="ion-text-center">
                    <br>
                    <ion-text class="font-text">
                        Não sabe o código de acesso? <b class="text-link" (click)="presentModalContact()">Entre em contato</b> com a liderança do bairro ou visite o
                        <ion-text color="primary"> <strong (click)="presentModalContactSupportPoint()">ponto de apoio</strong></ion-text> no bairro.
                    </ion-text>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
    <usucampeao-footer></usucampeao-footer>
</ion-content>
