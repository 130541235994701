import { ProcessProgressComponent } from './process-progress.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {UiMobileModule}from '@usucampeao/ui-mobile';
import { PipesModule } from '../../pipes/pipes.module';



@NgModule({
  imports: [CommonModule,
            UiMobileModule,
            IonicModule,
            PipesModule,
          ],
  declarations: [ProcessProgressComponent],
  exports: [ProcessProgressComponent],
})
export class ProcessProgressModule {}
