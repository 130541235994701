import { DispositivoStatus } from '.';

export class DispositivoDto {
  id: string;
  token: string;
  navegador: string;
  status: DispositivoStatus;
  dataCriacao: Date;
  dataAtualizacao: Date;
}
