import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../../../../services/loading.service';
import { ToastService } from '../../../../../services/toast.service';
import { PropertyService } from '../../../../registration/property/state/property.service';
import { RegisterQuery } from '../../../state/register.query';

@Component({
  selector: 'usucampeao-register-photo',
  templateUrl: 'lot-photo.page.html',
  styleUrls: ['lot-photo.page.scss'],
})
export class LotPhotoPage implements ViewWillEnter, ViewWillLeave {
  public photo: File;
  private ngUnsubscribe = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private registerQuery: RegisterQuery,
    private propertyService: PropertyService,
    private loadingService: LoadingService,
    private toastService: ToastService
  ) { }

  ionViewWillEnter(): void {
    this.registerQuery.selectLoading()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(async isLoading => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Seta a foto no component
   * @param photo foto selecionada
   */
  public setImage(photo: File): void {
    this.photo = photo;
  }

  /**
   * Atualiza a foto da fachada do imóvel
   */
  public async updateFacadePhoto(): Promise<void> {
    this.registerQuery.ids$
      .pipe(
        switchMap(response => this.propertyService.updateFacadePhoto(response.registrationId, response.propertyId, this.photo)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        () => this.router.navigate([`endereco`], { relativeTo: this.route }),
        async () => await this.toastService.erro('Erro ao atualizar foto da fachada do imóvel. Por favor tente novamente.')
      );
  }

  public skipPhoto(): void {
    this.router.navigate([`endereco`], { relativeTo: this.route })
  }
}
