import { Validators } from '@angular/forms';
import { Textbox, ElementBase, InputMode } from '@usucampeao/ui-mobile';

export function getProprietaryContactForm(readOnlyOption: boolean): ElementBase<string>[] {
  return [
    new Textbox({
      key: 'email',
      label: 'E-mail',
      readonly: readOnlyOption,
      inputMode: InputMode.EMAIL,
      type: 'email',
      validators: [Validators.email]
    }),
    new Textbox({
      key: 'cellphone',
      label: 'Telefone celular',
      readonly: readOnlyOption,
      mask: 'telefone',
      inputMode: InputMode.PHONE,
      type: 'tel',
      validators: [Validators.required, Validators.minLength(15), Validators.maxLength(15)]
    }),
    new Textbox({
      key: 'home',
      label: 'Telefone residencial',
      readonly: readOnlyOption,
      mask: 'telefone',
      inputMode: InputMode.NUMBER,
      type: 'tel',
      validators: [Validators.minLength(14), Validators.maxLength(15)]
    }),
    new Textbox({
      key: 'message',
      label: 'Telefone recado',
      readonly: readOnlyOption,
      mask: 'telefone',
      inputMode: InputMode.NUMBER,
      type: 'tel',
      validators: [Validators.minLength(14), Validators.maxLength(15)]
    }),
    new Textbox({
      key: 'contactName',
      label: 'Nome do contato para recado',
      readonly: readOnlyOption,
      validators: [Validators.required]
    }),
  ];
}
