import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DocumentsOwnerState, DocumentsOwnerStore } from './store';

@Injectable({ providedIn: 'root' })
export class DocumentsOwnerQuery extends Query<DocumentsOwnerState> {
 
  constructor(protected store: DocumentsOwnerStore) {
    super(store);
  }

}
