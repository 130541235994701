import { format } from 'date-fns';
import { ElementBase } from "./elements";

export class Textbox extends ElementBase<string> {
  controlType = 'textbox';
}

export class Dropdown extends ElementBase<string> {
  controlType = 'dropdown';
}

export class Datetime extends ElementBase<string> {
  controlType = 'date';
}
export class Radio extends ElementBase<string> {
  controlType = 'radio';
}
export class RadioList extends ElementBase<string> {
  controlType = 'radiolist';
}

export class TextArea extends ElementBase<string> {
  controlType = 'textarea';
}

export function Inputmask(mask: string) {
  switch (mask) {
    case 'cpf':
      return '000.000.000-00'
    case 'codenumber':
      return '000000'
    case 'telefone':
      return '(00) 00000-0000'
    case 'data':
      return "00/00/0000"
    case 'cnpj':
      return "99.999.999/9999-99";
    case 'cep':
      return "00000-000";
    case 'niver':
      return format(new Date(), 'dd/MM/YYYY');
    default:
      return ''
  }
}

export function InputMaskTypes(mask: string): RegExp {
  switch (mask) {
    case 'cpf':
      return /[\d\.-]/;
    case 'codenumber':
      return /\d/;
    case 'telefone':
      return /\d/;
    case 'data':
      return /[\d\/]/;
    case 'cnpj':
      return /[\d\.\/-]/;
    case 'cep':
      return /[\d-]/;
    case 'niver':
    default:
      return /./;
  }
}

export function InputMaskCurrencyFormatter(value: number): string {
  const str = `${value}`.replace(/\D/g, '');
  const totalCents = parseInt(str) || 0;
  const cents = totalCents % 100;
  const units = (totalCents - cents) / 100;
  let unitsStr = units.toString();
  const thousand = [];
  while (unitsStr.length) {
    thousand.unshift(unitsStr.substr(-3));
    unitsStr = unitsStr.substr(0, unitsStr.length - 3);
  }
  const formattedValue = thousand.join('.') + ',' + ('0' + cents).slice(-2);
  return `R$ ${formattedValue}`;
}
