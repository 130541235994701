import { Expose } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsString, ValidateIf } from 'class-validator';
import { TipoAlteracao } from './tipo-alteracao.enum';

const TIPOS_PROPRIETARIO = [
  TipoAlteracao.PROPRIETARIO_DADOS_BASICOS,
  TipoAlteracao.PROPRIETARIO_DADOS_CONTATO,
  TipoAlteracao.PROPRIETARIO_DADOS_DOCUMENTOS,
  TipoAlteracao.PROPRIETARIO_DOCUMENTOS
];
export class AlteracaoDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  descricao: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf((value: AlteracaoDto) => TIPOS_PROPRIETARIO.includes(value.tipo))
  proprietarioId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf((value: AlteracaoDto) => TIPOS_PROPRIETARIO.includes(value.tipo))
  proprietarioCadastroId?: string;

  @Expose()
  @IsEnum(TipoAlteracao)
  @IsNotEmpty()
  tipo: TipoAlteracao;
}
