export enum PaymentStatus {
  CREATED = 'CRIADO',
  ERROR = 'ERRO',
  ISSUE_PAYMENT_REQUESTED = 'SOLICITADO_EMISSAO_PAGAMENTO',
  PROGRAMMED = 'PROGRAMADO',
  PAID = 'PAGO',
  MANUAL = 'MANUAL',
  NOT_PAID = 'NAO_PAGO',
  CANCELED = 'CANCELADO',
  PAID_CANCELED = 'PAGO_CANCELADO'
}
