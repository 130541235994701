import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
    private navCtrl: NavController
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        filter(url => !this.history.slice(-1).includes(url)),
        tap(url => this.history.push(url))
      )
      .subscribe();
  }

  /**
   * Navega para rota anterior
   */
  public goBack(): void {
    this.history.pop();
    this.navCtrl.setDirection('back', true, 'back');
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigate(['home']);
    }
  }

  public clearHistoryNavigation(): void {
    this.history = []
  }
}
