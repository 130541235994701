import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { ModalController, ToastController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { DocumentFileSubtype, ProjetoTipo, PropertyAcquisitionDocument, PropertyOwnershipDocument } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoadingService } from '../../../../../services/loading.service';
import { NavigationService } from '../../../../../services/navigation.service';
import { AnotherDocumentModal } from '../../../../modal/another-document/another-document.modal';
import { RegisterService } from '../../../../register/state/register.service';
import { RegistrationQuery } from '../../../state/registration.query';
import { RegistrationService } from '../../../state/registration.service';
import { DocumentsPropertyQuery } from '../state/query';
import { JustificationPage } from './justification/justification.page';
@Component({
  selector: 'usucampeao-type',
  templateUrl: 'type.page.html',
  styleUrls: ['type.page.scss'],
})
export class TypePage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  public type = 'escolher';
  public acquisitionDocuments = Object.values(PropertyAcquisitionDocument).sort((a, b) => a.localeCompare(b));
  public ownershipDocuments = Object.values(PropertyOwnershipDocument).sort((a, b) => a.localeCompare(b));
  public existingTypes = [];
  private id: string;
  private propertyId: string;
  private ngUnsubscribe = new Subject();


  constructor(
    private loaderService: LoadingService,
    private modalController: ModalController,
    private navigationService: NavigationService,
    private propertyDocumentQuery: DocumentsPropertyQuery,
    private registrationQuery: RegistrationQuery,
    private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastController: ToastController,
    private registerService: RegisterService,

  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        first(),
        tap(params => this.id = params.id),
        tap(params => this.propertyId = params.propertyId),
        tap(params => this.type = params.documentType),
        switchMap(() => this.registrationQuery.selectEntity(this.id)),
        switchMap(cadastro => this.registerService.buscarDetalheProjeto(cadastro.projetoFid)),
        tap(projeto => {
          if (projeto.tipo === ProjetoTipo.AVERBACAO) {
            this.ownershipDocuments = [PropertyOwnershipDocument.PROPERTY_REGISTRATION];
            this.acquisitionDocuments = [];
          }
        }),
        tap(() => this.registrationService.getFilePropety(this.id, this.propertyId)),
        switchMap(() => this.propertyDocumentQuery.select('data')),
        tap(data => {
          this.existingTypes = data.map(doc => doc.type)
          // .filter(t => t !== PropertyAcquisitionDocument.OTHERS);// TODO: reabilitar filtro após atualizar back-end para permitir múltiplos "outros" documentos
        }),
        first(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => { },
        async (error) => {
          Bugsnag.notify('Tipo de documento do imovel - Erro ao buscar documentos', error)
          const toast = await this.toastController.create({
            message: 'Erro ao buscar informações. Tente novamente mais tarde.',
            duration: 5000,
          });
          toast.present();
        });

    this.registrationQuery.selectLoading()
      .pipe(
        finalize(async () => await this.loaderService.dismiss()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async isLoading => {
        if (isLoading) {
          await this.loaderService.createLoader();
        } else {
          await this.loaderService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public get documentFileSubtype(): typeof DocumentFileSubtype {
    return DocumentFileSubtype;
  }

  public get projetoTipo(): typeof ProjetoTipo {
    return ProjetoTipo;
  }

  public navigateBack(): void {
    this.navigationService.goBack();
  }

  async navigateToJustification(tipo: DocumentFileSubtype) {
    const modal = await this.modalController.create({
      component: JustificationPage,
      componentProps: {
        id: this.id,
        propertyId: this.propertyId,
        subtype: tipo,
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.navegar) {
      this.navigateBack();
    }
  }

  public navigateToPhoto(item: string) {
    if (item === PropertyAcquisitionDocument.OTHERS) {
      this.presentModalOthers();
    } else {
      this.router.navigate([`foto`, item, 'novo'], { relativeTo: this.route, replaceUrl: true });
    }

  }

  async presentModalOthers() {
    const modal = await this.modalController.create({
      component: AnotherDocumentModal,
      cssClass: 'smaller-modal',
    });
    await modal.present();
    const modalReturn = await modal.onDidDismiss();
    if (!modalReturn?.data) {
      return;
    }
    this.router.navigate([`foto`, PropertyAcquisitionDocument.OTHERS, 'novo'], { relativeTo: this.route, replaceUrl: true, queryParams: { nome: modalReturn.data } });
  }
}
