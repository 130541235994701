import { Endereco } from '@usucampeao/interfaces';
import { Expose, Type } from 'class-transformer';
import { IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { AddressDto } from '../address/address.dto';

export class CreatePropertyDto {
  @IsString()
  @IsOptional()
  id?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  projetoId: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  blockId: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  lotId: number;

  @Type(() => Endereco)
  @ValidateNested()
  @IsOptional()
  endereco?: AddressDto;

  constructor(projetoId: string, blockId: number, lotId: number, endereco?: AddressDto) {
    this.projetoId = projetoId;
    this.blockId = blockId;
    this.lotId = lotId;
    this.endereco = endereco;
  }
}
