import { ModalController } from '@ionic/angular';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'usucampeao-instruction',
  templateUrl: 'instruction.modal.html',
  styleUrls: ['instruction.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InstructionModal {
  constructor(public ModalController: ModalController, public router: Router) {}

  dismiss() {
    this.ModalController.dismiss();
  }

  navigateTo(rota: string): void {
    if (rota === 'dismiss') {
      this.dismiss();
    } else {
      this.dismiss();
      this.router.navigate([rota]);
    }
  }
}
