<div id="print-section" class="c-data-contract__text-terms">
    <div class="title">
        <h1>CONTRATO DE ADESÃO PARA A PRESTAÇÃO DE SERVIÇOS DE REGULARIZAÇÃO FUNDIÁRIA </h1>
    </div>
    <div class="personal-data">
        <table class="contract-table">
            <tr>
                <td>
                    <p>
                        Por este instrumento particular, de um lado,
                        <span style="font-weight: bold;">NOME:</span> {{ proprietario?.name }},
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>CPF:</strong> {{ proprietario?.cpf }},
                        <strong>RG:</strong> {{ proprietario?.rg?.number || '' }},
                        <strong>EMISSOR/UF:</strong> {{ proprietario?.rg?.issuer || '' }}/{{ proprietario?.rg?.state || ''}}
                    </p>
                </td>
            </tr>
            <tr *ngIf="(proprietario?.maritalStatus === maritalStatus.MARRIED) && conjuge ">
                <td>
                    <p>
                        <span style="font-weight: bold;">CÔNJUGE/COMPANHEIRO:</span> {{ conjuge?.name }},
                        <strong>CPF:</strong> {{ conjuge?.cpf }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Rua:</strong> {{ imovel?.address?.street }} Nº.{{ imovel?.address?.number }}
                        <ng-container *ngIf="imovel?.address?.complement">
                            <strong>Complemento: </strong> {{ imovel?.address?.complement }}
                        </ng-container>
                        <strong>Bairro: </strong> {{ imovel?.address?.neighborhood }} <strong>Cidade: </strong> {{ imovel?.address?.city }} <strong>UF: </strong> {{ imovel?.address?.state }}
                    </p>
                </td>
            </tr>
            <tr  *ngIf="cadastro?.reurbModalidade">
                <td>
                    <p>
                        <strong>Condições REURB:</strong> Renda Familiar menor R$ 5.225 e não ter sido beneficiado pela REURB anteriormente.
                        <br> (&nbsp;
                        <ng-container *ngIf="cadastro.reurbModalidade === reurbModalidade.REURB_S" >X
                        </ng-container>) REURB–S ou (&nbsp;
                        <ng-container *ngIf="cadastro.reurbModalidade === reurbModalidade.REURB_E">X
                        </ng-container>) REURB–E
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Qual o segmento de uso do imóvel:</strong> (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.RESIDENTIAL">X
                        </ng-container>) Residencial (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.COMMERCIAL">X
                        </ng-container>) Comercial (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.MIXED">X
                        </ng-container>) Misto
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Estado Civil:</strong> (&nbsp;
                        <ng-container *ngIf="proprietario?.maritalStatus === maritalStatus.MARRIED">X</ng-container>&nbsp;) Casado(a) (&nbsp;
                        <ng-container *ngIf="proprietario?.maritalStatus === maritalStatus.SINGLE">X</ng-container>&nbsp;) Solteiro(a) (&nbsp;
                        <ng-container *ngIf="proprietario?.maritalStatus === maritalStatus.WIDOWED">X</ng-container>&nbsp;) Viúvo(a) (&nbsp;
                        <ng-container *ngIf="proprietario?.maritalStatus === maritalStatus.SEPARATED">X</ng-container>&nbsp;) Separado(a)
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>E-mail: </strong> {{ proprietario?.email }} <strong> Telefone: </strong> {{ proprietario?.phones?.cellphone || ''}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="height: 20mm;">
                        <strong>Observações: </strong>
                    </p>
                </td>
            </tr>
        </table>
    </div>
    <div class="content">
        <p>
            Doravante
            <ng-container *ngIf="!conjuge"> denominado <strong>"CONTRATANTE"</strong></ng-container>
            <ng-container *ngIf="conjuge"> denominados <strong>"CONTRATANTES"</strong></ng-container>; e, de outro lado, <strong>USUCAMPEÃO TECNOLOGIA EM
    REGULARIZAÇÃO IMOBILIÁRIA LTDA.</strong>, pessoa jurídica de direito privado, com SEDE na Rua Claudio Soares 72, cj 614/618 Pinheiros, São Paulo – SP, inscrita no CNPJ/MF sob o nº 36.174.049/0001-08, e FILIAL Rua José Rodrigues Pereira 514, Estoril,
            Belo Horizonte – MG inscrita no CNPJ/MF <strong>36.174.049/0002-99</strong>, neste ato representada de acordo com seus atos constitutivos, doravante denominado
            <strong><u>"USUCAMPEÃO"</u></strong>,
            <!--  <ng-container *ngIf="allotment?.split_pagamento"> e
            <strong>{{allotment?.split_parametros?.parceiro_nome | uppercase}}</strong>, pessoa jurídica de direito privado, com sede no endereço {{allotment?.split_parametros?.parceiro_endereco}}, inscrita no CNPJ/MF sob o {{allotment?.split_parametros?.parceiro_documento
            | mask: 'CPF_CNPJ'}}, neste ato representada de acordo com seus atos constitutivos, doravante denominado <strong>“PARCEIRA”</strong>,
        </ng-container> celebram o presente  --><strong>CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE REGULARIZAÇÃO
    FUNDIÁRIA</strong>, regido pelas seguintes cláusulas:
            <div class="clausula">
                <p>
                    <strong>Cláusula Primeira – OBJETO</strong><br>
                    <strong>1.1.</strong> Este Contrato estabelece as condições pelas quais a <strong>USUCAMPEÃO</strong> prestará
                    <ng-container *ngIf="!conjuge">
                        ao <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge">
                        aos <strong>CONTRATANTES</strong>
                    </ng-container> serviços de regularização fundiária “REURB”, em três etapas, visando realizar os procedimentos necessários para obtenção da titulação da unidade imobiliária da qual
                    <ng-container *ngIf="!conjuge">
                        o <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge">
                        os <strong>CONTRATANTES</strong>
                    </ng-container> detém a posse, nos termos da Lei Federal 13.465/17 e Decreto Federal n° 9.310/2018.
                    <br>
                    <strong>1.1.1.</strong> Os serviços serão realizados conforme as seguintes etapas:
                    <br>
                    <strong>a.</strong> PRIMEIRA ETAPA: Coleta de dados e pré-análise - 10% (dez porcento) dos serviços;
                    <br>
                    <strong>b.</strong> SEGUNDA ETAPA: Engenharia, Memorial do Perímetro, Levantamento Planialtimétrico Cadastral do Núcleo e Projeto Urbanístico do Núcleo - 20% (vinte porcento) dos serviços;
                    <br>
                    <strong>c.</strong> TERCEIRA ETAPA: Protocolo REURB e entrega da Certidão de Regularização Fundiária (CRF) - 70% (setenta porcento) dos serviços.
                </p>
            </div>
            <div class="clausula">
                <p>
                    <strong>Cláusula Segunda – RESPONSABILIDADE DAS PARTES</strong><br>
                    <strong>2.1.</strong> São obrigações
                    <ng-container *ngIf="!conjuge">
                        do <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge">
                        dos <strong>CONTRATANTES</strong>
                    </ng-container> efetuar o pagamento na forma da Cláusula Terceira e fornecer todas as informações e proprietarios solicitados pela <strong>USUCAMPEÃO</strong> na execução dos Serviços.<br>
                    <strong>2.2.</strong> São obrigações da <strong>USUCAMPEÃO</strong> a execução dos Serviços constantes na Cláusula Primeira e no Anexo 1 (escopo do serviço).
                </p>
            </div>
            <div class="clausula">
                <p>
                    <strong>Cláusula Terceira – REMUNERAÇÃO E FORMA DE PAGAMENTO</strong><br>
                    <ng-container *ngIf="cadastro?.meioPagamento">
                        <strong>3.1.</strong> Pela prestação dos Serviços objeto deste Contrato,
                        <ng-container *ngIf="!conjuge">
                            o <strong>CONTRATANTE</strong> pagará
                        </ng-container>
                        <ng-container *ngIf="conjuge">
                            os <strong>CONTRATANTES</strong> pagarão
                        </ng-container>
                        a quantia <u>total</u> de {{ (cadastro?.valorContrato | currency: 'BRL') }} ({{(cadastro?.valorContrato)}}) a ser paga da seguinte forma:
                        <ng-container *ngIf="cadastro?.meioPagamento === meioPagamento.BOLETO">

                            <ng-container *ngIf="cadastro.valorEntrada && cadastro?.totalParcelas === 0">
                                No boleto à vista.
                            </ng-container>

                            <ng-container *ngIf="cadastro.valorEntrada && cadastro?.totalParcelas">
                                No boleto com entrada de {{ (cadastro.valorEntrada | currency: 'BRL') }} ({{ (cadastro.valorEntrada) }}) e em mais {{ cadastro?.totalParcelas }}x parcelas de {{ (cadastro.valorParcela | currency: 'BRL') }} ({{(cadastro.valorParcela) }}).
                            </ng-container>

                            <ng-container *ngIf="!cadastro.valorEntrada && cadastro?.totalParcelas">
                                No boleto em {{ cadastro?.totalParcelas }}x de {{ (cadastro.valorParcela | currency: 'BRL') }} ({{ (cadastro.valorParcela) }}).
                            </ng-container>

                        </ng-container>
                        <ng-container *ngIf="cadastro?.meioPagamento === meioPagamento.CARTAO_CREDITO">
                            No cartão de crédito em até {{ cadastro?.totalParcelas }}x.
                        </ng-container>
                        <!-- <ng-container *ngIf="allotment?.split_pagamento">
                        {{allotment?.split_parametros?.porcentagem}}% ({{convertNumber(allotment?.split_parametros?.porcentagem, false)}} por cento) deste valor será pago para a
                        <strong>PARCEIRA {{allotment?.split_parametros?.parceiro_nome}}</strong> e o restante para
                        <strong>USUCAMPEÃO.</strong>
                        <br>
                        <strong>3.1.1</strong> O valor pago para a <strong>USUCAMPEÃO</strong> deverá ser realizado da seguinte forma: mediante emissão de Nota Fiscal própria.
                        <ng-container *ngIf="allotment?.split_pagamento">
                            <br>
                            <strong>3.1.2</strong> O valor pago para a <strong>PARCEIRA</strong> deverá ser realizado da seguinte forma: mediante emissão de Nota Fiscal própria da Parceira.
                        </ng-container> -->
                    </ng-container>
                    <br>


                    <strong>3.2.</strong> Havendo impontualidade no cumprimento da obrigação
                    <ng-container *ngIf="!conjuge">
                        pelo <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge">
                        pelos <strong>CONTRATANTES</strong>
                    </ng-container>, este incorrerá em multa de 2% (dois por cento) sobre o valor em atraso corrigido e juros moratórios de 1% (um por cento) ao mês, sob o valor devido. <br>
                    <strong>3.3.</strong> Os direitos reais a serem atribuídos
                    <ng-container *ngIf="!conjuge">
                        ao <strong>CONTRATANTE</strong>, estarão definidos
                    </ng-container>
                    <ng-container *ngIf="conjuge"> aos <strong>CONTRATANTES</strong>, estarão definidos
                    </ng-container> na Certidão de Regularização Fundiária, a ser expedida pela Prefeitura, na forma do artigo 41 da lei 13.465/17. Para ser beneficiário dos citados direitos e receber a titulação de sua unidade imobiliária, o nome e a
                    unidade imobiliária
                    <ng-container *ngIf="!conjuge">do <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> dos <strong>CONTRATANTES</strong> </ng-container>
                    deverão constar na CRF, conforme artigo 41, inciso VI da lei 13.465/17. Assim sendo,
                    <ng-container *ngIf="!conjuge"> fica o <strong>CONTRATANTE</strong> ciente</ng-container>
                    <ng-container *ngIf="conjuge"> ficam os <strong>CONTRATANTES</strong> cientes</ng-container>
                    de que, tanto seu nome, como a descrição de sua unidade imobiliária, somente constarão da CRF após quitação total do preço pactuado no item 3.1. Com fulcro no parágrafo primeiro do artigo 14 da lei 13.465/17, a
                    <strong>USUCAMPEÃO</strong> entregará, para a Prefeitura, todos os projetos necessários e previstos nos artigos 35 e 36 da mesma lei, acompanhados da lista dos ocupantes de cada unidade imobiliária que tenham quitado o preço estipulado
                    no item 3.1; mais o memorial descritivo de cada unidade. Quando todos esses proprietarios técnicos estiverem devidamente concluídos, ANTES de encaminhar para a Prefeitura, a <strong>USUCAMPEÃO</strong>, entrará em contato com
                    <ng-container *ngIf="!conjuge"> o <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> os <strong>CONTRATANTES</strong> para oferecer
                    </ng-container>
                    <ng-container *ngIf="!conjuge"> para oferecer ao <strong> mesmo</strong>, as seguintes opções:
                    </ng-container>
                    <ng-container *ngIf="conjuge"> para oferecer aos <strong>mesmos</strong>, as seguintes opções:
                    </ng-container> <br>
                    <strong>3.3.1.</strong>&nbsp;<u>Efetuar a quitação total do preço pactuado.</u> Nesse caso,
                    <ng-container *ngIf="!conjuge">o nome do <strong>CONTRATANTE</strong> já constará
                    </ng-container>
                    <ng-container *ngIf="conjuge"> os nomes dos <strong>CONTRATANTES</strong> já constarão
                    </ng-container>
                    da primeira lista a ser a ser apresentada para a Prefeitura para inserir na CRF, a fim de que, após o registro da CRF, seja <u>aberta matrícula individualizada da unidade imobiliária em nome
      <ng-container *ngIf="!conjuge"> do <strong>CONTRATANTE</strong>.
      </ng-container>
      <ng-container *ngIf="conjuge"> dos <strong>CONTRATANTES</strong>.</ng-container>
    </u> <br>
                    <strong>3.3.2.</strong>&nbsp;<u>Continuar pagando o preço de forma parcelada,</u> como pactuado na assinatura do presente contrato. Nesse caso, <u>
      <ng-container *ngIf="!conjuge">o nome do <strong>CONTRATANTE</strong> somente será informado
      </ng-container>
      <ng-container *ngIf="conjuge"> os nomes dos <strong>CONTRATANTES</strong> somente serão
        informados
      </ng-container>
      para a Prefeitura após a quitação do preço total
    </u>, ou seja, após a finalização do parcelamento. Assim sendo, após essa finalização, a
                    <strong>USUCAMPEÃO</strong> informará a Prefeitura, solicitando que
                    <ng-container *ngIf="!conjuge">o nome do
                        <strong>CONTRATANTE</strong> seja inserido
                    </ng-container>
                    <ng-container *ngIf="conjuge"> os nomes dos <strong>CONTRATANTES</strong> sejam inseridos
                    </ng-container>
                    em lista complementar da CRF, na forma do artigo 23, §6º da lei 13.465/17 (cadastro complementar).<br>
                    <strong>3.3.3.</strong> Em ambos os casos, a unidade imobiliária será titulada em nome
                    <ng-container *ngIf="!conjuge">do <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> dos <strong>CONTRATANTES</strong>
                    </ng-container>, variando apenas o momento que essa titulação ocorrerá.<br>
                    <strong>3.3.4.</strong> Poderá, se entender viável, após análise técnica e financeira, dispensar a tramitação prevista nos itens 3.3, 3.3.1,3.3.2 e 3.3.3, já inserindo
                    <ng-container *ngIf="!conjuge"> o nome do <strong>CONTRATANTE</strong> </ng-container>
                    <ng-container *ngIf="conjuge"> os nomes dos <strong>CONTRATANTES</strong> </ng-container> na primeira lista a ser apresentada para a Prefeitura.<br>
                    <strong>3.4.</strong>
                    <ng-container *ngIf="!conjuge"> Fica o <strong>CONTRATANTE</strong> ciente</ng-container>
                    <ng-container *ngIf="conjuge"> Ficam os <strong>CONTRATANTES</strong> cientes </ng-container>
                    de que todos os projetos técnicos que serão apresentados para a Prefeitura, são de uso exclusivo da
                    <strong>USUCAMPEÃO</strong>, a qual detém os direitos autorais dos mesmos.
                </p>
            </div>
            <div class="clausula">
                <p>
                    <strong>Cláusula Quarta – RESCISÃO</strong><br>
                    <strong>4.1.</strong> Este Contrato poderá ser rescindido imotivadamente
                    <ng-container *ngIf="!conjuge"> pelo <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> pelos <strong>CONTRATANTES</strong> </ng-container>, desde que seja enviada notificação prévia, com antecedência mínima de 15 (quinze) dias, gerando as seguintes consequências:
                    <br>
                    <strong>4.1.1.</strong> Se a rescisão ocorrer por parte
                    <ng-container *ngIf="!conjuge"> do <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> dos <strong>CONTRATANTES</strong> </ng-container> durante a PRIMEIRA ETAPA citada no item 1.1.2.a, os seguintes valores deverão ser pagos à <strong>USUCAMPEÃO</strong>
                    <!-- <ng-container *ngIf="allotment?.split_pagamento"> e a <strong>PARCEIRA</strong> proporcionalmente (conforme percentual disposto no item 3.1)</ng-container>: -->
                    <br>
                    <strong>4.1.1.1.</strong> 20% (vinte porcento) do valor global do contrato por multa contratual;
                    <br>
                    <strong>4.1.1.2.</strong> 10% (dez porcento) do valor global do contrato referente aos serviços da primeira etapa.
                    <br>
                    <strong>4.1.2.</strong> Se a rescisão ocorrer por parte
                    <ng-container *ngIf="!conjuge"> do <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> dos <strong>CONTRATANTES</strong> </ng-container> durante a SEGUNDA ETAPA citada no item 1.1.2.2. , os seguintes valores deverão ser pagos à <strong>USUCAMPEÃO</strong>
                    <!-- <ng-container *ngIf="allotment?.split_pagamento"> e a <strong>PARCEIRA</strong> proporcionalmente (conforme percentual disposto no item 3.1)</ng-container>: -->
                    <br>
                    <strong>4.1.2.1.</strong> 20% (vinte porcento) do valor global do contrato por multa contratual;
                    <br>
                    <strong>4.1.2.2.</strong> 10% (dez porcento) do valor global do contrato referente aos serviços da primeira etapa;
                    <br>
                    <strong>4.1.2.3.</strong> 20% (vinte porcento) do valor global do contrato referente aos serviços prestados na segunda etapa.
                    <br>
                    <strong>4.1.3.</strong> Se a rescisão ocorrer por parte
                    <ng-container *ngIf="!conjuge"> do <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> dos <strong>CONTRATANTES</strong> </ng-container> após o protocolo de REURB junto a municipalidade, os seguintes valores deverão ser pagos à <strong>USUCAMPEÃO</strong>
                    <!--  <ng-container *ngIf="allotment?.split_pagamento"> e a <strong>PARCEIRA</strong> proporcionalmente (conforme percentual disposto no item 3.1)</ng-container>: -->
                    <br>
                    <strong>4.1.3.1.</strong> 20% (vinte porcento) do valor global do contrato por multa contratual;
                    <br>
                    <strong>4.1.3.2.</strong> 10% (dez porcento) do valor global do contrato referente aos serviços da primeira etapa;
                    <br>
                    <strong>4.1.3.3.</strong> 20% (vinte porcento) do valor global do contrato referentes aos serviços prestados na segunda etapa;
                    <br>
                    <strong>4.1.3.4.</strong> 20% (vinte porcento) do valor global do contrato referentes aos custos da terceira etapa.
                    <br>
                    <strong>4.2.</strong> No caso de rescisão por motivo de indeferimento do processo administrativo de REURB pela Prefeitura, <strong>por fato não atribuível à USUCAMPEÃO,</strong> não haverá a restituição de nenhum dos valores pagos
                    até o momento do indeferimento. A mesma condição se aplica em caso da negativa de registro do projeto de regularização fundiária por parte do Cartório de Registro de Imóveis, por fato também não atribuível à USUCAMPEÃO.
                    <br>
                </p>
            </div>
            <div class="clausula">
                <p>
                    <strong>Cláusula Quinta – DISPOSIÇÕES GERAIS</strong><br>
                    <strong>5.1.</strong> A <strong>USUCAMPEÃO</strong> poderá ceder o recebível para instituições financeiras, ficando expressamente autorizada para tanto
                    <ng-container *ngIf="!conjuge">pelo <strong>CONTRATANTE.</strong></ng-container>
                    <ng-container *ngIf="conjuge">pelos <strong>CONTRATANTES.</strong></ng-container>
                    <br>
                    <strong>5.2.</strong> Nenhuma das Partes poderá ser considerada como inadimplente de suas obrigações contratuais se tal fato for resultante de caso fortuito ou força maior.<br>
                    <strong>5.3.</strong> O Presente Contrato se refere exclusivamente ao serviço de regularização fundiária na esfera administrativa perante a Prefeitura.<br>
                    <strong>5.3.1.</strong> Não estão contempladas neste contrato, despesas com tributos inerentes à unidade imobiliária, taxas/preços públicos municipais e emolumentos de cartórios.
                    <br>
                    <strong>5.3.2.</strong> Não estão contemplados neste contrato, eventuais procedimentos judiciais que sejam necessários para a viabilização da regularização.
                    <br>
                    <strong>5.3.3.</strong> Conforme itens 3.3.1 e 3.3.2, a entrega da Certidão de Regularização Fundiária (CRF) está condicionada ao pagamento total dos serviços
                    <ng-container *ngIf="!conjuge"> pelo <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> pelos <strong>CONTRATANTES</strong> </ng-container>.
                    <br>
                </p>
            </div>
            <div class="clausulaSexta">
                <p>
                    <strong>Cláusula Sexta – FORO</strong><br>
                    <strong>6.1.</strong> Para dirimir quaisquer controvérsias oriundas deste Contrato, as Partes elegem o Foro da Comarca de São Paulo, Estado de São Paulo. E, por estarem assim, justas e contratadas, firmam o presente Contrato em 2 (duas)
                    vias de igual teor e forma, na presença das testemunhas abaixo, para que produza seus regulares efeitos de direito.
                </p>
                <p>&nbsp;</p>
                <p>{{ imovel?.address?.city }}, {{ cadastro?.contractDate | date: 'longDate'}}.</p>
            </div>
            <div class="subscription" *ngIf="isPrint">
              <div class="table">
                <div class="column">
                  <p>______________________________________________</p>
                  <p>CONTRATANTE/OUTORGANTE</p>
                  <div class="column__infos">
                    <p>Representante: {{ proprietario?.name }} </p>
                    <p>CPF: {{ proprietario?.cpf }}</p>
                  </div>
                </div>

                <div class="column column--no-border-top" *ngIf="conjuge">
                  <p>______________________________________________</p>
                  <p>CONTRATANTE/OUTORGANTE</p>
                  <div class="column__infos">
                    <p>Representante: {{ conjuge?.name }} </p>
                    <p>CPF: {{ conjuge?.cpf }}</p>
                  </div>
                </div>

               <!--  <div class="column column--no-border" *ngIf="!isMultipleResidents() && allotment?.split_pagamento"></div>

                <div class="column column--no-border-top" [class.no-border-left]="isMultipleResidents()"
                  *ngIf="allotment?.split_pagamento">
                  <p class="column__title">PARCEIRA</p>
                  <p>______________________________________________</p>
                  <p>{{ allotment?.split_parametros?.parceiro_nome }} </p>
                </div> -->

                <div class="column column--no-border-left">
                  <img class="assinaturaCeo" alt="Assinatura CEO" src="assets/img/assinatura.png">
                  <p>______________________________________________</p>
                  <p>EDUARDO GERMANO</p>
                  <div class="column__infos">
                    <p>USUCAMPEÃO - CEO</p>
                    <p>CNPJ: 36.174.049/0001-08</p>
                  </div>
                </div>

                <div class="column">
                  <p>______________________________________________</p>
                  <div class="column__infos">
                    <p>Testemunhas</p>
                    <p>CPF:</p>
                  </div>
                </div>

                <div class="column">
                  <p>______________________________________________</p>
                  <div class="column__infos">
                    <p>Testemunhas</p>
                    <p>CPF:</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="attachment">

                <h1>ANEXO I<br> ESCOPO DO SERVIÇO</h1>


                <p> <strong>1.1.</strong> Análise detalhada do imóvel e da posse, as medidas jurídicas, urbanísticas, ambientais e sociais com a finalidade de incorporar o imóvel informal ao ordenamento territorial urbano do município com a sua consequente
                    titulação. </p>
                <p><strong>1.2.</strong> Ingresso com processo administrativo de REURB, perante a municipalidade;</p>
                <p><strong>1.3.</strong> Elaboração do Projeto de Regularização Fundiária, conforme o artigo 30 do Decreto Federal nº 9.310 de 2018, e artigo 35 da Lei Federal 13.465 de 16 de julho de 2017:</p>
                <p> <strong>1.3.1.</strong> Levantamento planialtimétrico e cadastral com georreferenciamento, que demonstrará as unidades, as construções, o sistema viário, as áreas públicas, os acidentes geográficos e os demais elementos caracterizadores
                    do núcleo a ser regularizado;</p>
                <p><strong>1.3.2.</strong> Estudo das desconformidades e da situação jurídica, urbanística e ambiental;</p>
                <p> <strong>1.3.3.</strong> Projeto urbanístico;</p>
                <p><strong>1.3.4.</strong> Memorial descritivo do imóvel;</p>
                <p><strong> 1.3.5.</strong> Elaboração de cronograma físico de serviços e de obras de infraestrutura essencial, compensações urbanísticas, ambientais, com orientação referente às responsabilidades decorrentes da Assinatura de Termo de Compromisso
                    se for o caso;</p>
                <p> <strong>1.3.6.</strong> Toda a <strong><u>gestão de processo administrativo</u></strong> poderá ser acompanhada pelo site: <strong><u>www.usucampeao.com.br</u></strong> com as seguintes funcionalidades:</p>
                <p> <strong>1.3.6.1.</strong> Sistema de gestão seguro com <i>login</i> e senha na Plataforma digital;</p>
                <p> <strong>1.3.6.2.</strong> Possibilidade de realizar o cadastramento dos dados pessoais e da posse da propriedade;
                </p>
                <p> <strong>1.3.6.3.</strong> Serviços de mensagem para fornecer informações sobre o andamento do processo com os ocupantes;
                </p>
                <p> <strong>1.3.6.4.</strong> Serviços de inclusão de proprietarios pessoais e que comprovam a posse das propriedades;
                </p>
                <p>
                    <strong> 1.3.6.5.</strong> Sistema de meios de pagamento dos valores do serviço através da plataforma, através do cartão de crédito e boleto bancário;
                </p>

            </div>
    </div>
</div>
