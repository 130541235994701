import { Expose, plainToInstance } from 'class-transformer';
import { ProjetoConfigurarDetalhesComerciaisDto } from './projeto-configurar-detalhes-comerciais.dto';
import { ProjetoVendaStatus } from './projeto-venda-status.enum';
import { ProjetoDto } from './projeto.dto';

export class ProjetoDetalhesComerciaisDto extends ProjetoConfigurarDetalhesComerciaisDto {
  @Expose()
  vendaStatus: ProjetoVendaStatus;

  /**
   * Cria uma nova instância de ProjetoDetalhesComerciaisDto a partir de dtos
   * @param props DTO de ProjetoDetalhesComerciaisDto
   * @returns nova instância de ProjetoDetalhesComerciaisDto
   */
  static from(props?: ProjetoDto | Partial<ProjetoDto>): ProjetoDetalhesComerciaisDto {
    return plainToInstance(ProjetoDetalhesComerciaisDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
