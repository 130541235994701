<div class="content-vertical-space-between">
  <ion-grid class="ion-padding ion-no-margin">
    <ion-row
      class="ion-padding-top ion-padding-bottom ion-justify-content-center"
    >
      <ion-col class="ion-no-padding ion-padding-top" sizeXs="12" sizeSm="6">
        <ion-text class="title title--large">
          Confirme o código de telefone
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding-top ion-justify-content-center">
      <ion-col class="ion-no-padding" sizeXs="12" sizeSm="6">
        <ion-text class="font-text">
          Enviamos um SMS para o número
          <strong>{{ authQuery?.mePhoneNumber$ | async | TELEFONE }}</strong>,
          confirme o código enviado nos campos abaixo.
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding-top">
      <ion-col sizeMd="6" sizeLg="3" class="ion-no-padding ion-no-margin">
        <form mode="md" [formGroup]="form">
          <div *ngFor="let field of form$" class="form-row ion-no-padding">
            <usucampeao-build-form
              [field]="field"
              [form]="form"
            ></usucampeao-build-form>
          </div>
        </form>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding-top">
      <ion-col sizeMd="6" sizeLg="3">
        <ion-button mode="md" expand="block" (click)="onSubmit()"
          >Continuar</ion-button
        >
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-text class="font-text"><p>Não recebeu o SMS?</p> </ion-text>
        <ion-text class="text-link" (click)="reenviarCodigo()">
          <strong> Reenviar código </strong>
        </ion-text>
        ou
        <ion-text class="text-link" (click)="goBack()">
          <strong> trocar número</strong>.
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <usucampeao-footer></usucampeao-footer>
</div>
