<ion-content class="ion-padding">
  <div class="content-vertical-space-between">
    <div>
      <ion-text class="text-size title">Outro documento</ion-text><br><br>
      <ion-text class="subtext-size"> Qual o nome do documento que você gostaria de enviar? </ion-text>
      <form [formGroup]="form" class="ion-text-center ion-margin-top ion-margin-bottom">
        <div *ngFor="let field of form$" class="form-row ion-no-padding">
          <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
        </div>
      </form>
    </div>
    <div>
      <ion-grid class="ion-no-padding ion-no-margin">
        <ion-row class="ion-justify-content-end">
          <ion-col size="6">
            <ion-button mode="md" expand="block"  (click)="dismiss()" fill="clear" >
             Cancelar
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button mode="md" expand="block" (click)="navigateForward()">Continuar</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
