<ion-card class="attendance-card ion-no-margin">
    <img class="img-facade" [lazyLoad]="propertyFacadePhoto | async" [defaultImage]="loadingImage" [errorImage]="defaultImage" />
    <ion-card-header>
        <usucampeao-attendance-badge [status]="registration?.status"></usucampeao-attendance-badge>
    </ion-card-header>
    <ion-card-content>
        <ion-text class="title title--variant">
           {{projeto?.nome||''}}
        </ion-text>
        <ng-container *ngIf="registration?.address; let address">
            <ion-text class="font-text font-text--small">
                {{ address.street }}, {{address.number}} {{address.complement}}
            </ion-text>
            <ion-text class="description-text description-text--small description-text--light">
                {{ formatLocation(address) }}
            </ion-text>
        </ng-container>

        <ng-container *ngIf="!registration?.address">
            <ion-text class="font-text font-text--small">
                Endereço não cadastrado
            </ion-text>
            <ion-text class="description-text description-text--small description-text--light">
                {{ formatLocation() }}
            </ion-text>
        </ng-container>
    </ion-card-content>
    <ion-footer mode="md">
        <ion-button fill="clear" expand="full" (click)="navigateToInformationForm()">
            Ver
        </ion-button>
    </ion-footer>
</ion-card>
