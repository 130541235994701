import { Nationality, UF } from '@usucampeao/interfaces';
import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, IsNumber, IsString, Min, ValidateIf } from 'class-validator';
import { Gender } from '../../enums/gender.enum';
import { GroupsClassTransformer } from '../../enums/groups-class-transformer.enum';
import { MaritalStatus } from './marital-status.enum';
import { MatrimonialRegime } from './matrimonial-regime.enum';
import { OwnerFillInStatusDto } from './owner-fill-in-status.dto';
import { OwnerDto } from './owner.dto';



export const STATUS_REGIME_BENS_DATA_CASAMENTO = [MaritalStatus.MARRIED, MaritalStatus.SEPARATED];

export class GetUpdateOwnerPersonalDataDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsEnum(Gender)
  @IsNotEmpty()
  @Expose()
  gender: Gender;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  birthDate: Date;

  @Expose()
  @IsEnum(Nationality)
  @IsNotEmpty()
  nationality: Nationality;

  @Expose()
  @Transform(({ value, obj }) => {
    if (obj.nationality !== Nationality.BRASIL && obj.state) {
      return null;
    }
    return value;
  })
  @IsEnum(UF)
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object.nationality === Nationality.BRASIL)
  state: UF;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object.nationality === Nationality.BRASIL)
  naturalidade: string;

  @Expose()
  @IsEnum(MaritalStatus)
  @IsNotEmpty()
  maritalStatus: MaritalStatus;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!STATUS_REGIME_BENS_DATA_CASAMENTO.includes(obj.maritalStatus) && obj.matrimonialRegime) {
      return null;
    }
    return value;
  })
  @IsEnum(MatrimonialRegime)
  @IsNotEmpty()
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object && STATUS_REGIME_BENS_DATA_CASAMENTO.includes(object.maritalStatus))
  matrimonialRegime: MatrimonialRegime;

  @Expose()
  @Transform(({ value, obj }) => {
    if (!STATUS_REGIME_BENS_DATA_CASAMENTO.includes(obj.maritalStatus) && obj.weddingDate) {
      return null;
    }
    return value;
  })
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  @ValidateIf((object: GetUpdateOwnerPersonalDataDto) => object && STATUS_REGIME_BENS_DATA_CASAMENTO.includes(object.maritalStatus))
  weddingDate: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  profession: string;

  @Expose({ groups: [GroupsClassTransformer.SHOW_MAIN] })
  main?: boolean;

  @Expose()
  @IsNumber()
  @Min(0)
  @IsNotEmpty()
  familyIncome: number;

  @Expose({ groups: [GroupsClassTransformer.SHOW_MAIN] })
  spouseId: string;

  fillInStatus: OwnerFillInStatusDto;

  /**
   * Cria uma nova instância de GetUpdateOwnerPersonalDataDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdateOwnerPersonalDataDto
   */
  static from(props?: OwnerDto | Partial<OwnerDto>, groups: GroupsClassTransformer[] = []): GetUpdateOwnerPersonalDataDto {
    return plainToInstance(GetUpdateOwnerPersonalDataDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true, groups });
  }
}
