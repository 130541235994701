/**
 * Enumeration of personal document types.
 */
export enum PersonalDocumentType {
  /**
   * CPF document.
   */
  CPF = 'CPF',
  /**
   * RG document.
   */
  RG = 'RG',
  /**
   * Driver's license.
   */
  CNH = 'CNH',
  /**
   * Payslip - a note given to an employee when they have been paid,
   * detailing the amount of pay given, and the tax and insurance deducted.
   */
  HOLERITE = 'HOLERITE',
  /**
   * Comprovante de renda do morador. Nesse caso, precisamos do comprovante de
   * renda dos últimos 3 meses.
   */
  COMPROVANTE_RENDA = 'COMPROVANTE_RENDA',
  /**
   * Income tax.
   */
  IR = 'IR',
  /**
   * Proof of residence.
   */
  COMPROVANTE_ENDERECO = 'COMPROVANTE_DE_ENDERECO',
  /**
   * Birth certificate.
   */
  CERTIDAO_NASCIMENTO = 'CERTIDAO_DE_NASCIMENTO',
  /**
   * Marriage certificate.
   */
  CERTIDAO_CASAMENTO = 'CERTIDAO_DE_CASAMENTO',
  /**
   * Death certificate.
   */
  CERTIDAO_OBITO = 'CERTIDAO_DE_OBITO',
  /**
   * Divorce certificate.
   */
  CERTIDAO_DIVORCIO = 'CERTIDAO_DE_DIVORCIO',

}
