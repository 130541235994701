import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ProjetoSimplificadoDto } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../../services/loading.service';
import { AllotmentUnavailableModal } from '../../modal/allotment-unavailable/allotment-unavailable.modal';
import { ProjetoQuery } from '../../projeto/state/projeto.query';
import { RegisterService } from '../state/register.service';

@Component({
  selector: 'usucampeao-select-allotment',
  templateUrl: 'select-allotment.page.html',
  styleUrls: ['select-allotment.page.scss'],
})
export class SelectAllotmentPage implements OnInit {

  public nucleos: ProjetoSimplificadoDto[] = [];
  private ngUnsubscribe = new Subject();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private registerService: RegisterService,
    private loadingService: LoadingService,
    private projetoQuery: ProjetoQuery
  ) { }

  ngOnInit(): void {
    this.getAllProjects$();
  }

  ionViewWillEnter(): void {
    this.projetoQuery.selectLoading()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(async isLoading => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public navigateToCoreInstruction(nucleo): void {
    this.router.navigate([nucleo.id], { relativeTo: this.route });
  }
  async presentModalContact() {
    const modal = await this.modalController.create({
      component: AllotmentUnavailableModal,
      cssClass: 'allotment-unavailable-modal',
    });
    return await modal.present();
  }

  public getAllProjects$(): void {
    this.registerService.getAllRegisters().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
    this.projetoQuery.allProjet$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data) => {
      this.nucleos = data;
    })
  }
}
