<ion-footer class="ion-no-border ">
    <ion-toolbar class=" ion-no-padding">
        <ion-grid class="ion-no-margin ion-no-padding ion-padding-top">
            <ion-row class="ion-justify-content-center ">
                <ion-col sizeMd="6" sizeLg="3" class="ion-text-center">
                    <ng-content></ng-content>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                    <ion-img src="assets/imgs/logo-sbc.png"></ion-img>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
