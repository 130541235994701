<ion-content class="ion-padding">
    <ion-grid class="ion-no-padding">
        <ion-row>
            <ion-col>
                <ion-text class="title title--large">
                    Bem Vindo!
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-padding-top">
            <ion-col>
                <ion-text class="font-text">
                    Crie sua conta para cadastrar e acompanhar seus processos de regularização
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-col sizeMd="6">
                <form mode="md" [formGroup]="form" (ngSubmit)="onSubmit()" class="ion-text-center">
                    <div *ngFor="let field of form$" class="form-row ion-no-padding">
                        <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
                    </div>
                </form>
            </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding ion-justify-content-center ion-padding-top">
            <ion-col sizeMd="6">
                <ion-checkbox mode="md" [(ngModel)]="aceitePrivacidade"></ion-checkbox>
                <ion-text class="font-text font-text--small line-height">
                    Eu li, estou ciente das condições de tratamento dos meus dados pessoais e dou o meu consentimento, quando aplicável, conforme descrito nesta <a (click)="navigateToPrivacyPolicy()">Politica de Privacidade</a>.
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding-top">
            <ion-col sizeMd="6" sizeLg="3">
                <ion-button expand="block" mode="md" (click)="onSubmit()">Entrar</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

    <usucampeao-footer></usucampeao-footer>
</ion-content>
