import { Type } from 'class-transformer';
import { IsDateString, IsEmail, IsNotEmpty, IsNumberString, IsOptional, IsString, Length, ValidateIf, ValidateNested } from 'class-validator';
import { Address } from '../address/address.model';
/* import { ApiProperty } from '@nestjs/swagger'; */
export class CustomerDto {
  @IsString()
  @IsOptional()
  /*  @ApiProperty() */
  id?: string;

  @IsString()
  @IsNotEmpty()
  /*  @ApiProperty() */
  name: string;

  @IsEmail()
  @IsNotEmpty()
  /*  @ApiProperty() */
  email: string;

  @IsNumberString()
  @Length(10, 11)
  @IsNotEmpty()
  /*  @ApiProperty() */
  phone: string;

  @IsNumberString()
  @Length(11, 14)
  @IsNotEmpty()
  /*  @ApiProperty() */
  document: string;

  @IsDateString()
  @IsNotEmpty()
  /*  @ApiProperty() */
  birthdate?: Date;

  @Type(() => Address)
  @ValidateNested()
  @IsNotEmpty()
  /*  @ApiProperty() */
  address: Address;
}
