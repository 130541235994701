import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { NavigationService } from '../../../../services/navigation.service';
import { ToastService } from '../../../../services/toast.service';
import { ContactModal } from '../../../modal/contact/contact.modal';
import { RegistrationService } from '../../state/registration.service';

@Component({
  selector: 'usucampeao-confirm-appointment',
  templateUrl: 'confirm-appointment.page.html',
  styleUrls: ['confirm-appointment.page.scss'],
})
export class ConfirmAppointmentPage {
  @Input() selectedDate: Date;
  @Input() registrationId: string;
  @Output() previousStep = new EventEmitter<void>();

  constructor(
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private modalController: ModalController,
    private toastService: ToastService
  ) { }

  public setTimer(date: Date): void {
    this.selectedDate = date;
  }

  public previous() {
    this.previousStep.emit();
  }

  public confirmAppointment(): void {
    this.registrationService.setSchedulingDate(this.registrationId, this.selectedDate)
      .pipe(
        take(1)
      )
      .subscribe(
        () => this.navigationService.goBack(),
        async () => this.toastService.erro('Erro ao confirmar agendamento. Por favor tente novamente.')
      );
  }

  async presentModalContact() {
    const modal = await this.modalController.create({
      component: ContactModal,
      cssClass: 'small-modal',
    });
    return await modal.present();
  }
}
