import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { PagamentoDetalhesDto, PagamentoListagemDto } from '@usucampeao/lib-reurb-simplificado';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PagamentoService {

  constructor(
    private http: HttpClient,
  ) { }

  public buscarProximoVencimento(): Observable<PagamentoListagemDto[]> {
    return this.http.get<PagamentoListagemDto[]>('/registrations/pagamentos/proximo-vencimento');
  }

  /**
   * Busca os pagamentos de um atendimento.
   * @param registrationId id do atendimento
   * @returns lista de pagamento
   */
  getPayments(registrationId: string, params?: any) {
    return this.http.get<PagamentoListagemDto[]>(`/registrations/${registrationId}/payments`, { params: params })
      .pipe(catchError(erro => {
        Bugsnag.notify(erro, (event) => {
          event.context = 'service-payment-data'
          event.addMetadata('params', { registrationId });
          event.addMetadata('metadata', {
            url: `/registrations/${registrationId}/payments`,
            title: 'Erro ao buscar pagamentos:' + registrationId + '/',
            statusCode: erro.error.statusCode,
            message: erro.error.message,
            error: erro.error.error,
          });
        });
        return throwError(erro);
      }));
  }

  /**
   * Busca resumo dos pagamento de um atendimento.
   * @param registrationId id do atendimento
   * @return resumo
   */
  getPaymentsResume(registrationId: string) {
    let params = new HttpParams();
    params = params.append('cadastrosIds', registrationId);
    return this.http.get(`/resumo-pagamentos`, { params: params }).pipe(catchError(erro => {
      Bugsnag.notify(erro, (event) => {
        event.context = 'service-payment-resume-data'
        event.addMetadata('params', { 'cadastrosIds': registrationId });
        event.addMetadata('metadata', {
          url: `/resumo-pagamentos`,
          title: 'Erro ao buscar resumo dos pagamentos:' + registrationId + '/',
          statusCode: erro.error.statusCode,
          message: erro.error.message,
          error: erro.error.error,
        });
      });
      return throwError(erro);
    }));
  }

  /**
   * Busca detalhe de um pagamento.
   * @param registrationId id do atendimento
   * @param id id do pagamento
   * @returns lista de proprietários
   */
  getPaymentSelected(registrationId: string, id: string) {
    return this.http.get<PagamentoDetalhesDto>(`/registrations/${registrationId}/payments/${id}`)
      .pipe(
        catchError(erro => {
          Bugsnag.notify(erro, (event) => {
            event.context = 'service-payment-selected-data'
            event.addMetadata('params', { registrationId, id });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/payments/${id}`,
              title: 'Erro ao buscar pagamento selecionado:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          });
          return throwError(erro);
        })
      );
  }

}
