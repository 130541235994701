import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { GetUpdatePropertyLocalizationDto, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, first, switchMap, take, takeUntil } from 'rxjs/operators';
import { Lot } from '../../../../models/lot.interface';
import { LoadingService } from '../../../../services/loading.service';
import { NavigationService } from '../../../../services/navigation.service';
import { ToastService } from '../../../../services/toast.service';
import { AuthQuery } from '../../../auth/state/auth.query';
import { LotSelectionModal } from '../../../modal/lot-selection/lot-selection.modal';
import { PropertyQuery } from '../state/property.query';
import { PropertyService } from '../state/property.service';
import { RegistrationQuery } from './../../state/registration.query';

@Component({
  selector: 'usucampeao-edit-map',
  templateUrl: 'edit-map.page.html',
  styleUrls: ['edit-map.page.scss'],
})
export class EditMapPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  showModal = true;
  public lot: Lot;
  private ngUnsubscribe = new Subject();
  private propertyId: string;
  private registrationId: string;
  public projectid = '';
  fluxo: boolean;
  public blockAccess = false;

  constructor(
    public authQuery: AuthQuery,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private navigationService: NavigationService,
    public propertyQuery: PropertyQuery,
    private propertyService: PropertyService,
    private registrationQuery: RegistrationQuery,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter(): void {
    this.route.queryParams
      .pipe(
        first(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((params) => {
        this.fluxo = params['fluxo'];
      });

    this.route.params
      .pipe(
        first(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((params) => {
        const registration = this.registrationQuery.getValue();
        const status = registration.entities[params.id];
        this.validateStatus(status.status);
        this.projectid = status.projetoFid
        this.propertyId = params.propertyId;
        this.registrationId = params.id;
        this.getProperty();
      });

    this.propertyQuery.selectLoading()
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async isLoading => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  private getProperty(): void {
    this.propertyQuery.selectEntity(this.propertyId)
      .pipe(
        take(1),
        switchMap((property) => {
          if (property.localizationDataLoaded) {
            return of(GetUpdatePropertyLocalizationDto.from(this.propertyQuery.getEntity(this.propertyId)));
          }
          return this.propertyService.getLocalizationData(this.propertyId);
        })
      )
      .subscribe(
        (property) => {
          this.lot = {
            id: property.lotId,
            quadra: property.blockId,
          };
          this.showModal = true;
        },
        async () => await this.toastService.erro('Erro ao buscar dados do imóvel. Tente novamente mais tarde.')
      );
  }

  async presentModalAskHelp() {
    const modal = await this.modalController.create({
      component: LotSelectionModal,
      cssClass: 'lot-selection-modal',
    });
    return await modal.present();
  }

  public setLot(lot: Lot): void {
    this.lot = lot;
  }

  public updateLocalization(): void {
    const updateLocalization = new GetUpdatePropertyLocalizationDto();
    updateLocalization.blockId = this.lot.quadra_id;
    updateLocalization.lotId = Number(this.lot.id);
    this.propertyService
      .updateLocalizationData(
        this.registrationId,
        this.propertyId,
        updateLocalization
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (this.fluxo) {
          this.router.navigate([`../endereco`], {
            relativeTo: this.route,
            queryParams: { fluxo: true },
          });
        } else {
          this.navigationService.goBack();
        }
      }, async () => await this.toastService.erro('Erro ao atualizar dados do imóvel. Tente novamente mais tarde.'));
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
    }
  }
}
