import { Component, Input } from '@angular/core';
import { NotificacaoDto } from '@usucampeao/lib-reurb-simplificado';


@Component({
  selector: 'usucampeao-venha-buscar-titulo',
  templateUrl: './venha-buscar-titulo.component.html',
  styleUrls: ['./venha-buscar-titulo.component.scss'],
})
export class VenhaBuscarTituloComponent {
  @Input()
  public notification!: NotificacaoDto;

  constructor() {}
}