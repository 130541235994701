

import { CadastroDocumentoTipo } from '@usucampeao/lib-reurb-simplificado';
import { PropertyAcquisitionDocument } from '../property/property-occupation/property-acquisition-document.enum';
import { PropertyOwnershipDocument } from '../property/property-occupation/property-ownership-document.enum';
import { DocumentFileSubtype } from './document-file-subtype.enum';
import { DocumentReferenceType } from './document-reference-type.enum';
import { OwnerDocument } from './owner-document.enum';
import { StatusDocument } from './status-document.enum';

export type DocumentFileType = OwnerDocument | PropertyAcquisitionDocument | PropertyOwnershipDocument | CadastroDocumentoTipo;

export class DocumentFileDto {
  id: string;
  name?: string;
  registrationId: string;
  refId: string;
  refType: DocumentReferenceType;
  type: DocumentFileType;
  subtype: DocumentFileSubtype;
  approvedDisapprovedDate: Date;
  approvedDisapprovedUserId: string;
  status: StatusDocument;
  justification: string;
  reasonRejection: string;
  createdAt: Date;
  publico: string | boolean;
  userCreationId: string;
  usuarioCriacaoNome: string;
  updatedAt: Date;
  updateUserId: string;
}
