import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';
import { UF } from '../enums/uf.enum';

export class GrupoFamiliar {
  @IsEnum(UF)
  @IsOptional()
  uf: UF;

  @IsString()
  @IsOptional()
  cidade: string;

  @IsNumber()
  @IsOptional()
  residenciaMunicipio: number;
}
