<ion-content>
  <div class="content-vertical-space-between">
    <div class="ion-padding-bottom">
      <usucampeao-header mode="md" backRoute="/" title="Foto da Fachada"></usucampeao-header>
      <div class="ion-padding-start ion-padding-end">
        <ion-text class="font-text">
          Precisamos de uma foto da fachada para encontrar seu imóvel
        </ion-text>
      </div>
    </div>
    <div>
      <usucampeao-photo class="ion-margin-top" type="lot" (setImage)="setImage($event)"></usucampeao-photo>
    </div>
    <usucampeao-footer class="ion-padding-start ion-padding-end ion-padding-bottom">
      <ion-button mode="md" expand="block" (click)="updateFacadePhoto()" *ngIf="photo">Continuar</ion-button>
      <br>
      <ion-button fill="outline" mode="md" expand="block" (click)="back()">Cancelar</ion-button>
    </usucampeao-footer>
  </div>
</ion-content>
