import { PaymentType } from '@usucampeao/lib-reurb-simplificado';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'PropertyPayment'
})
export class PropertyPaymentPipe implements PipeTransform {

  transform(value: PaymentType): string {
    switch (value) {
      case PaymentType.TICKET:
        return 'Boleto bancário';

      case PaymentType.CARD:
        return 'Cartão de crédito';

      default:
        return value;
    }
  }

}
