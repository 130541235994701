import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/browser';
import { ModalController, ToastController } from '@ionic/angular';
import { RegistrationService } from '../../registration/state/registration.service';
@Component({
  selector: 'usucampeao-confirma-reenvio-cadastro',
  templateUrl: 'confirma-reenvio-cadastro.modal.html',
  styleUrls: ['confirma-reenvio-cadastro.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmaReenvioCadastroModal {
  @Input() id: string;

  constructor(
    public modalController: ModalController,
    public router: Router,
    private registrationService: RegistrationService,
    public toastController: ToastController,
  ) { }

  dismiss() {
    this.modalController.dismiss();
  }
  reenviar() {
    this.registrationService.sendForAnalysis(this.id)
      .subscribe(
        () => {
          this.modalController.dismiss();
        },
        async (err) => {
          Bugsnag.notify("Erro ao reenviar dados para análise. Tente novamente mais tarde.", err);
          const toast = await this.toastController.create({
            message: 'Erro ao reenviar dados para análise. Tente novamente mais tarde.',
            duration: 5000,
          });
          toast.present();
        }
      );
  }


}
