import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../footer/footer.module';
import { MenuItemModule } from '../menu-item/menu-item.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    FooterModule,
    MenuItemModule,
    ToolbarModule,
  ],
  exports: [MenuComponent],
})
export class MenuModule { }
