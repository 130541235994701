import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { AllotmentUnavailableModal } from './allotment-unavailable.modal';

@NgModule({
  declarations: [AllotmentUnavailableModal],
  imports: [
    CommonModule,
    IonicModule,
    UiMobileModule,
  ],
  exports:[AllotmentUnavailableModal],
})
export class AllotmentUnavailableModule {}
