/**
 * File categories that can be uploaded.
 */
export enum FileCategory {
  /**
   * Contratos.
   */
  CONTRACT = 'contracts',
  /**
   * Documentos pessoais.
   */
  DOCUMENT = 'documents',
  /**
   * Arquivos de projetos.
   */
  PROJECT = 'projects',
  /**
   * Arquivos do imóvel.
   */
  PROPERTY = 'property',
  /**
   * Arquivo de loteamento.
   */
  ALLOTMENT = 'allotment'
}
