import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { ElementBase, TextArea, toFormGroup } from '@usucampeao/ui-mobile';
import { RegistrationService } from '../../registration/state/registration.service';

@Component({
  selector: 'usucampeao-exclui-cadastro',
  templateUrl: 'exclui-cadastro.modal.html',
  styleUrls: ['exclui-cadastro.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ExcluiCadastroModal implements OnInit {
  @Input() id: string;
  @Input() justification: string;
  isVisible = false;
  justificadaValue = '';

  form$: ElementBase<string>[] | null = [];
  form!: FormGroup;

  constructor(
    public modalController: ModalController,
    public router: Router,
    private registrationService: RegistrationService,
    public toastController: ToastController,
  ) { }

  ngOnInit() {
    this.form$ = this.getForm();
    this.form = toFormGroup(this.getForm() as ElementBase<string>[]);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async encerrar() {
    const justificativa = this.form.value
    if (!justificativa?.justification) {
      const toast = await this.toastController.create({
        message: 'Preencha a justificativa',
        duration: 2000
      });
      toast.present();
      return;
    }
    const menssagem = { justificativaCancelamento: justificativa?.justification }
    this.registrationService.excluiCadastro(this.id, menssagem).subscribe(async () => {
      this.modalController.dismiss(); const toast = await this.toastController.create({
        message: 'Atendimento encerrado com sucesso',
        duration: 2000
      });
      toast.present();
      this.router.navigateByUrl('/');
    }, async () => {
      this.modalController.dismiss(); const toast = await this.toastController.create({
        message: 'Erro ao encerrar atendimento',
        duration: 2000
      });
      toast.present();
    })
  }

  private getForm(): ElementBase<string>[] {
    return [
      new TextArea({
        key: 'justification',
        label: 'Especificar',
        value: this.justification || '',
        validators: [Validators.required]
      }),
    ];
  }
}
