import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ProprietariosCadastroDto } from '@usucampeao/lib-reurb-simplificado';

interface Owner extends ProprietariosCadastroDto {
  personalDataLoaded?: boolean;
  documentsDataLoaded?: boolean;
  contactDataLoaded?: boolean;
}

export type OwnerState = EntityState<Owner>

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'owners', resettable: true })
export class OwnersStore extends EntityStore<OwnerState> {

  constructor() {
    super();
  }

}
