import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactModal } from '../../../modal/contact/contact.modal';

@Component({
  selector: 'usucampeao-select-hour',
  templateUrl: 'select-hour.page.html',
  styleUrls: ['select-hour.page.scss'],
})
export class SelectHourPage implements OnInit {
  @Input() selectedDate: Date = new Date();
  @Output() nextStep = new EventEmitter<Date>();
  @Output() previousStep = new EventEmitter<void>();

  public dates: Date[];

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit(): void {
    this.dates = [
      new Date(this.selectedDate.setHours(8, 0, 0, 0)),
      new Date(this.selectedDate.setHours(9, 0, 0, 0)),
      new Date(this.selectedDate.setHours(10, 0, 0, 0)),
      new Date(this.selectedDate.setHours(11, 0, 0, 0)),
      new Date(this.selectedDate.setHours(12, 0, 0, 0)),
      new Date(this.selectedDate.setHours(13, 0, 0, 0)),
      new Date(this.selectedDate.setHours(14, 0, 0, 0)),
      new Date(this.selectedDate.setHours(15, 0, 0, 0)),
      new Date(this.selectedDate.setHours(16, 0, 0, 0)),
      new Date(this.selectedDate.setHours(17, 0, 0, 0))
    ]
  }

  public setTimer(date: Date,): void {
    this.selectedDate = date;
  }

  public navigateToAppointment(): void {
    this.nextStep.emit(this.selectedDate);
  }

  onSubmit() { }
  async presentModalContact() {
    const modal = await this.modalController.create({
      component: ContactModal,
      cssClass: 'small-modal',
    });
    return await modal.present();
  }
}
