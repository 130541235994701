<ion-content class="ion-align-itens-center photo-registration">
    <div class="content-vertical-space-between">
        <usucampeao-header mode="md" backRoute=".." title="Minha foto"></usucampeao-header>
        <usucampeao-photo (setImage)="setImage($event)"></usucampeao-photo>
        <div class="ion-padding">
            <ion-grid class="ion-margin-top">
                <ion-row>
                    <ion-col>
                        <ion-text class="ion-text-center">
                            <p class="font-text">Tire uma foto para ajudar nossos assistentes a te identificar</p>
                        </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center" *ngIf="photo">
                    <ion-col sizeMd="6" sizeLg="3" class="ion-text-center">
                        <ion-button mode="md" expand="block" (click)="updateFacadePhoto()">Continuar</ion-button>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                    <ion-col sizeMd="6" sizeLg="3" class="ion-text-center">
                        <ion-button mode="md" fill="outline" expand="block" (click)="goBack()">Cancelar</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>
    <usucampeao-footer></usucampeao-footer>
</ion-content>
