<ion-item>
    <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
        <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </p>
    </ion-label>
    <ion-skeleton-text animated style="width: 20px; height: 20px; border-radius: 10px" slot="end"></ion-skeleton-text>
</ion-item>