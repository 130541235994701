import { Type } from 'class-transformer';
import { ArrayMinSize, IsArray, IsDateString, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ListAttendance } from './list-attendance.model';


export class AttendanceChangePhase {
  @IsNumber()
  @IsNotEmpty()
  phaseId: number;

  @IsNumber()
  @IsNotEmpty()
  actualPhase: number;

  @IsArray()
  @ArrayMinSize(1)
  @IsNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => ListAttendance)
  listAttendances: ListAttendance[]

  @IsDateString()
  @IsOptional()
  date?: Date;

  @IsString()
  @IsOptional()
  address?: string;
}
