<div class="schedule content-vertical-space-between">
    <usucampeao-header [stepNavigation]="true" (navigationEmit)="previousStep.emit()" mode="md" title="Agendamento" backRoute="..">
        <ion-text>
            <p class="ion-no-margin font-text ion-padding-start ion-padding-end">Selecione um horário para a visita do técnico em <b>10/10/2021</b></p>
        </ion-text>
    </usucampeao-header>
    <div class="grid">
        <ion-chip *ngFor="let date of dates" class="ion-text-center" [ngClass]="{'selected': date === selectedDate}" (click)="setTimer(date)">
            {{ date | date:'HH:mm' }}
        </ion-chip>
    </div>
    <usucampeao-footer class="ion-padding-start ion-padding-end">
        <ion-button mode="md" expand="block" type="submit" (click)="navigateToAppointment()">
            Continuar
        </ion-button>
        <ion-text>
            <p class="ion-text-center ion-padding font-text">Agende a validação presencial dos documentos. Em caso de dúvidas, <b class="text-link" (click)="presentModalContact()">entre em contato.</b></p>
        </ion-text>
    </usucampeao-footer>
</div>