import { IonicModule } from '@ionic/angular';
import { InformationAlertModal } from './information-alert.modal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';

@NgModule({
  imports: [CommonModule,IonicModule, UiMobileModule, CommonModule],

  declarations: [InformationAlertModal],

  exports:[InformationAlertModal],
})
export class InformationAlertModule {}
