import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { RegistrationDto, PropertyDto } from '@usucampeao/lib-reurb-simplificado';

export interface RegistrationState {
  property: PropertyDto | Partial<PropertyDto>;
  registration: RegistrationDto | Partial<RegistrationDto>;
}

export function createInitialState(): RegistrationState {
  return {
    property: null,
    registration: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'register',
  resettable: true
})
export class RegisterStore extends Store<RegistrationState> {
  constructor() {
    super(createInitialState());
  }

  public loadRegistration(registration: Partial<RegistrationState>) {
    this.update(registration);
  }
}
