import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Camera, CameraResultType } from '@capacitor/camera';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
})
export class PhotoComponent implements OnInit {
  @Input() type = 'person';
  @Input() button = false;
  @Input() requiredphoto = false;
  @Input() imageUrl!: string;
  @Input() buttonText = 'Trocar foto';
  @Input() messageRequired = 'Foto obrigatória';
  @Output() setImage = new EventEmitter<File>();
  @Output() remove = new EventEmitter<string>();

  defaultImage = '../../../../assets/imgs/placeholder.png';
  private disabled = false;
  constructor(public ModalController: ModalController) { }

  ngOnInit() { }

  public async convertImage() {
    if (this.disabled) {
      return;
    }

    this.disabled = true;
    try {
      const image = await Camera.getPhoto({
        quality: 80,
        saveToGallery: false,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      });
      const imageData = this.b64toBlob(image.base64String, `image/${image.format}`)
      this.imageUrl = `data:image/${image.format};base64,` + image.base64String;
      this.setImage.emit(imageData);
    } catch (error) {
      //Bugsnag.notify('Componente foto - Erro ao tirar foto.', error)
      // não é necessário fazer nada, o usuário apenas cancelou a câmera
    }
    this.disabled = false;
  }

  public removeImage() {
    this.remove.emit(this.imageUrl);
    this.imageUrl = '';
  }

  b64toBlob(b64Data: string, contentType = '') {
    const rawData = atob(b64Data);
    const bytes = new Array(rawData.length);
    for (let x = 0; x < rawData.length; x++) {
      bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);

    const blob = new Blob([arr], { type: contentType });

    return new File([blob], `${new Date().getTime()}`, { type: contentType });
  }

}
