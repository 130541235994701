import { PagamentoStatus } from '../../payment/pagamento-status.enum';

export class Bs2IntegracaoDadosConectados {
  status: PagamentoStatus;
  integracaoId: string;
  integracaoNumero: number;
  dataEmissao: Date;
  dataVencimento: Date;
  valorPago?: number;
  dataPagamento?: Date;
  valorDevido?: number;
  codigoDeBarras?: string;
  linhaDigitavel?: string;

  constructor(
    status: PagamentoStatus,
    integracaoId: string,
    integracaoNumero: number,
    dataEmissao: Date,
    dataVencimento: Date,
    valorPago?: number,
    dataPagamento?: Date,
    valorDevido?: number,
    codigoDeBarras?: string,
    linhaDigitavel?: string,
  ) {
    this.status = status;
    this.integracaoId = integracaoId;
    this.integracaoNumero = integracaoNumero;
    this.dataEmissao = dataEmissao;
    this.valorPago = valorPago;
    this.dataPagamento = dataPagamento;
    this.valorDevido = valorDevido;
    this.dataVencimento = dataVencimento;
    this.codigoDeBarras = codigoDeBarras;
    this.linhaDigitavel = linhaDigitavel;
  }
}
