import { IsCpf } from '@usucampeao/utils';
import { Expose, Transform, Type } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { Bs2Endereco } from '.';
import { Bs2TipoCliente } from './bs2-tipo-cliente.enum';

export class Bs2Cliente {
  @Expose()
  @IsEnum(Bs2TipoCliente)
  @IsNotEmpty()
  tipo: Bs2TipoCliente;

  @Expose()
  @Transform(({ value }) => value.replace(/[^\d]+/g, ''))
  @IsCpf()
  @IsString()
  @IsNotEmpty()
  documento: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  nome: string;

  @Expose()
  @Type(() => Bs2Endereco)
  @ValidateNested()
  @IsNotEmpty()
  endereco: Bs2Endereco;

  constructor(
    tipo: Bs2TipoCliente,
    documento: string,
    nome: string,
    endereco: Bs2Endereco
  ) {
    this.tipo = tipo;
    this.documento = documento;
    this.nome = nome;
    this.endereco = endereco;
  }
}
