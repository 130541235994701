import { IsOptional, IsString } from 'class-validator';

export class TopicMensagemDto<T> {
  @IsOptional()
  attributes: T;

  @IsString()
  @IsOptional()
  data: string;

  @IsString()
  @IsOptional()
  messageId: string;

  @IsString()
  @IsOptional()
  message_id: string;

  @IsString()
  @IsOptional()
  publishTime: Date;

  @IsString()
  @IsOptional()
  publish_time: Date;
}
