import { ContactSupportModule } from './../../../modal/contact-support-point/contact-support.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllotmentCodeRoutingModule } from './allotment-code-routing.module';
import { IonicModule } from '@ionic/angular';
import { AllotmentCodePage } from './allotment-code.page';
import { HeaderModule } from '../../../../components/header/header.module';
import { FooterModule } from '../../../../components/footer/footer.module';
import { FaseNucleoModule } from '../../../../components/fase-nucleo/fase-nucleo.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ContactModule } from './../../../modal/contact/contact.module';


@NgModule({
  declarations: [
    AllotmentCodePage
  ],
  imports: [
    AllotmentCodeRoutingModule,
    UiMobileModule,
    CommonModule,
    ContactModule,
    ContactSupportModule,
    FaseNucleoModule,
    FooterModule,
    FormsModule,
    HeaderModule,
    IonicModule,
    ReactiveFormsModule,
  ]
})
export class AllotmentCodeModule { }
