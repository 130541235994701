import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';
import { PipesModule } from '../../../../pipes/pipes.module';
import { PropertyOccupancyPipe } from '../../../../pipes/property-occupancy.pipe';
import { PropertySituationPipe } from '../../../../pipes/property-situation.pipe';
import { PropertyUsagePipe } from '../../../../pipes/property-usage.pipe';
import { ChangeAlertModule } from './../../../../components/change-alert/change-alert.module';
import { EditAdittionalInformationRoutingModule } from './edit-additional-information-routing.module';
import { EditAdittionalInformationPage } from './edit-additional-information.page';

@NgModule({
  declarations: [
    EditAdittionalInformationPage
  ],
  imports: [
    CommonModule,
    EditAdittionalInformationRoutingModule,
    FooterModule,
    FormsModule,
    HeaderModule,
    IonicModule,
    ReactiveFormsModule,
    UiMobileModule,
    PipesModule,
    ChangeAlertModule,
  ],
  providers: [
    PropertyUsagePipe,
    PropertyOccupancyPipe,
    PropertySituationPipe
  ]
})
export class EditAdittionalInformationModule { }
