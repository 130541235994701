import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ChangeAlertModule } from '../../../../components/change-alert/change-alert.module';
import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';
import { EditAddressRoutingModule } from './edit-address-routing.module';
import { EditAddressPage } from './edit-address.page';

@NgModule({
  declarations: [
    EditAddressPage
  ],
  imports: [
    CommonModule,
    EditAddressRoutingModule,
    FooterModule,
    FormsModule,
    HeaderModule,
    IonicModule,
    ReactiveFormsModule,
    UiMobileModule,
    ChangeAlertModule,
  ]
})
export class EditAddressModule { }
