import { MapComponent } from './map.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [MapComponent],
  imports: [AgmCoreModule, CommonModule, IonicModule],
  exports: [MapComponent],
})
export class MapModule {



}
