import { AlteracaoDto } from './alteracao.dto';
import { Expose, Type } from 'class-transformer';
import { ArrayMinSize, IsArray, ValidateNested } from 'class-validator';

export class CreateRevisaoDto {
  @Expose()
  @Type(() => AlteracaoDto)
  @IsArray()
  @ValidateNested({ each: true })
  alteracoes: AlteracaoDto[];
}
