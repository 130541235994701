import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsEmail, IsNotEmpty, IsOptional, IsString, ValidateIf, ValidateNested } from 'class-validator';
import { OwnerFillInStatusDto } from './owner-fill-in-status.dto';
import { OwnerDto } from './owner.dto';
import { PhoneDto } from './phone.dto';

export class GetUpdateOwnerContactDto {
  @Expose()
  @IsEmail()
  @IsOptional()
  @ValidateIf(data => data.email)
  email?: string;

  @Expose()
  @Type(() => PhoneDto)
  @ValidateNested()
  @IsNotEmpty()
  phones: PhoneDto;

  @Expose()
  @IsString()
  @IsOptional()
  contactName: string;

  fillInStatus?: OwnerFillInStatusDto;

  /**
   * Cria uma nova instância de GetUpdateOwnerContactDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdateOwnerContactDto
   */
  static from(props?: OwnerDto | Partial<OwnerDto>): GetUpdateOwnerContactDto {
    return plainToInstance(GetUpdateOwnerContactDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
