import { Injectable } from "@angular/core";
import { ID, QueryEntity } from '@datorama/akita';
import { ProjetoState, ProjetoStore } from './projeto.store';

@Injectable({ providedIn: 'root' })
export class ProjetoQuery extends QueryEntity<ProjetoState> {
  public areLoaded$ = this.select(state => {
    return state.loaded;
  });

  public allProjet$ = this.selectAll();

  constructor(protected store: ProjetoStore) {
    super(store);
  }

  selectById(id: ID) {
    this.select(id);
  }
}