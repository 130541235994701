import { Pipe, PipeTransform } from '@angular/core';
import { PropertyOwnershipDocument } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'PropertyOwnershipDocumentPipe',
})
export class PropertyOwnershipDocumentPipe implements PipeTransform {
  transform(value: PropertyOwnershipDocument): string {
    switch (value) {
      case PropertyOwnershipDocument.HEALTH_CARE_CARD:
        return 'Carteira de posto de saúde';

      case PropertyOwnershipDocument.IPTU:
        return 'IPTU';

      case PropertyOwnershipDocument.SCHOOL_ENROLLMENT:
        return 'Matrícula escolar de familiares';

      case PropertyOwnershipDocument.WATER_OR_LIGHT_BILL:
        return 'Recibo ou conta de água ou luz';

      case PropertyOwnershipDocument.PROPERTY_REGISTRATION:
        return 'Matrícula do imóvel';

      default:
        return value;
    }
  }
}
