import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotAddressRoutingModule } from './lot-address-routing.module';
import { IonicModule } from '@ionic/angular';
import { LotAddressPage } from './lot-address.page';
import { HeaderModule } from '../../../../../components/header/header.module';
import { FooterModule } from '../../../../../components/footer/footer.module';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../../../../pipes/pipes.module';
import { PropertyUsagePipe } from '../../../../../pipes/property-usage.pipe';
import { PropertyOccupancyPipe } from '../../../../../pipes/property-occupancy.pipe';
import { PropertySituationPipe } from '../../../../../pipes/property-situation.pipe';


@NgModule({
  declarations: [
    LotAddressPage
  ],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    UiMobileModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    LotAddressRoutingModule,
   PipesModule
  ],
  providers: [
    PropertyOccupancyPipe,
   PropertySituationPipe,
    PropertyUsagePipe
  ]
})
export class LotAddressModule { }
