import { Component, Input } from '@angular/core';
import {
  CadastroContratoDto,
  MaritalStatus,
  MeioPagamento,
  OwnerDto,
  PropertyDto,
  PropertyUsage
} from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usu-declaracao-de-domicilio',
  templateUrl: './declaracao-de-domicilio.html',
  styleUrls: ['./declaracao-de-domicilio.scss'],
})
export class DeclaracaoDeDomicilioComponent {
  @Input() cadastro!: CadastroContratoDto;
  @Input() proprietario!: Partial<OwnerDto>;
  @Input() conjuge!: Partial<OwnerDto>;
  @Input() imovel!: Partial<PropertyDto>;


  public get meioPagamento(): typeof MeioPagamento {
    return MeioPagamento
  }

  public get imovelTipo(): typeof PropertyUsage {
    return PropertyUsage
  }

  public get maritalStatus(): typeof MaritalStatus {
    return MaritalStatus
  }

}
