import { Pipe, PipeTransform } from '@angular/core';
import { PropertyAcquisitionDocument } from '@usucampeao/lib-reurb-simplificado';

@Pipe({
  name: 'PropertyAcquisitionDocumentPipe',
})
export class PropertyAcquisitionDocumentPipe implements PipeTransform {
  transform(value: PropertyAcquisitionDocument): string {
    switch (value) {
      case PropertyAcquisitionDocument.ASSIGNMENT_OF_RIGHTS_AGREEMENT:
        return 'Contrato de cessão de direitos';

      case PropertyAcquisitionDocument.DEED:
        return 'Escritura';

      case PropertyAcquisitionDocument.DONATION_CONTRACT:
        return 'Contrato de doação';

      case PropertyAcquisitionDocument.DONATION_DEED:
        return 'Escritura de doação';

      case PropertyAcquisitionDocument.FORMAL_SHARING:
        return 'Formal de partilha';

      case PropertyAcquisitionDocument.EXCHANGE_CONTRACT:
        return 'Contrato de permuta';

      case PropertyAcquisitionDocument.LAW_SUIT:
        return 'Ação judicial de usucapião';

      case PropertyAcquisitionDocument.LEASE_AGREEMENT:
        return 'Contrato de Comodato';

      case PropertyAcquisitionDocument.OTHERS:
        return 'Outros';

      case PropertyAcquisitionDocument.PUBLIC_CONCESSION:
        return 'Concessão do poder público';

      case PropertyAcquisitionDocument.PURCHASE_AND_SALE_AGREEMENT:
        return 'Contrato de compra e venda';

      case PropertyAcquisitionDocument.PURCHASE_RECEIPT:
        return 'Recibo de compra';

      default:
        return value;
    }
  }
}
