import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator';
import { BaseEntityDto } from '../base-entity/base-entity-dto.model';
import { DomainTeamDto } from './domain-team-dto.model';

/**
 * Model interface (DTO) for 'dominio' entity.
 * Dominio represents a third-party tenant, having its own domain, theme, manager, team, rules, etc.
 */
export class DomainDto extends BaseEntityDto {
  /**
   * Dominio unique ID.
   */
  @IsOptional()
  @IsString()
  id: string;

  /**
   * Dominio name.
   */
  @IsOptional()
  @IsString()
  name?: string;
  /**
   * Dominio description.
   */
  @IsOptional()
  @IsString()
  description?: string;
  /**
   * Dominio region.
   */
  @IsOptional()
  @IsString()
  local?: string;
  /**
   * Dominio city/county.
   */
  @IsNotEmpty()
  @IsString()
  city: string;

  /**
   * ID da cidade no Postgree
   */
  @IsNotEmpty()
  @IsNumber()
  cityId: number;

  /**
   * Dominio state/province.
   */
  @IsNotEmpty()
  @IsString()
  state: string;

  /**
   * Dominio themed application URL.
   */
  @IsOptional()
  @IsString()
  url?: string;
  /**
   * Dominio themed back-office application URL.
   */
  @IsOptional()
  @IsString()
  backoffice?: string;

  /**
   * Dominio theme.
   */
  @IsOptional()
  @IsString()
  theme?: string;
  /**
   * Dominio logo URL reference.
   */
  @IsOptional()
  @IsString()
  logo?: string;

  /**
   * Dominio actor/representative/avatar name.
   */
  @IsOptional()
  @IsString()
  actorName?: string;
  /**
   * Dominio actor/representative/avatar image URL.
   */
  @IsOptional()
  @IsString()
  actorImage?: string;

  /**
   * Dominio manager name.
   */
  @IsOptional()
  @IsString()
  managerName?: string;
  /**
   * Dominio manager image (photo) URL.
   */
  @IsOptional()
  @IsString()
  managerImage?: string;
  /**
   * Dominio manager's phone number.
   */
  @IsOptional()
  @IsString()
  managerPhone?: string;
  /**
   * Dominio manager's email address.
   */
  @IsOptional()
  @IsString()
  managerEmail?: string;

  /**
   * Dominio local business partner name.
   */
  @IsOptional()
  @IsString()
  partnerName?: string;
  /**
   * Dominio local business partner image (photo) URL.
   */
  @IsOptional()
  @IsString()
  partnerImage?: string;
  /**
   * Dominio local business partner's phone number.
   */
  @IsOptional()
  @IsString()
  partnerPhone?: string;
  /**
   * Dominio local business partner's email address.
   */
  @IsOptional()
  @IsString()
  partnerEmail?: string;

  /**
   * Dominio themed introduction message.
   */
  @IsOptional()
  @IsString()
  siteIntro?: string;
  @IsOptional()
  @IsString()
  siteSceneTxt?: string;
  @IsOptional()
  @IsString()
  siteAboutProjTxt?: string;
  /**
   * Dominio themed footer sponsored message.
   */
  @IsOptional()
  @IsString()
  siteFooterMsg?: string;
  /**
   * Dominio themed footer sponsored image URL.
   */
  @IsOptional()
  @IsString()
  siteFooterApoio?: string;
  /**
   * Dominio team.
   */
  @IsOptional()
  team?: DomainTeamDto;

  /**
   * Número de loteamentos
   */
  @IsNotEmpty()
  @IsNumber()
  allotments: number;
}
