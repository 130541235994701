export function preprocessData(data): unknown {
  return { id: data.id, ...data.data() };
}

export function convertDate(data: any): any {
  if (data?.toDate) {
    return data.toDate();
  }
  return data;
}
