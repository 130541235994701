import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RegisterStore, RegistrationState } from './register.store';

@Injectable({ providedIn: 'root' })
export class RegisterQuery extends Query<RegistrationState> {
  public registrationId$ = this.select(state => state?.registration?.id);
  public propertyId$ = this.select(state => state?.registration?.propertyId);
  public ids$ = this.select(state => {
    return {
      registrationId: state?.registration?.id,
      propertyId: state?.registration?.propertyId,
    }
  });

  constructor(protected store: RegisterStore) {
    super(store);
  }

}
