import { Pipe, PipeTransform } from '@angular/core';
import { PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';


@Pipe({
  name: 'pagamentoStatus'
})
export class PagamentoStatusPipe implements PipeTransform {

  transform(value: PagamentoStatus): string {
    switch (value) {
      case PagamentoStatus.CANCELADO:
        return 'Cancelado';
      case PagamentoStatus.CRIADO:
        return 'Pendente';
      case PagamentoStatus.EM_ANALISE:
        return 'Em análise';
      case PagamentoStatus.ERRO:
        return 'Erro';
      case PagamentoStatus.MANUAL:
        return 'Manual';
      case PagamentoStatus.NAO_PAGO:
        return 'Não pago';
      case PagamentoStatus.PAGO:
        return 'Pago';
      case PagamentoStatus.PAGO_CANCELADO:
        return 'Pagamento cancelado';
      case PagamentoStatus.PROGRAMADO:
        return 'Programado';
      case PagamentoStatus.SOLICITADO_EMISSAO_PAGAMENTO:
        return 'Solicitado emissão pagamento';
      default:
        return value;
    }
  }

}
