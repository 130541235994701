export enum ProjetoHistoricoTipo {
  CRIAR_PROJETO = 'PROJETO_CRIADO',
  EDITAR_DADOS_BASICOS = 'EDITAR_DADOS_BASICOS',
  EDITAR_DADOS_COMERCIAIS = 'EDITAR_DADOS_COMERCIAIS',
  EDITAR_IMAGEM = 'EDITAR_IMAGEM',
  EDITAR_MAPA = 'EDITAR_MAPA',
  CRIAR_TABELA_PRECO = 'CRIAR_TABELA_PRECO',
  EDITAR_TABELA_PRECO = 'EDITAR_TABELA_PRECO',
  HABILITAR_VENDAS = 'HABILITAR_VENDAS',
  DESABILITAR_VENDAS = 'DESABILITAR_VENDAS',
}
