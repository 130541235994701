<ion-content>
  <div class="content-vertical-space-between" >
    <usucampeao-header mode="md" backRoute=".." title="Notificações"> </usucampeao-header>
    <div *ngIf="(notifications$ | async).length === 0" class="notificacao__content">
      <ion-icon color="light" name="notifications-off-sharp" class="notificacao__icon"></ion-icon>
      <ion-text class="font-text ion-text-center">
        Nenhuma notificação
        <br />
      </ion-text>
    </div>
    <ion-grid class="ion-no-margin ion-no-padding" *ngIf="(notifications$ | async).length > 0">
      <ion-row class="ion-no-padding">
        <ion-col class="ion-no-padding">
          <ion-list class="ion-padding-start ion-padding-end">
            <ion-item
            mode="md"
            *ngFor="let notification of notifications$ | async"
            class="ion-no-padding"
            detail="true"
            (click)="navigateToSee(notification)"
            >
            <usucampeao-notification-badge [tipo]="notification.tipo"></usucampeao-notification-badge>
            <div class="ion-padding-bottom ion-padding-top ion-padding-start">
              <ion-text class="badge">
                <h2 class="font-text ion-no-margin" [class.bold]="notification.status !== statusNotificacao.LIDA">
                  {{ notification.titulo }}
                </h2>
                <div>
                  <ion-badge
                  color="primary"
                  mode="md"
                  slot="end"
                  class="badge-font ion-margin-horizontal"
                  *ngIf="notification.status !== statusNotificacao.LIDA"
                  >novo</ion-badge
                  >
                </div>
              </ion-text>
              <ion-text class="description-text description-text--small"> {{ notification.descricao }} </ion-text>
              <ion-text class="description-text">
                <p class="ion-no-margin">{{notification.dataEnvio || "" | date}}</p>
              </ion-text>
            </div>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
    <div class="ion-padding-start ion-padding-end">
      <usucampeao-footer>
        <ion-button mode="md" expand="block" class="button-outline" (click)="navigateToNotification()">
          Voltar
        </ion-button>
      </usucampeao-footer>
    </div>
  </div>
</ion-content>
