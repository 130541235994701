import { Transform } from 'class-transformer';
import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class MapaDto {
  @IsString()
  @IsNotEmpty()
  idMapa: string;

  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  latitudeMinima: number;

  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  latitudeMaxima: number;

  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  longitudeMinima: number;

  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  longitudeMaxima: number;

  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  zoomMinimo: number;

  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  zoomMaximo: number;

  @Transform(({ value }) => +value)
  @IsNumber()
  @IsNotEmpty()
  zoomInicial: number;
}
