/**
 * Enumeration of realty/property document types.
 */
export enum StatusDocument {
  /**
   * Fotos do documento ainda não foram enviadas.
   */
  PENDENTE = 'PENDENTE',
  /**
   * Fotos do documento foram enviadas.
   */
  ENVIADO = 'ENVIADO',
  /**
   * Documento aprovado.
   */
  APROVADO = 'APROVADO',
}
