import { ReurbModalidade } from '../../enums';
import { MeioPagamento } from '../pagamento';
import { DiaPagamento } from '../pagamento/dia-pagamento.enum';
import { CadastroStatusVendaType } from './cadastro-status-venda.enum';
import { FamilyDto } from './family.dto';
import { RegistrationStatus } from './registration-status.enum';

export class RegistrationDto {
  id: string;
  projetoId: number;
  projetoFid: string;
  propertyId: string;
  reurbModalidade: ReurbModalidade
  ownerId: string;
  proprietarioCadastroId: string;
  ownerName: string;
  owners: FamilyDto[];
  usersIds: string[];
  status: RegistrationStatus;
  statusVenda: CadastroStatusVendaType;
  comments: string;
  schedulingDate: Date;
  deliveryDate: Date;
  createdAt: Date;
  createUser: string;
  updatedAt: Date;
  updateUser: string;
  diaVencimento: DiaPagamento;
  valorEntrada: number;
  valorContrato: number;
  totalParcelas: number;
  valorParcela: number;
  meioPagamento: MeioPagamento;
  contractDate: Date;
  canceladoPeloCliente: boolean;
  justificativaCancelamento: string;
  usuarioCancelamentoId: string;
  lotId: number;
  blockId: number;
  usuarioIdResponsavel: number;
  usuarioIdInicioContrato: number;
  usuarioIdColetaDados: number
  usuarioIdAnalise: number
  usuarioIdFechamentoContrato: number;
  usuarioFidResponsavel: string;
  usuarioFidInicioContrato: string;
  usuarioFidColetaDados: string;
  usuarioFidAnalise: string;
  usuarioFidFechamentoContrato: string;
  diarioOficialLink?: string;
  prefeituraProtocolo?: string;
  cartorioProtocolo?: string;
  entregaDataPrevista?: Date;
  entregaLocal?: string;
  entregaData?: Date;

  constructor(registration?: Partial<RegistrationDto>) {
    if (registration) {
      Object.keys(registration).forEach(key => this[key] = registration[key]);
    }
  }
}
