import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';
import { ProcessProgressModule } from '../../../../components/process-progress/process-progress.module';
import { InviteModule } from './../../../modal/invite-fill/invite.module';
import { DetailsRoutingModule } from './details-routing.module';
import { DetailsPage } from './details.page';

import { LazyLoadImageModule } from 'ng-lazyload-image';
@NgModule({
  declarations: [
    DetailsPage
  ],
  imports: [
    CommonModule,
    HeaderModule,
    IonicModule,
    InviteModule,
    ProcessProgressModule,
    DetailsRoutingModule,
    FooterModule,
    LazyLoadImageModule
  ]
})
export class DetailsModule { }
