/**
 * Enumeration of realty/property document types.
 */
export enum PropertyDocumentType {
  /**
   * Assignment of realty/property rights.
   */
  CESSAO_DIREITO = 'CESSAO_DIREITO',
  /**
   * Service (water, electricity, phone) bill receipt.
   */
  COMPROVANTE_CONTA = 'COMPROVANTE_CONTA',
  /**
   * Purchase and sale agreement (contract).
   */
  CONTRATO_COMPRA_VENDA = 'CONTRATO_COMPRA_VENDA',
  /**
   * Property deed.
   */
  ESCRITURA = 'ESCRITURA',
  /**
   * IPTU - Imposto Predial e Territorial Urbana.
   */
  IPTU = 'IPTU',
  /**
   * Purchase and sale receipt.
   */
  RECIBO = 'RECIBO',
  /**
   * Fachada do imóvel
   */
  FACHADA = 'FACHADA',

}
