
/**
 * Enum para status do contrato assinado
 */
export enum ContractSentStatus {
  /**
   * Envio pendente
   */
  PENDENTE = 'PENDENTE',
  /**
   * Foi enviado mas aguarda aprovação
   */
  AGUARDANDO_APROVACAO = 'AGUARDANDO_APROVACAO',
  /**
   * Aprovado
   */
  APROVADO = 'APROVADO'
}
