import { IonicModule } from '@ionic/angular';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiMobileModule } from '@usucampeao/ui-mobile';

import { ContactModal } from './contact.modal';

@NgModule({
  imports: [CommonModule, IonicModule, UiMobileModule, CommonModule],

  declarations: [ContactModal],

  exports: [ContactModal],
})
export class ContactModule { }
