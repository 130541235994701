<ion-content class="ion-padding" scrollY="false">
    <div class="content-vertical-space-between">
        <div>
            <ion-text class="font-text font-text--medium">Entre em contato por um dos nossos canais de comunicação:</ion-text>
        </div>
        <div>
            <ion-grid class="ion-no-padding ion-no-margin">
                <ion-row class="ion-padding-bottom">
                    <ion-col>
                        <ion-icon class="icon-phone" color="primary" name="phone-portrait-outline"></ion-icon>
                        <a class="font-text font-text--large color" href="tel:08005911328">
               0800 591 1328
               </a>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-icon color="primary" class="icon-mail" name="mail-outline"></ion-icon>
                        <a class="font-text font-text--small color" href="mailto:ajuda@poderegularizar.com.br">
               ajuda@poderegularizar.com.br
               </a>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div>
            <ion-grid class="ion-no-padding">
                <ion-row>
                    <ion-col></ion-col>
                    <ion-col>
                        <ion-button fill="clear" (click)="dismiss()">Entendi</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>
</ion-content>
