import { IsEnum, IsOptional, IsString } from 'class-validator';
import { UF } from '../../enums/uf.enum';

export class Naturalidade {
  @IsEnum(UF)
  @IsOptional()
  estado: UF;

  @IsString()
  @IsOptional()
  municipio: string;
}
