import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ViewWillLeave } from '@ionic/angular';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../../../services/loading.service';
import { ToastService } from '../../../../services/toast.service';
import { AuthService } from '../../state/auth.service';

@Component({
  selector: 'usucampeao-cadastro-foto',
  templateUrl: 'photo-registration.page.html',
  styleUrls: ['photo-registration.page.scss'],
})
export class PhotoRegistrationPage implements ViewWillLeave, OnDestroy {
  public base64Image!: string;
  public photo: File;
  private ngUnsubscribe = new Subject();

  constructor(
    public router: Router,
    private loadingService: LoadingService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  public setImage(photo: File): void {
    this.photo = photo;
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public async navigateToRegistrationCompleted(): Promise<void> {
    await this.updateFacadePhoto();
  }

  public skipPhotoRegistration(): void {
    this.router.navigate([`/cadastro-finalizado/`]);
  }

  private async updateFacadePhoto(): Promise<void> {
    await this.loadingService.createLoader();
    this.authService
      .updateAccountPhoto(this.photo)
      .pipe(
        finalize(async () => await this.loadingService.dismiss()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.router.navigate([`/cadastro-finalizado/`])),
      async () => this.toastService.erro('Erro ao atualizar foto da fachada do imóvel.');
  }

}
