<usucampeao-header mode="md" backRoute="../" title="Novo Cadastro"></usucampeao-header>
<div class="ion-padding-bottom ion-padding-start color">
    <ion-text class="font-text">
        Indique o lote do seu imóvel no mapa
    </ion-text>
</div>

<ion-content scrollY="false">
    <div class="map">
        <usucampeao-map *ngIf="projetoId" [projetoId]="projetoId" (setLot)="setLot($event)"></usucampeao-map>
    </div>

    <div class="modal" *ngIf="showModal">
        <div class="modal__container">
            <ion-text class="font-text font-text--large ion-padding-top">
                <strong>Quadra {{lot.quadra || ''}}, Lote {{ lot.label || '' }}</strong>
            </ion-text>

            <div class="modal__actions">
                <ion-button class="ion-padding" expand="block" mode="md" (click)="validaDataCriacaoCadastro()">
                    Continuar
                </ion-button>

                <ion-text>
                    <p class="font-text ion-text-center ion-padding-top" (click)="presentModalAskHelp()">
                        Solicitar ajuda
                    </p>
                </ion-text>
            </div>
        </div>
    </div>
</ion-content>