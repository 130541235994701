<ion-content class="ion-align-itens-center ion-padding">
    <div class="content-vertical-space-between">
        <ion-header class="ion-text-left  ion-no-border">
            <ion-text>
                <h1 class="title ion-no-margin title">Pronto!</h1>
            </ion-text><br>
            <ion-text class="ion-text-left font-text">Agora você já pode cadastrar e consultar processo de regularização.</ion-text>
        </ion-header>
        <div>
            <ion-grid class="ion-margin-top">
                <ion-row>
                    <ion-col class="photo-registration__flex">
                        <div class="photo-registration__image">
                            <ion-icon name="checkmark-sharp"></ion-icon>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center ion-padding-bottom">

                    <ion-text class="ion-text-center">
                        <p class="font-text">
                            Sua conta foi Criada
                        </p>
                    </ion-text>
                </ion-row>
                <ion-row class="ion-padding-top">
                    <ion-button mode="md" expand="block" (click)="navigateToRegistrationCompleted()">Continuar</ion-button>
                </ion-row>
            </ion-grid>
        </div>
        <usucampeao-footer></usucampeao-footer>
    </div>
</ion-content>
