import { AnotherDocumentModal } from './another-document.modal';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule,IonicModule, UiMobileModule, ReactiveFormsModule],

  declarations: [AnotherDocumentModal],

  exports:[AnotherDocumentModal],
})
export class AnotherDocumentModule {}
