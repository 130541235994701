import { Type } from 'class-transformer';
import { IsDateString, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Ref } from '../ref/ref.model';
import { EmailInfo } from './email-info.model';
import { NotificationStatus } from './notification-status.enum';
import { NotificationType } from './notification-type.enum';
import { ReasonNotification } from './reason-notification.enum';

export class Notification {
  @IsString()
  @IsOptional()
  id: string;

  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  atendimento?: Ref;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  pagamento?: Ref;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  documento?: Ref;

  @IsEnum(ReasonNotification)
  @IsNotEmpty()
  motivo: ReasonNotification;

  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  destinatario: Ref;

  @Type(() => EmailInfo)
  @ValidateNested()
  @IsNotEmpty()
  payload?: EmailInfo;

  @IsString()
  @IsNotEmpty()
  descricao: string;

  @IsEnum(NotificationStatus)
  @IsOptional()
  status: NotificationStatus;

  @IsString()
  @IsOptional()
  erro_descricao?: string;


  @IsEnum(NotificationType)
  @IsNotEmpty()
  tipo: NotificationType;

  @IsDateString()
  @IsOptional()
  data_recebimento?: Date;

  @IsDateString()
  @IsOptional()
  data_leitura?: Date;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  usuario_criacao?: Ref;

  @IsDateString()
  @IsOptional()
  data_criacao?: Date;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  usuario_atualizacao?: Ref;

  @IsDateString()
  @IsOptional()
  data_atualizacao?: Date;
}
