export enum PropertyNumberInstallments {
  IN_CASH = 'À_VISTA_COM_DESCONTO_POR_R$_3420',
  ENTRY_FOUR_INSTALLMENTS = 'ENTRADA_DE_R$_600_+_4_PARCELAS_DE_R$_750',
  ENTRY_EIGHT_INSTALLMENTS = 'ENTRADA_DE_R$_600_+_8_PARCELAS_DE_R$_375',
  ENTRY_TEN_INSTALLMENTS = 'ENTRADA_DE_R$_600_+_10_PARCELAS_DE_R$_300',
  ENTRY_TWELVE_INSTALLMENTS = 'ENTRADA_DE_R$_600_+_12_PARCELAS_DE_R$_250',
}


export function getPropertyNumberInstallments(status) {
  switch(status) {
    case PropertyNumberInstallments.IN_CASH:
    return {
      entrada: 0,
      parcela: 0,
      valor_parcela: 0,
      total: 3420,
    };
    case PropertyNumberInstallments.ENTRY_FOUR_INSTALLMENTS:
      return {
        entrada: 600,
        parcela: 4,
        valor_parcela:750,
        total: 3600,
      };
      case PropertyNumberInstallments.ENTRY_EIGHT_INSTALLMENTS:
      return {
        entrada: 600,
        parcela: 8,
        valor_parcela:375,
        total: 3600,
      };
      case PropertyNumberInstallments.ENTRY_TEN_INSTALLMENTS:
      return {
        entrada: 600,
        parcela: 10,
        valor_parcela:300,
        total: 3600,
      };
      case PropertyNumberInstallments.ENTRY_TWELVE_INSTALLMENTS:
      return {
        entrada: 600,
        parcela: 12,
        valor_parcela:250,
        total: 3600,
      };
      default:
        return {
        entrada: null,
        parcela: null,
        valor_parcela: null,
        total: 3600,
        };
  }
}
