/**
 * Enum para os tipos de referencia do documento
 */
export enum DocumentReferenceType {
  /**
   * Documento de atendimento
   */
  ATENDIMENTO = 'ATENDIMENTO',
  /**
   * Documento de imovel
   */
  IMOVEL = 'IMOVEL',
  /**
   * Documento de pessoa
   */
  PESSOA = 'PESSOA',
  /**
   * Documento de loteamento
   */
  LOTEAMENTO = 'LOTEAMENTO',
  /**
   * Documento para validação da conta de usuário
   */
  VALIDACAO_CONTA = 'VALIDACAO_CONTA'


}
