import { IsEnum, IsString } from 'class-validator';
import { CondicoesGerais } from '../condicoes-gerais.enum';
import { CoberturaTipo } from './cobertura-tipo.enum';

export class Cobertura {
  @IsEnum(CoberturaTipo)
  tipo: CoberturaTipo;

  @IsString()
  tipoEspecifique?: string;

  @IsEnum(CondicoesGerais)
  condicoesGerais: CondicoesGerais;
}
