import { IsArray, IsDateString, IsEnum, IsNotEmpty, IsNumber, IsNumberString, IsOptional, IsString } from 'class-validator';
import { PaymentType } from '../enums/payment-type.enum';

export class TransactionDto {
  @IsString()
  @IsOptional()
  id?: string;

  @IsString()
  @IsNotEmpty()
  seller: string;

  @IsString()
  @IsNotEmpty()
  customerId: string;

  @IsNumberString()
  @IsNotEmpty()
  amount: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsEnum(PaymentType)
  @IsNotEmpty()
  paymentType: PaymentType;

  @IsDateString()
  @IsOptional()
  expirationDate?: Date;

  @IsDateString()
  @IsOptional()
  paymentLimitDate?: Date;

  @IsArray()
  @IsOptional()
  bodyInstructions?: string[];

  @IsNumber()
  @IsOptional()
  installments?: number;

  bankSlipId?: string;

  @IsString()
  @IsOptional()
  bankSlipUrl?: string;

  @IsString()
  @IsOptional()
  bankSlipBarCode?: string;

  @IsString()
  @IsNotEmpty()
  splitSeller: string;

  @IsNumber()
  @IsNotEmpty()
  splitPercentage: number;
}
