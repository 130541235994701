import {
  Component,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'usucampeao-tutorial',
  templateUrl: 'tutorial.modal.html',
  styleUrls: ['tutorial.modal.scss'],
  encapsulation: ViewEncapsulation.None,
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TutorialModal implements OnInit {
  @ViewChild('swiper') swiper!: SwiperComponent;
  public showPreviousButton = false;
  public showNextButton = true;

  public config: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
    spaceBetween: 100,
  };

  public SLIDES = [
    {
      img: '../../../assets/undraw_Note_list_re_r4u9 1.svg',
      text:
        'Inicie o seu cadastro selecionando o bairro em que seu imóvel está localizado.',
    },
    {
      img: '../../../assets/undraw_Destination_re_sr74 1.svg',
      text:
        'Localize seu imóvel dentro do bairro. Um técnico irá confirmar essa informação na validação presencial.',
    },
    {
      img: '../../../assets/undraw_Project_completed_re_pqqq 1.svg',
      text: 'Preencha todas as informações sobre seu imóvel e sua família.',
    },
    {
      img: '../../../assets/undraw_export_files_re_99ar 1.svg',
      text:
        'Envie todos os documentos solicitados do seu imóvel e da sua família para validação. ',
    },
    {
      img: '../../../assets/undraw_profile_image_re_ic2f 2.svg',
      text: 'Aguarde o resultado da pré-análise dos seus dados e documentos.',
    },
    {
      img: '../../../assets/undraw_Calendar_re_ki49 1.svg',
      text:
        'Agende a visita do técnico para validação presencial dos dados e documentos. ',
    },
    {
      img: '../../../assets/undraw_contract_uy56 1.svg',
      text:
        'Durante a visita do técnico, você também precisará assinar declarações para anexar ao processo.',
    },
    {
      img: '../../../assets/undraw_attached_file_n4wm 1.svg',
      text:
        'Aguarde a aprovação e publicação no Diário Oficial e o registro no cartório de imóveis.',
    },
    {
      img: '../../../assets/undraw_location_review_dmxd 2.svg',
      text:
        'A sua escritura registrada será entregue em evento com sua família. Parabéns por essa conquista!',
    },
  ];

  constructor(
    private ngZone: NgZone,
    public modalController: ModalController,
  ) { }

  ngOnInit() {
    SwiperCore.use([Pagination]);
  }

  private updateButtonsFlags() {
    this.showPreviousButton = this.swiper?.swiperRef?.activeIndex > 0;
    this.showNextButton =
      this.swiper?.swiperRef?.activeIndex < this.SLIDES.length - 1;
  }

  swiperSlideChanged() {
    this.ngZone.run(() => this.updateButtonsFlags());
  }

  slideNext() {
    this.swiper.swiperRef.slideNext();
  }
  slidePrev() {
    this.swiper.swiperRef.slidePrev();
  }

  skipTutorial(): void {
    this.modalController.dismiss();
  }

  finishTutorial(): void {
    this.modalController.dismiss();
  }
}
