<div class="borderlist">
  <ion-item lines="none" detail class="ion-no-padding ion-margin-bottom">
    <ion-icon class="centrohome" *ngIf="home" name="barcode-outline"></ion-icon>

    <ion-badge *ngIf="!home" [color]="cor" mode="md">
      <ion-icon class="centro" name="barcode-outline"></ion-icon>
    </ion-badge>

    <div class="ion-padding-start infos">
      <ion-text *ngIf="!home">
        <h2 class="font-text ion-no-margin">{{ pagamento.parcela === 0 ? 'Entrada' : 'Parcela ' + pagamento.parcela }}
        </h2>
      </ion-text>

      <ion-text [class.description-text]="!home">
        {{pagamento.valorDevido | currency:'BRL' || '' }}
      </ion-text><br>

      <ion-text class="description-text">
        Vencimento em: {{pagamento.dataVencimento| date: 'dd/MM/yyyy'||'--' || '' }}
      </ion-text><br>

      <ion-text *ngIf="home" class="description-text">
        <ion-button style=" margin-top: 6px; width: 4rem;" size="small">PAGAR</ion-button>
      </ion-text>

      <ion-chip [class.atraso]="pagamentoAtrasado" slot="end" *ngIf="mostrarStatusPagamento">
        <ion-label>{{ pagamentoAtrasado ? 'Em atraso' : 'Prox. Vencimento' }}</ion-label>
      </ion-chip>
    </div>
  </ion-item>
</div>
