import { IsBoolean, IsEnum } from 'class-validator';
import { CondicoesGerais } from '../condicoes-gerais.enum';
import { Fossa } from './fossa-tipo.enum';

export class EsgotamentoSanitario {
  @IsBoolean()
  ligacaoRedePublica: boolean;

  @IsBoolean()
  ligacaoRedePluvial: boolean;

  @IsEnum(Fossa)
  fossa: Fossa;

  @IsEnum(CondicoesGerais)
  condicoesGerais: CondicoesGerais;
}
