import { PropertySituationPipe } from './../../../../../pipes/property-situation.pipe';
import { PropertyUsagePipe } from './../../../../../pipes/property-usage.pipe';
import { PropertyOccupancy, PropertySituation, PropertyUsage } from "@usucampeao/lib-reurb-simplificado";
import { Dropdown, ElementBase, InputMode, Textbox } from "@usucampeao/ui-mobile";
import { Validators } from '@angular/forms';
import { PropertyOccupancyPipe } from '../../../../../pipes/property-occupancy.pipe';


export function getFormLotAddress(propertyUsagePipe: PropertyUsagePipe): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'usage',
      label: 'Utilização do imóvel',
      options: Object.values(PropertyUsage)
        .map(usage => {
          return {
            key: usage,
            value: propertyUsagePipe.transform(usage)
          }
        }),
      validators: [Validators.required]
    }),
  ]
}

export function getFormSpecifyUsagel(): ElementBase<string>[] {
  return [
    new Textbox({
      key: 'specifyUsage',
      label: 'Especifique o uso',
    }),
  ]
}

export function getFormOccupation(propertyOccupancyPipe: PropertyOccupancyPipe, propertySituationPipe: PropertySituationPipe): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'occupation',
      label: 'Tipo de ocupação',
      options: Object.values(PropertyOccupancy)
        .map(occupation => {
          return {
            key: occupation,
            value: propertyOccupancyPipe.transform(occupation)
          }
        }),
      validators: [Validators.required]
    }),
    new Dropdown({
      key: 'situation',
      label: 'Situação do imóvel',
      options: Object.values(PropertySituation)
        .map(situation => {
          return {
            key: situation,
            value: propertySituationPipe.transform(situation)
          }
        }),
      validators: [Validators.required]
    }),
  ]
}

export function getFormSpecifySituation(): ElementBase<string>[] {
  return [
    new Textbox({
      key: 'SpecifySituation',
      label: 'Especifique a situação',
    }),
  ]
}

export function getFormResidentsQuantity(): ElementBase<string>[] {
  return [

    new Textbox({
      key: 'residentsQuantity',
      label: 'Quantidade de moradores',
      value: '0',
      inputMode: InputMode.NUMBER,
      type: 'number',
      validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
    }),
    new Textbox({
      key: 'residenceTime',
      label: 'Tempo de residência (em anos)',
      inputMode: InputMode.NUMBER,
      type: 'number',
      validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
    }),
    new Textbox({
      key: 'housesQuantity',
      label: 'Estão no mesmo lote',
      description: 'Especifique a quantidade de moradias que...',
      value: '0',
      inputMode: InputMode.NUMBER,
      type: 'number',
      validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
    }),
    new Textbox({
      key: 'housesQuantitySameOwner',
      label: 'São do mesmo proprietário',
      value: '0',
      inputMode: InputMode.NUMBER,
      type: 'number',
      validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
    }),
    new Textbox({
      key: 'entriesQuantity',
      label: 'Possuem entradas independentes',
      value: '0',
      inputMode: InputMode.NUMBER,
      type: 'number',
      validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
    }),
    new Textbox({
      key: 'entriesQuantitySamePavement',
      label: 'Acessam pelo mesmo pavimento',
      note: 'Pode ser mesmo quintal ou viela',
      value: '0',
      inputMode: InputMode.NUMBER,
      type: 'number',
      validators: [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]
    }),
  ];
}

export function getFormAddress(): ElementBase<string>[] {
  return [
    new Textbox({
      key: 'zipCode',
      label: 'CEP',
      description: 'Endereço',
      mask: 'cep',
      inputMode: InputMode.PHONE,
      type: 'tel',
      validators: [Validators.required, Validators.minLength(9), Validators.maxLength(9)]
    }),
    new Textbox({
      key: 'street',
      label: 'Logradouro',
      validators: [Validators.required]
    }),
    new Textbox({
      key: 'number',
      label: 'Número',
      type: 'number',
      inputMode: InputMode.PHONE,
      validators: [Validators.required]
    }),
    new Textbox({
      key: 'complement',
      label: 'Complemento',
    }),
    new Textbox({
      key: 'neighborhood',
      visible: false,
    }),
    new Textbox({
      key: 'city',
      visible: false,
    }),
    new Textbox({
      key: 'state',
      visible: false,
    }),
  ];
}
