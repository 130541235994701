import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ToolbarComponent } from '../../components/toolbar/toolbar.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [CommonModule, IonicModule, LazyLoadImageModule],
  exports: [ToolbarComponent],
})
export class ToolbarModule {



}
