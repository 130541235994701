import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDateString,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { EstadoCivil } from '../../enums/estado-civil.enum';
import { Genero } from '../../enums/genero.enum';
import { GrauInstrucao } from '../../enums/grau-instrucao.enum';
import { SituacaoProfissional } from '../../enums/situacao-profissional.enum';
import { BeneficioSocial } from './beneficio-social.enum';
import { DadosMaritais } from './dados-maritais.model';
import { DocumentosCadastroSocial } from './documentos.model';
import { DadosEmancipacao } from './emancipacao.model';
import { Filiacao } from './filiacao.model';
import { Naturalidade } from './naturalidade.model';
import { RelacaoFamiliar } from './relacao-familiar.enum';
export class MoradorCadastroSocial {
  @IsString()
  nome: string;

  @Type(() => DocumentosCadastroSocial)
  @IsNotEmpty()
  @ValidateNested()
  documentos: DocumentosCadastroSocial;

  @IsString()
  @IsOptional()
  celular: string;

  @IsEmail()
  email: string;

  @Type(() => Filiacao)
  @ValidateNested()
  filiacao: Filiacao;

  @Type(() => DadosMaritais)
  @ValidateNested()
  @IsOptional()
  dadosMaritais: DadosMaritais;

  @IsDateString()
  @IsOptional()
  dataNascimento: Date;

  @Type(() => DadosEmancipacao)
  @ValidateNested()
  dadosEmancipacao: DadosEmancipacao;

  @IsEnum(Genero)
  @IsOptional()
  genero: Genero;

  @IsString()
  @IsOptional()
  generoEspecifique?: string;

  @IsEnum(EstadoCivil)
  @IsOptional()
  estadoCivil: EstadoCivil;

  @IsEnum(GrauInstrucao)
  @IsOptional()
  grauInstrucao: GrauInstrucao;

  @IsEnum(SituacaoProfissional)
  @IsOptional()
  situacaoProfissional: SituacaoProfissional;

  @IsString()
  @IsOptional()
  situacaoProfissionalEspecifique?: string;

  @Type(() => Naturalidade)
  @ValidateNested()
  naturalidade: Naturalidade;

  @IsEnum(BeneficioSocial)
  @IsOptional()
  beneficioSocial?: BeneficioSocial;

  @IsBoolean()
  @IsOptional()
  deficiencia: boolean;

  @IsString()
  @IsOptional()
  deficienciaEspecifique?: string;

  @IsBoolean()
  @IsOptional()
  problemaSaude: boolean;

  @IsString()
  @IsOptional()
  problemaSaudeEspecifique?: string;

  @IsNumber()
  @IsOptional()
  rendaBruta: number;

  @IsEnum(RelacaoFamiliar)
  @IsOptional()
  relacaoFamiliar?: RelacaoFamiliar;
}
