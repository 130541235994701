import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';
import { ParcelaModule } from '../../../../components/parcela/parcela.module';
import { ResumoFinanceiroModule } from '../../../../components/resumo-financeiro/resumo-financeiro.module';
import { ToolbarModule } from '../../../../components/toolbar/toolbar.module';
import { PipesModule } from '../../../../pipes/pipes.module';
import { RegistrationHelpModule } from '../../../modal/registration-help/registration-help.module';
import { DetalhesPageRoutingModule } from './detalhes-routing.module';
import { DetalhesPage } from './detalhes.page';

@NgModule({
  imports: [
    CommonModule,
    UiMobileModule,
    IonicModule,
    ToolbarModule,
    FooterModule,
    HeaderModule,
    DetalhesPageRoutingModule,
    PipesModule,
    ResumoFinanceiroModule,
    ParcelaModule,
    RegistrationHelpModule,
  ],
  providers: [
  ],
  declarations: [DetalhesPage],
})
export class DetalhesPageModule { }
