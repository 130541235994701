/**
 * Enum para o dia de vencimento do boleto de pagamento.
 */
export enum PaymentDueDay {
  /**
   * Vencimento todo dia 5
   */
  CINCO = 5,
  /**
   * Vencimento todo dia 10
   */
  DEZ = 10,
  /**
   * Vencimento todo dia 15
   */
  QUINZE = 15
}
