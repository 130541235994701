import { IsEnum, IsString } from 'class-validator';
import { CondicoesGerais } from '../condicoes-gerais.enum';
import { ParedesExternasTipo } from './paredes-externas-tipo.enum';

export class ParedesExternas {
  @IsEnum(ParedesExternasTipo)
  tipo: ParedesExternasTipo;

  @IsString()
  tipoEspecifique?: string;

  @IsEnum(CondicoesGerais)
  condicoesGerais: CondicoesGerais;
}
