<div [formGroup]="form">
    <div [ngSwitch]="field.controlType" [class.form-group]="field.visible">
        <ion-text *ngIf="field.description !== ''" class="description-text description-text--medium">{{field.description}}
        </ion-text>
        <div *ngIf="field.visible">
            <ion-item class="input" *ngSwitchCase="'textbox'">
                <ion-label mode="md" position="floating" [attr.for]="field.key">
                    <ion-text class="input-text">
                        {{ field.label }}
                    </ion-text>
                </ion-label>
                <ion-input mode="md" [formControlName]="field.key" [id]="field.key" [inputmode]="field.inputMode" [type]="field.type" [readonly]="field.readonly" min="0" (keydown)="acceptWithMask($event, field.mask)" (textInput)="acceptWithMaskAndroid($event, field.mask)" (ionInput)="updateWithMask($event, field.key, field.mask)">
                </ion-input>
            </ion-item>
        </div>
        <div *ngIf="field.visible">
            <ion-item *ngSwitchCase="'radiolist'" lines="none" class="ion-no-padding">
                <ion-list mode="md" lines="none">
                    <ion-radio-group class="radiolist" [disabled]="field.readonly" [id]="field.key" [formControlName]="field.key">
                        <ion-item *ngFor="let opt of field.options" class="ion-no-padding">
                            <ion-radio color="secondary" [value]="opt.key" class="ion-margin-horizontal"></ion-radio>
                            <ion-label>{{ opt.value }}</ion-label>
                        </ion-item>
                    </ion-radio-group>
                </ion-list>
            </ion-item>
        </div>
        <div *ngIf="field.visible">
            <ion-item class="input" *ngSwitchCase="'date'" (click)="openDatepickerModal()">
                <ion-label position="floating" [attr.for]="field.key">
                    <ion-text class="input-text">
                        {{ field.label }}
                    </ion-text>
                </ion-label>
                <ion-input [id]="field.key" type="text" readonly="true" [ngModel]="selectedDate| date: 'dd/MM/YYYY'" [ngModelOptions]="{standalone: true}"></ion-input>
                <ion-icon class="suffix-icon" color="medium" name="calendar" slot="end" [class.disabled]="field.readonly">
                </ion-icon>
            </ion-item>
        </div>
        <div *ngIf="field.visible">
            <ion-item class="input" *ngSwitchCase="'dropdown'">
                <ion-label position="floating" [attr.for]="field.key">
                    <ion-text class="input-text">
                        {{ field.label }}
                    </ion-text>
                </ion-label>
                <ion-select [disabled]="field.disabled" [id]="field.key" [formControlName]="field.key" okText="Selecionar" cancelText="Cancelar">
                    <ion-select-option *ngFor="let opt of field.options" [value]="opt.key">
                        {{ opt.value }}</ion-select-option>
                </ion-select>
            </ion-item>
        </div>
        <div *ngIf="field.visible">
            <ion-item class="textarea" *ngSwitchCase="'textarea'">
                <ion-label position="floating" [attr.for]="field.key">
                    <ion-text class="input-text">
                        {{ field.label }}
                    </ion-text>
                </ion-label>
                <ion-textarea rows="6" cols="20" [formControlName]="field.key" [id]="field.key"></ion-textarea>
            </ion-item>
        </div>
        <ion-text *ngIf="field.note !== '' && !showMessageError" class="description-text">{{field.note}}</ion-text>

        <div class="error-message" *ngIf="showMessageError">
            <ion-text color="danger">
                <span>{{messageError}}</span>
            </ion-text>
        </div>
    </div>
</div>
