import { TabelaPrecoParcela } from './tabela-preco-parcela.enum';

export class TabelaPrecoParcelaListaDto {
  parcela: TabelaPrecoParcela;
  valorAto: number;
  quantidadeParcela: number;
  valorParcela: number;
  valorTotal: number;

  constructor(
    parcela: TabelaPrecoParcela,
    valorAto: number,
    quantidadeParcela: number,
    valorParcela: number,
    valorTotal: number,
  ) {
    this.parcela = parcela;
    this.valorAto = valorAto;
    this.quantidadeParcela = quantidadeParcela;
    this.valorParcela = valorParcela;
    this.valorTotal = valorTotal;
  }
}
