import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SwiperModule } from 'swiper/angular';

import { CreateAccountPage } from './create-account/create-account.page';
import { PrivacyPolicyModule } from './create-account/privacy-policy/privacy-policy.module';
import { EditPersonalPhotoPage } from './edit-photo/edit-personal-photo';
import { HelpPage } from './help/help.page';
import { LoginPage } from './login.page';
import { LoginPageRoutingModule } from './login-routing.module';
import { NumberAuthenticationPage } from './number-authentication/number-authentication.page';
import { PhotoRegistrationPage } from './photo-registration/photo-registration.page';
import { RegistrationCompletedPage } from './registration-completed/registration-completed';
import { ViewAccountPage } from './view-account/view-account.page';
import { FooterModule } from '../../../components/footer/footer.module';
import { ToolbarModule } from '../../../components/toolbar/toolbar.module';
import { MenuItemModule } from '../../../components/menu-item/menu-item.module';
import { PhonePipe } from '../../../pipes/telefone.pipe';
import { PhotoModule } from '../../../components/photo/photo.module';
import { TutorialModule } from './../../modal/tutorial/tutorial.module';
import { HeaderModule } from './../../../components/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    LazyLoadImageModule,
    UiMobileModule,
    ReactiveFormsModule,
    LoginPageRoutingModule,
    ToolbarModule,
    FormsModule,
    MenuItemModule,
    FooterModule,
    PhotoModule,
    HeaderModule,
    SwiperModule,
    TutorialModule,
    PrivacyPolicyModule,
  ],
  declarations: [
    LoginPage,
    PhonePipe,
    ViewAccountPage,
    CreateAccountPage,
    NumberAuthenticationPage,
    HelpPage,
    EditPersonalPhotoPage,
    PhotoRegistrationPage,
    RegistrationCompletedPage,
  ],
})
export class LoginModule { }
