import { IsNotEmpty, IsString } from 'class-validator';

export class EmailInfo {
  @IsString()
  @IsNotEmpty()
  toAddress: string;

  @IsString(({ each: true }))
  @IsNotEmpty()
  ccAddresses?: string[];

  @IsString()
  @IsNotEmpty()
  subject: string;

  @IsString()
  @IsNotEmpty()
  template: string;

  attachments?: any[];
}
