import { Endereco } from '@usucampeao/interfaces';
import { Expose } from 'class-transformer';

export class PayloadNotificacaoDto {
  @Expose()
  alteracoes?: string[];

  @Expose()
  dataVencimento?: Date;

  @Expose()
  valorPagamento?: number;

  @Expose()
  dataVisita?: Date;

  @Expose()
  valorDevido?: number;

  @Expose()
  endereco?: Endereco;

  @Expose()
  parcela?: number;

  @Expose()
  pagamentoId?: string;
}
