import { PaginationLinks } from './pagination-links.interface';
import { PaginationMeta } from './pagination-meta.interface';

export class Pagination<T> {
  items: T[];
  links: PaginationLinks;
  meta: PaginationMeta;

  constructor(items: T[], meta: PaginationMeta, links: PaginationLinks) {
    this.items = items;
    this.meta = meta;
    this.links = links;
  }
}
