import { Type } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { RegistrationStatus } from './registration-status.enum';

export class CadastroAlterarStatusDto {
  @IsEnum(RegistrationStatus)
  @IsNotEmpty()
  status: RegistrationStatus;

  @IsString()
  @IsOptional()
  diarioOficialLink?: string;

  @IsString()
  @IsOptional()
  prefeituraProtocolo?: string;

  @IsString()
  @IsOptional()
  cartorioProtocolo?: string;

  @Type(() => Date)
  @IsDate()
  @IsOptional()
  entregaDataPrevista?: Date;

  @IsString()
  @IsOptional()
  entregaLocal?: string;

  @Type(() => Date)
  @IsDate()
  @IsOptional()
  entregaData?: Date;
}
