export enum PagamentoStatus {
  CANCELADO = 'CANCELADO',
  CRIADO = 'CRIADO',
  EM_ANALISE = 'EM_ANALISE',
  ERRO = 'ERRO',
  MANUAL = 'MANUAL',
  NAO_PAGO = 'NAO_PAGO',
  SOLICITADO_EMISSAO_PAGAMENTO = 'SOLICITADO_EMISSAO_PAGAMENTO',
  PAGO = 'PAGO',
  PAGO_CANCELADO = 'PAGO_CANCELADO',
  PROGRAMADO = 'PROGRAMADO'
}
