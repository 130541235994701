import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Bugsnag from '@bugsnag/browser';
import { ToastController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { OwnerDto } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { OwnersQuery } from './state/owners.query';
import { OwnersService } from './state/owners.service';

@Component({
  selector: 'usucampeao-owners',
  templateUrl: 'owners.page.html',
  styleUrls: ['owners.page.scss'],
})
export class OwnersPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  defaultImage = '../../../assets/person-circle-outline.svg';
  public owners: Partial<OwnerDto>[];
  private registrationId: string;
  private ngUnsubscribe = new Subject();
  public spouse: OwnerDto;
  public owner: OwnerDto;

  constructor(
    private ownersService: OwnersService,
    private ownersQuery: OwnersQuery,
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.registrationId = params.id;
        this.getOwners();
      },
        async (erro) => {
          Bugsnag.notify('Detalhes proprietario - erro ao buscar proprietarios', erro)
          const toast = await this.toastController.create({
            message: 'Erro ao buscar os dados. Tente novamente mais tarde.',
            duration: 5000,
          });
          toast.present();
        });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  private getOwners() {
    this.ownersQuery.selectAll()
      .pipe(
        switchMap(owners => {
          if (owners.length === 0) {
            return this.ownersService.getOwnersByRegistrationId(this.registrationId);
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((owners) => { this.owners = owners },
        async (erro) => {
          Bugsnag.notify('Detalhes proprietario - erro ao buscar proprietarios', erro)
          const toast = await this.toastController.create({
            message: 'Erro ao buscar os dados. Tente novamente mais tarde.',
            duration: 5000,
          });
          toast.present();
        });
  }

  public navigateToProprietaryEdit(): void {
    this.router.navigate([`detalhes`], { relativeTo: this.route });
  }

  public navigateToProprietary(): void {
    this.router.navigate([`editar-dados`], { relativeTo: this.route });
  }
}
