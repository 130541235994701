import { Component, Input } from '@angular/core';
import {
  CadastroContratoDto,
  MaritalStatus,
  MeioPagamento,
  OwnerDto,
  PropertyDto,
  PropertyUsage
} from '@usucampeao/lib-reurb-simplificado';
import { TipoContract } from '../enums/tipo-contract.enum';

@Component({
  selector: 'lib-print-contract',
  templateUrl: './print-contract.component.html',
  styleUrls: ['./print-contract.component.scss']
})
export class PrintContractComponent {
  @Input() cadastro!: CadastroContratoDto;
  @Input() proprietario!: Partial<OwnerDto>;
  @Input() conjuge!: Partial<OwnerDto>;
  @Input() imovel!: Partial<PropertyDto>;
  @Input() tipo!: TipoContract;
  @Input() isPrint = false;

  public get meioPagamento(): typeof MeioPagamento {
    return MeioPagamento
  }

  public get imovelTipo(): typeof PropertyUsage {
    return PropertyUsage
  }

  public get maritalStatus(): typeof MaritalStatus {
    return MaritalStatus
  }

  public get tipoContract(): typeof TipoContract {
    return TipoContract
  }
}
