<div id="print-contract">
    <table>
        <thead>
            <tr>
                <td>
                    <div class="header-space">&nbsp;</div>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="content" [ngSwitch]="tipo">
                        <usu-procuracao-reurb-averbacao *ngSwitchCase="tipoContract.PROCURACAO_REURB_AVERBACAO" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-procuracao-reurb-averbacao>

                        <usu-morador-averbacao *ngSwitchCase="tipoContract.MORADOR_AVERBACAO" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-morador-averbacao>

                        <usu-morador-usucampeao-2021 *ngSwitchCase="tipoContract.MORADOR_USUCAMPEAO_2021"  [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro" [isPrint]="true"></usu-morador-usucampeao-2021>

                        <usu-morador-usucampeao-reurb-averbacao *ngSwitchCase="tipoContract.MORADOR_USUCAMPEAO_REURB_AVERBACAO" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-morador-usucampeao-reurb-averbacao>

                        <usu-procuracao-averbacao *ngSwitchCase="tipoContract.PROCURACAO_AVERBACAO" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-procuracao-averbacao>

                        <usu-declaracao-de-autonomo-e-renda *ngSwitchCase="tipoContract.DECLARACAO_DE_AUTONOMO_DE_RENDA" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-declaracao-de-autonomo-e-renda>

                        <usu-questionario-juridico *ngSwitchCase="tipoContract.QUESTIONARIO_JURIDICO" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-questionario-juridico>

                        <usu-declaracao-de-domicilio *ngSwitchCase="tipoContract.DECLARACAO_DE_DOMICILIO" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-declaracao-de-domicilio>

                        <usu-declaracao-de-vizinhos *ngSwitchCase="tipoContract.DECLARACAO_DE_VIZINHOS" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro"></usu-declaracao-de-vizinhos>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td>
                    <div class="footer-space">&nbsp;</div>
                </td>
            </tr>
        </tfoot>
    </table>
    <div class="header">
        <img src="assets/images/logo.png" alt="UsuCampeão logo">
    </div>
    <div class="footer">
        <p>
            SP - Rua Claudio Soares, 72, 6º andar, bairro Pinheiros, CEP 05.422-030, São Paulo - SP, inscrita no CNPJ/MF sob o nº 36.174.049/0001-08. - MINAS – Rua Jose Rodrigues Pereira, 514, 2˚ andar – Ed Datasinc – Buritis – BH/MG - fones: SP: (11) 2537-9430 MG:
            (31) 7576-99950 SC: (48) 999260832 <br> e-mail: reurb@usucampeao.com.br
        </p>
    </div>
</div>

<div [ngSwitch]="tipo">

    <usu-procuracao-reurb-averbacao [proprietario]="proprietario" [conjuge]="conjuge" [proprietario]="proprietario" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.PROCURACAO_REURB_AVERBACAO"></usu-procuracao-reurb-averbacao>

    <usu-morador-averbacao [proprietario]="proprietario" [conjuge]="conjuge" [proprietario]="proprietario" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.MORADOR_AVERBACAO"></usu-morador-averbacao>

    <usu-morador-usucampeao-2021 [proprietario]="proprietario" [conjuge]="conjuge" [proprietario]="proprietario" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.MORADOR_USUCAMPEAO_2021"></usu-morador-usucampeao-2021>

    <usu-morador-usucampeao-reurb-averbacao [proprietario]="proprietario" [conjuge]="conjuge" [proprietario]="proprietario" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.MORADOR_USUCAMPEAO_REURB_AVERBACAO"></usu-morador-usucampeao-reurb-averbacao>

    <usu-procuracao-averbacao [proprietario]="proprietario" [conjuge]="conjuge" [proprietario]="proprietario" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.PROCURACAO_AVERBACAO"></usu-procuracao-averbacao>


    <usu-declaracao-de-autonomo-e-renda [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.DECLARACAO_DE_AUTONOMO_DE_RENDA"></usu-declaracao-de-autonomo-e-renda>

    <usu-questionario-juridico [conjuge]="conjuge" [proprietario]="proprietario" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.QUESTIONARIO_JURIDICO"></usu-questionario-juridico>

    <usu-declaracao-de-domicilio [conjuge]="conjuge" [proprietario]="proprietario" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.DECLARACAO_DE_DOMICILIO"></usu-declaracao-de-domicilio>

    <usu-declaracao-de-vizinhos [conjuge]="conjuge" [proprietario]="proprietario" [imovel]="imovel" [cadastro]="cadastro" *ngSwitchCase="tipoContract.DECLARACAO_DE_VIZINHOS"></usu-declaracao-de-vizinhos>
</div>
