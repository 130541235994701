import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { FooterModule } from '../../components/footer/footer.module';
import { HeaderModule } from '../../components/header/header.module';
import { ParcelaModule } from '../../components/parcela/parcela.module';
import { TutorialModule } from './../modal/tutorial/tutorial.module';
import { AttendanceCardComponentModule } from './attendance-card/attendance-card.module';
import { HomePageRoutingModule } from './home-routing.module';
import { HomePage } from './home.page';

@NgModule({
  imports: [
    AttendanceCardComponentModule,
    CommonModule,
    FormsModule,
    FooterModule,
    HeaderModule,
    HomePageRoutingModule,
    ParcelaModule,
    IonicModule,
    UiMobileModule,
    ReactiveFormsModule,
    TutorialModule,
  ],
  declarations: [HomePage],
})
export class HomePageModule { }
