import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ChangeAlertModule } from '../../../../components/change-alert/change-alert.module';
import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';
import { ProcessProgressModule } from '../../../../components/process-progress/process-progress.module';
import { SkeletonItemModule } from '../../../../components/skeleton-item/skeleton-item.module';
import { PipesModule } from '../../../../pipes/pipes.module';
import { AskHelpModule } from '../../../modal/ask-help/ask-help.module';
import { InstructionModule } from '../../../modal/instruction/instruction.module';
import { PropertyDocumentsPageRoutingModule } from './property-documents-routing.module';
import { PropertyDocumentsPage } from './property-documents.page';

@NgModule({
  imports: [
    CommonModule,
    UiMobileModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    PipesModule,
    PropertyDocumentsPageRoutingModule,
    ProcessProgressModule,
    HeaderModule,
    FooterModule,
    SkeletonItemModule,
    ChangeAlertModule,
    InstructionModule,
    AskHelpModule
  ],
  declarations: [
    PropertyDocumentsPage,
  ],
})
export class PropertyDocumentsModule { }
