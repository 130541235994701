import { Validators } from '@angular/forms';
import {
  DiaPagamento, PaymentType, PropertyCardInstallments, TabelaPrecoParcelaListaDto
} from '@usucampeao/lib-reurb-simplificado';
import { Dropdown, ElementBase } from '@usucampeao/ui-mobile';
import { DiaPagamentoPipe } from '../../../pipes/dia-pagamento.pipe';
import { PropertyCardInstallmentsPipe } from './../../../pipes/property-card-installments.pipe';
import { PropertyNumberInstallmentsPipe } from './../../../pipes/property-number-installments.pipe';
import { PropertyPaymentPipe } from './../../../pipes/property-payment.pipe';

export function getformPayment(
  propertyPaymentPipe: PropertyPaymentPipe
): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'paymentType',
      label: 'Forma de pagamento',
      value: PaymentType.TICKET,
      options: [
        {
          key: PaymentType.TICKET,
          value: propertyPaymentPipe.transform(PaymentType.TICKET),
        },
      ],
      validators: [Validators.required],
    }),
  ];
}
export function getformCardInstallments(
  propertyCardInstallmentsPipe: PropertyCardInstallmentsPipe
): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'CardInstallments',
      label: 'Número de parcelas',
      options: Object.values(PropertyCardInstallments).map(
        (CardInstallments) => {
          return {
            key: CardInstallments,
            value: propertyCardInstallmentsPipe.transform(CardInstallments),
          };
        }
      ),
    }),
  ];
}
export function getformNumberInstallments(
  propertyNumberInstallmentsPipe: PropertyNumberInstallmentsPipe, parcelas: TabelaPrecoParcelaListaDto[]
): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'installments',
      label: 'Número de parcelas',
      options: parcelas.map(
        (parcelas) => {
          return {
            key: parcelas.parcela,
            value: propertyNumberInstallmentsPipe.transform(parcelas.parcela),
          };
        }
      ),
      validators: [Validators.required],
    }),
  ];
}

export function getformExpirationPreference(
  propertyExpirtationPreferencePipe: DiaPagamentoPipe
): ElementBase<string>[] {
  return [
    new Dropdown({
      key: 'dueDate',
      label: 'Preferência de vencimento',
      options: Object.values(DiaPagamento).map((preference: DiaPagamento) => {
        return {
          key: preference.toString(),
          value: propertyExpirtationPreferencePipe.transform(preference),
        };
      }),
      validators: [Validators.required],
    }),
  ];
}
