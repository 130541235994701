import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { ResumoFinanceiroComponent } from './resumo-financeiro.component';

@NgModule({
  declarations: [ResumoFinanceiroComponent],
  imports: [CommonModule, IonicModule, PipesModule],
  exports: [ResumoFinanceiroComponent],
})
export class ResumoFinanceiroModule {


}
