import { RegistrationStatus } from './registration-status.enum';

export class ListaCadastroDto {
  id: string;
  ownerName: string;
  lote: string;
  quadra: string;
  status: RegistrationStatus;
  updatedAt: Date;

  constructor(
    id: string,
    ownerName: string,
    lote: string,
    quadra: string,
    status: RegistrationStatus,
    updatedAt: Date,
  ) {
    this.id = id;
    this.ownerName = ownerName;
    this.lote = lote;
    this.quadra = quadra;
    this.status = status;
    this.updatedAt = updatedAt;
  }
}
