import { VencimentoProximoComponent } from './componentes/vencimento-próximo/vencimento-proximo.component';
import { VencimentoAmanhaComponent } from './componentes/vencimento-amanhã/vencimento-amanha.component';
import { VenhaBuscarTituloComponent } from './componentes/venha-buscar-titulo/venha-buscar-titulo.component';
import { PendenciasEncontradasComponent } from './componentes/pendencias-encontradas/pendencias-encontradas.component';
import { PendenciasCadastroComponent } from './componentes/pendencias-cadastro/pendencias-cadastro.component';
import { CadastroPreAprovadoComponent } from './componentes/cadastro-pre-aprovado/cadastro-pre-aprovado.component';
import { AgendamentoRealizadoComponent } from './componentes/agendamento-realizado/agendamento-realizado.component';
import { AgendamentoDisponivelComponent } from './componentes/agendamento-disponivel/agendamento-disponivel.component';

import { RouterModule } from '@angular/router';
import { LazyLoadImageHooks } from './../../hooks/lazy-load-image-hooks';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';

import { ToSeePage } from './toSee/to-see.page';
import { NotificationBadgeComponentModule } from './notification-attendance-badge/notification-badge.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NotificationPage } from './notification.page';
import { NotificationPageRoutingModule } from './notification-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { HeaderModule } from '../../components/header/header.module';
import { FooterModule } from '../../components/footer/footer.module';

@NgModule({
  imports: [
    CommonModule,
    UiMobileModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    LazyLoadImageModule,
    NotificationPageRoutingModule,
    HeaderModule,
    FooterModule,
    NotificationBadgeComponentModule,
    RouterModule,
  ],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks,
    },
  ],
  declarations: [
    NotificationPage,
    ToSeePage,
    AgendamentoDisponivelComponent,
    AgendamentoRealizadoComponent,
    CadastroPreAprovadoComponent,
    PendenciasCadastroComponent,
    PendenciasEncontradasComponent,
    VenhaBuscarTituloComponent,
    VencimentoAmanhaComponent,
    VencimentoProximoComponent,
  ],
})
export class NotificationPageModule {}
