<ion-item lines="none">
    <ion-grid>
        <ion-row *ngIf="status">
            <ion-col size="2">
                <ion-icon [name]="statusIcon(status)" *ngIf="statusIcon(status) !== 'ribbon-sharp'" [ngClass]="status ? (status | lowercase) : 'gray'"></ion-icon>
                <ion-icon src="assets/ribbon.svg" *ngIf="statusIcon(status) === 'ribbon-sharp'"></ion-icon>
            </ion-col>
            <ion-col size="10" class="ion-justify-text">
                <ion-row>
                    <ion-text class="font-text font-text--large">{{ statusTitle(status) || "" }}</ion-text>
                </ion-row>
                <ion-row>
                    <ion-text class="description-text">{{ statusDescription(status) || ""}}</ion-text>
                </ion-row>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!status">
            <ion-col size="2">
                <ion-skeleton-text animated style="width: 35px; height: 35px; border-radius: 18px;"></ion-skeleton-text>
            </ion-col>
            <ion-col size="10" class="ion-justify-text">
                <ion-row>
                    <ion-skeleton-text animated style="width: 80%; height: 1.125rem;"></ion-skeleton-text>
                </ion-row>
                <ion-row>
                    <ion-skeleton-text animated style="width: 90%; height: 0.75rem;"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 50%; height: 0.75rem;"></ion-skeleton-text>
                </ion-row>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>