import { Expose, plainToClass, Type } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { MeioPagamento, PagamentoDto } from '.';

export class PagamentoCriarDto {
  @Expose()
  @IsNumber()
  @IsNotEmpty()
  parcela: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  totalParcelas: number;

  @Expose()
  @IsString()
  @IsOptional()
  descricao?: string;

  @Expose()
  @IsEnum(MeioPagamento)
  @IsNotEmpty()
  meioPagamento: MeioPagamento;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  valorDevido: number;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  dataVencimento: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  proprietarioId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  imovelId: string;

  @Expose()
  @IsString()
  @IsOptional()
  pagamentoAntigoId?: string;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  pagamentoAntigoVencimento?: Date;

  constructor(
    parcela: number,
    totalParcelas: number,
    meioPagamento: MeioPagamento,
    valorDevido: number,
    dataVencimento: Date,
    proprietarioId: string,
    imovelId: string,
    descricao?: string,
    pagamentoAntigoId?: string,
    pagamentoAntigoVencimento?: Date
  ) {
    this.parcela = parcela;
    this.totalParcelas = totalParcelas;
    this.meioPagamento = meioPagamento;
    this.valorDevido = valorDevido;
    this.dataVencimento = dataVencimento;
    this.proprietarioId = proprietarioId;
    this.imovelId = imovelId;
    this.descricao = descricao;
    this.pagamentoAntigoId = pagamentoAntigoId;
    this.pagamentoAntigoVencimento = pagamentoAntigoVencimento;
  }

  /**
   * Cria uma nova instância de PagamentoCriarDto a partir de dtos
   * @param props DTO de Cadastro
   * @returns nova instância de PagamentoCriarDto
   */
  static from(props?: PagamentoDto | Partial<PagamentoDto>): PagamentoCriarDto {
    return plainToClass(PagamentoCriarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
