import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { CreateRegistrationDto, GetUpdatePropertyLocalizationDto } from '@usucampeao/lib-reurb-simplificado';
import { Subject, Subscription } from 'rxjs';
import { concatMap, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Lot } from '../../../../models/lot.interface';
import { LoadingService } from '../../../../services/loading.service';
import { ToastService } from '../../../../services/toast.service';
import { EncerraCadastroModal } from '../../../modal/encerra-cadastro/encerra-cadastro.modal';
import { LotSelectionModal } from '../../../modal/lot-selection/lot-selection.modal';
import { PropertyService } from '../../../registration/property/state/property.service';
import { RegisterQuery } from '../../state/register.query';
import { RegisterService } from '../../state/register.service';
@Component({
  selector: 'usucampeao-register-map',
  templateUrl: 'select-lot.page.html',
  styleUrls: ['select-lot.page.scss'],
})

export class SelectLotPage implements ViewWillEnter, ViewWillLeave {
  public showModal = false;
  public lot!: Lot;
  public adress!: string;
  public lotId!: string;
  private ngUnsubscribe = new Subject();
  public subscription!: Subscription;
  public projetoId: string;

  constructor(
    private loadingService: LoadingService,
    private modalController: ModalController,
    private propertyService: PropertyService,
    private registerQuery: RegisterQuery,
    private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => {
          this.projetoId = params.id;
        }),
        switchMap(() => this.registerQuery.selectLoading()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async res => {
        if (res) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public setLot(lot: Lot): void {
    this.lot = lot;
    this.showModal = true;
  }

  public async validaDataCriacaoCadastro(): Promise<void> {
    this.navigateToPhoto();
  }

  private navigateToPhoto(): void {
    const registration = new CreateRegistrationDto();
    registration.blockId = Number(this.lot.quadra_id);
    registration.lotId = Number(this.lot.id);

    this.registerQuery.ids$
      .pipe(
        first(),
        concatMap(ids => {
          if (ids?.registrationId && ids?.propertyId) {
            const updateLocalization = new GetUpdatePropertyLocalizationDto();
            updateLocalization.blockId = registration.blockId;
            updateLocalization.lotId = registration.lotId;
            return this.propertyService.updateLocalizationData(ids.registrationId, ids.propertyId, updateLocalization)
              .pipe(map(() => ids));
          }
          return this.registerService.create(this.projetoId, registration);
        })
      )
      .subscribe(
        res => {
          this.router.navigate([`../lote`, res.propertyId], { relativeTo: this.route })
        },
        async error => {
          if (error.errorCode === 508) {
            await this.presentModalEncerraCadastro();
          } else {
            await this.toastService.erro('Erro ao selecionar o lote. Tente novamente mais tarde.');
          }
        }
      );
  }

  public async presentModalAskHelp(): Promise<void> {
    const modal = await this.modalController.create({
      component: LotSelectionModal,
      cssClass: 'lot-selection-modal',
    });

    await modal.present();
  }

  private async presentModalEncerraCadastro(): Promise<void> {
    const modal = await this.modalController.create({
      component: EncerraCadastroModal,
      cssClass: 'small-modal small-modal--instruction',
    });
    return await modal.present();
  }
}
