import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import bugsnag from '@bugsnag/js';
import {
  GetUpdateOwnerContactDto,
  GetUpdateOwnerDocumentsDto,
  GetUpdateOwnerPersonalDataDto,
  ListFamilyDto,
  OwnerDto,
  OwnerSimplifiedDto
} from '@usucampeao/lib-reurb-simplificado';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { OwnersStore } from './owners.store';


@Injectable({ providedIn: 'root' })
export class OwnersService {

  constructor(
    private http: HttpClient,
    private ownersStore: OwnersStore,
  ) { }

  /**
   * Busca os proprietários de um atendimento.
   * @param registrationId id do atendimento
   * @returns lista de proprietários
   */
  public getOwnersByRegistrationId(registrationId: string): Observable<OwnerSimplifiedDto[]> {
    this.ownersStore.setLoading(true);
    return this.http.get<ListFamilyDto[]>(`/registrations/${registrationId}/families`)
      .pipe(
        map(entities =>
          entities
            .map(entity => entity.owners)
            .reduce((curr, acc) => curr.concat(acc), [])),
        tap(ownersSimplified => {
          const owner: any = ownersSimplified.map(t => new OwnerDto(t));
          this.ownersStore.add(owner);
        }),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getOwnersByRegistration-id'
            event.addMetadata('params', { registrationId });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/families`,
              title: 'Erro ao buscar os proprietários de um atendimento:' + registrationId,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.ownersStore.setLoading(false)),
      );
  }

  /**
   * Busca os dados simplificados do proprietário.
   * @param id id do proprietário
   * @returns dados simplificados do proprietário
   */
  public getSimplifiedData(cadastroId: string, id: string): Observable<OwnerSimplifiedDto> {
    this.ownersStore.setLoading(true);
    return this.http.get<OwnerSimplifiedDto>(`/registrations/${cadastroId}/owners/${id}/simplified`)
      .pipe(
        tap(owner => this.ownersStore.update(id, owner)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getSimplified-data'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/owners/${id}/simplified`,
              title: 'Erro ao buscar os dados simplificados do proprietário:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.ownersStore.setLoading(false)),
      );
  }

  /**
   * Busca os dados básicos do proprietário e os atualiza na store.
   * @param id id do proprietário
   * @returns dados básicos do proprietário
   */
  public getPersonalData(cadastroId: string, id: string): Observable<GetUpdateOwnerPersonalDataDto> {
    this.ownersStore.setLoading(true);
    return this.http.get<GetUpdateOwnerPersonalDataDto>(`/registrations/${cadastroId}/owners/${id}/personal-data`)
      .pipe(
        tap(owner => this.ownersStore.update(id, { ...owner, personalDataLoaded: true })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getPersonal-data'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/owners/${id}/personal-data`,
              title: 'Erro ao buscar os dados básicos do proprietário e os atualiza na store:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.ownersStore.setLoading(false)),
      );
  }

  /**
   * Busca os dados de documentos do proprietário e os atualiza na store.
   * @param id id do proprietário
   * @returns dados de documentos do proprietário
   */
  public getDocumentsData(cadastroId: string, id: string): Observable<GetUpdateOwnerDocumentsDto> {
    this.ownersStore.setLoading(true);
    return this.http.get<GetUpdateOwnerDocumentsDto>(`/registrations/${cadastroId}/owners/${id}/documents`)
      .pipe(
        tap(owner => this.ownersStore.update(id, { ...owner, documentsDataLoaded: true })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getDocuments-data'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/owners/${id}/documents`,
              title: 'Erro ao buscar os dados de documentos do proprietário e os atualiza na store:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.ownersStore.setLoading(false)),
      );
  }

  /**
   * Busca os dados de contato do proprietário e os atualiza na store.
   * @param id id do proprietário
   * @returns dados de contato do proprietário
   */
  public getContactData(cadastroId: string, id: string): Observable<GetUpdateOwnerContactDto> {
    this.ownersStore.setLoading(true);
    return this.http.get<GetUpdateOwnerContactDto>(`/registrations/${cadastroId}/owners/${id}/contact`)
      .pipe(
        tap(owner => this.ownersStore.update(id, { ...owner, contactDataLoaded: true })),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-getContact-data'
            event.addMetadata('params', { id });
            event.addMetadata('metadata', {
              url: `/owners/${id}/contact`,
              title: 'Erro ao buscar os dados de contato do proprietário e os atualiza na store:' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.ownersStore.setLoading(false)),
      );
  }

  /**
   * Atualiza os dados básicos do proprietário.
   * @param registrationId id do atendimento
   * @param id id do proprietário
   * @param personalData dados a serem atualizados
   */
  public updatePersonalData(registrationId: string, id: string, personalData: GetUpdateOwnerPersonalDataDto): Observable<void> {
    this.ownersStore.setLoading(true);
    return this.http.put<void>(`/registrations/${registrationId}/owners/${id}/personal-data`, personalData)
      .pipe(
        tap(() => this.ownersStore.update(id, personalData)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updatePersonal-data'
            event.addMetadata('payload', personalData);
            event.addMetadata('params', { registrationId, id });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/owners/${id}/personal-data`,
              title: 'Erro ao atualizar os dados básicos do proprietário:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.ownersStore.setLoading(false)),
      );
  }

  /**
   * Atualiza os dados de documento do proprietário.
   * @param registrationId id do atendimento
   * @param id id do proprietário
   * @param documentsData dados a serem atualizados
   */
  public updateDocumentsData(registrationId: string, id: string, documentsData: GetUpdateOwnerDocumentsDto): Observable<void> {
    this.ownersStore.setLoading(true);
    return this.http.put<void>(`/registrations/${registrationId}/owners/${id}/documents-data`, documentsData)
      .pipe(
        tap(() => this.ownersStore.update(id, documentsData)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateDocuments-data'
            event.addMetadata('payload', documentsData);
            event.addMetadata('params', { registrationId, id });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/owners/${id}/documents-data`,
              title: 'Erro ao atualizar os dados de documento do proprietário:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.ownersStore.setLoading(false)),
      );
  }

  /**
   * Atualiza os dados de contato do proprietário.
   * @param registrationId id do atendimento
   * @param id id do proprietário
   * @param contactData dados a serem atualizados
   */
  public updateContactData(registrationId: string, id: string, contactData: GetUpdateOwnerContactDto): Observable<void> {
    this.ownersStore.setLoading(true);
    return this.http.put<void>(`/registrations/${registrationId}/owners/${id}/contact`, contactData)
      .pipe(
        tap(() => this.ownersStore.update(id, contactData)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-updateContact-data'
            event.addMetadata('payload', contactData);
            event.addMetadata('params', { registrationId, id });
            event.addMetadata('metadata', {
              url: `/registrations/${registrationId}/owners/${id}/contact`,
              title: 'Erro ao atualizar os dados de contato do proprietário:' + registrationId + '/' + id,
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.ownersStore.setLoading(false)),
      );
  }
}
