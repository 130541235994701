import { PropertyFillInStatusDto } from '../property/property-fill-in-status.dto';

import { PendingOwnerData } from './pending-owner-data.model';

export class PendingSections {
  ownersData: PendingOwnerData[];
  propertyData: PropertyFillInStatusDto;

  constructor(
    ownersData: PendingOwnerData[],
    propertyData: PropertyFillInStatusDto
  ) {
    this.ownersData = ownersData;
    this.propertyData = propertyData;
  }

}
