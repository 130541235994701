import { Type } from 'class-transformer';
import { IsBoolean, IsDateString, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ActivationUserDocumentType } from '../enums/activation-document-type.enum';
import { AllotmentDocumentType } from '../enums/allotment-document-type.enum';
import { DocumentReferenceType } from '../enums/document-reference-type.enum';
import { PersonalDocumentType } from '../enums/personal-document-type.enum';
import { PropertyDocumentType } from '../enums/property-document-type.enum';
import { ServiceDocumentType } from '../enums/service-document-type.enum';
import { StatusDocument } from '../enums/status-document.enum';
import { Ref } from '../ref/ref.model';


/**
 * Tipos de documentos disponiveis.
 */
export type IDocumentType = ServiceDocumentType | PropertyDocumentType | PersonalDocumentType | AllotmentDocumentType | ActivationUserDocumentType;


/**
 * Model interface (DTO) for 'Documento' entity.
 * A 'Documento' represents a document's data.
 */
export class DocumentFile {
  /**
   * Document unique ID.
   */
  @IsString()
  @IsOptional()
  id?: string;

  /**
   * Document name.
   */
  @IsString()
  @IsOptional()
  nome?: string;

  /**
   * Document creation timestamp.
   */
  @IsDateString()
  @IsOptional()
  data_criacao?: Date;

  /**
   * Document last updated timestamp.
   */
  @IsDateString()
  @IsOptional()
  data_atualizacao?: Date;

  /**
   * Reference to the user who created this document.
   */
  @Type(() => Ref)
  usuario_criacao?: Ref;

  /**
   * Reference to the user who last updated this document.
   */
  @Type(() => Ref)
  @ValidateNested()
  usuario_atualizacao?: Ref;

  /**
   * Status do documento
   */
  @IsEnum(StatusDocument)
  @IsOptional()
  status?: StatusDocument;

  /**
   * Referencia do documento. Pode ser de Pessoa, Imóvel ou Atendimento.
   */
  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  ref: Ref;

  /**
   * Define de qual objeto é o documento, podendo ser de Atendimento,
   * Pesssoa ou Imovel.
   */
  @IsEnum(DocumentReferenceType)
  tipo_referencia: DocumentReferenceType;

  /**
   * Informa qual o tipo do documento. Pode ser RG, CPF, etc..
   */
  // TODO: Adicionar validação
  tipo: PersonalDocumentType | PropertyDocumentType | ServiceDocumentType | AllotmentDocumentType | ActivationUserDocumentType;

  /**
   * Data da aprovação do documento.
   */
  @IsDateString()
  @IsOptional()
  data_aprovacao?: Date;

  /**
   * Usuário que aprovou o documento.
   */
  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  usuario_aprovacao?: Ref;

  /**
   * Informa se um documento que foi rejeitado já foi atualizado.
   */
  @IsBoolean()
  @IsOptional()
  documento_atualizado?: boolean;
}

/**
 * Enum para o nome da referencia do documento RG dentro do upload.
 */
// TODO: VERIFICAR SE AINDA SERÁ UTILIZADO ESSE CONTROLE
export enum NomeRefRG {
  /**
   * Frente do RG
   */
  RG_FRENTE = 'RG_FRENTE',
  /**
   * Verso do RG
   */
  RG_VERSO = 'RG_VERSO'
}

/**
 * Enum para o nome da referencia do documento Comprovante de Renda dentro do upload.
 */
// TODO: VERIFICAR SE AINDA SERÁ UTILIZADO ESSE CONTROLE
export enum NomeRefComprovanteRenda {
  /**
   * 1 mês atrás
   */
  MES_1 = 'MES_1',
  /**
   * 2 meses atrás
   */
  MES_2 = 'MES_2',
  /**
   * 3 meses atrás
   */
  MES_3 = 'MES_3'
}
