import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { ElementBase, InputMode, Textbox, toFormGroup } from '@usucampeao/ui-mobile';
import { FcmService } from '../../../../services/fcm.service';
import { ToastService } from '../../../../services/toast.service';
import { AuthQuery } from '../../state/auth.query';
import { AuthService } from '../../state/auth.service';
import { WindowsService } from '../../state/windows.service';

@Component({
  selector: 'usucampeao-number-authentication',
  templateUrl: 'number-authentication.page.html',
  styleUrls: ['number-authentication.page.scss'],
})
export class NumberAuthenticationPage implements OnInit {
  @Output() back = new EventEmitter<void>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public windowRef: any;
  form$: ElementBase<string>[] | null = [];
  form!: FormGroup;
  public showCodePage = false;

  constructor(
    private authService: AuthService,
    public toastService: ToastService,
    private alertController: AlertController,
    private fcmService: FcmService,
    public winref: WindowsService,
    private loadingController: LoadingController,
    public authQuery: AuthQuery,
    private router: Router,
  ) { }

  ngOnInit() {
    this.windowRef = this.winref.win;
    this.form$ = this.getForm();
    this.form = toFormGroup(this.getForm() as ElementBase<string>[]);
  }

  public get win() {
    return window;
  }

  getForm() {
    const questions: ElementBase<string>[] = [
      new Textbox({
        key: 'code',
        mask: 'codenumber',
        type: 'text',
        label: 'Código',
        inputMode: InputMode.NUMBER,
        validators: [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      }),
    ];
    return questions;
  }

  public async onSubmit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const loading = await this.loadingController.create();
    await loading.present();

    const usercode = this.form.value;
    const winrefcode = this.winref.get();
    winrefcode.confirmationResult.confirm(usercode.code)
      .then(async () => {
        const user = await this.authService.getUserData();
        if (user) {
          this.fcmService.getFcmToken();
          this.router.navigate([''], { replaceUrl: true });
        } else {
          this.router.navigate(['/criar-conta/']);
        }
        await loading.dismiss();
      })
      .catch(async (error) => {
        await loading.dismiss();
        if(error.status === 401){
          this.router.navigate(['/criar-conta/']);
        } else {
          await this.toastService.erro('Erro ao verificar telefone. Verifique o código e tente novamente.');
        }
      });
  }

  public async reenviarCodigo(): Promise<void> {
    const code = this.winref.getCode();
    const phone = this.authQuery.getValue().phone
    const phoneNumber = '+55' + phone.replace(/\D/g, "");
    const recaptchaResponse = await this.authService.login({
      phoneNumber,
      code,
    });

    this.windowRef.confirmationResult = recaptchaResponse;
    this.winref.set(this.windowRef)
    const alert = await this.alertController.create({
      header: 'Mensagem',
      message: 'Enviamos um novo codigo.',
      buttons: ['OK'],
    });
    await alert.present();
  }

  goBack() {
    this.back.emit();
  }
}
