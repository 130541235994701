import { LeadDto } from '../leads/lead.dto';
import { ListRegistration } from './list-registration.dto';

export class CadastroResumoVendedorDto {
  cadastrosCorrigir: ListRegistration[];
  cadastrosAndamento: ListRegistration[];
  cadastrosFechados: ListRegistration[];
  leads: LeadDto[];

  constructor(
    cadastrosCorrigir: ListRegistration[],
    cadastrosAndamento: ListRegistration[],
    cadastrosFechados: ListRegistration[],
    leads: LeadDto[],
  ) {
    this.cadastrosCorrigir = cadastrosCorrigir;
    this.cadastrosAndamento = cadastrosAndamento;
    this.cadastrosFechados = cadastrosFechados;
    this.leads = leads;
  }
}
