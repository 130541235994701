import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SwUpdate } from '@angular/service-worker';
import { AlertController, MenuController, Platform, ToastController, ViewWillLeave } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './pages/auth/state/auth.service';

@Component({
  selector: 'usucampeao-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, ViewWillLeave, OnDestroy {
  private ngUnsubscribe = new Subject();
  public deferredPrompt: any;
  config: any;

  @HostListener('window:beforeinstallprompt', ['$event'])
  async onbeforeinstallprompt(e: any) {
    e.preventDefault();
    this.deferredPrompt = e;
    await this.showAlertInstallPwa();
  }

  constructor(
    private alertCtrl: AlertController,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private cookiesService: CookieService,
    private authService: AuthService,
    private afMessaging: AngularFireMessaging,
    private menu: MenuController,
  ) {

    this.afMessaging.requestToken
      .subscribe();



    this.swUpdate.available.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async () => {
      await this.showAlertUpdatePwa();
    });
  }

  async ngOnInit(): Promise<void> {
    await this.showToastIos();
    // Network.addListener('networkStatusChange', async (status) => {
    this.authService.setUserConnectionStatus(true);
    // });


  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  async openMenu() {
    await this.menu.open();
  }

  onSlideChange() { }

  onSwiper(e: any) { }

  /**
   * Controle para exibir o toast de instalação do app no iphone
   */
  private async showToastIos(): Promise<void> {
    const isInStandaloneMode: boolean =
      'standalone' in window.navigator && (window.navigator as any).standalone;
    if (this.platform.is('ios') && !isInStandaloneMode) {
      await this.showToastInstallPwa();
    }
  }



  async verifyInternet() {
    const toast = await this.toastCtrl.create({
      message:
        'Verifique sua conexão de internet!',
      position: 'bottom',
    });
    await toast.present();
  }



  /**
   * Exibe um toast explicando como instalar o app no Safari
   */
  private async showToastInstallPwa(): Promise<void> {
    const installCookie = this.cookiesService.get(
      environment.cookieInstallName
    );

    if (!installCookie) {
      const toast = await this.toastCtrl.create({
        message:
          '<p>Instale nosso app no seu Iphone. Clique no ícone <ion-icon name="share-outline"></ion-icon> no Safari e depois em "Adicionar à Tela de Início".</p>',
        position: 'bottom',
        buttons: [
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
              this.cookiesService.set(environment.cookieInstallName, 'true', 3);
            },
          },
        ],
      });
      await toast.present();
    }
  }
  /**
   * Mostra alerta para instalar PWA
   */
  private async showAlertInstallPwa(): Promise<void> {
    const installCookie = this.cookiesService.get(
      environment.cookieInstallName
    );
    if (!installCookie) {
      const alert = await this.alertCtrl.create({
        header: 'Atenção',
        message:
          'Instale nosso aplicativo para facilitar o acesso aos seus processos de regularização.',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              this.cookiesService.set(environment.cookieInstallName, 'true', 3);
            },
          },
          {
            text: 'Instalar agora',
            handler: () => {
              this.deferredPrompt.prompt();
              this.deferredPrompt = null;
            },
          },
        ],
      });
      await alert.present();
    }
  }

  /**
   * Exibe um alerta de atualização do aplicativo
   */
  private async showAlertUpdatePwa(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: 'Há uma nova atualização do aplicativo.',
      buttons: [
        {
          text: 'Atualizar',
          handler: () => {
            this.cookiesService.delete(environment.cookieInstallName);
            window.location.reload();
          },
        },
      ],
    });
    await alert.present();
  }
}
