<ion-content class="ion-align-itens-center ion-padding">
    <div class="content-vertical-space-between">
        <ion-header class="ion-text-left  ion-no-border">
            <ion-text>
                <h1 class="title ion-no-margin">Quase Lá!</h1>
            </ion-text><br>
            <ion-text class="ion-text-left">
                <p class="ion-no-margin">
                    Tire uma foto para ajudar nosso assistentes a te identificar
                </p>
            </ion-text>
        </ion-header>
        <div class="ion-margin-top">
            <usucampeao-photo (setImage)="setImage($event)"></usucampeao-photo>
        </div>
        <div>
            <ion-text class="ion-text-center" *ngIf="!photo">
                <p class="description-text description-text--large">Não é um bom momento? <a class="text-link text-link--large" (click)="skipPhotoRegistration()">Deixe para depois</a></p>
            </ion-text>
            <ion-button mode="md" expand="block" (click)="navigateToRegistrationCompleted()" *ngIf="photo">Continuar</ion-button>
        </div>
        <usucampeao-footer></usucampeao-footer>
    </div>
</ion-content>
