import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FileDto, PropertyDto, PropertyOccupationDto } from '@usucampeao/lib-reurb-simplificado';

export class Property extends PropertyDto {
  propertyOccupation?: PropertyOccupationDto;
  localizationDataLoaded?: boolean;
  addressDataLoaded?: boolean;
  additionalDataLoaded?: boolean;
  occupationDataLoaded?: boolean;
}

export type PropertyState = EntityState<Property>

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'property', resettable: true })
export class PropertyStore extends EntityStore<PropertyState> {
  constructor() {
    super();
  }

  public changeFile(id: string, file: FileDto) {
    this.update(id, { files: [file] })
  }
}
