import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllotmentDetailPage } from './allotment-detail.page';

const routes: Routes = [
  { path: '', component: AllotmentDetailPage },
  {
    path: 'codigo',
    loadChildren: () =>
      import('./allotment-code/allotment-code.module').then((m) => m.AllotmentCodeModule),
  },
  {
    path: 'lote',
    loadChildren: () =>
      import('./select-lot/select-lot.module').then((m) => m.SelectLotModule),
  },
  {
    path: 'lote/:id',
    loadChildren: () =>
      import('./lot-detail/lot-detail.module').then((m) => m.LotDetailModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AllotmentDetailRoutingModule { }
