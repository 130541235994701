<ion-content class="ion-padding">
  <ion-text class="title title--large">
    Novo documento
  </ion-text>
  <p>
    <ion-text class="font-text">
      Qual o tipo de documento que você quer adicionar?
    </ion-text>
  </p>
  <p>
    <ion-text class="font-text">
      Quanto mais documentos enviados, melhor andará o seu processo.
    </ion-text>
  </p>
  <ion-grid class="ion-no-padding ion-no-margin">
    <ion-row class="ion-justify-content-center ion-no-padding ion-no-margin">
      <ion-col sizeMd="3" sizeLg="6" class="ion-no-padding ion-no-margin">
        <div class="ion-no-padding ion-no-margin" *ngIf="(type ==='escolher' || type === documentFileSubtype.ACQUISITION)">
          <ion-text class="description-text description-text--medium" *ngIf="type === 'escolher'"><br> Documentos de
            aquisição
          </ion-text><br>
          <div class="ion-no-padding ion-no-margin" *ngFor="let item of acquisitionDocuments">
            <usucampeao-menu-item *ngIf="!existingTypes.includes(item)" [title]="item | PropertyAcquisitionDocumentPipe" (click)="navigateToPhoto(item)">
            </usucampeao-menu-item>
          </div>
       <p class="ion-text-center font-text font-text--small">
            <ion-text>
              Não possui um documento de aquisição?
            </ion-text><br>
            <ion-text color="primary" (click)="navigateToJustification(documentFileSubtype.ACQUISITION)">
              <strong>
                Adicione uma justificativa
              </strong>
            </ion-text>
          </p>
        </div>
        <div class="ion-padding-top" *ngIf="type ==='escolher' || type === documentFileSubtype.OWNERSHIP">
          <ion-text class="description-text description-text--medium" *ngIf="type === 'escolher'">
            Documentos de comprovação de posse
          </ion-text>
          <div *ngFor="let item of ownershipDocuments">
            <usucampeao-menu-item *ngIf="!existingTypes.includes(item)" [title]="item | PropertyOwnershipDocumentPipe" (click)="navigateToPhoto(item)">
            </usucampeao-menu-item>
          </div>
         <p class="ion-text-center font-text font-text--small">
            <ion-text>
              Não possui um documento de posse?
            </ion-text><br>
            <ion-text color="primary" (click)="navigateToJustification(documentFileSubtype.OWNERSHIP)">
              <strong>
                Adicione uma justificativa
              </strong>
            </ion-text>
          </p>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col sizeMd="3">
        <div class="ion-padding-top ion-no-margin">
          <ion-button fill="outline" mode="md" expand="block" (click)="navigateBack()">Cancelar</ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <usucampeao-footer></usucampeao-footer>
</ion-content>
