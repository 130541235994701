import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { FooterModule } from '../../components/footer/footer.module';
import { HeaderModule } from '../../components/header/header.module';
import { PhotoModule } from '../../components/photo/photo.module';
import { ProcessProgressModule } from '../../components/process-progress/process-progress.module';
import { ProcessStatusModule } from '../../components/process-status/process-status.module';
import { SkeletonItemModule } from '../../components/skeleton-item/skeleton-item.module';
import { LazyLoadImageHooks } from '../../hooks/lazy-load-image-hooks';
import { PipesModule } from '../../pipes/pipes.module';
import { ConfirmaReenvioCadastroModule } from '../modal/confirma-reenvio-cadastro/confirma-reenvio-cadastro.module';
import { ContactModule } from '../modal/contact/contact.module';
import { ContratoModule } from '../modal/contract/contract.module';
import { ExcluiCadastroModule } from '../modal/exclui-cadastro/exclui-cadastro.module';
import { RegistrationHelpModule } from '../modal/registration-help/registration-help.module';
import { RegistrationSubmitHelpModule } from '../modal/registration-submit-help/registration-submit-help.module';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationPage } from './registration.page';

@NgModule({
    declarations: [RegistrationPage],
    imports: [
        RegistrationRoutingModule,
        CommonModule,
        ContactModule,
        ContratoModule,
        FooterModule,
        PhotoModule,
        HeaderModule,
        IonicModule,
        ProcessProgressModule,
        ProcessStatusModule,
        LazyLoadImageModule,
        PipesModule,
        SkeletonItemModule,
        RegistrationHelpModule,
        RegistrationSubmitHelpModule,
        ConfirmaReenvioCadastroModule,
        ExcluiCadastroModule
    ],
    providers: [
        {
            provide: LAZYLOAD_IMAGE_HOOKS,
            useClass: LazyLoadImageHooks
        }
    ]
})
export class RegistrationModule { }
