import { Component, Input, OnChanges } from '@angular/core';
import { StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-process-progress',
  templateUrl: 'process-progress.component.html',
  styleUrls: ['process-progress.component.scss'],
})
export class ProcessProgressComponent implements OnChanges {
  @Input() icon!: string | null;
  @Input() title!: string | null;
  @Input() titleClass!: string | null;
  @Input() subtitle: string;
  @Input() statusIcon!: string | null;
  @Input() statusIconClass!: string | null;
  @Input() statusText!: string | null;
  @Input() statusTextClass!: string | null;
  @Input() detail = false;
  @Input() disabled = false;
  @Input() status = StatusPreenchimento.PENDENTE;
  public nomeIcone: string;
  public classeIcone: string;

  constructor() { }

  ngOnChanges(): void {
    switch (this.status) {
      case StatusPreenchimento.APROVADO:
      case StatusPreenchimento.ENVIADO:
        this.nomeIcone = 'checkmark-circle-sharp';
        this.classeIcone = 'icon-complete';
        break;

      case StatusPreenchimento.ALTERACAO_NECESSARIA:
        this.nomeIcone = 'close-circle';
        this.classeIcone = 'icon-changes';
        break;
      case StatusPreenchimento.JUSTIFICADO:
        this.nomeIcone = 'checkmark-circle-sharp';
        this.classeIcone = 'icon-incomplete';
        break;

      default:
        this.nomeIcone = 'ellipse-outline';
        this.classeIcone = 'icon-incomplete';
    }
  }
}

