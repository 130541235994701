import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import bugsnag from '@bugsnag/js';
import { withTransaction } from '@datorama/akita';
import {
  AccessCode,
  CreateRegistrationDto, LoteListarMapaDto, ProjetoDetalhesComerciaisDto, ProjetoSimplificadoDto,
  ProjetoVendaStatus,
  PropertyDto,
  RegistrationSimplifiedDto,
  RegistrationStatus,
  TabelaPrecoValoresDto,
  UpdatePropertyAddressAdditionalInformationsDto
} from '@usucampeao/lib-reurb-simplificado';
import { isBefore } from 'date-fns';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProjetoStore } from '../../projeto/state/projeto.store';
import { PropertyStore } from '../../registration/property/state/property.store';
import { RegistrationStore } from '../../registration/state/registration.store';
import { RegisterStore } from './register.store';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private registerStore: RegisterStore,
    private http: HttpClient,
    private registrationStore: RegistrationStore,
    private projetstore: ProjetoStore,
    private propertyStore: PropertyStore
  ) { }

  public dataValidaNovoCadastro(): boolean {
    return isBefore(new Date(), new Date(Date.UTC(2022, 0, 31, 3)));
  }

  /**
  * busca todos projetos
  * @returns dados de projetos
  */
  public getAllRegisters(status = ProjetoVendaStatus.EM_ANDAMENTO): Observable<ProjetoSimplificadoDto[]> {
    const params = new HttpParams().set('status', status);
    this.projetstore.setLoading(true);
    return this.http.get<ProjetoSimplificadoDto[]>(`/projetos/status`, { params: params })
      .pipe(
        tap((res: ProjetoSimplificadoDto[]) => this.projetstore.set(res)),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-get-registrations'
            event.addMetadata('metadata', {
              url: `/projeto/status`,
              title: 'Erro ao buscar loteamentos',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.projetstore.setLoading(false))
      );
  }

  public buscarValoresTabelaPrecoProjeto(id: string): Observable<TabelaPrecoValoresDto[]> {
    return this.http.get<TabelaPrecoValoresDto[]>(`/projetos/${id}/tabela-preco/valores`).pipe(catchError(erro => {
      bugsnag.notify(erro, (event) => {
        event.context = 'service-get-project-preco-info'
        event.addMetadata('payload', { id });
        event.addMetadata('metadata', {
          url: `/projetos/${id}/tabela-preco/valores`,
          title: 'Erro ao buscar dados preco do projeto',
          statusCode: erro.error.statusCode,
          message: erro.error.message,
          error: erro.error.error,
        });
      })
      return throwError(erro);
    }))
  }

  public buscaConfigProjetoMapa(id: string): Observable<any> {
    return this.http.get<any>(`/projetos/${id}/mapa`).pipe(catchError(erro => {
      bugsnag.notify(erro, (event) => {
        event.context = 'service-get-project-mapa-info'
        event.addMetadata('payload', { id });
        event.addMetadata('metadata', {
          url: `/projetos/${id}/mapa`,
          title: 'Erro ao buscar dados mapa do projeto',
          statusCode: erro.error.statusCode,
          message: erro.error.message,
          error: erro.error.error,
        });
      })
      return throwError(erro);
    }))
  }

  public buscarLotes(id: string): Observable<LoteListarMapaDto[]> {
    return this.http.get<LoteListarMapaDto[]>(`/projetos/${id}/lotes`).pipe(catchError(erro => {
      bugsnag.notify(erro, (event) => {
        event.context = 'service-get-project-lotes-info'
        event.addMetadata('payload', { id });
        event.addMetadata('metadata', {
          url: `/projetos/${id}/lotes`,
          title: 'Erro ao buscar dados lotes do projeto',
          statusCode: erro.error.statusCode,
          message: erro.error.message,
          error: erro.error.error,
        });
      })
      return throwError(erro);
    }))
  }

  public buscarDetalheComercialProjeto(id: string): Observable<ProjetoDetalhesComerciaisDto> {
    this.resetStore();
    return this.http.get<ProjetoDetalhesComerciaisDto>(`/projetos/${id}/detalhes-comerciais`).pipe(catchError(erro => {
      bugsnag.notify(erro, (event) => {
        event.context = 'service-get-project-comercial-info'
        event.addMetadata('payload', { id });
        event.addMetadata('metadata', {
          url: `/projetos/${id}/detalhes-comerciais`,
          title: 'Erro ao buscar dados comerciais do projeto',
          statusCode: erro.error.statusCode,
          message: erro.error.message,
          error: erro.error.error,
        });
      })
      return throwError(erro);
    }))
  }

  public buscarDetalheProjeto(id: string): Observable<ProjetoSimplificadoDto> {
    return this.http.get<ProjetoSimplificadoDto>(`/projetos/${id}`).pipe(catchError(erro => {
      bugsnag.notify(erro, (event) => {
        event.context = 'service-get-project-info'
        event.addMetadata('payload', { id });
        event.addMetadata('metadata', {
          url: `/projetos/${id}`,
          title: 'Erro ao buscar dados do projeto',
          statusCode: erro.error.statusCode,
          message: erro.error.message,
          error: erro.error.error,
        });
      })
      return throwError(erro);
    }))
  }

  /**
   * Adiciona um novo cadastro
   * @param projetoId id do projeto
   * @param createRegistration dados para criar novo cadastro
   * @returns dados de criação de cadastro
   */
  public create(projetoId: string, createRegistration: CreateRegistrationDto): Observable<CreateRegistrationDto> {
    this.registerStore.setLoading(true);
    return this.http.post<CreateRegistrationDto>(`/projetos/${projetoId}/cadastros`, createRegistration)
      .pipe(
        withTransaction(res => {
          const property: Partial<PropertyDto> = {
            id: res.propertyId,
            lotId: res.lotId,
            blockId: res.blockId
          };
          const value = {
            property: property,
            registration: {
              id: res.id,
              propertyId: res.propertyId
            }
          };
          const registration: Partial<RegistrationSimplifiedDto> = {
            id: res.id,
            blockId: res.blockId,
            lotId: res.lotId,
            projetoFid: res.projetoFid,
            propertyId: res.propertyId,
            status: RegistrationStatus.INCOMPLETE,
            statusVenda: RegistrationStatus.INCOMPLETE,
            address: null,
            files: [],
          }

          this.registerStore.loadRegistration(value);
          this.propertyStore.add(property as any);
          this.registrationStore.add(registration as RegistrationSimplifiedDto);
        }),
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-create-registration'
            event.addMetadata('payload', createRegistration);
            event.addMetadata('metadata', {
              url: `/projetos/${projetoId}/cadastros`,
              title: 'Erro ao adicionar um novo cadastro',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false))
      );
  }

  /**
   * Adiciona endereço e informações adicionais no imóvel
   * @param registrationId id do cadastro
   * @param propertyId id do imóvel
   * @param data dados de endereço e informações adicionais do imóvel
   */
  public addPropertyAddressAndAdditionalInformations(
    data: UpdatePropertyAddressAdditionalInformationsDto
  ): Observable<void> {
    this.registerStore.setLoading(true);
    const registration = this.registerStore.getValue().registration;
    return this.http.put<void>(
      `/registrations/${registration.id}/properties/${registration.propertyId}/address-additional-information`,
      data
    ).pipe(
      tap(() => this.registrationStore.update(registration.id, { address: data.address })),
      catchError(erro => {
        bugsnag.notify(erro, (event) => {
          event.context = 'service-addPropertyAddressAndAdditional-informations'
          event.addMetadata('payload', data);
          event.addMetadata('query params', { address: data.address });
          event.addMetadata('metadata', {
            url: `/registrations/${registration.id}/properties/${registration.propertyId}/address-additional-information`,
            title: 'Erro ao adicionar endereço e informações adicionais no imóvel',
            statusCode: erro.error.statusCode,
            message: erro.error.message,
            error: erro.error.error,
          });
        })
        return throwError(erro);
      }),
      finalize(() => this.registerStore.setLoading(false)),
    );
  }

  /**
   * Valida código do loteamento
   * @param id id do loteamento
   * @param code código informado
   */
  public validateAllotmentCode(id: string, code: string): Observable<string> {
    this.registerStore.setLoading(true);
    const accessCode = new AccessCode(code);
    return this.http.post(`/allotments/${id}/access-code`, accessCode, { responseType: 'text' })
      .pipe(
        catchError(erro => {
          bugsnag.notify(erro, (event) => {
            event.context = 'service-validateAllotment-code'
            event.addMetadata('payload', accessCode);
            event.addMetadata('params', { id });
            event.addMetadata('query params', { responseType: 'text' });
            event.addMetadata('metadata', {
              url: `/allotments/${id}/access-code`,
              title: 'Erro ao validar código do bairro',
              statusCode: erro.error.statusCode,
              message: erro.error.message,
              error: erro.error.error,
            });
          })
          return throwError(erro);
        }),
        finalize(() => this.registerStore.setLoading(false))
      );
  }

  /**
   * Reseta o store
   */
  public resetStore(): void {
    this.registerStore.reset();
  }
}
