// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Expose, Type } from 'class-transformer';
import { IsDate, IsEnum, IsNotEmpty, IsNumber, IsString, ValidateNested } from 'class-validator';
import { Aplicacao } from '.';
import { Bs2Cliente } from '..';
import { IntegracaoStatus } from '../../integracao/integracao-status.enum';
import { PagamentoStatus } from '../../payment/pagamento-status.enum';

export class Bs2IntegracaoCriarDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  contratoId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  pagamentoId: string;

  @Expose()
  @Type(() => Bs2Cliente)
  @ValidateNested()
  @IsNotEmpty()
  cliente: Bs2Cliente;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  valor: number;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  dataVencimento: Date;

  @Expose()
  @IsEnum(PagamentoStatus)
  @IsNotEmpty()
  pagamentoStatus: PagamentoStatus;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  parcela: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  totalParcelas: number;

  @Expose()
  @IsEnum(Aplicacao)
  @IsNotEmpty()
  aplicacao: Aplicacao;

  status?: IntegracaoStatus;

  constructor(
    contratoId: string,
    pagamentoId: string,
    cliente: Bs2Cliente,
    valor: number,
    dataVencimento: Date,
    pagamentoStatus: PagamentoStatus,
    parcela: number,
    totalParcelas: number,
    aplicacao: Aplicacao,
  ) {
    this.contratoId = contratoId;
    this.pagamentoId = pagamentoId;
    this.cliente = cliente;
    this.valor = valor;
    this.dataVencimento = dataVencimento;
    this.pagamentoStatus = pagamentoStatus;
    this.parcela = parcela;
    this.totalParcelas = totalParcelas;
    this.aplicacao = aplicacao;
  }
}
