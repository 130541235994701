import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsNumberString,
  IsOptional,

  IsString,
  ValidateNested
} from 'class-validator';
import { Endereco } from '../../endereco/endereco.model';
import { AbastecimentoAguaTipo } from './abastecimento-agua-tipo.enum';
import { BanheiroTipo } from './banheiro-tipo.enum';
import { DadosIptu } from './dados-iptu.model';
import { DestinoLixo } from './destino-lixo.enum';
import { DocumentacaoImovel } from './documentacao-imovel.enum';
import { EsgotamentoSanitarioTipo } from './esgotamento-sanitario-tipo.enum';
import { ImovelAdicional } from './imovel-adicional/imovel-adicional.model';
import { InstalacaoEnergiaEletricaTipo } from './instalacao-energia-eletrica-tipo.enum';
import { RelacaoImovelTipo } from './relacao-imovel-tipo.enum';
import { UsoImovelTipo } from './uso-imovel-tipo.enum';

export class ImovelCadastroSocial {
  @Type(() => Endereco)
  @ValidateNested()
  @IsNotEmpty()
  endereco: Endereco;

  @IsEnum(RelacaoImovelTipo)
  @IsOptional()
  tipo: RelacaoImovelTipo;

  @IsString()
  @IsOptional()
  nomeProprietario?: string;

  @IsNumberString()
  @IsOptional()
  telefoneProprietario?: string;

  @IsString()
  @IsOptional()
  tipoEspecifique?: string;

  @IsEnum(UsoImovelTipo)
  @IsOptional()
  uso: UsoImovelTipo;

  @IsNumber()
  @IsOptional()
  quantidadeOcupacao: number;

  @IsBoolean()
  @IsOptional()
  acessoIndependente?: boolean;

  @IsNumber()
  @IsOptional()
  tempoOcupacao: number;

  @IsBoolean()
  @IsOptional()
  possuiIptu: boolean;

  @Type(() => DadosIptu)
  @ValidateNested()
  dadosIptu: DadosIptu;

  @IsEnum(DocumentacaoImovel)
  @IsOptional()
  documentacao: DocumentacaoImovel;

  @IsString()
  @IsOptional()
  documentacaoEspecifique?: string;

  @IsBoolean()
  @IsOptional()
  energiaEletrica: boolean;

  @IsEnum(InstalacaoEnergiaEletricaTipo)
  @IsOptional()
  instalacaoEnergiaEletricaTipo: InstalacaoEnergiaEletricaTipo;

  @IsBoolean()
  @IsOptional()
  abastecimentoAgua: boolean;

  @IsEnum(AbastecimentoAguaTipo)
  @IsOptional()
  abastecimentoAguaTipo: AbastecimentoAguaTipo;

  @IsString()
  @IsOptional()
  abastecimentoAguaTipoEspecifique?: string;

  @IsBoolean()
  @IsOptional()
  esgotamentoSanitario: boolean;

  @IsEnum(EsgotamentoSanitarioTipo)
  @IsOptional()
  esgotamentoSanitarioTipo: EsgotamentoSanitarioTipo;

  @IsString()
  @IsOptional()
  esgotamentoSanitarioTipoEspecifique?: string;

  @IsEnum(DestinoLixo)
  @IsOptional()
  destinoLixo: DestinoLixo;

  @IsString()
  @IsOptional()
  destinoLixoEspecifique?: string;

  @IsEnum(BanheiroTipo)
  @IsOptional()
  banheiro: BanheiroTipo;

  @IsBoolean()
  @IsOptional()
  atendidoPavimentacao: boolean;

  @IsBoolean()
  @IsOptional()
  captacaoAguasPluviais: boolean;

  @Type(() => Endereco)
  @ValidateNested()
  imovelAdicional?: ImovelAdicional;
}
