<ion-content class="ion-padding">
  <ion-text class="title title--large">Novo documento</ion-text>
  <p>
    <ion-text class="font-text"> Qual o tipo de documento que você quer adicionar? </ion-text>
  </p>
  <div>
    <ion-text class="font-text"> Pelo menos um documento pessoal deve possuir foto. </ion-text>
  </div>
  <ion-grid class="ion-no-padding ion-no-margin">
    <ion-row class="ion-justify-content-center">
      <ion-col sizeMd="3" sizeLg="6">
        <div class="ion-no-padding ion-no-margin" *ngIf="!type || type === 'escolher' || type === 'identidade' ">
          <ion-text class="description-text description-text--medium" *ngIf="!type|| type === 'escolher'">
            <br />Documentos de identidade </ion-text
          ><br />
          <usucampeao-menu-item
            title="RG"
            *ngIf="!documentoEnviado(tipoDocumentoProprietario.RG)"
            (click)="navigateToPhoto(tipoDocumentoProprietario.RG)"
          ></usucampeao-menu-item>
          <usucampeao-menu-item
            title="CPF"
            *ngIf="!documentoEnviado(tipoDocumentoProprietario.CPF)"
            (click)="navigateToPhoto(tipoDocumentoProprietario.CPF)"
          ></usucampeao-menu-item>
          <usucampeao-menu-item
            title="CNH"
            *ngIf="!documentoEnviado(tipoDocumentoProprietario.CNH)"
            (click)="navigateToPhoto(tipoDocumentoProprietario.CNH)"
          ></usucampeao-menu-item>
          <usucampeao-menu-item
          title="Sem Documentos para enviar"
          *ngIf="semDocumentosIdentidade()"
          [navigation]="false"
        ></usucampeao-menu-item>
        </div>
        <div class="ion-padding-top" *ngIf="!type || type === 'escolher' || type === 'estado-civil'">
          <ion-text class="description-text description-text--medium" *ngIf="!type || type === 'escolher'"
            >Comprovante de estado civil</ion-text
          >
          <usucampeao-menu-item
            title="Certidão de nascimento"
            *ngIf="!documentoEnviado(tipoDocumentoProprietario.BIRTH_CERTIFICATE) && mostrarItem(tipoDocumentoProprietario.BIRTH_CERTIFICATE)"
            (click)="navigateToPhoto('nascimento')"
          ></usucampeao-menu-item>
          <usucampeao-menu-item
            title="Certidão de casamento"
            *ngIf="!documentoEnviado(tipoDocumentoProprietario.MARRIAGE_CERTIFICATE) && mostrarItem(tipoDocumentoProprietario.MARRIAGE_CERTIFICATE)"
            (click)="navigateToPhoto('casamento')"
          ></usucampeao-menu-item>
          <usucampeao-menu-item
            title="Certidão de divórcio"
            *ngIf="!documentoEnviado(tipoDocumentoProprietario.DIVORCE_CERTIFICATE) && mostrarItem(tipoDocumentoProprietario.DIVORCE_CERTIFICATE)"
            (click)="navigateToPhoto('divorcio')"
          ></usucampeao-menu-item>
          <usucampeao-menu-item
            title="Certidão de óbito"
            *ngIf="!documentoEnviado(tipoDocumentoProprietario.DEATH_CERTIFICATE) && mostrarItem(tipoDocumentoProprietario.DEATH_CERTIFICATE)"
            (click)="navigateToPhoto('obito')"
          ></usucampeao-menu-item>
          <usucampeao-menu-item
          title="Sem Documentos para enviar"
          *ngIf="semDocumentosEstadoCivil()"
          [navigation]="false"
        ></usucampeao-menu-item>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-col sizeMd="3 ">
        <div class="button-padding ion-no-margin">
          <ion-button fill="outline " mode="md" expand="block " (click)="navigateBack() ">Cancelar </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
