import { MatrimonialRegime } from '@usucampeao/lib-reurb-simplificado';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'MatrimonialRegime'
})
export class MatrimonialRegimePipe implements PipeTransform {
  transform(value: MatrimonialRegime): string {
    switch (value) {
      case MatrimonialRegime.SEPARATE_PROPERTY_WITH_EQUITABLE_DISTRIBUTION:
      return 'Comunhão parcial';
      case MatrimonialRegime.TENANCY_BY_THE_ENTIRETY:
      return 'Comunhão universal';
      case MatrimonialRegime.SEPARATE_PROPERTY:
      return 'Separação total';
      case MatrimonialRegime.ACCRUAL_SYSTEM:
      return 'Participação final aquestos';
      default:
      return 'Status não cadastrado';
    }
  }
}
