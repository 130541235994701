import { Expose, Type } from 'class-transformer';
import { ReurbModalidade } from '../../enums';
import { AddressDto } from '../address/address.dto';
import { FileDto } from '../file';
import { CadastroStatusVendaType } from './cadastro-status-venda.enum';
import { RegistrationStatus } from './registration-status.enum';

export class ListRegistration {
  @Expose()
  id: string;

  @Expose()
  status: RegistrationStatus;

  @Expose()
  statusVenda: CadastroStatusVendaType;

  @Expose()
  projetoFid: string;

  @Expose()
  projetoNome: string;

  @Expose()
  @Type(() => AddressDto)
  address: AddressDto;

  @Expose()
  propertyId: string;

  @Expose()
  files: FileDto[];

  @Expose()
  reurbModalidade?: ReurbModalidade;

  @Expose()
  contractDate?: Date;

  constructor(id: string,
    status: RegistrationStatus,
    statusVenda: CadastroStatusVendaType,
    projetoFid: string,
    projetoNome: string,
    address: AddressDto,
    propertyId: string,
    files: FileDto[],
    reurbModalidade?: ReurbModalidade,
    contractDate?: Date
  ) {
    this.id = id;
    this.status = status;
    this.statusVenda = statusVenda;
    this.projetoFid = projetoFid;
    this.projetoNome = projetoNome;
    this.address = address;
    this.propertyId = propertyId;
    this.files = files;
    this.contractDate = contractDate;
    this.reurbModalidade = reurbModalidade;
  }
}
