<ion-content class="ion-padding">
  <div class="content-vertical-space-between">
    <span></span>
    <div>
      <ion-text class="text-size title">{{title}}</ion-text><br><br>
      <ion-text class="subtext-size"> {{text}} </ion-text>
      <br><br>
      <ion-text class="subtext-size"> {{subtext}}</ion-text><br>
    </div>
    <div>
      <ion-grid class="ion-no-padding ion-no-margin">
        <ion-row class="ion-justify-content-end">
          <ion-col size="auto">
            <ion-button mode="md" expand="block" *ngIf="clearButton"  fill="clear" (click)="navigateTo(clearButtonRota)">
              <b>{{clearButton}}</b>
            </ion-button>
          </ion-col>
          <ion-col size="auto">
            <ion-button mode="md" expand="block" *ngIf="fillButton" (click)="navigateTo(fillButtonRota)">{{fillButton}}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
