import { Expose, plainToInstance } from 'class-transformer';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { DocumentFileSubtype } from './document-file-subtype.enum';
import { DocumentFileDto, DocumentFileType } from './document-file.dto';

export class CreateDocumentFileDto {
  @Expose()
  @IsString()
  @IsOptional()
  name?: string;

  @Expose()
  @IsNotEmpty()
  type: DocumentFileType;

  @Expose()
  @IsString()
  @IsOptional()
  publico?: string | boolean;

  @Expose()
  @IsString()
  @IsOptional()
  descricao?: string;

  @Expose()
  @IsString()
  @IsOptional()
  observacoes?: string;

  registrationId?: string;

  subtype?: DocumentFileSubtype;

  justification?: string;

  /**
   * Cria uma nova instância de GetUpdateOwnerContactDto a partir de dtos
   * @param props DTO de Owner
   * @returns nova instância de GetUpdateOwnerContactDto
   */
  static from(props?: DocumentFileDto | Partial<DocumentFileDto>): CreateDocumentFileDto {
    return plainToInstance(CreateDocumentFileDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
