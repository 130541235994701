export enum UsuarioBackofficeTipo {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMINISTRADOR = 'ADMINISTRADOR',
  GERENTE_FINANCEIRO = 'GERENTE_FINANCEIRO',
  ANALISTA_FINANCEIRO = 'ANALISTA_FINANCEIRO',
  GERENTE_COMERCIAL = 'GERENTE_COMERCIAL',
  ANALISTA_COMERCIAL = 'ANALISTA_COMERCIAL',
  GERENTE_OPERACAO = 'GERENTE_OPERACAO',
  ANALISTA_OPERACAO = 'ANALISTA_OPERACAO',
  GERENTE_JURIDICO = 'GERENTE_JURIDICO',
  ANALISTA_JURIDICO = 'ANALISTA_JURIDICO',
  GERENTE_REGIONAL = 'GERENTE_REGIONAL',
  ANALISTA_REGIONAL = 'ANALISTA_REGIONAL',
  SUPERVISOR_LOCAL = 'SUPERVISOR_LOCAL',
  ATENDENTE = 'ATENDENTE',
  USUARIO = 'USUARIO',
}
