/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-date-picker-modal',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss']
})
export class DatePickerModalComponent {
  @Input() value!: Date;
  @Input() minDate!: Date;
  @Input() maxDate!: Date;

  constructor(private modalController: ModalController) { }

  public async close(event: any) {
    await this.modalController.dismiss(new Date(event));
  }
}
