import { Endereco } from '@usucampeao/interfaces';
import { IsCpf, RemoveSpecialCharacters } from '@usucampeao/utils';
import { Type } from 'class-transformer';
import { IsBoolean, IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString, Length, ValidateNested } from 'class-validator';
import { NivelInteresse } from './nivel-interesse.enum';

export class LeadCriarDto {
  @IsString()
  @IsNotEmpty()
  nome: string;

  @IsEmail()
  @IsNotEmpty()
  email: string;

  @RemoveSpecialCharacters()
  @IsString()
  @IsCpf()
  @IsNotEmpty()
  cpf: string;

  @RemoveSpecialCharacters()
  @IsString()
  @Length(11, 15)
  @IsNotEmpty()
  telefoneCelular: string;

  @RemoveSpecialCharacters()
  @IsString()
  @Length(10, 15)
  @IsOptional()
  telefonResidencial?: string;

  @IsBoolean()
  @IsNotEmpty()
  celularWhatsapp: boolean;

  @Type(() => Endereco)
  @ValidateNested()
  @IsNotEmpty()
  endereco: Endereco;

  @IsEnum(NivelInteresse)
  @IsNotEmpty()
  nivelIntersse: NivelInteresse;

  @IsString()
  @IsOptional()
  observacao?: string;
}
