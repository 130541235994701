import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { FooterModule } from '../../../components/footer/footer.module';
import { HeaderModule } from '../../../components/header/header.module';
import { PhotoModule } from '../../../components/photo/photo.module';
import { ProcessProgressModule } from '../../../components/process-progress/process-progress.module';
import { SkeletonItemModule } from '../../../components/skeleton-item/skeleton-item.module';
import { LazyLoadImageHooks } from '../../../hooks/lazy-load-image-hooks';
import { PipesModule } from '../../../pipes/pipes.module';
import { PropertyRoutingModule } from './property-routing.module';
import { PropertyPage } from './property.page';

@NgModule({
  declarations: [
    PropertyPage
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    FooterModule,
    HeaderModule,
    IonicModule,
    ProcessProgressModule,
    PropertyRoutingModule,
    PhotoModule,
    PipesModule,
    SkeletonItemModule,
  ],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks
    },
  ]
})
export class PropertyModule { }
