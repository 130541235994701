import { Permissoes } from '../permissao/permissoes.model';
import { Ref } from '../ref/ref.model';
import { Telefone } from '../telefone/telefone.model';

/**
 * Enumeration for user roles.
 */
export enum UsuarioTipo {
  /**
   * Global administrator.
   */
  ADMIN = 'ADMIN',
  /**
   * Global manager.
   */
  GLOBAL_MANAGER = 'GLOBAL_MANAGER',
  /**
   * Financial manager.
   */
  FINANCIAL_MANAGER = 'FINANCIAL_MANAGER',
  /**
   * Financial analyst
   */
  FINANCIAL_ANALYST = 'FINANCIAL_ANALYST',
  /**
   * Commercial manager
   */
  COMMERCIAL_MANAGER = 'COMMERCIAL_MANAGER',
  /**
   * Commercial analyst
   */
  COMMERCIAL_ANALYST = 'COMMERCIAL_ANALYST',
  /**
   * Operation manager
   */
  OPERATIONS_MANAGER = 'OPERATIONS_MANAGER',
  /**
   * Opetarion analyst
   */
  OPERATIONS_ANALYST = 'OPERATIONS_ANALYST',
  /**
   * Legal manager
   */
  LEGAL_MANAGER = 'LEGAL_MANAGER',
  /**
   * Legal analyst
   */
  LEGAL_ANALYST = 'LEGAL_ANALYST',
  /**
   * Regional (region/city/district) manager. Responsible for a domain ('dominio').
   */
  REGIONAL_MANAGER = 'REGIONAL_MANAGER',
  /**
   * Regional analyst
   */
  REGIONAL_ANALYST = 'REGIONAL_ANALYST',
  /**
   * Allotment manager. Responsible for a single
   */
  MANAGER = 'MANAGER',
  /**
   * "Uber-like partner", a sales clerk.
   * A PARTNER can register properties for other users.
   */
  PARTNER = 'PARTNER',
  /**
   * Regular user, usually a customer.
   */
  USER = 'USER',
}

/**
 * Enumeration for user account status.
 */
export enum UsuarioStatus {
  /**
   * Account is active.
   */
  ACTIVE = 'ACTIVE',
  /**
   * Account has been deactivated. As a result, user should not be able to log into the application.
   */
  INACTIVE = 'INACTIVE',
}

/**
 * Model interface (DTO) for 'Usuario' entity.
 * A 'Usuario' represents an,
 */
export interface Usuario {
  /**
   * User unique ID.
   */
  id: string;

  /**
   * User (full) name, i.e. name and surname.
   */
  nome: string;
  /**
   * User (account) name.
   */
  usuario: string;
  /**
   * User email.
   */
  email: string;
  /**
   * User telefone
   */
  telefone: Telefone;
  /**
   * User person data.
   */
  pessoa: Ref;

  /**
   * User role.
   */
  tipo: UsuarioTipo;
  /**
   * User permissions.
   */
  permissoes: Permissoes;
  /**
   * List of domains ("dominios") this user has access to.
   * Unlike other Ref fields, we're using simple ID array to allow queries on this field.
   */
  dominios: string[];

  /**
   * User account status.
   */
  ativo: UsuarioStatus;

  /**
   * User account creation timestamp.
   */
  data_criacao: Date;
  /**
   * User account last updated timestamp.
   */
  data_atualizacao: Date;
  /**
   * Reference to the user who created this account.
   */
  usuario_criacao: Ref;
  /**
   * Reference to the user who last updated this account.
   */
  usuario_atualizacao: Ref;
  /**
   * ID de cliente (usuário) no sistema Maxnível, para integração.
   */
  maxnivel_cliente_id: number;
  /**
   * ID de endereço do usuário no sistema Maxnível, para integração.
   */
  maxnivel_endereco_id: number;
}
