import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PropertyDocumentsPage } from './property-documents.page';

const routes: Routes = [
  {
    path: '', component: PropertyDocumentsPage
  },
  {
    path: 'documento/:documentType',
    loadChildren: () =>
      import('./type/type.module').then((m) => m.TypeModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PropertyDocumentsPageRoutingModule { }
