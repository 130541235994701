<ng-container *ngIf="lot?.id" class="edit-map__container">
    <usucampeao-header mode="md" backRoute="/lote" title="Lote do Imóvel"></usucampeao-header>
    <div class="ion-padding-bottom ion-padding-start">
        <ion-text class="font-text">
            Indique o lote do seu imóvel no mapa
        </ion-text>
    </div>
    <ion-content scrollY="false">
        <div class="map">
            <usucampeao-map [projetoId]="projectid"  [selectedId]="lot.id" (setLot)="setLot($event)"></usucampeao-map>
        </div>
        <div class="modal" *ngIf="showModal">
            <div class="modal__container">
                <ion-text class="font-text font-text--large ion-padding-top">
                    <strong>Quadra {{lot?.quadra || ''}}, Lote {{ lot?.label || '' }}</strong>
                </ion-text>

                <div class="modal__actions">
                    <ion-button *ngIf="!blockAccess && (this.authQuery.isConnectionOnline$ | async)"
                        [disabled]="propertyQuery.selectLoading() | async" class="ion-padding" expand="block" mode="md" (click)="updateLocalization()">
                        {{ fluxo ? 'Continuar': 'Salvar' }}
                    </ion-button>

                    <ion-label *ngIf="(this.authQuery.isConnectionOnline$ |async)===false">
                      <ion-icon name="cloud-offline-outline" slot="start"></ion-icon>
                      Sem internet
                    </ion-label>

                    <p class="font-text ion-text-center ion-padding-top">
                        <ion-text color="primary"><strong (click)="presentModalAskHelp()">Solicitar ajuda</strong>
                        </ion-text>
                    </p>
                </div>
            </div>
        </div>
    </ion-content>
</ng-container>
