import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { OwnerSimplifiedDto, StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LoadingService } from '../../../../services/loading.service';
import { ToastService } from '../../../../services/toast.service';
import { AuthQuery } from '../../../auth/state/auth.query';
import { InviteModal } from '../../../modal/invite-fill/invite.modal';
import { OwnersQuery } from '../state/owners.query';
import { OwnersService } from '../state/owners.service';

@Component({
  selector: 'usucampeao-details',
  templateUrl: 'details.page.html',
  styleUrls: ['details.page.scss'],
})
export class DetailsPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  public defaultImage = '../../../assets/person-circle-outline.svg';
  private registrationId: string;
  private ngUnsubscribe = new Subject();
  public spouse: OwnerSimplifiedDto;
  public owner: OwnerSimplifiedDto;

  constructor(
    private authQuery: AuthQuery,
    private loaderService: LoadingService,
    private modalController: ModalController,
    private ownersQuery: OwnersQuery,
    private ownersService: OwnersService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        switchMap((params) => {
          this.registrationId = params.id;
          return this.getOwners();

        }),
        takeUntil(this.ngUnsubscribe))
      .subscribe();

    this.ownersQuery
      .selectLoading()
      .pipe(
        finalize(async () => await this.loaderService.dismiss()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async (loading) => {
        if (loading) {
          await this.loaderService.createLoader();
        } else {
          await this.loaderService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public get statusPreenchimento(): typeof StatusPreenchimento {
    return StatusPreenchimento;
  }

  /**
   * Foto do proprietário
   */
  public get ownerPhoto() {
    return this.authQuery.photoId$
      .pipe(
        filter(id => !!id),
        map(id => `/users/my-photo/${id}`)
      );
  }

  /**
   * Busca os proprietários do atendimento
   */
  private getOwners() {
    return this.ownersService.getOwnersByRegistrationId(this.registrationId)
      .pipe(
        take(1),
        tap(owners => {
          this.owner = owners.find((owner) => owner.main);
          this.spouse = owners.find(owner => !owner.main);
        }),
        catchError(() => this.toastService.erro('Erro ao buscar os dados. Por favor tente novamente mais tarde.')),
        takeUntil(this.ngUnsubscribe),
      );
  }

  /**
   * Navega para a rota selecionada
   * @param id id do proprietário
   * @param route rota selecionada
   */
  public goTo(id: string, route: string, fluxo: boolean): void {
    if (fluxo) {
      this.router.navigate([id, route], {
        relativeTo: this.route,
        queryParams: { fluxo: true },
      });
    } else {
      this.router.navigate([id, route], { relativeTo: this.route });
    }
  }

  async presentModalInviteFill() {
    const modal = await this.modalController.create({
      component: InviteModal,
      cssClass: 'small-modal',
    });
    return await modal.present();
  }

  public continue(): void {
    if (this.owner.fillInStatus?.personalData === StatusPreenchimento.PENDENTE) {
      this.goTo(this.owner.id, `dados-basicos`, true);
    } else if (this.owner.fillInStatus?.documentsData === StatusPreenchimento.PENDENTE) {
      this.goTo(this.owner.id, `documentos`, true);
    } else if (this.owner.fillInStatus?.contactData === StatusPreenchimento.PENDENTE) {
      this.goTo(this.owner.id, `contato`, true);
    } else if (this.owner.fillInStatus?.documents === StatusPreenchimento.PENDENTE) {
      this.goTo(this.owner.id, `envio-documentos`, true);
    } else if (this.spouse.fillInStatus?.personalData === StatusPreenchimento.PENDENTE) {
      this.goTo(this.spouse.id, `dados-basicos`, true);
    } else if (this.spouse.fillInStatus?.documentsData === StatusPreenchimento.PENDENTE) {
      this.goTo(this.spouse.id, `documentos`, true);
    } else if (this.spouse.fillInStatus?.contactData === StatusPreenchimento.PENDENTE) {
      this.goTo(this.spouse.id, `contato`, true);
    } else if (this.spouse.fillInStatus?.documents === StatusPreenchimento.PENDENTE) {
      this.goTo(this.spouse.id, `envio-documentos`, true);
    }
    else {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }
}
