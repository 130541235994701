import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-confirma-envio',
  templateUrl: 'confirma-envio.modal.html',
  styleUrls: ['confirma-envio.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmaEnvioModal {
  constructor(
    public modalController: ModalController,
    public router: Router,
  ) {}

  dismiss() {
    this.modalController.dismiss();
  }

  navigateTo(): void {
    this.modalController.dismiss(SUBMIT_CONFIRMATION_TOKEN);
  }
}

export const SUBMIT_CONFIRMATION_TOKEN = 'confirmado envio';
