
export class QuadraListarDto {
  id: number;
  label: string;

  constructor(
    label: string,
    id: number,
  ) {
    this.label = label
    this.id = id;
  }
}
