import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ExcluiCadastroModal } from './exclui-cadastro.modal';

@NgModule({
  declarations: [ExcluiCadastroModal],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    UiMobileModule,
  ],
  exports: [ExcluiCadastroModal],
})
export class ExcluiCadastroModule { }
