import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';
import { PhotoModule } from '../../../../components/photo/photo.module';
import { EditPhotoPage } from './edit-photo.page';
import { EditPhotoRoutingModule } from './edit-photo-routing.module';

@NgModule({
  declarations: [
    EditPhotoPage
  ],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    IonicModule,
    PhotoModule,
    EditPhotoRoutingModule,
  ]
})
export class EditPhotoModule { }
