export enum GrauInstrucao {
  ANALFABETO = 'ANALFABETO',
  FUNDAMENTAL_INCOMPLETO = 'FUNDAMENTAL_INCOMPLETO',
  FUNDAMENTAL_COMPLETO = 'FUNDAMENTAL_COMPLETO',
  MEDIO_INCOMPLETO = 'MEDIO_INCOMPLETO',
  MEDIO_COMPLETO = 'MEDIO_COMPLETO',
  GRADUACAO = 'GRADUACAO',
  POS_GRADUACAO = 'POS_GRADUACAO',
  MESTRADO = 'MESTRADO',
  DOUTORADO = 'DOUTORADO',
}
