import { ContactModule } from './../../modal/contact/contact.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAllotmentRoutingModule } from './select-allotment-routing.module';
import { IonicModule } from '@ionic/angular';
import { SelectAllotmentPage } from './select-allotment.page';
import { HeaderModule } from '../../../components/header/header.module';
import { FooterModule } from '../../../components/footer/footer.module';
import { AllotmentUnavailableModule } from '../../modal/allotment-unavailable/allotment-unavailable.module';


@NgModule({
  declarations: [
    SelectAllotmentPage
  ],
  imports: [
    CommonModule,
    ContactModule,
    HeaderModule,
    IonicModule,
    FooterModule,
    AllotmentUnavailableModule,
    SelectAllotmentRoutingModule
  ]
})
export class SelectAllotmentModule { }
