import { Component, Input } from '@angular/core';
import {
  CadastroContratoDto,
  MaritalStatus,
  MeioPagamento,
  OwnerDto,
  PropertyDto,
  PropertyUsage,
  ReurbModalidade
} from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usu-morador-usucampeao-reurb-averbacao',
  templateUrl: './morador-usucampeao-reurb-averbacao.html',
  styleUrls: ['./morador-usucampeao-reurb-averbacao.scss'],
})
export class MoradorUsucampeaoReurbAverbacaoComponent {
  @Input() cadastro!: CadastroContratoDto;
  @Input() proprietario!: Partial<OwnerDto>;
  @Input() conjuge!: Partial<OwnerDto>;
  @Input() imovel!: Partial<PropertyDto>;
  @Input() isPrint = false;


  public get meioPagamento(): typeof MeioPagamento {
    return MeioPagamento
  }

  public get imovelTipo(): typeof PropertyUsage {
    return PropertyUsage
  }

  public get maritalStatus(): typeof MaritalStatus {
    return MaritalStatus
  }
  public get reurbModalidade(): typeof ReurbModalidade {
    return ReurbModalidade;
  }
}
