import { Type } from 'class-transformer';
import { IsDateString, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { StatusUpload } from '../enums/status-upload.enum';
import { Ref } from '../ref/ref.model';

/**
 * Model interface (DTO) for 'Upload' entity.
 * A 'Upload' represents an uploaded file.
 */
export class Upload {
  /**
   * Upload unique ID.
   */
  @IsString()
  @IsOptional()
  id?: string;

  /**
   * Uploaded file name.
   */
  @IsString()
  nome: string;

  /**
   * Uploaded file URL.
   */
  @IsString()
  @IsNotEmpty()
  url?: string;

  /**
   * Uploaded file mimeType
   */
  @IsString()
  @IsNotEmpty()
  mimeType?: string;

  /**
   * File upload date.
   */
  @IsDateString()
  @IsOptional()
  data_criacao?: Date;

  /**
   * Reference to the user who uploaded this file.
   */
  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  usuario_criacao?: Ref;

  /**
   * File upload update date.
   */
  @IsDateString()
  @IsOptional()
  data_atualizacao?: Date;

  /**
   * Reference to the user who update uploaded this file.
   */
  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  usuario_atualizacao?: Ref;

  /**
   * Data da aprovação/reprovação do documento.
   */
  @IsDateString()
  @IsOptional()
  data_aprovacao_reprovacao?: Date;

  /**
   * Usuário que aprovou/reprovou o documento.
   */
  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  usuario_aprovacao_reprovacao?: Ref;

  /**
   * Data da solicitação de exclusão do arquivo do documento.
   */
  @IsDateString()
  @IsOptional()
  data_exclusao?: Date;

  /**
   * Usuario que solicitou a exclusão do arquivo do documento.
   */
  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  usuario_exclusao?: Ref;

  /**
   * Status do documento
   */
  @IsEnum(StatusUpload)
  status: StatusUpload;

  /**
   * Referência do documento
   */
  @Type(() => Ref)
  @ValidateNested()
  @IsNotEmpty()
  documento: Ref;

  // TODO: Handle file expiration/removal.
}
