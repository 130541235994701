import { Pipe, PipeTransform } from '@angular/core';
import { DiaPagamento } from '@usucampeao/lib-reurb-simplificado';


@Pipe({
  name: 'diaPagamento'
})
export class DiaPagamentoPipe implements PipeTransform {

  transform(value: DiaPagamento): string {
    switch (value) {
      case DiaPagamento.DIA_5:
        return 'Todo dia 5';

      case DiaPagamento.DIA_10:
        return 'Todo dia 10';

      case DiaPagamento.DIA_15:
        return 'Todo dia 15';

      case DiaPagamento.DIA_20:
        return 'Todo dia 20';

      default:
        return value;
    }
  }

}
