import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { PagamentoListagemDto } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastService } from '../../services/toast.service';
import { PagamentoService } from '../registration/pagamento/state/pagamentos.service';
import { RegistrationQuery } from '../registration/state/registration.query';
import { RegistrationService } from '../registration/state/registration.service';
import { TutorialModal } from './../modal/tutorial/tutorial.modal';

@Component({
  selector: 'usucampeao-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnDestroy, ViewWillEnter, ViewWillLeave {
  private ngUnsubscribe = new Subject();
  public registrations$ = this.registrationQuery.selectAll();
  public allResgistrationIDs = [];
  public pagamentos = [];
  public mostrarTodosPagamentos = false;

  constructor(
    private registrationQuery: RegistrationQuery,
    private pagamentoService: PagamentoService,
    private registrationService: RegistrationService,
    public loadingController: LoadingController,
    private router: Router,
    private route: ActivatedRoute,
    public modalController: ModalController,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter() {
    this.registrationService.listRegistrationsByUser()
      .pipe(
        switchMap(() => this.pagamentoService.buscarProximoVencimento()),
        tap(pagamentos => this.pagamentos = pagamentos),
        catchError(async () => await this.toastService.erro('Erro ao carregar cadastros. Tente novamente mais tarde.')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  /**
   * Controla se mostra todos os pagamentos próximos ao vencimento
   */
  public toggleMostrarPagamentos(): void {
    this.mostrarTodosPagamentos = !this.mostrarTodosPagamentos;
  }

  public async navega(): Promise<void> {
    this.router.navigate([`/novo-cadastro/loteamento`]);
    const modal = await this.modalController.create({
      component: TutorialModal,
      cssClass: 'poderegularizar-tutorial',
    });
    return await modal.present();
  }

  public navigateToParcela(pagamento: PagamentoListagemDto): void {
    this.router.navigate(["pagamento/", pagamento.id, pagamento.cadastroId], { relativeTo: this.route });
  }

}
