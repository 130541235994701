import { RegistrationCompletedPage } from './registration-completed/registration-completed';
import { PhotoRegistrationPage } from './photo-registration/photo-registration.page';
import { HelpPage } from './help/help.page';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guard/auth.guard';
import { LoginPage } from './login.page';
import { ViewAccountPage } from './view-account/view-account.page';
import { EditPersonalPhotoPage } from './edit-photo/edit-personal-photo';
import { CreateAccountPage } from './create-account/create-account.page';
import { CreateAccountGuard } from '../guard/create-account.guard';

const routes: Routes = [
  {
    path: 'criar-conta',
    component: CreateAccountPage,
  },
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'minha-conta',
    canActivate: [AuthGuard],
    component: ViewAccountPage,
  },
  {
    path: 'trocar-foto',
    canActivate: [AuthGuard,],
    component: EditPersonalPhotoPage,
  },
  {
    path: 'ajuda',
    canActivate: [AuthGuard],
    component: HelpPage,
  },
  {
    path: 'tirar-foto',
    canActivate: [AuthGuard],
    component: PhotoRegistrationPage,
  },
  {
    path: 'cadastro-finalizado',
    canActivate: [AuthGuard],
    component: RegistrationCompletedPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginPageRoutingModule { }
