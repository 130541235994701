<ion-header class="ion-no-border ion-no-margin ion-padding-start ion-padding-top">
  <ion-item lines="none" class="ion-no-padding">
    <div class="ion-text-center">
      <ion-avatar class="item-avatar">
        <img class="image" [defaultImage]="defaultImage" [lazyLoad]="userPhoto | async" />
      </ion-avatar>
      <div *ngIf="editOption === true" class="font-text">
        <ion-text color="secondary" (click)="navigateToEditPhoto()"><strong>Trocar foto</strong></ion-text>
      </div>
    </div>
    <ion-label>
      <ion-text class="font-text font-text--large">
        {{ authQuery.meName$ |async }} </ion-text><br>
      <ion-text class="description-text description-text--medium">
        {{ authQuery.meCpf$ |async }}
      </ion-text>
    </ion-label>
    <ion-icon (click)="close()" icon="close-outline" class="icon ion-padding-horizontal ion-activatable ripple-parent"
      text="fechar" color="medium" name="close-outline">
    </ion-icon>
  </ion-item>
</ion-header>
