import { FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ElementBase, Textbox, toFormGroup } from '@usucampeao/ui-mobile';

@Component({
  selector: 'usucampeao-another-document',
  templateUrl: 'another-document.modal.html',
  styleUrls: ['another-document.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AnotherDocumentModal implements OnInit {
  form$: ElementBase<string>[] | null = [];
  form!: FormGroup;
  constructor(public modalController: ModalController, public router: Router) { }
  ngOnInit() {
    this.form$ = this.getForm();
    this.form = toFormGroup(this.getForm() as ElementBase<string>[]);
  }

  getForm() {
    const questions: ElementBase<string>[] = [
      new Textbox({
        key: 'new-document',
        label: 'Nome do documento',
        required: true,
        validators: [Validators.required],
      }),
    ];
    return questions;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  navigateForward(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const nomeDocumento = this.form?.get('new-document')?.value;
    if (!nomeDocumento) {
      return;
    }
    this.modalController.dismiss(nomeDocumento);
  }
}
