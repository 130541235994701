<ion-content class="ion-no-padding">
    <div class="content-vertical-space-between">
        <usucampeao-header mode="md" backRoute=".." title="Minha conta"></usucampeao-header>
        <div>
            <ion-grid class="ion-no-padding ion-padding-start ion-padding-end">
                <ion-row class="ion-align-items-center">
                    <ion-col sizeXs="12" sizeSm="6" offsetSm="3">
                        <ion-avatar class="item-avatar">
                            <img class="image" [defaultImage]="defaultImage" [lazyLoad]="userPhoto$ | async" />
                        </ion-avatar>
                        <div class="font-text ion-text-center" *ngIf="edit === false">
                            <ion-text color="primary" (click)="navigateToEditPhoto()">
                                <strong>Trocar foto</strong>
                            </ion-text>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                    <ion-col sizeXs="12" sizeSm="6" class="ion-no-margin ion-no-padding">
                        <form mode="md" [formGroup]="form" class="ion-text-center ion-padding-top" (ngSubmit)="onSubmit()">
                            <div align="center">
                                <div *ngFor="let field of form$" class="form-row ion-no-padding">
                                    <usucampeao-build-form [field]="field" [form]="form"></usucampeao-build-form>
                                </div>
                            </div>
                        </form>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                    <ion-col sizeMd="6" sizeLg="3" class="ion-no-margin ion-no-padding ion-padding-top" *ngIf="edit === false">
                        <ion-button expand="block" type="submit" mode="md" (click)="editAccount()">
                            Editar
                        </ion-button>
                        <br>
                        <ion-button fill="outline" expand="block" type="submit" mode="md" (click)="goBack()">
                            Voltar
                        </ion-button>
                    </ion-col>
                    <ion-col sizeMd="6" sizeLg="3" class="ion-no-margin ion-no-padding ion-padding-top" *ngIf="edit === true">
                        <ion-button mode="md" expand="block" type="submit" (click)="onSubmit()">
                            Continuar
                        </ion-button>
                        <br>
                        <ion-button mode="md" fill="outline" expand="block" (click)="viewAccount()">
                            Cancelar
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <usucampeao-footer></usucampeao-footer>
    </div>
</ion-content>