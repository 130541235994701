export class MenuItem {
  title: string;
  description: string;
  status: string;
  classColor: MenuItemClassColor;
}

export enum MenuItemClassColor {
  PENDING = 'secondary-red-text',
  SENT = 'secondary-text'
}
