import { IsDateString, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class OmieAccountReceivableDto {
  @IsNumber()
  @IsOptional()
  //@ApiProperty()
  id?: number;

  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  paymentId: string;

  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  customerId: number;

  @IsDateString()
  @IsNotEmpty()
  //@ApiProperty()
  dueDate: Date;

  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  amount: number;

  @IsString()
  @IsNotEmpty()
  //@ApiProperty()
  categoryId: string;

  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  bankAccountId: number;

  @IsNumber()
  @IsNotEmpty()
  //@ApiProperty()
  projectId: number;

  @IsNumber()
  @IsOptional()
  installment?: number;

  @IsNumber()
  @IsOptional()
  totalInstallment?: number;
}
