export const environment = {
  production: true,
  combined: 'combined-sw.js',
  persistName: 'PodeRegularizarState',
  bugsnag: {
    apiKey: '8991f1247c630fb99203a3d73c36e55e',
    releaseStage: 'homolog',
  },
  api: {
    baseUrl: '/api'
  },
  firebase: {
    apiKey: 'AIzaSyATNNiYJsrX2mVpqRvzUqJHe7ME3zrxyfI',
    authDomain: 'usucampeao-app-dev.firebaseapp.com',
    databaseURL: 'https://usucampeao-app-dev.firebaseio.com',
    projectId: 'usucampeao-app-dev',
    storageBucket: 'usucampeao-app-dev.appspot.com',
    messagingSenderId: '633541209929',
    appId: '1:633541209929:web:8a72f9a1afb19589af7498',
    measurementId: 'G-T02W1BB40B',
  },
  publicVapidKey: "BLTVDexX-2zlySf8wgqp7rZ_23-NQCcyQ-a0lyNspDuNt-nyhUL16uKt4NIP8vM1GU00qds9uhTNV7HNecT1HI0",
  cookieInstallName: 'cookieInstallHomolog',
  mapbox: {
    token: "pk.eyJ1IjoidXN1Y2FtcGVhb3RpIiwiYSI6ImNrdTc0MzZoczVnanEyd28yNnZmbGswM3AifQ.YZO1hyCefOE5zguNyF0N5A"
  }
};
