/**
 * Enum para status de pagamento do atendimento.
 */
export enum AttendancePaymentStatus {
  /**
   * A parcela foi paga em dia.
   */
  EM_DIA = 'EM_DIA',
  /**
   * A parcela está atrasada.
   */
  ATRASADO = 'ATRASADO',
  /**
   * Todos os pagamentos foram efetuados.
   */
  PAGO = 'PAGO'
}
