import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController, ViewWillEnter, ViewWillLeave } from "@ionic/angular";
import { PagamentoListagemDto, PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from "rxjs";
import { take } from 'rxjs/operators';
import { NavigationService } from "../../../services/navigation.service";
import { ToastService } from '../../../services/toast.service';
import { AuthQuery } from "../../auth/state/auth.query";
import { RegistrationHelpModal } from "../../modal/registration-help/registration-help.modal";
import { PagamentoService } from "./state/pagamentos.service";

@Component({
  selector: 'usucampeao-pagamento',
  templateUrl: 'pagamento.page.html',
  styleUrls: ['pagamento.page.scss'],
})
export class PagamentoPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  private ngUnsubscribe = new Subject();
  private id: string;
  isLoading = false;
  isLoadingSelected = false;
  resumepagamentos;

  constructor(
    public authQuery: AuthQuery,
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private pagamentoService: PagamentoService,
    private toastService: ToastService
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        take(1)
      )
      .subscribe((params) => {
        this.id = params.id
        this.getAllpayments()
      })
  }

  getAllpayments(): void {
    this.isLoading = true;
    this.pagamentoService.getPayments(this.id)
      .pipe(
        take(1)
      )
      .subscribe(data => {
        this.resumepagamentos = this.getResume(data);
        this.isLoading = false;
      }, async () => await this.toastService.erro('Erro ao buscar pagamentos. Tente novamente mais tarde.'))
  }

  private getResume(pagamentos: PagamentoListagemDto[]) {
    const dataResume = [{
      parcela: pagamentos.filter((item) => item.status === PagamentoStatus.CRIADO).length,
      valorDevido: this.sumParcelas(pagamentos.filter((item) => item.status === PagamentoStatus.CRIADO || item.status === PagamentoStatus.PROGRAMADO)),
      status: PagamentoStatus.CRIADO,
    },
    {
      parcela: pagamentos.filter((item) => item.status === PagamentoStatus.PAGO).length,
      valorDevido: this.sumParcelas(pagamentos.filter((item) => item.status === PagamentoStatus.PAGO)),
      status: PagamentoStatus.PAGO,
    },
    {
      parcela: pagamentos.filter((item) => item.status === PagamentoStatus.NAO_PAGO).length,
      valorDevido: this.sumParcelas(pagamentos.filter((item) => item.status === PagamentoStatus.NAO_PAGO)),
      status: PagamentoStatus.NAO_PAGO,
    },
    {
      parcela: pagamentos.filter((item) => item.status === PagamentoStatus.EM_ANALISE).length,
      valorDevido: this.sumParcelas(pagamentos.filter((item) => item.status === PagamentoStatus.EM_ANALISE)),
      status: PagamentoStatus.EM_ANALISE,
    }].filter((item) => item.valorDevido > 0);

    return {
      entrada: pagamentos[0]?.valorDevido || 0,
      numeroBoletos: pagamentos.length > 2 ? pagamentos.length - 1 : pagamentos.length || 0,
      valorParcela: pagamentos[1]?.valorDevido || 0,
      total: this.sumParcelas(pagamentos),
      valorPendente: pagamentos.filter((pagamento) => pagamento.status === PagamentoStatus.CRIADO || pagamento.status === PagamentoStatus.PROGRAMADO || pagamento.status === PagamentoStatus.NAO_PAGO),
      valorPago: pagamentos.filter((item) => item.status === PagamentoStatus.PAGO),
      resume: dataResume

    }
  }

  sumParcelas(payment) {
    if (!payment || payment.length === 0) {
      return 0
    }
    return payment.map((item) => item.valorDevido).reduce((acc, parcela) => acc + parcela, 0)
  }

  async presentModalContact() {
    const modal = await this.modalController.create({
      component: RegistrationHelpModal,
      cssClass: 'registration-help-modal',
    });
    return await modal.present();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public goBack(): void {
    this.navigationService.goBack();
  }

  navigateToParcela(data) {
    this.router.navigate(["detalhes", data, this.id], { relativeTo: this.route });
  }






}
