import { IsDateString, IsEnum, IsOptional, IsString } from 'class-validator';
import { UF } from '../../enums';

export class DadosEmancipacao {
  @IsString()
  @IsOptional()
  numeroRegistro: string;

  @IsDateString()
  @IsOptional()
  dataRegistro: Date;

  @IsString()
  @IsOptional()
  numeroCartorio: string;

  @IsEnum(UF)
  @IsOptional()
  estado: UF;

  @IsString()
  @IsOptional()
  cidade: string;
}
