import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class ProjetoQuadraCriarDto {
  @IsNumber()
  @IsNotEmpty()
  quadraId: number;

  @IsString()
  @IsNotEmpty()
  projetoFid: string;

  constructor(
    quadraId: number,
    projetoFid: string,
  ) {
    this.quadraId = quadraId;
    this.projetoFid = projetoFid;
  }
}
