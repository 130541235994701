import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController, ViewWillLeave } from '@ionic/angular';
import { TipoContract } from '@usucampeao/lib-contratos';
import {
  CadastraDadosPagamentoDto, PaymentType,
  ProjetoSimplificadoDto, RegistrationSimplifiedDto, TabelaPrecoDetalhesDto,
  TabelaPrecoParcela,
  TabelaPrecoParcelaListaDto
} from '@usucampeao/lib-reurb-simplificado';
import { ElementBase, toFormGroup } from '@usucampeao/ui-mobile';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DiaPagamentoPipe } from '../../../pipes/dia-pagamento.pipe';
import { PropertyCardInstallmentsPipe } from '../../../pipes/property-card-installments.pipe';
import { PropertyNumberInstallmentsPipe } from '../../../pipes/property-number-installments.pipe';
import { LoadingService } from '../../../services/loading.service';
import { NavigationService } from '../../../services/navigation.service';
import { ToastService } from '../../../services/toast.service';
import { ConfirmaEnvioModal, SUBMIT_CONFIRMATION_TOKEN } from '../../modal/confirma-envio/confirma-envio.modal';
import { ContractModal } from '../../modal/contract/contract.modal';
import { InformationAlertModal } from '../../modal/information-alert/information-alert.modal';
import { RegistrationSubmitHelpModal } from '../../modal/registration-submit-help/registration-submit-help.modal';
import { PropertyService } from '../property/state/property.service';
import { RegistrationQuery } from '../state/registration.query';
import { RegistrationService } from '../state/registration.service';
import { PropertyPaymentPipe } from './../../../pipes/property-payment.pipe';
import {
  getformCardInstallments,
  getformExpirationPreference,
  getformNumberInstallments,
  getformPayment
} from './property-to-send.form';

@Component({
  selector: 'usucampeao-property-to-send',
  templateUrl: 'property-to-send.page.html',
  styleUrls: ['property-to-send.page.scss'],
})
export class PropertyToSendPage implements ViewWillLeave, OnDestroy {
  public fluxo: boolean;
  public formPayment$: ElementBase<string>[] | null = [];
  public formDueDate: ElementBase<string>[] | null = [];
  public formNumberInstallments$: ElementBase<string>[] | null = [];
  public formCardInstallments$: ElementBase<string>[] | null = [];
  public form!: FormGroup;
  public payLoad = '';
  public hasDueDate = true;
  public hasCardDueDate = false;
  private id: string;
  public projeto: ProjetoSimplificadoDto;
  public tabelaPrecoSelecionada: TabelaPrecoDetalhesDto;
  public tabelaPreco: TabelaPrecoDetalhesDto[];
  public cadastro: RegistrationSimplifiedDto;

  public acceptTerms = false;
  public comAverbacao = false;
  private ngUnsubscribe = new Subject();

  constructor(
    private loadingService: LoadingService,
    private modalController: ModalController,
    private navigationService: NavigationService,
    private propertyCardInstallmentsPipe: PropertyCardInstallmentsPipe,
    private diaPagamentoPipe: DiaPagamentoPipe,
    private propertyNumberInstallmentsPipe: PropertyNumberInstallmentsPipe,
    private propertyPaymentPipe: PropertyPaymentPipe,
    private propertyService: PropertyService,
    private registrationQuery: RegistrationQuery,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) { }


  private inicializarForm(): void {
    this.formPayment$ = getformPayment(this.propertyPaymentPipe);
    this.formDueDate = getformExpirationPreference(
      this.diaPagamentoPipe
    );
    this.formNumberInstallments$ = getformNumberInstallments(
      this.propertyNumberInstallmentsPipe, this.tabelaPrecoSelecionada.parcelas
    );
    this.formCardInstallments$ = getformCardInstallments(
      this.propertyCardInstallmentsPipe
    );
    this.form = toFormGroup(this.getForm() as ElementBase<string>[]);
    this.form.get('paymentType').setValue(PaymentType.TICKET);
    this.form.valueChanges.subscribe((data) => this.validateForm(data));
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        tap(params => this.id = params.id),
        switchMap(() => this.registrationQuery.selectEntity(this.id)),
        tap((cadastro: RegistrationSimplifiedDto) => this.cadastro = cadastro),
        switchMap(cadastro => this.registrationService.buscarDetalhesProjeto(cadastro.projetoFid)),
        tap(projeto => this.projeto = projeto),
        switchMap(() => this.registrationService.buscaDetalhesPreco(this.projeto.id)),
        tap(tabelaPreco => this.tabelaPreco = tabelaPreco),
        tap(() => this.selecionarTabelaPreco()),
        tap(() => this.inicializarForm()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    this.registrationQuery
      .selectLoading()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (isLoading) => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public get showDueDate(): boolean {
    return this.hasDueDate && this.form?.get('installments')?.value !== TabelaPrecoParcela.PARCELA_0;
  }

  public get pagamentoSelecionado(): TabelaPrecoParcelaListaDto {
    const pagamentoSelecionado = this.form?.get('installments')?.value;
    return this.tabelaPrecoSelecionada?.parcelas?.find(parcela => parcela.parcela === pagamentoSelecionado);
  }

  private getForm(): ElementBase<string>[] {
    const formCreate: ElementBase<string>[] = [
      ...getformPayment(this.propertyPaymentPipe),
      ...getformExpirationPreference(this.diaPagamentoPipe),
      ...getformNumberInstallments(this.propertyNumberInstallmentsPipe, this.tabelaPrecoSelecionada.parcelas),
      ...getformCardInstallments(this.propertyCardInstallmentsPipe),
    ];
    return formCreate;
  }

  public selecionarTabelaPreco(): void {
    const tabelaPrecoSelecionada = this.tabelaPreco.find((item) => item.modalidade === this.cadastro.reurbModalidade && (this.comAverbacao ? item.averbacao : !item.averbacao));
    this.tabelaPrecoSelecionada = tabelaPrecoSelecionada ? tabelaPrecoSelecionada[0] : this.tabelaPreco[0];
  }

  public async onSubmit(): Promise<void> {
    const form = this.form.value;
    this.form.markAllAsTouched();
    if (this.form.invalid && form.installments !== "PARCELA_0") {
      await this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
      return;
    }

    if (!this.acceptTerms) {
      this.presentTermsAlertModal();
      return;
    }
    const cadastraDadosPagamentoDto = this.buildDadosPagamento();
    this.cadastraPagamento(cadastraDadosPagamentoDto);
  }

  public buildDadosPagamento(): CadastraDadosPagamentoDto {
    const formValue = Object.assign({}, this.form.value);
    const payment = this.tabelaPrecoSelecionada.parcelas.find((item) => item.parcela === formValue.installments)
    const cadastraDadosPagamentoDto = new CadastraDadosPagamentoDto();
    cadastraDadosPagamentoDto.valorContrato = payment.valorTotal;
    cadastraDadosPagamentoDto.totalParcelas = payment.quantidadeParcela;
    cadastraDadosPagamentoDto.valorParcela = payment?.valorParcela ? payment?.valorParcela : payment.valorAto;
    cadastraDadosPagamentoDto.valorEntrada = payment.valorAto;
    cadastraDadosPagamentoDto.diaVencimento = formValue.dueDate;
    cadastraDadosPagamentoDto.meioPagamento = formValue.paymentType;
    cadastraDadosPagamentoDto.valorAverbacao = this.tabelaPrecoSelecionada.valorAverbacao;

    return cadastraDadosPagamentoDto;
  }

  private cadastraPagamento(cadastraPagamentoDto: CadastraDadosPagamentoDto) {
    this.registrationService
      .cadastrarDadosPagamento(this.id, cadastraPagamentoDto)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        () => this.navigationService.goBack(),
        async () => await this.toastService.erro('Erro ao cadastrar pagamentos. Tente novamente mais tarde.'));
  }

  public async presentModalClickHereRequestHelp(): Promise<void> {
    const modal = await this.modalController.create({
      component: RegistrationSubmitHelpModal,
      cssClass: 'registration-submit-help-modal',
    });
    return await modal.present();
  }

  public async presentModalConfirmaEnvio(): Promise<void> {
    const modal = await this.modalController.create({
      component: ConfirmaEnvioModal,
      cssClass: 'confirma-envio-modal',
    });
    await modal.present();
    const modalReturn = await modal.onDidDismiss();
    if (modalReturn?.data === SUBMIT_CONFIRMATION_TOKEN) {
      return this.onSubmit();
    }
  }

  private async presentTermsAlertModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: InformationAlertModal,
      cssClass: 'smaller-modal',
      componentProps: {
        title: 'Atenção',
        text:
          'Você precisa aceitar os termos de contração para continuar com o envio do cadastro para análise.',
        clearButton: 'Entendi',
        clearButtonRota: 'dismiss',
      },
    });
    return await modal.present();
  }

  public async presentModalContract(): Promise<void> {
    const modal = await this.modalController.create({
      component: ContractModal,
      componentProps: {
        cadastroId: this.id,
        projeto: this.projeto,
        tipo: this.comAverbacao ? TipoContract.MORADOR_USUCAMPEAO_REURB_AVERBACAO : TipoContract.MORADOR_USUCAMPEAO_2021,
        tabelaPreco: this.buildDadosPagamento(),
      },
    });
    return await modal.present();
  }

  validateForm(data: any): void {
    this.hasDueDate = data.paymentType === PaymentType.TICKET;
    this.hasCardDueDate = data.paymentType === PaymentType.CARD;
    if (data.installments.includes('VISTA')) {
      setTimeout(() => this.form.controls.dueDate.disable(), 1000);
    } else {
      setTimeout(() => this.form.controls.dueDate.enable(), 1000);
    }
  }


  /*  public navigateNextSalveToContact(): void {
     this.router.navigate([
       `../../home`, { relativeTo: this.route }
     ]);
   } */
  public goBack(): void {
    this.navigationService.goBack();
  }


}
