import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../../../../components/footer/footer.module';
import { HeaderModule } from '../../../../components/header/header.module';
import { MapModule } from '../../../../components/map/map.module';
import { LotSelectionInstructionModule } from '../../../modal/lot-selection/lot-selection.module';
import { EditMapRoutingModule } from './edit-map-routing.module';
import { EditMapPage } from './edit-map.page';

@NgModule({
  declarations: [
    EditMapPage
  ],
  imports: [
    CommonModule,
    EditMapRoutingModule,
    HeaderModule,
    FooterModule,
    IonicModule,
    MapModule,
    LotSelectionInstructionModule,
  ]
})
export class EditMapModule { }
