
import { AgmCoreModule } from '@agm/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePT from '@angular/common/locales/pt';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { UiMobileModule } from '@usucampeao/ui-mobile';
import 'firebase/analytics';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { pageTransition } from './app.transition';
import { MenuModule } from './components/menu/menu.module';
import { SkeletonItemModule } from './components/skeleton-item/skeleton-item.module';
import { AuthModule } from './pages/auth/auth.module';
import { AuthInterceptor } from './pages/auth/interceptors/auth.interceptor';
import { PrivacyPolicyModule } from './pages/auth/login/create-account/privacy-policy/privacy-policy.module';

registerLocaleData(localePT, 'pt-br')

firebase.initializeApp(environment.firebase);
!environment.production ? firebase.auth().useEmulator('http://localhost:9099') : null;
const analytics = firebase.analytics();
firebase.auth().onIdTokenChanged(user => {
  if (user) {
    analytics.setUserId(user.uid);
  }
});

// configure Bugsnag asap
Bugsnag.start({
  apiKey: environment.bugsnag.apiKey,
  enabledReleaseStages: ['production', 'homolog'],
  releaseStage: environment.bugsnag.releaseStage,
});

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot({ navAnimation: pageTransition }),
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    NgxMaskModule.forRoot(),
    UiMobileModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AuthModule,
    AppRoutingModule,
    MenuModule,
    PrivacyPolicyModule,
    SkeletonItemModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCtjnRqLkf07gUN6dHnGvMUhqD_DAQ0tNk',
      libraries: ['places'],
    }),
    ServiceWorkerModule.register(environment.combined, {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:1000',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID, useValue: 'pt-br'
    },
    {
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
    CookieService,
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
