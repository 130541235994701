import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'usucampeao-encerra-cadastro',
  templateUrl: 'encerra-cadastro.modal.html',
  styleUrls: ['encerra-cadastro.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EncerraCadastroModal {
  constructor(private modalController: ModalController, private router: Router) {}

  dismiss() {
    this.modalController.dismiss();
  }

  navigateTo(rota: string): void {
    this.dismiss();
    if (rota !== 'dismiss') {
      this.router.navigate([rota]);
    }
  }
}
