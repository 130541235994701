<ion-content class="ion-no-padding">
    <div class="content-vertical-space-between">
        <usucampeao-header mode="md" backRoute=".." title="Ajuda"></usucampeao-header>
        <div>
            <ion-grid class="ion-no-margin ion-no-padding ion-padding-start ion-padding-end">
                <ion-row class="ion-justify-content-center">
                    <ion-col sizeXs="12" sizeSm="6">
                        <ion-text class="title title--large">Precisa de ajuda?</ion-text>
                    </ion-col>
                </ion-row>
                <ion-row class="padding-bottom ion-justify-content-center">
                    <ion-col sizeXs="12" sizeSm="6">
                        <ion-text class="font-text">
                            Entre em contato gratuitamente através do telefone ou do email abaixo
                        </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-padding-bottom ion-justify-content-center">
                    <ion-col sizeXs="12" sizeSm="6">
                        <ion-icon class="icon-phone" color="primary" name="phone-portrait-outline"></ion-icon>
                        <a class="font-text font-text--extra-large color" href="tel:08005911328">
                            0800 591 1328
                        </a>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center ion-justify-content-center">
                    <ion-col sizeXs="12" sizeSm="6">
                        <ion-icon color="primary" class="icon-mail" name="mail-outline"></ion-icon>
                        <a class="font-text font-text--large color" href="mailto:ajuda@poderegularizar.com.br">
                            ajuda@poderegularizar.com.br
                        </a>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div class="ion-padding-start ion-padding-end">
            <ion-button mode="md" fill="outline" expand="block" (click)="navigateBack()">Voltar</ion-button>
            <usucampeao-footer> </usucampeao-footer>
        </div>
    </div>
</ion-content>