import { Expose } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, ValidateIf } from 'class-validator';
import { MeioPagamento } from '../pagamento';
import { DiaPagamento } from '../pagamento/dia-pagamento.enum';

export class CadastraDadosPagamentoDto {
  @IsNumber()
  @IsNotEmpty()
  valorContrato: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  valorEntrada: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  valorAverbacao?: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  valorParcela: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  totalParcelas: number;

  @Expose()
  @IsEnum(MeioPagamento)
  @IsNotEmpty()
  meioPagamento: MeioPagamento;

  @Expose()
  @IsEnum(DiaPagamento)
  @IsNotEmpty()
  @ValidateIf(data => data.totalParcelas)
  diaVencimento: DiaPagamento;
}
