import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { ProjetoSimplificadoDto, PropertySimplifiedDto, RegistrationStatus, StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';
import { Observable, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map, shareReplay, switchMap, takeUntil, tap
} from 'rxjs/operators';
import { LoadingService } from '../../../services/loading.service';
import { ToastService } from '../../../services/toast.service';
import { RegistrationQuery } from './../state/registration.query';
import { PropertyQuery } from './state/property.query';
import { PropertyService } from './state/property.service';

@Component({
  selector: 'usucampeao-property',
  templateUrl: 'property.page.html',
  styleUrls: ['property.page.scss'],
})
export class PropertyPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  public defaultImage = '../../../../assets/imgs/defaultImage.svg';
  public placeholder = '../../../../assets/imgs/placeholder.png';
  public property: PropertySimplifiedDto;
  public id: string;
  public blockAccess = false;
  public projeto: ProjetoSimplificadoDto;

  private ngUnsubscribe = new Subject();
  private registrationId: string;


  constructor(
    private loadingService: LoadingService,
    private propertyQuery: PropertyQuery,
    private propertyService: PropertyService,
    private registrationQuery: RegistrationQuery,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        first(),
        tap((params) => this.id = params.propertyId),
        tap((params) => {
          const registration = this.registrationQuery.getValue();
          const status = registration.entities[params.id];
          this.validateStatus(status?.status);
        }),
        tap((params) => this.registrationId = params.id),
        tap(() => this.getSimplifiedData()),
        switchMap(() => this.propertyQuery.selectEntity(this.id)),
        tap(property => this.property = property),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    this.propertyQuery.selectLoading()
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async isLoading => {
        if (isLoading) {
          await this.loadingService.createLoader();
        } else {
          await this.loadingService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public get statusPreenchimento(): typeof StatusPreenchimento {
    return StatusPreenchimento;
  }

  /**
   * Foto da fachada do imóvel
   */
  public get propertyFacadePhoto(): Observable<string> {
    return this.propertyQuery.getFiles(this.id)
      .pipe(
        map(files => files ? files[0] : null),
        filter(() => !!this.id),
        map(file => {
          if (file?.id) {
            return `/properties/${this.id}/photo/${file?.id}`
          }
          return this.defaultImage;
        },
          shareReplay({ bufferSize: 1, refCount: true }),
        )
      );
  }

  /*
  Valida o status e bloqueia edição caso necessário
  */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
    }
  }

  /**
   * Busca os dados simplificados do imóvel
   */
  private getSimplifiedData(): void {
    this.propertyService.getSimplifiedData(this.id)
      .pipe(
        catchError(async () => await this.toastService.erro('Erro ao buscar dados do imóvel. Tente novamente mais tarde.')),
        takeUntil(this.ngUnsubscribe),
      ).subscribe();
  }

  /**
   * Seta a foto no component
   * @param photo foto selecionada
   */
  public setImage(photo: any): void {
    this.propertyService.updateFacadePhoto(this.registrationId, this.id, photo)
      .pipe(
        catchError(async () => await this.toastService.erro('Erro ao atualizar foto. Tente novamente mais tarde.')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public goTo(route: string): void {
    this.router.navigate([route], { relativeTo: this.route });
  }

  public navigateToPhotoRegistration(): void {
    this.router.navigate([`foto-fachada`], { relativeTo: this.route });
  }
}
