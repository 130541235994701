<ion-content class="ion-padding-start ion-padding-end" scrollY="false">
    <ion-text>
        <p class="title">Dúvidas?</p>
    </ion-text>
    <ion-text class="font-text">
        <p>Caso tenha dúvidas referente a forma de pagamento,
            ligue gratuitamente para nossa central de atendimento no número</p>
        <p><a href="tel:08005911328">0800 591 1328</a></p>
        <p>Ou envie suas dúvidas no e-mail</p>
        <p><a href="mailto:ajuda@poderegularizar.com.br">ajuda@poderegularizar.com.br</a></p>
    </ion-text>
    <ion-grid>
        <ion-row class="ion-justify-content-end">
            <ion-col size="auto">
                <a (click)="dismiss()"><b>Entendi</b></a>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
