<ion-content>
  <usucampeao-header mode="md" backRoute="../.." title="Proprietário">
    <ion-text>
      <p
        class="ion-no-padding ion-padding-start description-text description-text--small"
      >
        Proprietário
      </p>
    </ion-text>
  </usucampeao-header>
  <div class="ion-no-padding ion-padding-start ion-padding-end">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-justify-content-center">
        <ion-col sizeXs="12" sizeSm="6">
          <ion-item class="ion-no-padding" lines="none">
            <ion-thumbnail slot="start">
              <ion-avatar>
                <img
                  class="image"
                  [defaultImage]="defaultImage"
                  [lazyLoad]="ownerPhoto | async"
                />
              </ion-avatar>
            </ion-thumbnail>
            <ion-label>
              <h1>{{ owner?.name || 'Não informado' }}</h1>
            </ion-label>
          </ion-item>
          <ion-list>
            <usucampeao-process-progress
              icon="person-outline"
              title="Dados Básicos"
              titleClass="small"
              subtitle="Informações pessoais, estado civil e profissão"
              [status]="owner?.fillInStatus?.personalData"
              [statusText]="owner?.fillInStatus?.personalData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="owner?.fillInStatus?.personalData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
              (click)="goTo(owner.id, 'dados-basicos', false)"
            >
            </usucampeao-process-progress>

            <usucampeao-process-progress
              icon="id-card-outline"
              title="Documentos"
              titleClass="small"
              subtitle="Informação dos seus documentos"
              [status]="owner?.fillInStatus?.documentsData"
              [statusText]="owner?.fillInStatus?.documentsData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="owner?.fillInStatus?.documentsData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
              (click)="goTo(owner.id, 'documentos', false)"
            >
            </usucampeao-process-progress>

            <usucampeao-process-progress
              icon="call-outline"
              title="Contatos"
              titleClass="small"
              subtitle="Informações de contato"
              [status]="owner?.fillInStatus?.contactData"
              [statusText]="owner?.fillInStatus?.contactData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="owner?.fillInStatus?.contactData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
              (click)="goTo(owner.id, 'contato', false)"
            >
            </usucampeao-process-progress>

            <usucampeao-process-progress
              icon="document-attach-outline"
              title="Documentos Enviados"
              titleClass="small"
              subtitle="Foto dos seus documentos"
              [status]="owner?.fillInStatus?.documents"
              [statusText]="owner?.fillInStatus?.documents === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="owner?.fillInStatus?.documents === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
              (click)="goTo(owner.id, 'envio-documentos', false)"
            >
            </usucampeao-process-progress>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid
      class="ion-no-padding ion-margin-top ion-padding-top"
      *ngIf="spouse"
    >
      <ion-header>
        <ion-text>
          <p class="ion-no-padding description-text description-text--medium">
            Cônjuge/companheiro(a)
          </p>
        </ion-text>
      </ion-header>
      <ion-row>
        <ion-col>
          <ion-item class="ion-no-padding" lines="none">
            <ion-thumbnail slot="start">
              <ion-avatar>
                <img
                  class="image"
                  [defaultImage]="defaultImage"
                  [lazyLoad]="''"
                />
              </ion-avatar>
            </ion-thumbnail>
            <ion-label>
              <h1>{{ spouse?.name || 'Não informado' }}</h1>
            </ion-label>
          </ion-item>
          <ion-list>
            <usucampeao-process-progress
              icon="person-outline"
              title="Dados Básicos"
              titleClass="small"
              subtitle="Informações pessoais, estado civil e profissão"
              [status]="spouse?.fillInStatus?.personalData"
              [statusText]="spouse?.fillInStatus?.personalData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="spouse?.fillInStatus?.personalData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
              (click)="goTo(spouse.id, 'dados-basicos', false)"
            >
            </usucampeao-process-progress>

            <usucampeao-process-progress
              icon="id-card-outline"
              title="Documentos"
              titleClass="small"
              subtitle="Informação dos seus documentos"
              [status]="spouse?.fillInStatus?.documentsData"
              [statusText]="spouse?.fillInStatus?.documentsData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="spouse?.fillInStatus?.documentsData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
              (click)="goTo(spouse.id, 'documentos', false)"
            >
            </usucampeao-process-progress>

            <usucampeao-process-progress
              icon="call-outline"
              title="Contatos"
              titleClass="small"
              subtitle="Informações de contato"
              [status]="spouse?.fillInStatus?.contactData"
              [statusText]="spouse?.fillInStatus?.contactData === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="spouse?.fillInStatus?.contactData === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
              (click)="goTo(spouse.id, 'contato', false)"
            >
            </usucampeao-process-progress>

            <usucampeao-process-progress
              icon="document-attach-outline"
              title="Documentos Enviados"
              titleClass="small"
              subtitle="Foto dos seus documentos"
              [status]="spouse?.fillInStatus?.documents"
              [statusText]="spouse?.fillInStatus?.documents === statusPreenchimento.PENDENTE ? 'Preencher' : 'Alterar'"
              [statusTextClass]="spouse?.fillInStatus?.documents === statusPreenchimento.PENDENTE ? 'secondary-red-text' : 'secondary-text'"
              (click)="goTo(spouse.id, 'envio-documentos', false)"
            >
            </usucampeao-process-progress>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
            class="ion-margin-bottom"
            mode="md"
            expand="block"
            (click)="continue()"
          >
            Continuar</ion-button
          >
        </ion-col>
      </ion-row>
      <!-- <ion-row>
                <ion-col>
                    <ion-text class="ion-text-center ion-no-padding font-text">
                        <p>Não possui os dados dele(a)?</p>
                        <p>
                            <a (click)="presentModalInviteFill()">
                                <b> Convide para preencher </b>
                            </a>
                        </p>
                    </ion-text>
                </ion-col>
            </ion-row> -->
    </ion-grid>
    <usucampeao-footer> </usucampeao-footer>
  </div>
</ion-content>
