<div id="print-section" class="c-data-contract__text-terms">
    <div class="title">
        <h1>CONTRATO DE ADESÃO PARA A PRESTAÇÃO DE SERVIÇOS DE AVERBAÇÃO DE DECLARAÇÃO DE EXISTÊNCIA DE EDIFICAÇÃO </h1>
    </div>
    <div class="personal-data">
        <table class="contract-table">
            <tr>
                <td>
                    <p>
                        Por este instrumento particular, de um lado,
                        <span style="font-weight: bold;">NOME:</span> {{ proprietario?.name }},
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>CPF:</strong> {{ proprietario?.cpf }},
                        <strong>RG:</strong> {{ proprietario?.rg?.number || '' }},
                        <strong>EMISSOR/UF:</strong> {{ proprietario?.rg?.issuer || '' }}/{{ proprietario?.rg?.state || ''}}
                    </p>
                </td>
            </tr>
            <tr *ngIf="(proprietario?.maritalStatus === maritalStatus.MARRIED) && conjuge ">
                <td>
                    <p>
                        <span style="font-weight: bold;">CÔNJUGE/COMPANHEIRO:</span> {{ conjuge?.name }},
                        <strong>CPF:</strong> {{ conjuge?.cpf }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Rua:</strong> {{ imovel?.address?.street }} Nº.{{ imovel?.address?.number }}
                        <ng-container *ngIf="imovel?.address?.complement">
                            <strong>Complemento: </strong> {{ imovel?.address?.complement }}
                        </ng-container>
                        <strong>Bairro: </strong> {{ imovel?.address?.neighborhood }} <strong>Cidade: </strong> {{ imovel?.address?.city }} <strong>UF: </strong> {{ imovel?.address?.state }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Qual o segmento de uso do imóvel:</strong> (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.RESIDENTIAL">X
                        </ng-container>) Residencial (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.COMMERCIAL">X
                        </ng-container>) Comercial (&nbsp;
                        <ng-container *ngIf="imovel?.usage === imovelTipo.MIXED">X
                        </ng-container>) Misto
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>Estado Civil:</strong> (&nbsp;
                        <ng-container *ngIf="proprietario?.maritalStatus === maritalStatus.MARRIED">X</ng-container>&nbsp;) Casado(a) (&nbsp;
                        <ng-container *ngIf="proprietario?.maritalStatus === maritalStatus.SINGLE">X</ng-container>&nbsp;) Solteiro(a) (&nbsp;
                        <ng-container *ngIf="proprietario?.maritalStatus === maritalStatus.WIDOWED">X</ng-container>&nbsp;) Viúvo(a) (&nbsp;
                        <ng-container *ngIf="proprietario?.maritalStatus === maritalStatus.SEPARATED">X</ng-container>&nbsp;) Separado(a)
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>E-mail: </strong> {{ proprietario?.email }} <strong> Telefone: </strong> {{ proprietario?.phones?.cellphone || ''}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="height: 20mm;">
                        <strong>Observações: </strong>
                    </p>
                </td>
            </tr>
        </table>
    </div>
    <div class="content">
        <p>
            Doravante
            <ng-container *ngIf="!conjuge"> denominado <strong>"CONTRATANTE"</strong></ng-container>
            <ng-container *ngIf="conjuge"> denominados <strong>"CONTRATANTES"</strong></ng-container>; e, de outro lado, <strong>USUCAMPEÃO TECNOLOGIA EM
    REGULARIZAÇÃO IMOBILIÁRIA LTDA.</strong>, pessoa jurídica de direito privado, com SEDE na Rua Claudio Soares 72, cj 614/618 Pinheiros, São Paulo – SP, inscrita no CNPJ/MF sob o nº 36.174.049/0001-08, e FILIAL Rua José Rodrigues Pereira 514, Estoril,
            Belo Horizonte – MG inscrita no CNPJ/MF <strong>36.174.049/0002-99</strong>, neste ato representada de acordo com seus atos constitutivos, doravante denominado
            <strong><u>"USUCAMPEÃO"</u></strong>,
            <!--  <ng-container *ngIf="allotment?.split_pagamento"> e
            <strong>{{allotment?.split_parametros?.parceiro_nome | uppercase}}</strong>, pessoa jurídica de direito privado, com sede no endereço {{allotment?.split_parametros?.parceiro_endereco}}, inscrita no CNPJ/MF sob o {{allotment?.split_parametros?.parceiro_documento
            | mask: 'CPF_CNPJ'}}, neste ato representada de acordo com seus atos constitutivos, doravante denominado <strong>“PARCEIRA”</strong>,
        </ng-container> celebram o presente  --><strong>CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE REGULARIZAÇÃO
    FUNDIÁRIA</strong>, regido pelas seguintes cláusulas:
            <div class="clausula">
                <p>
                    <strong>Cláusula Primeira – OBJETO</strong><br>
                    <strong>1.1.</strong> Este Contrato estabelece as condições pelas quais a <strong>USUCAMPEÃO</strong> prestará
                    <ng-container *ngIf="!conjuge">
                        ao <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge">
                        aos <strong>CONTRATANTES</strong>
                    </ng-container> Serviços de averbação de Declaração de Existência de Edificação, nos termos da Lei Municipal nº 6.885, de 28 de novembro de 2019, e Lei Federal 13.464/2017, visando a emissão da certidão e regularização da situação
                    do imóvel perante os orgãos pertinentes com a devida alteração na matrícula do imóvel.
                    <br>
                    <strong>1.1.2.</strong> Para realização do serviços
                    <ng-container *ngIf="!conjuge">
                        o <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge">
                        os <strong>CONTRATANTES</strong>
                    </ng-container>deverão apresentar os seguintes documentos:
                    <br>
                    <strong>a.</strong> certidão de propriedade atualizada, emitida pelo competente Cartório de Registro de Imóvel;
                    <br>
                    <strong>b.</strong> comprovante de endereço;
                    <br>
                    <strong>c.</strong> cópia do lançamento de IPTU do último ano; e
                    <br>
                    <strong>d.</strong> Procuração assinada com poderes específicos junto à Prefeitura e Cartório para averbar a construção da matrícula.
                </p>
            </div>
            <div class="clausula">
                <p>
                    <strong>Cláusula Segunda – RESPONSABILIDADE DAS PARTES</strong><br>
                    <strong>2.1.</strong> São obrigações
                    <ng-container *ngIf="!conjuge">
                        do <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge">
                        dos <strong>CONTRATANTES</strong>
                    </ng-container> efetuar o pagamento na forma da Cláusula Terceira e fornecer todas as informações e proprietarios solicitados pela <strong>USUCAMPEÃO</strong> na execução dos Serviços.<br>
                    <strong>2.2.</strong> São obrigações da <strong>USUCAMPEÃO</strong> a execução dos Serviços constantes na Cláusula Primeira e no Anexo 1 (escopo do serviço).
                </p>
            </div>
            <div class="clausula">
                <p>
                    <strong>Cláusula Terceira – REMUNERAÇÃO E FORMA DE PAGAMENTO</strong><br>
                    <ng-container *ngIf="cadastro?.meioPagamento">
                        <strong>3.1.</strong> Pela prestação dos Serviços objeto deste Contrato,
                        <ng-container *ngIf="!conjuge">
                            o <strong>CONTRATANTE</strong> pagará
                        </ng-container>
                        <ng-container *ngIf="conjuge">
                            os <strong>CONTRATANTES</strong> pagarão
                        </ng-container>
                        a quantia <u>total</u> de {{ (cadastro?.valorContrato | currency: 'BRL') }} ({{(cadastro?.valorContrato)}}) a ser paga da seguinte forma:
                        <ng-container *ngIf="cadastro?.meioPagamento === meioPagamento.BOLETO">

                            <ng-container *ngIf="cadastro.valorEntrada && cadastro?.totalParcelas === 0">
                                No boleto à vista.
                            </ng-container>

                            <ng-container *ngIf="cadastro.valorEntrada && cadastro?.totalParcelas">
                                No boleto com entrada de {{ (cadastro.valorEntrada | currency: 'BRL') }} ({{ (cadastro.valorEntrada) }}) e em mais {{ cadastro?.totalParcelas }}x parcelas de {{ (cadastro.valorParcela | currency: 'BRL') }} ({{(cadastro.valorParcela) }}).
                            </ng-container>

                            <ng-container *ngIf="!cadastro.valorEntrada && cadastro?.totalParcelas">
                                No boleto em {{ cadastro?.totalParcelas }}x de {{ (cadastro.valorParcela | currency: 'BRL') }} ({{ (cadastro.valorParcela) }}).
                            </ng-container>

                        </ng-container>
                        <ng-container *ngIf="cadastro?.meioPagamento === meioPagamento.CARTAO_CREDITO">
                            No cartão de crédito em até {{ cadastro?.totalParcelas }}x.
                        </ng-container>
                        <!-- <ng-container *ngIf="allotment?.split_pagamento">
                        {{allotment?.split_parametros?.porcentagem}}% ({{convertNumber(allotment?.split_parametros?.porcentagem, false)}} por cento) deste valor será pago para a
                        <strong>PARCEIRA {{allotment?.split_parametros?.parceiro_nome}}</strong> e o restante para
                        <strong>USUCAMPEÃO.</strong>
                        <br>
                        <strong>3.1.1</strong> O valor pago para a <strong>USUCAMPEÃO</strong> deverá ser realizado da seguinte forma: mediante emissão de Nota Fiscal própria.
                        <ng-container *ngIf="allotment?.split_pagamento">
                            <br>
                            <strong>3.1.2</strong> O valor pago para a <strong>PARCEIRA</strong> deverá ser realizado da seguinte forma: mediante emissão de Nota Fiscal própria da Parceira.
                        </ng-container> -->
                    </ng-container>
                    <br>


                    <strong>3.2.</strong> Havendo impontualidade no cumprimento da obrigação
                    <ng-container *ngIf="!conjuge">
                        pelo <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge">
                        pelos <strong>CONTRATANTES</strong>
                    </ng-container>, este incorrerá em multa de 2% (dois por cento) sobre o valor em atraso corrigido e juros moratórios de 1% (um por cento) ao mês, sob o valor devido. <br>
                    <strong>3.3.</strong> Os direitos reais a serem atribuídos aos CONTRATANTES, estarão definidos na Declaração de Existência de Edificação, a ser expedida pelo orgão competente, nos termos da Lei Municipal nº 6.885, de 28 de novembro
                    de 2019.<br>
                    <strong>3.4</strong>Ficam os CONTRATANTES cientes de que todos os projetos técnicos que serão apresentados para a Prefeitura, são de uso exclusivo da USUCAMPEÃO, a qual detém os direitos autorais dos mesmos. <br>
            </div>
            <div class="clausula">
                <p>
                    <strong>Cláusula Quarta – RESCISÃO</strong><br>
                    <strong>4.1.</strong> Este Contrato poderá ser rescindido imotivadamente
                    <ng-container *ngIf="!conjuge"> pelo <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> pelos <strong>CONTRATANTES</strong> </ng-container>, desde que seja enviada notificação prévia, com antecedência mínima de 15 (quinze) dias, gerando as seguintes consequências:
                    <br>
                    <strong>4.1.1.</strong> Neste caso, não restarão obrigações de Parte para Parte, nem de entregar, nem de pagar.
                    <br>
                    <strong>4.2.</strong> No caso de rescisão por motivo de indeferimento da Averbação, <strong>por fato não atribuível à USUCAMPEÃO,</strong> como, por exemplo, erros na documentação entregue
                    <ng-container *ngIf="!conjuge"> pelo <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> pelos <strong>CONTRATANTES</strong> </ng-container>, não haverá a restituição de nenhum dos valores pagos até o momento do indeferimento. A mesma condição se aplica em caso da negativa de registro por parte do Cartório de Registro
                    de Imóveis, <strong>por fato também não atribuível à USUCAMPEÃO,</strong> como, por exemplo, erros na documentação entregue
                    <ng-container *ngIf="!conjuge"> pelo <strong>CONTRATANTE</strong>
                    </ng-container>
                    <ng-container *ngIf="conjuge"> pelos <strong>CONTRATANTES</strong> </ng-container>, não haverá a restituição de nenhum dos valores pagos até o momento do indeferimento. A mesma condição se aplica em caso da negativa de registro do projeto de regularização fundiária
                    por parte do Cartório de Registro de Imóveis, por fato também não atribuível à <strong> USUCAMPEÃO</strong>.
                    <br>
                </p>
            </div>
            <div class="clausula">
                <p>
                    <strong>Cláusula Quinta – DISPOSIÇÕES GERAIS</strong><br>
                    <strong>5.1.</strong> A <strong>USUCAMPEÃO</strong> poderá ceder o recebível para instituições financeiras, ficando expressamente autorizada para tanto
                    <ng-container *ngIf="!conjuge">pelo <strong>CONTRATANTE.</strong></ng-container>
                    <ng-container *ngIf="conjuge">pelos <strong>CONTRATANTES.</strong></ng-container>
                    <br>
                    <strong>5.2.</strong> Nenhuma das Partes poderá ser considerada como inadimplente de suas obrigações contratuais se tal fato for resultante de caso fortuito ou força maior.<br>
                    <strong>5.3.</strong> O Presente Contrato se refere exclusivamente ao serviço de regularização fundiária na esfera administrativa perante a Prefeitura.<br>
                    <strong>5.3.1.</strong> Não estão contempladas neste contrato, despesas com tributos inerentes à unidade imobiliária, taxas/preços públicos municipais e emolumentos de cartórios.
                    <br>
                    <strong>5.3.2.</strong> Não estão contemplados neste contrato, eventuais procedimentos judiciais que sejam necessários para a viabilização da averbação.
                    <br>
                </p>
            </div>
            <div class="clausulaSexta">
                <p>
                    <strong>Cláusula Sexta – FORO</strong><br>
                    <strong>6.1.</strong> Para dirimir quaisquer controvérsias oriundas deste Contrato, as Partes elegem o Foro da Comarca de São Paulo, Estado de São Paulo. E, por estarem assim, justas e contratadas, firmam o presente Contrato em 2 (duas)
                    vias de igual teor e forma, na presença das testemunhas abaixo, para que produza seus regulares efeitos de direito.
                </p>
                <p>&nbsp;</p>
                <p>{{ imovel?.address?.city }}, {{ cadastro?.contractDate | date: 'longDate'}}.</p>
            </div>

            <div class="subscription">
                <div class="table">
                    <div class="column">
                        <p>______________________________________________</p>
                        <p>CONTRATANTE/OUTORGANTE</p>
                        <div class="column__infos">
                            <p>Representante: {{ proprietario?.name }} </p>
                            <p>CPF: {{ proprietario?.cpf }}</p>
                        </div>
                    </div>

                    <div class="column column--no-border-top" *ngIf="conjuge">
                        <p>______________________________________________</p>
                        <p>CONTRATANTE/OUTORGANTE</p>
                        <div class="column__infos">
                            <p>Representante: {{ conjuge?.name }} </p>
                            <p>CPF: {{ conjuge?.cpf }}</p>
                        </div>
                    </div>

                    <!-- <div class="column column--no-border" *ngIf="!isMultipleResidents() && allotment?.split_pagamento"></div>


                  <div class="column column--no-border-top" [class.no-border-left]="isMultipleResidents()" *ngIf="allotment?.split_pagamento">
                      <p class="column__title">PARCEIRA</p>
                      <p>______________________________________________</p>
                      <p>{{ allotment?.split_parametros?.parceiro_nome }} </p>
                  </div> -->


                    <div class="column column--no-border-left">
                        <img class="assinaturaCeo" alt="Assinatura CEO" src="assets/img/assinatura.png">
                        <p>______________________________________________</p>
                        <p>EDUARDO GERMANO</p>
                        <div class="column__infos">
                            <p>USUCAMPEÃO - CEO</p>
                            <p>CNPJ: 36.174.049/0001-08</p>
                        </div>
                    </div>

                    <div class="column">
                        <p>______________________________________________</p>
                        <div class="column__infos">
                            <p>Testemunhas</p>
                            <p>CPF:</p>
                        </div>
                    </div>

                    <div class="column">
                        <p>______________________________________________</p>
                        <div class="column__infos">
                            <p>Testemunhas</p>
                            <p>CPF:</p>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>