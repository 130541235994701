import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { UF } from '@usucampeao/interfaces';
import {
  AlteracaoDto,
  GetUpdateOwnerDocumentsDto,
  RegistrationSimplifiedDto, RegistrationStatus, TipoAlteracao
} from '@usucampeao/lib-reurb-simplificado';
import {
  Datetime,
  Dropdown,
  ElementBase,
  InputMode,
  Textbox,
  toFormGroup,
  validateCPF
} from '@usucampeao/ui-mobile';
import { of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { LoadingService } from '../../../../../services/loading.service';
import {
  getSubDay,
  getSubYears
} from '../../../../../services/shared.function.service';
import { ToastService } from '../../../../../services/toast.service';
import { AuthQuery } from '../../../../auth/state/auth.query';
import { RegistrationService } from '../../../state/registration.service';
import { OwnersQuery } from '../../state/owners.query';
import { OwnersService } from '../../state/owners.service';
import { NavigationService } from './../../../../../services/navigation.service';
import { RegistrationQuery } from './../../../state/registration.query';

@Component({
  selector: 'usucampeao-documents',
  templateUrl: 'documents.page.html',
  styleUrls: ['documents.page.scss'],
})
export class DocumentsPage implements OnInit, ViewWillEnter, ViewWillLeave, OnDestroy {
  form$: ElementBase<string>[] | null = [];
  form!: FormGroup;
  payLoad = '';
  isMain = true;
  private ngUnsubscribe = new Subject();
  private ownerId: string;
  private registrationId: string;
  public formRg: FormGroup;
  public formRg$: ElementBase<string>[] = [];
  fluxo: boolean;
  public registration: RegistrationSimplifiedDto;
  public blockAccess = false;
  public alteracoes: AlteracaoDto[] = [];

  constructor(
    private loaderService: LoadingService,
    private navigationService: NavigationService,
    private ownersQuery: OwnersQuery,
    private ownerService: OwnersService,
    private route: ActivatedRoute,
    public router: Router,
    public registrationQuery: RegistrationQuery,
    private registrationService: RegistrationService,
    public authQuery: AuthQuery,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.form$ = this.getForm(false);
    this.form = toFormGroup(this.getForm(false) as ElementBase<string>[]);
    this.formRg$ = this.getRgForm(false);
    this.formRg = toFormGroup(this.getRgForm(false) as ElementBase<string>[]);
  }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        const registration = this.registrationQuery.getValue();
        const status = registration.entities[params.id];
        this.validateStatus(status.status);
        this.ownerId = params.ownerId;
        this.registrationId = params.id;
        this.getDocumentsData();
      });

    this.route.queryParams
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.fluxo = params['fluxo'];
      });

    this.ownersQuery.selectLoading()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        finalize(async () => await this.loaderService.dismiss()),
      )
      .subscribe(async loading => {
        if (loading) {
          await this.loaderService.createLoader();
        } else {
          await this.loaderService.dismiss();
        }
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  private getForm(readOnlyOption: boolean): ElementBase<string>[] {
    return [
      new Textbox({
        key: 'cpf',
        label: 'CPF',
        readonly: readOnlyOption,
        mask: 'cpf',
        inputMode: InputMode.NUMBER,
        validators: [Validators.required, validateCPF],
      }),
    ];
  }

  private getRgForm(readOnlyOption: boolean): ElementBase<string>[] {
    return [
      new Textbox({
        key: 'number',
        label: 'RG',
        readonly: readOnlyOption,
        validators: [Validators.required, Validators.maxLength(15)],
      }),
      new Textbox({
        key: 'issuer',
        label: 'Orgão emissor',
        readonly: readOnlyOption,
        validators: [Validators.required],
      }),
      new Dropdown({
        key: 'state',
        label: 'Estado',
        readonly: readOnlyOption,
        options: Object.values(UF).map((uf) => {
          return {
            key: uf,
            value: uf,
          };
        }),
        validators: [Validators.required],
      }),
      new Datetime({
        key: 'issueDate',
        label: 'Data de emissão',
        readonly: readOnlyOption,
        type: 'date',
        minDate: getSubYears(),
        maxDate: getSubDay(),
        validators: [Validators.required],
      }),
    ];
  }

  /**
   * Valida o form e atualiza os dados
   */
  public onSubmit(): void {
    this.form.markAllAsTouched();
    this.formRg.markAllAsTouched();
    if (this.form.invalid || this.formRg.invalid) {
      return;
    }

    this.updateDocumentsData();
  }

  /**
   * Busca os dados de documento do proprietário.
   */
  private getDocumentsData(): void {
    this.ownersQuery.selectEntity(this.ownerId)
      .pipe(
        take(1),
        switchMap(owner => {
          this.isMain = owner.main;
          if (owner.documentsDataLoaded) {
            return of(GetUpdateOwnerDocumentsDto.from(owner))
          }
          return this.ownerService.getDocumentsData(this.registrationId, this.ownerId)
        }),
        tap(documentsData => {
          /* if (documentsData?.rg?.issueDate) {
            documentsData.rg.issueDate = moment(documentsData.rg.issueDate).format('YYYY-MM-DD') as any;
          }*/

          this.form.get('cpf').setValue(documentsData?.cpf);
          this.formRg.get('number').setValue(documentsData?.rg?.number);
          this.formRg.get('issuer').setValue(documentsData?.rg?.issuer);
          this.formRg.get('state').setValue(documentsData?.rg?.state);
          this.formRg.get('issueDate').setValue(documentsData?.rg?.issueDate);
        }),
        switchMap(() => this.registrationService.buscaAlteracoesCadastro(this.registrationId, TipoAlteracao.PROPRIETARIO_DADOS_DOCUMENTOS)),
        tap(alteracoes => this.alteracoes = (alteracoes || []).filter(alteracao => alteracao.proprietarioId === this.ownerId)),
        catchError(async () => await this.toastService.erro('Erro ao buscar documentos. Tente novamente mais tarde.'))
      ).subscribe();

  }

  /**
   * Atualiza dados do documento
   */
  private updateDocumentsData(): void {
    const documentsData: GetUpdateOwnerDocumentsDto = {
      ...this.form.value,
      rg: this.formRg.value
    }
    this.ownerService.updateDocumentsData(this.registrationId, this.ownerId, documentsData)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        () => {
          if (this.fluxo) {
            this.router.navigate([`../contato`], { relativeTo: this.route, queryParams: { fluxo: true } })
          }
          else {
            this.navigationService.goBack();
          }
        },
        async () => await this.toastService.erro('Erro ao atualizar documentos. Tente novamente mais tarde.'))
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro, a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
      this.form$ = this.getForm(true);
      this.form = toFormGroup(this.getForm(true) as ElementBase<string>[]);
      this.formRg$ = this.getRgForm(true);
      this.formRg = toFormGroup(this.getRgForm(true) as ElementBase<string>[]);
    }
  }
}
