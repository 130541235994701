import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDateString,
  IsEmail,
  IsEnum,
  IsNumberString,
  IsOptional,
  IsString,
  Length,
  ValidateNested,
} from 'class-validator';
import { Ref } from '../ref/ref.model';
import { AttendanceOrder } from './attendance-order.model';
import { StatusPedidoAtivacaoUsuario } from './status-pedido-ativacao-usuario.enum';

export class PedidoAtivacaoUsuario {
  @IsString()
  id: string;

  @IsString()
  nome: string;

  @IsEmail()
  email: string;

  @IsNumberString()
  @Length(11, 11)
  cpf: string;

  @IsString()
  @Length(14, 14)
  telefone: string;

  @IsDateString()
  @IsOptional()
  dataCriacao?: Date;

  @IsDateString()
  @IsOptional()
  dataAtualizacao?: Date;

  @Type(() => Ref)
  @ValidateNested()
  @IsOptional()
  usuarioAtualizacao: Ref;

  @IsEnum(StatusPedidoAtivacaoUsuario)
  @IsOptional()
  status?: StatusPedidoAtivacaoUsuario;

  @IsBoolean()
  @IsOptional()
  documentoEnviado?: boolean;

  @IsOptional()
  atendimentos?: AttendanceOrder[];

  @IsString()
  @IsOptional()
  motivo_recusa_documento?: string;

  @IsString()
  @IsOptional()
  motivo_recusa_documento_rosto?: string;

  @IsBoolean()
  @IsOptional()
  documento_aprovado?: boolean;

  @IsBoolean()
  @IsOptional()
  documento_rosto_aprovado?: boolean;
}
