import { IsNotEmpty, IsString, IsEmail, IsOptional } from 'class-validator';

export class ChangePhase {
  /**
   * Nome do usuário.
   */
  @IsString()
  @IsNotEmpty()
  person: string;
  /**
   * Logradouro do atendimento.
   */
  @IsString()
  @IsNotEmpty()
  address: string;
  /**
   * Nova fase do atendimento.
   */
  @IsString()
  @IsNotEmpty()
  phase: string;

  /**
   * Email.
   */
  @IsEmail()
  @IsOptional()
  email: string;
}
