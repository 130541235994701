import { Injectable } from '@angular/core';
import { Actions, Effect } from '@datorama/akita-ng-effects';
import { AuthStore } from './auth.store';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  constructor(private actions$: Actions, private authStore: AuthStore) {}

  @Effect({ dispatch: false })
  allActionsAuth = this.actions$;
}
