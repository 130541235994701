  <div class="content-vertical-space-between">
    <ion-grid class="ion-no-padding ion-padding-start ion-padding-end">
        <ion-row>
          <ion-col>
            <ion-item class="ion-no-padding" lines="none">
              <usucampeao-notification-badge
                [tipo]="notification?.tipo"
              ></usucampeao-notification-badge>
              <ion-text class="ion-padding-start">
                <p class="title title--medium ion-no-margin">
                  {{ notification?.titulo }}
                </p>
                <p class="ion-no-margin font-text font-text--small">
                  {{ notification?.dataEnvio || '' | date }}
                </p>
              </ion-text>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="font-text">
              <p>
                Olá, {{ nomeUsuario$ | async }}
              </p>
              <p>
                Uma parcela do processo de regularização do imóvel localizado em {{ notification?.payload.endereco.rua }} {{ notification?.payload.endereco?.numero }}, {{ notification?.payload?.endereco?.bairro }}, {{ notification?.payload?.endereco?.cidade }}, está <strong>próxima do vencimento.</strong>
              </p>
              <p>
                O boleto da <strong>parcela {{ notification?.payload?.parcela ===0 ?'Entrada':  notification?.payload?.parcela }}</strong> no valor de <strong>{{ notification?.payload.valorDevido | currency:'BRL' }},</strong> já está disponível e possui vencimento em <strong> {{ notification?.payload?.dataVencimento | date: 'dd/MM/yyyy' }}.</strong>
              </p>
              <p>
                <a class="text-link" (click)="goRegister()">Clique aqui</a> para navegar diretamente para o cadastro e acessar o boleto.
              </p>
              <p>
                Você pode pagar este boleto através do aplicativo do seu banco, internet banking, em casas lotéricas, agências bancárias ou em caixas eletrônicos.
              </p>
            </div>
          </ion-col>
        </ion-row>
    </ion-grid>
  </div>
