import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Gender, UpdateUserDto } from '@usucampeao/lib-reurb-simplificado';
import { Datetime, Dropdown, ElementBase, InputMode, Textbox, toFormGroup, validateCPF } from '@usucampeao/ui-mobile';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NavigationService } from '../../../../services/navigation.service';
import { getSubYears } from '../../../../services/shared.function.service';
import { ToastService } from '../../../../services/toast.service';
import { AuthQuery } from '../../state/auth.query';
import { AuthService } from '../../state/auth.service';

@Component({
  selector: 'usucampeao-view-account',
  templateUrl: 'view-account.page.html',
  styleUrls: ['view-account.page.scss'],
})
export class ViewAccountPage implements OnInit {

  defaultImage = '../../../../assets/person-circle-outline.svg';

  private userData = {
    name: '',
    cpf: '',
    birthDate: null,
    gender: '',
    email: '',
  };

  edit = false;
  form$: ElementBase<string>[] | null = [];
  form!: FormGroup;
  payLoad = '';

  constructor(
    private authQuery: AuthQuery,
    private authService: AuthService,
    private navigationService: NavigationService,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.userData = this.authQuery.getValue() as any;
    this.form$ = this.getForm();
    this.form = toFormGroup(this.getForm() as ElementBase<string>[]);
  }

  viewAccount() {
    this.edit = false;
    this.form$ = this.getForm(true);
    this.form = toFormGroup(this.getForm(true) as ElementBase<string>[]);
  }

  editAccount() {
    this.edit = true;
    this.form$ = this.getForm(false);
    this.form = toFormGroup(this.getForm(false) as ElementBase<string>[]);
  }

  public async onSubmit(): Promise<void> {
    if (!this.edit) {
      return;
    }

    if (this.form.invalid) {
      await this.toastService.erro('Preencha todos os campos obrigatórios do formulário.');
    }

    try {
      this.userData = this.form.value;
      await this.authService.updateAccount(this.userData as UpdateUserDto)
      this.edit = false;
      this.form$ = this.getForm(true);
      this.form = toFormGroup(this.getForm(true) as ElementBase<string>[]);
    }
    catch (erro) {
      await this.toastService.erro('Erro ao atualizar dados da conta.');
    }
  }

  public getForm(readOption = true): ElementBase<string>[] {
    const questions: ElementBase<string>[] = [
      new Textbox({
        key: 'name',
        label: 'Nome completo',
        value: this.userData?.name || '',
        readonly: readOption,
        validators: [Validators.required, Validators.pattern('^[A-zÀ-ú ]*$')]
      }),
      new Textbox({
        key: 'cpf',
        label: 'CPF',
        value: this.userData?.cpf || '',
        readonly: readOption,
        mask: 'cpf',
        inputMode: InputMode.NUMBER,
        validators: [Validators.required, validateCPF]
      }),
      new Datetime({
        key: 'birthDate',
        label: 'Data de nascimento',
        value: new Date(this.userData?.birthDate) as any,
        readonly: readOption,
        type: 'date',
        minDate: getSubYears(),
        maxDate: getSubYears(18),
        validators: [Validators.required]
      }),
      new Dropdown({
        key: 'gender',
        label: 'Gênero',
        value: this.userData?.gender || '',
        disabled: readOption,
        options: [
          { key: Gender.MASCULINE, value: 'Masculino' },
          { key: Gender.FEMININE, value: 'Feminino' },
          { key: Gender.OTHERS, value: 'Outro' },
        ],
        validators: [Validators.required]
      }),
      new Textbox({
        key: 'email',
        label: 'E-mail',
        value: this.userData?.email || '',
        readonly: readOption,
        inputMode: InputMode.EMAIL,
        type: 'email',
        validators: [Validators.required, Validators.email]
      }),
    ];

    return questions;
  }

  goBack(): void {
    this.navigationService.goBack();
  }

  public navigateToEditPhoto(): void {
    this.router.navigate(['/trocar-foto']);
  }

  public get userPhoto$(): Observable<string> {
    return this.authQuery.photoId$
      .pipe(
        filter(id => !!id),
        map(id => `/users/my-photo/${id}`),
      );
  }

  public get userName$(): Observable<string> {
    return this.authQuery.meName$;
  }

  public get userDocument$(): Observable<string> {
    return this.authQuery.meCpf$;
  }
}
