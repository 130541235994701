/**
 * Erro genérico do firebase
 */
export class FirebaseError extends Error {
  public readonly code: number;

  /**
   *
   * @param message mensagem de erro
   * @param code codigo do erro
   */
  constructor(message: string, code = 10) {
    super(message)
    this.name = 'FIREBASE_ERROR';
    this.code = code;
  }
}
