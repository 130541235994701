import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { ClickingHereModal } from './clicking-here.modal';

@NgModule({
  imports: [CommonModule,IonicModule, UiMobileModule, CommonModule],

  declarations: [ClickingHereModal],

  exports:[ClickingHereModal],
})
export class ClickingHereModule {}
