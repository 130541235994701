<div id="print-section" class="c-data-contract__text-terms">
    <div class="title">
        <h1>QUESTIONÁRIO JURÍDICO</h1>
    </div>
    <div class="personal-data">
        <table class="contract-table">
            <tr>
                <td>
                    <p>
                        <span style="font-weight: bold;">NOME MORADOR (a):</span> {{ proprietario?.name }},
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <span style="font-weight: bold;">TEMPO DE RESIDÊNCIA NO IMÓVEL: </span> _____, Anos
                    </p>
                </td>
            </tr>
            <tr>

                <td>
                    <p>
                        <span style="font-weight: bold;">PROFISSÃO: </span>
                    </p>
                    <p>
                        <span>QUAL A PROFISSÃO DO MORADOR (a)? </span>{{ proprietario?.profession }}
                    </p>
                    <p *ngIf="(proprietario?.maritalStatus === maritalStatus.MARRIED) && conjuge">
                        <span>QUAL A PROFISSÃO DO CÔNJUGE SE CASADO OU EM UNIÃO ESTÁVEL?</span>{{ conjuge?.profession }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>É PROPRIETÁRIO (a) DE OUTRO IMÓVEL COM NOME NO CARTÓRIO? </strong>
                        <br> (&nbsp;
                        <ng-container *ngIf="imovel?.possuiOutroImovel">X
                        </ng-container>) Sim (&nbsp;
                        <ng-container *ngIf="!imovel?.possuiOutroImovel">X
                        </ng-container>) Não
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>JÁ FOI BENEFICIADO (a) POR LEGITIMAÇÃO DE POSSE </strong>
                        <br> (&nbsp;
                        <ng-container>
                        </ng-container>) SIM, LEGITIMAÇÃO DE POSSE (&nbsp;
                        <ng-container>
                        </ng-container>) SIM, LEGITIMAÇÃO FUNDIÁRIA (&nbsp;
                        <ng-container>
                        </ng-container>) NÃO, NEHUM DOS DOIS
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong>JÁ RECEBEU CONCESSÃO DE USO DE BEM PÚBLICO? </strong>
                        <br> (&nbsp;
                        <ng-container>
                        </ng-container>) Sim (&nbsp;
                        <ng-container>
                        </ng-container>) Não
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        <strong> É FOREIRO (a) DE BEM IMÓVEL?  </strong>
                        <br> (&nbsp;
                        <ng-container>
                        </ng-container>) Sim (&nbsp;
                        <ng-container>
                        </ng-container>) Não
                    </p>
                </td>
            </tr>
        </table>
    </div>

    <div class="subscription">
        <div class="table">
            <div class="column">
                <p>______________________________________________</p>
                <p>ASSINATURA DO MORADOR</p>
                <div class="column__infos">
                    <p>Representante: {{ proprietario?.name }} </p>
                    <p>CPF: {{ proprietario?.cpf }}</p>
                </div>
            </div>
            <div class="column__infos">
                <p><strong>
                USU CAMPEAO TECNOLOGIA EM REGULARIZAÇÃO IMOBILIÁRIA LTDA
              </strong> CNPJ: 36.174.049/0001-08 / Endereço: Rua Cláudio Soares, 72, 6˚ andar / Telefone: 2537.9430 / Site: www.usucampeao.com.br</p>
                <p></p>
            </div>

        </div>
    </div>
</div>