import { PropertyCardInstallments } from '@usucampeao/lib-reurb-simplificado';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PropertyCardInstallments',
})
export class PropertyCardInstallmentsPipe implements PipeTransform {
  transform(value: PropertyCardInstallments): string {
    switch (value) {
      case PropertyCardInstallments.IN_CASH:
        return 'À vista com desconto, por R$ 3.420,00';
      case PropertyCardInstallments.ENTRY_FOUR_INSTALLMENTS:
        return '4 parcelas de R$ 900,00';
      case PropertyCardInstallments.ENTRY_EIGHT_INSTALLMENTS:
        return '8 parcelas de R$ 450,00';
      case PropertyCardInstallments.ENTRY_TEN_INSTALLMENTS:
        return '10 parcelas de R$ 360,00';
      case PropertyCardInstallments.ENTRY_TWELVE_INSTALLMENTS:
        return '12 parcelas de R$ 300,00';

      default:
        return value;
    }
  }
}
