import { Expose } from 'class-transformer';
import { IsNotEmpty, IsNumberString, Length, ValidateIf } from 'class-validator';

export class PhoneDto {
  @Expose()
  @IsNumberString()
  @Length(11, 11)
  @IsNotEmpty()
  @ValidateIf((o: PhoneDto) => !o.home && !o.message)
  cellphone: string;

  @Expose()
  @IsNumberString()
  @Length(10, 11)
  @IsNotEmpty()
  @ValidateIf((o: PhoneDto) => !o.cellphone && !o.message)
  home?: string;

  @Expose()
  @IsNumberString()
  @Length(10, 11)
  @IsNotEmpty()
  @ValidateIf((o: PhoneDto) => !o.home && !o.cellphone)
  message?: string;
}
