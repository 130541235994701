import { SwiperModule } from 'swiper/angular';
import { FooterModule } from './../../../components/footer/footer.module';
import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiMobileModule } from '@usucampeao/ui-mobile';
import { TutorialModal } from './tutorial.modal';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    UiMobileModule,
    CommonModule,
    FooterModule,
    SwiperModule,
  ],

  declarations: [TutorialModal],

  exports: [TutorialModal],
})
export class TutorialModule {}
