import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { HeaderComponent } from '../../components/header/header.component';
import { LazyLoadImageHooks } from '../../hooks/lazy-load-image-hooks';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    LazyLoadImageModule,
  ],
  exports: [HeaderComponent],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks
    }
  ]
})
export class HeaderModule { }
