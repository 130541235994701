<div class="card-pagamento ion-text-center">
  <ion-grid class="ion-no-padding ion-justify-content-center">
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-badge [color]="getIcone(pagamento.status).color" mode="md" >
          <ion-icon class="centro" [name]="getIcone(pagamento.status).icon"></ion-icon>
        </ion-badge>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-text class="description-text description-text--small">{{pagamento.status | pagamentoStatus }}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-text class="title title--small">{{pagamento.valorDevido | currency:'BRL' || '' }}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-text class="description-text description-text--small">{{pagamento.parcela || '' }} parcela(s)</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>  
</div>
