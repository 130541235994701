import { Expose, plainToInstance } from 'class-transformer';
import { ReurbModalidade } from '../../enums';
import { MeioPagamento } from '../pagamento';
import { PropertyDto } from '../property';
import { ProprietariosCadastroDto } from '../proprietario-cadastro';
import { CadastroStatusVendaType } from './cadastro-status-venda.enum';
import { RegistrationStatus } from './registration-status.enum';
import { RegistrationDto } from './registration.dto';

export class CadastroContratoDto {
  @Expose()
  id: string;

  @Expose()
  status: RegistrationStatus;

  @Expose()
  statusVenda: CadastroStatusVendaType;

  @Expose()
  projetoFid: string;

  @Expose()
  reurbModalidade?: ReurbModalidade;

  @Expose()
  blockId: number;

  @Expose()
  lotId: number;

  @Expose()
  quadra: string;

  @Expose()
  lote: string;

  @Expose()
  valorContrato: number;

  @Expose()
  totalParcelas: number;

  @Expose()
  valorParcela: number;

  @Expose()
  meioPagamento: MeioPagamento;

  @Expose()
  valorEntrada: number;

  @Expose()
  proprietarios: ProprietariosCadastroDto[];

  @Expose()
  imovel: PropertyDto;

  @Expose()
  contractDate: Date;

  /**
   * Cria uma nova instância de CadastroContratoDto a partir de dtos
   * @param props DTO de Cadastro
   * @returns nova instância de CadastroContratoDto
   */
  static build(proprietarios: ProprietariosCadastroDto[], imovel: PropertyDto, props?: RegistrationDto | Partial<PropertyDto>): CadastroContratoDto {
    const cadastroContratoDto = plainToInstance(CadastroContratoDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
    cadastroContratoDto.proprietarios = proprietarios;
    cadastroContratoDto.imovel = imovel;

    return cadastroContratoDto;
  }
}
