import { Attendance } from './../interfaces/attendance.interface';
export enum StatusProgress {
  COMPLETE = 'COMPLETO',
  INCOMPLETE = 'INCOMPLETO',
  SCHEDULE = 'AGENDAMENTO_DISPONÍVEL',
  CHANGES = 'ALTERAÇÕES_NECESSÁRIAS',
  SCHEDULED = 'AGENDADO',
  MISSED = 'AGENDAMENTO_PERDIDO',
  VISITED = 'VISITADO',
  PENDING = 'PENDENTE',
  UNDEFINED = 'INDEFINIDO',
}

export function getStatusInformation(
  progessItem: string,
  atendimento: Attendance | null
) {
  switch (progessItem) {
    case 'property':
      return {
        icon: 'home-outline',
        itemTitle: 'Imóvel',
        itemDescription: 'Preencha os dados do imóvel',
        rota: '/inicio/nucleo/proprietario/',
      };
    case 'owners':
      return {
        icon: 'people-outline',
        itemTitle: 'Proprietários',
        itemDescription: 'Dados e documentos dos proprietários',
        rota: '/',
      };
    case 'occupation':
      return {
        icon: 'clipboard-outline',
        itemTitle: 'Ocupação do imóvel',
        itemDescription: 'Caracterização da ocupação do imóvel',
        rota: '/',
      };
    case 'documents':
      return {
        icon: 'document-attach-outline',
        itemTitle: 'Documentos do imóvel',
        itemDescription: 'Fotos dos documentos do imóvel',
        rota: '/',
      };
    case 'validation':
      return {
        icon: 'calendar-outline',
        itemTitle: 'Visita para validação',
        itemDescription: 'Visita para validação das informações',
        rota: '/',
      };
    case 'identification':
      return {
        icon: 'map-outline',
        itemTitle:
          'Quadra ' +
          (atendimento?.allotment?.block || '') +
          ' Lote ' +
          (atendimento?.allotment?.lot || ''),
        itemDescription: 'Identificação do lote do imóvel ',
        rota: '/lote/mapa',
      };
    case 'address':
      return {
        icon: 'location-outline',
        itemTitle:
          (atendimento?.allotment?.street || '') +
          ', ' +
          (atendimento?.allotment?.number || ''),
        itemDescription: 'Endereço do imóvel',
        rota: '/lote/endereco',
      };
    case 'otherInformation':
      return {
        icon: 'home-outline',
        itemTitle: 'Outras informações',
        itemDescription: 'Informações adicionais sobre o imóvel',
        rota: '/lote/informacoes-adicionais',
      };
    case 'dataBasic':
      return {
        icon: 'person-outline',
        itemTitle: 'Dados Básicos',
        itemDescription: 'Informações pessoais, estado civil e profissão',
        rota: '/editar-proprietario/',
      };
    case 'contact':
      return {
        icon: 'call-outline',
        itemTitle: 'Contatos',
        itemDescription: 'Informações de contato',
        rota: '/editar-proprietario/proprietario-editar-contatos',
      };
    case 'documentPerson':
      return {
        icon: 'id-card-outline',
        itemTitle: 'Documentos',
        itemDescription: 'Informação dos seus documentos',
        rota: '/editar-proprietario/proprietario-editar-documentos',
      };
    case 'documentRG':
      return {
        icon: 'document-attach-outline',
        itemTitle: 'RG',
        itemDescription: 'Preencha os dados do imóvel',
        rota: '/lote',
      };
    case 'documentCPF':
      return {
        icon: 'document-attach-outline',
        itemTitle: 'CPF',
        itemDescription: 'Preencha os dados do imóvel',
        rota: '/lote',
      };
    case 'documentCNH':
      return {
        icon: 'document-attach-outline',
        itemTitle: 'CNH',
        itemDescription: 'Preencha os dados do imóvel',
        rota: '/lote',
      };
    case 'weddingCertificate':
      return {
        icon: 'document-attach-outline',
        itemTitle: 'Certidão de casamento',
        itemDescription: 'Preencha os dados do imóvel',
        rota: '/lote',
      };
    case 'brithCertificate':
      return {
        icon: 'document-attach-outline',
        itemTitle: 'Certidão de nascimento',
        itemDescription: 'Preencha os dados do imóvel',
        rota: '/lote',
      };
    case 'documentSent':
      return {
        icon: 'document-attach-outline',
        itemTitle: 'Documentos enviados',
        itemDescription: 'Foto dos seus documentos',
        rota: '/',
      };
    default:
      return {
        icon: '',
        itemTitle: '',
        itemDescription: '',
        rota: '',
      };
  }
}

export function getStatusProgressInformation(
  status: StatusProgress,
  atendimento: Attendance | null
) {
  switch (status) {
    case StatusProgress.COMPLETE:
      return {
        name: 'Alterar',
        nameClass: 'secondary-text',
        icon: 'checkmark-circle-sharp',
        iconClass: 'icon-complete',
      };
    case StatusProgress.INCOMPLETE:
      return {
        name: 'Preencher',
        nameClass: 'secondary-red-text',
        icon: 'ellipse-outline',
        iconClass: 'icon-incomplete',
      };
    case StatusProgress.CHANGES:
      return {
        name: 'Alterações necessárias',
        nameClass: 'secondary-red-text',
        icon: 'close-circle-shar',
        iconClass: 'icon-changes',
      };
    case StatusProgress.SCHEDULE:
      return {
        name: 'Agende a visita para validação',
        nameClass: 'secondary-red-text',
        icon: 'ellipse-outline',
        iconClass: 'icon-incomplete',
      };
    case StatusProgress.SCHEDULED:
      return {
        name: 'Agendado para ' + (atendimento?.scheduledDate || ''),
        nameClass: 'secondary-red-text',
        icon: 'ellipse-outline',
        iconClass: 'icon-incomplete',
      };
    case StatusProgress.MISSED:
      return {
        name: 'Faça um novo agendamento',
        nameClass: 'secondary-red-text',
        icon: 'ellipse-outline',
        iconClass: 'icon-incomplete',
      };
    case StatusProgress.VISITED:
      return {
        name: 'Visita realizada',
        nameClass: 'secondary-text',
        icon: 'checkmark-circle-sharp',
        iconClass: 'icon-complete',
      };
    case StatusProgress.PENDING:
      return {
        name: 'Indisponível até finalização do preenchimento',
        nameClass: 'secondary-red-text',
        icon: 'ellipse-outline',
        iconClass: 'icon-incomplete',
      };
    default:
      return {
        name: null,
        nameClass: null,
        icon: 'ellipse-outline',
        iconClass: 'icon-incomplete',
      };
  }
}
