import { Injectable } from '@angular/core';
import { StoreConfig, Store, ID } from '@datorama/akita';

export interface DocumentsPropertyState {
  id?: ID;
  data:any
}

export function createInitialState(): DocumentsPropertyState {
  return {
    id: '',
    data: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'documentsProperty',
  resettable: true,
  cache: {
    ttl: 3600000
  }
})
export class DocumentsPropertyStore extends Store<DocumentsPropertyState> {
  constructor() {
    super(createInitialState());
  }
}
