import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LotAddressPage } from './lot-address.page';

const routes: Routes = [
  { path: '', component: LotAddressPage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LotAddressRoutingModule { }
