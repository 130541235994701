import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./lot-photo/lot-photo.module').then((m) => m.LotPhotoModule),
  },
  {
    path: 'endereco',
    loadChildren: () =>
      import('./lot-address/lot-address.module').then((m) => m.LotAddressModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LotDetailRoutingModule { }
