import { ChangePhase } from './../emails/templates/troca-fase';
import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { PhaseDto } from './phase-dto.model';

export class ListAttendance {
  @IsString()
  @IsNotEmpty()
  id: string;

  @IsArray()
  @IsNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => PhaseDto)
  phases: PhaseDto[];

  @ValidateNested({ each: true })
  @Type(() => ChangePhase)
  @IsNotEmpty()
  changePhase: ChangePhase;
}
