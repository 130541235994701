import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { StatusType } from '../enums';

export class ReurbStatusDto {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  @IsNotEmpty()
  label: string;

  @IsEnum(StatusType)
  @IsNotEmpty()
  type: StatusType;
}
