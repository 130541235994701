
import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { TipoContract } from '@usucampeao/lib-contratos';
import {
  CadastraDadosPagamentoDto,
  CadastroContratoDto, OwnerDto, ProjetoSimplificadoDto
} from '@usucampeao/lib-reurb-simplificado';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { RegistrationService } from '../../registration/state/registration.service';

@Component({
  selector: 'usucampeao-contract',
  templateUrl: 'contract.modal.html',
  styleUrls: ['contract.modal.scss'],
})
export class ContractModal implements ViewWillEnter, ViewWillLeave, OnDestroy {
  @Input() cadastroId: string;
  @Input() projeto: ProjetoSimplificadoDto;
  @Input() tipo: TipoContract;
  @Input() tabelaPreco: CadastraDadosPagamentoDto;

  private readonly ngUnsubscribe = new Subject<void>();
  public dadosContrato: CadastroContratoDto;
  public proprietario: OwnerDto;
  public conjuge: OwnerDto;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private registrationService: RegistrationService,
  ) { }

  ionViewWillEnter(): void {
    this.registrationService.getDadosContrato(this.cadastroId)
      .pipe(
        tap(dadosContrato => this.dadosContrato = dadosContrato),
        tap(() => this.preencherValoresCadastro()),
        tap(() => this.proprietario = this.dadosContrato.proprietarios.find(proprietario => proprietario.main)),
        tap(() => this.conjuge = this.dadosContrato.proprietarios.find(proprietario => !proprietario.main)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public get contratoTipo(): typeof TipoContract {
    return TipoContract;
  }

  private preencherValoresCadastro(): void {
    if (!this.dadosContrato) {
      return;
    }

    this.dadosContrato.totalParcelas = this.tabelaPreco.totalParcelas;
    this.dadosContrato.meioPagamento = this.tabelaPreco.meioPagamento;
    this.dadosContrato.valorContrato = this.tabelaPreco.valorContrato;
    this.dadosContrato.valorEntrada = this.tabelaPreco.valorEntrada;
    this.dadosContrato.valorParcela = this.tabelaPreco.valorParcela;
    this.dadosContrato.contractDate = new Date();
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }

  public navigateTo(rota: string): void {
    if (rota === 'dismiss') {
      this.dismiss();
    } else {
      this.dismiss();
      this.router.navigate([rota]);
    }
  }
}
