import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalController, ViewWillEnter, ViewWillLeave } from "@ionic/angular";
import { PagamentoDetalhesDto } from '@usucampeao/lib-reurb-simplificado';
import { Subject } from "rxjs";
import { NavigationService } from "../../../../services/navigation.service";
import { ToastService } from '../../../../services/toast.service';
import { AuthQuery } from "../../../auth/state/auth.query";
import { RegistrationHelpModal } from "../../../modal/registration-help/registration-help.modal";
import { PagamentoService } from "../state/pagamentos.service";

@Component({
  selector: 'usucampeao-detalhes',
  templateUrl: 'detalhes.page.html',
  styleUrls: ['detalhes.page.scss'],
})
export class DetalhesPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  private ngUnsubscribe = new Subject();
  public pagamentoSelecionado: PagamentoDetalhesDto;
  private id: string;
  private cadastroId: string;

  isLoadingSelected = false;


  constructor(
    public authQuery: AuthQuery,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private pagamentoService: PagamentoService,
    private toastService: ToastService,
    private clipboard: Clipboard,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .subscribe((params) => {
        this.cadastroId = params.registration;
        this.id = params.id;
        this.selectedPayment();
      })
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public goBack(): void {
    this.navigationService.goBack();
  }

  private selectedPayment(): void {
    this.isLoadingSelected = true;
    this.pagamentoService.getPaymentSelected(this.cadastroId, this.id)
      .subscribe(pagamento => {
        this.pagamentoSelecionado = pagamento;
        this.isLoadingSelected = false;
      }, async () => {
        await this.toastService.erro('Erro ao buscar dados do pagamento. Tente novamente mais tarde.');
        this.isLoadingSelected = false;
      })
  }

  public abrirBoleto(): void {
    window.open(this.pagamentoSelecionado.downloadUrl)
  }

  public copiarCodigoBarras(): void {
    this.clipboard.copy(this.pagamentoSelecionado.linhaDigitavel);
  }

  public onSubmit(): void { }
  async presentModalContact() {
    const modal = await this.modalController.create({
      component: RegistrationHelpModal,
      cssClass: 'registration-help-modal',
    });
    return await modal.present();
  }
}
