import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'usucampeao-lot-selection',
  templateUrl: 'lot-selection.modal.html',
  styleUrls: ['lot-selection.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LotSelectionModal {

  constructor(
    public modalController: ModalController,
    public router: Router,
  ) {}

  dismiss() {
    this.modalController.dismiss();
  }

  navigateTo(rota: string): void {
    this.dismiss();
    if (rota !== 'dismiss') {
      this.router.navigate([rota]);
    }
  }
}
