<ion-menu side="start" menuId="menu" contentId="main-content" type="overlay">
  <ion-content scrollY="false">
    <div class="ion-padding-bottom">
      <usucampeao-toolbar></usucampeao-toolbar>
    </div>
    <ion-grid class="ion-padding">
      <ion-row>
        <ion-col>
          <usucampeao-menu-item
            icon="person-outline"
            title="Minha conta"
            subtitle="Visualize e atualize seus dados"
            (click)="closeAndNavigate('/minha-conta')"
          >
          </usucampeao-menu-item>
          <usucampeao-menu-item
            icon="file-tray-full-outline"
            title="Meus cadastros"
            subtitle="Cadastre e consulte seus processos"
            (click)="closeAndNavigate('/')"
          ></usucampeao-menu-item>
          <usucampeao-menu-item
            icon="notifications-outline"
            title="Notificações"
            [notificationNumber]="(totalNotificacoesNaoLidas$ | async)"
            subtitle="Verifique as notificações recebidas"
            (click)="closeAndNavigate('/notificacoes')"
          >
          </usucampeao-menu-item>
          <usucampeao-menu-item
            icon="help-circle-outline"
            title="Ajuda"
            subtitle="Alguma dúvida? Nós te ajudamos"
            (click)="closeAndNavigate('/ajuda')"
          >
          </usucampeao-menu-item>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-padding-top">
        <ion-col sizeMd="6" sizeLg="3" class="ion-text-center">
          <ion-button
            fill="outline"
            mode="md"
            expand="block"
            (click)="logout()"
            class="button-outline"
          >
            Sair
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <usucampeao-footer></usucampeao-footer>
  </ion-content>
</ion-menu>
