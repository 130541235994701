
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentTypePage } from './document-type.page';

const routes: Routes = [
  { path: '', component: DocumentTypePage },
  {
    path: 'foto/:tipo/:imgid',
    loadChildren: () =>
      import('./document-photo/document-photo.module').then((m) => m.DocumentPhotoModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentTypeRoutingModule { }
