export enum PayDay {
  /**
   * Entrada dia 5.
   */
  CINCO = 5,
  /**
   * Entrada dia 10.
   */
  DEZ = 10,
  /**
   * Entrada dia 15.
   */
  QUINZE = 15,
  /**
   * Entrada dia 20.
   */
  VINTE = 20,
  /**
   * Entrada dia 25.
   */
  VINTECINCO = 25
}
