import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PhotoComponent } from '../photo/photo.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
@NgModule({
  declarations: [PhotoComponent],
  imports: [CommonModule, IonicModule, LazyLoadImageModule],
  exports: [PhotoComponent],
})
export class PhotoModule { }
