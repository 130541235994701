import { Expose, plainToInstance, Type } from 'class-transformer';
import { IsDate, IsNotEmpty, IsNumber, IsString, ValidateNested } from 'class-validator';
import { Bs2Cliente, Bs2IntegracaoDto } from '..';

export class Bs2BoletoCriarDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  contratoId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  pagamentoId: string;

  @Expose()
  @Type(() => Bs2Cliente)
  @ValidateNested()
  @IsNotEmpty()
  cliente: Bs2Cliente;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  valor: number;

  @Expose()
  @Type(() => Date)
  @IsDate()
  @IsNotEmpty()
  dataVencimento: Date;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  parcela: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  totalParcelas: number;

  /**
   * Cria uma nova instância de Bs2BoletoCriarDto
   * @param props DTO de Bs2BoletoCriarDto
   * @returns nova instância de Bs2BoletoCriarDto
   */
  static from(props?: Bs2IntegracaoDto | Partial<Bs2IntegracaoDto>): Bs2BoletoCriarDto {
    return plainToInstance(Bs2BoletoCriarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
