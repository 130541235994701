import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './pages/auth/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'novo-cadastro/loteamento',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule)
  },
  {
    path: 'atendimento/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/registration/registration.module').then((m) => m.RegistrationModule)
  },
  {
    path: 'perfil',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'notificacoes',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/notifications/notification.module').then((m) => m.NotificationPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
