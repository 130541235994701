import {
  PersonalDocumentType,
  PropertyDocumentType,
  Ref,
  ReurbModality
} from '@usucampeao/interfaces';
import {
  IsNotEmpty,
  IsNumber,
  IsObject, IsString
} from 'class-validator';
import { BaseEntityDto } from '../base-entity/base-entity-dto.model';
/**
 * Model interface (DTO) for 'loteamento' entity.
 * 'Loteamento' represents an allotment with various realties/properties.
 */
export class AllotmentDto extends BaseEntityDto {
  /**
   * Dominio reference.
   */
  @IsNotEmpty()
  domain: Ref;
  /**
   * loteamento document ID.
   */
  @IsNotEmpty()
  @IsString()
  id: string;
  /**
   * Loteamento name.
   */
  @IsNotEmpty()
  @IsString()
  name: string;
  /**
   * Loteamento social characteristics.
   */
  @IsNotEmpty()
  @IsString()
  socialCharacteristics: string;
  /**
   * Map of regularization regimes acceptance.
   */
  @IsNotEmpty()
  @IsObject()
  modality: {
    [ReurbModality.REURB_E]: boolean;
    [ReurbModality.REURB_S]: boolean;
  };
  /**
   * Loteamento pricing rules for realty/property regularization.
   * The price for realty/property regularization may be a flat fee or be a factor of the realty/property size.
   * Note that each regularization regime has its own value.
   */
  @IsNotEmpty()
  @IsObject()
  values: {
    depende_tamanho: boolean;
    [ReurbModality.REURB_E]: number;
    [ReurbModality.REURB_S]: number;
  };
  /**
   * List of obligatory personal documents.
   */
  @IsNotEmpty()
  personalDocuments: PersonalDocumentType[];
  /**
   * List of obligatory realty/property documents.
   */
  @IsNotEmpty()
  propertyDocuments: PropertyDocumentType[];
  /**
   * List of partnerts.
   */
  @IsNotEmpty()
  attendants: Ref[];
  /**
   * IDs de produtos no sistema Maxnível para integração.
   */
  @IsNotEmpty()
  @IsObject()
  maxnivelProductId: {
    [ReurbModality.REURB_E]: number;
    [ReurbModality.REURB_S]: number;
  };

  /**
   * ID do projeto do projeto.
   */
  @IsNotEmpty()
  @IsNumber()
  projectId: string;
}
