
<ion-content class="ion-padding">
  <lib-print-contract [cadastro]="dadosContrato" [proprietario]="proprietario" [conjuge]="conjuge" [imovel]="dadosContrato?.imovel" [tipo]="tipo"></lib-print-contract>


    <ion-grid>
      <ion-row class="ion-justify-content-end">
        <ion-col size="auto">
          <a (click)="dismiss()"><b>Entendi</b></a>
        </ion-col>
      </ion-row>
    </ion-grid>
</ion-content>
