import { Component, Input } from '@angular/core';
import { PagamentoStatus } from '@usucampeao/lib-reurb-simplificado';

@Component({
  selector: 'usucampeao-resumo-financeiro',
  templateUrl: './resumo-financeiro.component.html',
  styleUrls: ['./resumo-financeiro.component.scss'],
})
export class ResumoFinanceiroComponent {
  @Input() pagamento: any;

  getIcone(tipo: string) {
    switch (tipo) {
      case PagamentoStatus.CRIADO:
        return { icon: "calendar-outline", color: "medium" };
      case PagamentoStatus.EM_ANALISE:
        return { icon: "hourglass-outline", color: "warning" };
      case PagamentoStatus.NAO_PAGO:
        return { icon: "alert-circle-outline", color: "danger" };
      case PagamentoStatus.PAGO:
        return { icon: "checkmark-circle-outline", color: "success" };
      default:
        break;
    }
  }

}
