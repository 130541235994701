import { format, subDays, subYears } from 'date-fns';

export function getSubYears(years = 100): Date {
  return subYears(new Date(), years);
}

export function getSubDay(day = 1): Date {
  return subDays(new Date(), day);
}

export function getStates() {
  return [
    { key: 'AC', value: 'Acre' },
    { key: 'AL', value: 'Alagoas' },
    { key: 'AP', value: 'Amapá' },
    { key: 'AM', value: 'Amazonas' },
    { key: 'BA', value: 'Bahia' },
    { key: 'CE', value: 'Ceará' },
    { key: 'DF', value: 'Distrito Federal' },
    { key: 'ES', value: 'Espírito Santo' },
    { key: 'GO', value: 'Goiás' },
    { key: 'MA', value: 'Maranhão' },
    { key: 'MT', value: 'Mato Grosso' },
    { key: 'MS', value: 'Mato Grosso do Sul' },
    { key: 'MG', value: 'Minas Gerais' },
    { key: 'PA', value: 'Pará' },
    { key: 'PB', value: 'Paraíba' },
    { key: 'PR', value: 'Paraná' },
    { key: 'PE', value: 'Pernambuco' },
    { key: 'PI', value: 'Piauí' },
    { key: 'RJ', value: 'Rio de Janeiro' },
    { key: 'RN', value: 'Rio Grande do Norte' },
    { key: 'RS', value: 'Rio Grande do Sul' },
    { key: 'RO', value: 'Rondônia' },
    { key: 'RR', value: 'Roraima' },
    { key: 'SC', value: 'Santa Catarina' },
    { key: 'SP', value: 'São Paulo' },
    { key: 'SE', value: 'Sergipe' },
    { key: 'TO', value: 'Tocantins' },
    { key: 'EX', value: 'Estrangeiro' },
  ];
}

export function getCountries() {
  return [
    { key: 'BRA', value: 'Brasil ' },
    { key: 'AFG', value: 'Afeganistão ' },
    { key: 'ZAF', value: 'África do Sul ' },
    { key: 'ALB', value: 'Albânia ' },
    { key: 'DEU', value: 'Alemanha ' },
    { key: 'AND', value: 'Andorra ' },
    { key: 'AGO', value: 'Angola ' },
    { key: 'AIA', value: 'Anguilla ' },
    { key: 'ATA', value: 'Antártida ' },
    { key: 'ATG', value: 'Antígua e Barbuda ' },
    { key: 'ANT', value: 'Antilhas Holandesas ' },
    { key: 'SAU', value: 'Arábia Saudita ' },
    { key: 'DZA', value: 'Argélia ' },
    { key: 'ARG', value: 'Argentina ' },
    { key: 'ARM', value: 'Armênia ' },
    { key: 'ABW', value: 'Aruba ' },
    { key: 'AUS', value: 'Austrália ' },
    { key: 'AUT', value: 'Áustria ' },
    { key: 'AZE', value: 'Azerbaijão ' },
    { key: 'BHS', value: 'Bahamas ' },
    { key: 'BHR', value: 'Bahrein ' },
    { key: 'BGD', value: 'Bangladesh ' },
    { key: 'BRB', value: 'Barbados ' },
    { key: 'BLR', value: 'Belarus ' },
    { key: 'BEL', value: 'Bélgica ' },
    { key: 'BLZ', value: 'Belize ' },
    { key: 'BEN', value: 'Benin ' },
    { key: 'BMU', value: 'Bermudas ' },
    { key: 'BOL', value: 'Bolívia ' },
    { key: 'BIH', value: 'Bósnia-Herzegóvina ' },
    { key: 'BWA', value: 'Botsuana ' },
    { key: 'BRN', value: 'Brunei ' },
    { key: 'BGR', value: 'Bulgária ' },
    { key: 'BFA', value: 'Burkina Fasso ' },
    { key: 'BDI', value: 'Burundi ' },
    { key: 'BTN', value: 'Butão ' },
    { key: 'CPV', value: 'Cabo Verde ' },
    { key: 'CMR', value: 'Camarões ' },
    { key: 'KHM', value: 'Camboja ' },
    { key: 'CAN', value: 'Canadá ' },
    { key: 'KAZ', value: 'Cazaquistão ' },
    { key: 'TCD', value: 'Chade ' },
    { key: 'CHL', value: 'Chile ' },
    { key: 'CHN', value: 'China ' },
    { key: 'CYP', value: 'Chipre ' },
    { key: 'SGP', value: 'Cingapura ' },
    { key: 'COL', value: 'Colômbia ' },
    { key: 'COG', value: 'Congo ' },
    { key: 'PRK', value: 'Coréia do Norte ' },
    { key: 'KOR', value: 'Coréia do Sul ' },
    { key: 'CIV', value: 'Costa do Marfim ' },
    { key: 'CRI', value: 'Costa Rica ' },
    { key: 'HRV', value: 'Croácia (Hrvatska) ' },
    { key: 'CUB', value: 'Cuba ' },
    { key: 'DNK', value: 'Dinamarca ' },
    { key: 'DJI', value: 'Djibuti ' },
    { key: 'DMA', value: 'Dominica ' },
    { key: 'EGY', value: 'Egito ' },
    { key: 'SLV', value: 'El Salvador ' },
    { key: 'ARE', value: 'Emirados Árabes Unidos ' },
    { key: 'ECU', value: 'Equador ' },
    { key: 'ERI', value: 'Eritréia ' },
    { key: 'SVK', value: 'Eslováquia ' },
    { key: 'SVN', value: 'Eslovênia ' },
    { key: 'ESP', value: 'Espanha ' },
    { key: 'USA', value: 'Estados Unidos ' },
    { key: 'EST', value: 'Estônia ' },
    { key: 'ETH', value: 'Etiópia ' },
    { key: 'FJI', value: 'Fiji ' },
    { key: 'PHL', value: 'Filipinas ' },
    { key: 'FIN', value: 'Finlândia ' },
    { key: 'FRA', value: 'França ' },
    { key: 'GAB', value: 'Gabão ' },
    { key: 'GMB', value: 'Gâmbia ' },
    { key: 'GHA', value: 'Gana ' },
    { key: 'GEO', value: 'Geórgia ' },
    { key: 'GIB', value: 'Gibraltar ' },
    { key: 'GBR', value: 'Grã-Bretanha (Reino Unido, UK) ' },
    { key: 'GRD', value: 'Granada ' },
    { key: 'GRC', value: 'Grécia ' },
    { key: 'GRL', value: 'Groelândia ' },
    { key: 'GLP', value: 'Guadalupe ' },
    { key: 'GUM', value: 'Guam (Território dos Estados Unidos) ' },
    { key: 'GTM', value: 'Guatemala ' },
    { key: 'GGY', value: 'Guernsey ' },
    { key: 'GUY', value: 'Guiana ' },
    { key: 'GUF', value: 'Guiana Francesa ' },
    { key: 'GIN', value: 'Guiné ' },
    { key: 'GNQ', value: 'Guiné Equatorial ' },
    { key: 'GNB', value: 'Guiné-Bissau ' },
    { key: 'HTI', value: 'Haiti ' },
    { key: 'NLD', value: 'Holanda ' },
    { key: 'HND', value: 'Honduras ' },
    { key: 'HKG', value: 'Hong Kong ' },
    { key: 'HUN', value: 'Hungria ' },
    { key: 'YEM', value: 'Iêmen ' },
    { key: 'BVT', value: 'Ilha Bouvet (Território da Noruega) ' },
    { key: 'IMN', value: 'Ilha do Homem ' },
    { key: 'CXR', value: 'Ilha Natal ' },
    { key: 'PCN', value: 'Ilha Pitcairn ' },
    { key: 'REU', value: 'Ilha Reunião ' },
    { key: 'ALA', value: 'Ilhas Aland ' },
    { key: 'CYM', value: 'Ilhas Cayman ' },
    { key: 'CCK', value: 'Ilhas Cocos ' },
    { key: 'COM', value: 'Ilhas Comores ' },
    { key: 'COK', value: 'Ilhas Cook ' },
    { key: 'FRO', value: 'Ilhas Faroes ' },
    { key: 'FLK', value: 'Ilhas Falkland (Malvinas) ' },
    { key: 'SGS', value: 'Ilhas Geórgia do Sul e Sandwich do Sul ' },
    { key: 'HMD', value: 'Ilhas Heard e McDonald (Território da Austrália) ' },
    { key: 'MNP', value: 'Ilhas Marianas do Norte ' },
    { key: 'MHL', value: 'Ilhas Marshall ' },
    { key: 'UMI', value: 'Ilhas Menores dos Estados Unidos ' },
    { key: 'NFK', value: 'Ilhas Norfolk ' },
    { key: 'SYC', value: 'Ilhas Seychelles ' },
    { key: 'SLB', value: 'Ilhas Solomão ' },
    { key: 'SJM', value: 'Ilhas Svalbard e Jan Mayen ' },
    { key: 'TKL', value: 'Ilhas Tokelau ' },
    { key: 'TCA', value: 'Ilhas Turks e Caicos ' },
    { key: 'VIR', value: 'Ilhas Virgens (Estados Unidos) ' },
    { key: 'VGB', value: 'Ilhas Virgens (Inglaterra) ' },
    { key: 'WLF', value: 'Ilhas Wallis e Futuna ' },
    { key: 'IND', value: 'índia ' },
    { key: 'IDN', value: 'Indonésia ' },
    { key: 'IRN', value: 'Irã ' },
    { key: 'RQ', value: 'Iraque ' },
    { key: 'RL', value: 'Irlanda ' },
    { key: 'SL', value: 'Islândia ' },
    { key: 'SR', value: 'Israel ' },
    { key: 'TA', value: 'Itália ' },
    { key: 'AM', value: 'Jamaica ' },
    { key: 'PN', value: 'Japão ' },
    { key: 'EY', value: 'Jersey ' },
    { key: 'OR', value: 'Jordânia ' },
    { key: 'EN', value: 'Kênia ' },
    { key: 'KIR', value: 'Kiribati ' },
    { key: 'KWT', value: 'Kuait ' },
    { key: 'LAO', value: 'Laos ' },
    { key: 'LVA', value: 'Látvia ' },
    { key: 'LSO', value: 'Lesoto ' },
    { key: 'LBN', value: 'Líbano ' },
    { key: 'LBR', value: 'Libéria ' },
    { key: 'LBY', value: 'Líbia ' },
    { key: 'LIE', value: 'Liechtenstein ' },
    { key: 'LTU', value: 'Lituânia ' },
    { key: 'LUX', value: 'Luxemburgo ' },
    { key: 'MAC', value: 'Macau ' },
    { key: 'MKD', value: 'Macedônia (República Yugoslava) ' },
    { key: 'MDG', value: 'Madagascar ' },
    { key: 'MYS', value: 'Malásia ' },
    { key: 'MWI', value: 'Malaui ' },
    { key: 'MDV', value: 'Maldivas ' },
    { key: 'MLI', value: 'Mali ' },
    { key: 'MLT', value: 'Malta ' },
    { key: 'MAR', value: 'Marrocos ' },
    { key: 'MTQ', value: 'Martinica ' },
    { key: 'MUS', value: 'Maurício ' },
    { key: 'MRT', value: 'Mauritânia ' },
    { key: 'MYT', value: 'Mayotte ' },
    { key: 'MEX', value: 'México ' },
    { key: 'FSM', value: 'Micronésia ' },
    { key: 'MOZ', value: 'Moçambique ' },
    { key: 'MDA', value: 'Moldova ' },
    { key: 'MCO', value: 'Mônaco ' },
    { key: 'MNG', value: 'Mongólia ' },
    { key: 'MNE', value: 'Montenegro ' },
    { key: 'MSR', value: 'Montserrat ' },
    { key: 'MMR', value: 'Myanma ' },
    { key: 'NAM', value: 'Namíbia ' },
    { key: 'NRU', value: 'Nauru ' },
    { key: 'NPL', value: 'Nepal ' },
    { key: 'NIC', value: 'Nicarágua ' },
    { key: 'NER', value: 'Níger ' },
    { key: 'NGA', value: 'Nigéria ' },
    { key: 'NIU', value: 'Niue ' },
    { key: 'NOR', value: 'Noruega ' },
    { key: 'NCL', value: 'Nova Caledônia ' },
    { key: 'NZL', value: 'Nova Zelândia ' },
    { key: 'OMN', value: 'Omã ' },
    { key: 'PLW', value: 'Palau ' },
    { key: 'PAN', value: 'Panamá ' },
    { key: 'PNG', value: 'Papua-Nova Guiné ' },
    { key: 'PAK', value: 'Paquistão ' },
    { key: 'PRY', value: 'Paraguai ' },
    { key: 'PER', value: 'Peru ' },
    { key: 'PYF', value: 'Polinésia Francesa ' },
    { key: 'POL', value: 'Polônia ' },
    { key: 'PRI', value: 'Porto Rico ' },
    { key: 'PRT', value: 'Portugal ' },
    { key: 'QAT', value: 'Qatar ' },
    { key: 'KGZ', value: 'Quirguistão ' },
    { key: 'CAF', value: 'República Centro-Africana ' },
    { key: 'COD', value: 'República Democrática do Congo ' },
    { key: 'DOM', value: 'República Dominicana ' },
    { key: 'CZE', value: 'República Tcheca ' },
    { key: 'ROM', value: 'Romênia ' },
    { key: 'RWA', value: 'Ruanda ' },
    { key: 'RUS', value: 'Rússia (antiga URSS) - Federação Russa ' },
    { key: 'ESH', value: 'Saara Ocidental ' },
    { key: 'VCT', value: 'Saint Vincente e Granadinas ' },
    { key: 'ASM', value: 'Samoa Americana ' },
    { key: 'WSM', value: 'Samoa Ocidental ' },
    { key: 'SMR', value: 'San Marino ' },
    { key: 'SHN', value: 'Santa Helena ' },
    { key: 'LCA', value: 'Santa Lúcia ' },
    { key: 'BLM', value: 'São Bartolomeu ' },
    { key: 'KNA', value: 'São Cristóvão e Névis ' },
    { key: 'MAF', value: 'São Martim ' },
    { key: 'STP', value: 'São Tomé e Príncipe ' },
    { key: 'SEN', value: 'Senegal ' },
    { key: 'SLE', value: 'Serra Leoa ' },
    { key: 'SRB', value: 'Sérvia ' },
    { key: 'SYR', value: 'Síria ' },
    { key: 'SOM', value: 'Somália ' },
    { key: 'LKA', value: 'Sri Lanka ' },
    { key: 'SPM', value: 'St. Pierre and Miquelon ' },
    { key: 'SWZ', value: 'Suazilândia ' },
    { key: 'SDN', value: 'Sudão ' },
    { key: 'SWE', value: 'Suécia ' },
    { key: 'CHE', value: 'Suíça ' },
    { key: 'SUR', value: 'Suriname ' },
    { key: 'TJK', value: 'Tadjiquistão ' },
    { key: 'THA', value: 'Tailândia ' },
    { key: 'TWN', value: 'Taiwan ' },
    { key: 'TZA', value: 'Tanzânia ' },
    { key: 'IOT', value: 'Território Britânico do Oceano índico ' },
    { key: 'ATF', value: 'Territórios do Sul da França ' },
    { key: 'PSE', value: 'Territórios Palestinos Ocupados ' },
    { key: 'TMP', value: 'Timor Leste ' },
    { key: 'TGO', value: 'Togo ' },
    { key: 'TON', value: 'Tonga ' },
    { key: 'TTO', value: 'Trinidad and Tobago ' },
    { key: 'TUN', value: 'Tunísia ' },
    { key: 'TKM', value: 'Turcomenistão ' },
    { key: 'TUR', value: 'Turquia ' },
    { key: 'TUV', value: 'Tuvalu ' },
    { key: 'UKR', value: 'Ucrânia ' },
    { key: 'UGA', value: 'Uganda ' },
    { key: 'URY', value: 'Uruguai ' },
    { key: 'UZB', value: 'Uzbequistão ' },
    { key: 'VUT', value: 'Vanuatu ' },
    { key: 'VAT', value: 'Vaticano ' },
    { key: 'VEN', value: 'Venezuela ' },
    { key: 'VNM', value: 'Vietnã ' },
    { key: 'ZMB', value: 'Zâmbia ' },
    { key: 'ZWE', value: 'Zimbábue ' },
  ];
}
