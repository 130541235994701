import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { ModalController, ToastController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { PropertyAcquisitionDocument, PropertyOwnershipDocument, RegistrationStatus } from '@usucampeao/lib-reurb-simplificado';
import { forkJoin, of, Subject } from 'rxjs';
import { first, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoadingService } from '../../../../../../services/loading.service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { RegistrationQuery } from '../../../../state/registration.query';
import { RegistrationService } from '../../../../state/registration.service';
import { JustificationPage } from '../justification/justification.page';

@Component({
  selector: 'usucampeao-photo-page',
  templateUrl: 'photo.page.html',
  styleUrls: ['photo.page.scss'],
})
export class PhotoPage implements ViewWillEnter, ViewWillLeave, OnDestroy {
  private ngUnsubscribe = new Subject();
  isEditing = false;
  private id: string;
  private documentType: PropertyAcquisitionDocument | PropertyOwnershipDocument;
  public isAcquisition = false;
  public isOwnership = false;
  public documentid: string;
  private propertyId: string;
  public base64Image = [];
  public documentName: any;
  public selectedIndex = 0;
  public allPhotosData = [];
  public novasfotos = [];
  public allPhotosSelectedToRemove = [];

  document: {
    name: any;
    text: string;
  };
  public blockAccess = false;

  constructor(
    private loaderService: LoadingService,
    private modalCtrl: ModalController,
    private navigationService: NavigationService,
    private registrationQuery: RegistrationQuery,
    private registration: RegistrationService,
    private route: ActivatedRoute,
    private toastController: ToastController,
  ) { }

  ionViewWillEnter(): void {
    this.route.params
      .pipe(
        first(),
        tap((params) => (this.id = params.id || 'novo')),
        tap((params) => (this.propertyId = params.propertyId)),
        tap((params) => {
          this.documentType = params.type;
          this.isAcquisition = Object.values(PropertyAcquisitionDocument).includes(params.type);
          this.isOwnership = Object.values(PropertyOwnershipDocument).includes(params.type);
        }),
        tap((params) => (this.documentid = params?.documentid)),
        tap((params) => (this.isEditing = !!params?.documentid)),
        // tap(() => this.getRegistration(this.registrationId)),
        switchMap(() => this.registrationQuery.selectEntity(this.id)),
        tap((listRegistration) => {
          const status = listRegistration.status;
          this.validateStatus(status);
        }),
        switchMap(() => this.registrationQuery.selectLoading()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async (isLoading) => {
        if (isLoading) {
          await this.loaderService.createLoader();
        } else {
          await this.loaderService.dismiss();
        }

        if (this.isEditing && this.documentid !== 'novo') {
          this.getPicture(this.documentid);
        } else {
          this.isEditing = false;
        }
      },
        async (error) => {
          Bugsnag.notify('Foto dos documentos do imovel - Erro ao editar foto ', error)
          const toast = await this.toastController.create({
            message: 'Erro ao buscar informações. Tente novamente mais tarde.',
            duration: 5000,
          });
          toast.present();
        }
      );
    this.route.queryParams
      .pipe(
        first(),
        tap((params) => { this.documentName = params.nome }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
  }

  public async setImage(img: any): Promise<void> {
    const convert = await this.blobToBase64(img);
    this.base64Image[this.selectedIndex] = { img: convert, file: img };
    this.novasfotos = [{ img: convert, file: img }, ...this.novasfotos]
  }

  public remove(): void {
    if (this.base64Image.length === 0) return;
    this.base64Image.splice(this.selectedIndex, 1);
    const selected = this.allPhotosData[this.selectedIndex];
    this.allPhotosSelectedToRemove.push(selected);
    this.allPhotosData = this.allPhotosData.filter((item) => item.id !== selected.id)
  }

  private blobToBase64(blob): Promise<string | ArrayBuffer> {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  public backImage(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex -= 1;
    }
  }

  public async navigateToJustification(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: JustificationPage,
      componentProps: {
        id: this.id,
        propertyId: this.propertyId,
        type: this.documentType,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.navegar) {
      this.navigateBack();
    }


  }

  public navigateBack(): void {
    this.navigationService.goBack();
  }

  async navigateForward(): Promise<void> {
    await this.loaderService.createLoader();
    const photoRequest = this.isEditing
      ? this.registration.updateFilePropety(
        this.id,
        this.propertyId,
        this.documentid,
        this.novasfotos.map((item) => item.file),
        this.allPhotosSelectedToRemove.map((item) => item.id)
      )
      : this.registration.sendFilePropety(
        this.id,
        this.propertyId,
        this.base64Image.map((item) => item.file),
        this.documentType,
        this.documentName,
      );

    photoRequest.then(
      async () => {
        await this.loaderService.dismiss();
        const toast = await this.toastController.create({
          message: 'Documento enviado com sucesso',
          duration: 4000,
        });
        toast.present();
        this.navigationService.goBack();
      },
      async () => {
        await this.loaderService.dismiss();
        const toast = await this.toastController.create({
          message: 'Erro ao enviar o documento',
          duration: 6000,
        });
        toast.present();
      }
    );
  }

  public addImage(): void {
    if (this.base64Image[this.selectedIndex]) {
      this.base64Image.push('');
      this.selectedIndex += 1;
    }
  }

  public nextImage(): void {
    if (this.base64Image[this.selectedIndex]) {
      this.selectedIndex += 1;
    }
  }

  async getPicture(id: string) {
    await this.loaderService.createLoader();
    this.registration
      .getFileOwer(id)
      .pipe(
        switchMap((data) => {
          this.allPhotosData = data;
          return of(
            data.map((photo) => this.registration.getFiles(id, photo.id))
          ).pipe(mergeMap((q) => forkJoin(q)));
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        async (res) => {

          await this.loaderService.dismiss();
          res.map(async (value, index) => {
            this.base64Image[index] = {
              img: await this.blobToBase64B(value),
              file: value,
            };
          });
        },
        async () => {
          await this.loaderService.dismiss();
        }
      );
  }

  blobToBase64B(blob): Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Valida o status e bloqueia edição caso necessário
   * @param {string} status status do cadastro a ser validado
   */
  private validateStatus(status: string): void {
    if (status === RegistrationStatus.IN_ANALYSIS) {
      this.blockAccess = true;
    }
  }
}
