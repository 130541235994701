import { SentDocumentsPage } from './sent-documents.page';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: SentDocumentsPage,
  },
  {
    path: ':type',
    loadChildren: () =>
      import('./document-type/document-type.module').then((m) => m.DocumentTypeModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SentDocumentsRoutingModule { }
