import { UF } from '@usucampeao/interfaces';
import { Expose, plainToInstance } from 'class-transformer';
import { ProjetoModalidadeType } from './projeto-modalidade.enum';
import { ProjetoStatus } from './projeto-status.enum';
import { ProjetoTipo } from './projeto-tipo.enum';
import { ProjetoVendaStatus } from './projeto-venda-status.enum';
import { ProjetoDto } from './projeto.dto';

export class ProjetoListarDto {
  @Expose()
  id: string;

  @Expose()
  nome: string;

  @Expose()
  cidade: string;

  @Expose()
  estado: UF;

  @Expose()
  loteQuantidade: number;

  @Expose()
  vendaQuantidade: number;

  @Expose()
  status: ProjetoStatus;

  @Expose()
  vendaStatus: ProjetoVendaStatus;

  @Expose()
  tipo: ProjetoTipo;

  @Expose()
  averbacao?: boolean;

  @Expose()
  modalidade?: ProjetoModalidadeType;

  /**
   * Cria uma nova instância de ProjetoListarDto a partir de dtos
   * @param props DTO de ProjetoListarDto
   * @returns nova instância de ProjetoListarDto
   */
  static from(props?: ProjetoDto | Partial<ProjetoDto>): ProjetoListarDto {
    return plainToInstance(ProjetoListarDto, props, { exposeUnsetFields: false, excludeExtraneousValues: true });
  }
}
